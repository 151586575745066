import { connect } from 'react-redux';
import { Button, Icon, Table } from 'semantic-ui-react';
import { useRenderingFunctions } from '~/components/SmartField/hooks/useRenderingFunctions';
import { SparePartPricing } from '~/store/price-list/types';
import { parsePrice } from '~/utils/parsePrice';
import { roundQuantity } from '~/utils/roundQuantity';
import { TBackorderColumn } from './Lines';
import { MIN_VALUE, NUMBER_VALUES, REQUIRED } from '~/components/SmartField/lib/validator';
import { Backorder, BackorderLine } from '~/store/backorder/types';

import React, { useEffect, useState } from 'react';
import SmartWrapper from '~/components/SmartField/SmartWrapper';
import UberSearch from '~/components/UberSearch/UberSearch';
import dictionariesStatic from '~/store/dictionaries/static';
import CodingCell from './CodingCell';
import InvoiceErpDetails from '~/pages/Accounting/Details/InvoiceErp';
import GoodsReceivedNotesDetails from '~/pages/Accounting/Details/GoodsReceivedNotes';
import { Link } from 'react-router-dom';

type TReduxActions = {};

type TCompProps = {
  backorder: Backorder;
  loading: boolean;
  columns: TBackorderColumn[];
  lineIn: BackorderLine;
  isNew: boolean;
  editMode: boolean;
  addCodingHandler: (line: BackorderLine) => void;
  replacementsHandler: (product: SparePartPricing) => void;
  saveNewLineHandler: (referenceId: string) => void;
  removeLineHandler: (line: BackorderLine) => void;
};

type TBackorderLineProps = TCompProps;

const BackorderItem: React.FC<TBackorderLineProps> = ({
  backorder,
  loading,
  columns,
  lineIn,
  isNew,
  editMode,
  addCodingHandler,
  replacementsHandler,
  saveNewLineHandler,
  removeLineHandler,
}) => {
  const [line, setLine] = useState(lineIn);

  useEffect(() => {
    setLine(lineIn);
  }, [lineIn]);

  const { renderNumber, renderSelect } = useRenderingFunctions(line, editMode);

  const handleRemoveLine = () => {
    removeLineHandler(line);
  };

  if (isNew) {
    const handleAddNewLine = (selected: any): string => {
      const product = selected.product;
      if (product.orderLineType === 'REPLACED' && product.replacements) {
        replacementsHandler(product);
      } else {
        saveNewLineHandler(selected.key);
      }
      return '';
    };

    const mapProducts = (products: SparePartPricing[]): any[] => {
      return products.map((product) => ({
        key: product.referenceId,
        title: product.description,
        price: product.referenceId,
        product: product,
      }));
    };

    return (
      <Table.Row>
        <Table.Cell>{line.number}</Table.Cell>
        <Table.Cell colSpan="2">
          <UberSearch
            fluid
            input={{ placeholder: 'Wyszukiwanie referencji' }}
            endpoint="/spare-parts-price-list"
            queryParams={[
              {
                name: 'supplier',
                value: backorder.supplier,
              },
            ]}
            mapper={(response) => mapProducts(response.slice(0, 10))}
            onResultSelect={handleAddNewLine}
          />
        </Table.Cell>
        <Table.Cell colSpan="9" style={{ borderLeft: 'none' }} />
        <Table.Cell textAlign="center">
          <Button basic compact circular icon="delete" onClick={handleRemoveLine} disabled={loading || !editMode} />
        </Table.Cell>
      </Table.Row>
    );
  }

  const handleLineUpdate = (line: BackorderLine, response: any): void => {
    setLine(line);
  };

  const listConfirmedDocuments = (confirmedDocuments: string[]): JSX.Element[] => {
    return confirmedDocuments.map((document, index) => {
      return (
        <InvoiceErpDetails noteId={document} key={index} closeHandler={() => {}}>
          <Icon.Group size="large" style={{ marginLeft: 10, cursor: 'pointer' }} title="Podgląd dokumentu źródłowego">
            <Icon name="circle outline" fitted />
            <Icon name="info" size="mini" fitted />
          </Icon.Group>
        </InvoiceErpDetails>
      );
    });
  };

  const listReceivedDocuments = (receivedDocuments: string[]): JSX.Element[] => {
    return receivedDocuments.map((document, index) => {
      return (
        <GoodsReceivedNotesDetails noteId={document} key={index}>
          <Icon.Group size="large" style={{ marginLeft: 10, cursor: 'pointer' }} title="Podgląd PZ">
            <Icon name="circle outline" fitted />
            <Icon name="info" size="mini" fitted />
          </Icon.Group>
        </GoodsReceivedNotesDetails>
      );
    });
  };

  const listReceptionDocuments = (receptionDocuments: string[]): JSX.Element[] => {
    return receptionDocuments.map((document, index) => {
      return (
        <GoodsReceivedNotesDetails noteId={document} key={index}>
          <Icon.Group size="large" style={{ marginLeft: 10, cursor: 'pointer' }} title="Podgląd PZ">
            <Icon name="circle outline" fitted />
            <Icon name="info" size="mini" fitted />
          </Icon.Group>
        </GoodsReceivedNotesDetails>
      );
    });
  };

  const getCell = (column: TBackorderColumn): JSX.Element => {
    switch (column.name) {
      case 'codes':
        return (
          <Table.Cell key={column.name}>
            <CodingCell
              line={line}
              loading={loading}
              addCodingHandler={addCodingHandler}
              editMode={editMode}
              disablePopup
            />
          </Table.Cell>
        );
      case 'supplier':
        return (
          <Table.Cell key={column.name}>{renderSelect('supplier', dictionariesStatic.SPARE_PART_SUPPLIER)}</Table.Cell>
        );
      case 'quantity':
        return (
          <Table.Cell key={column.name}>
            {renderNumber('quantity', {
              style: { width: 95 },
              step: line.packagingQuantity,
              saveMapper: (value) => roundQuantity(value, line.packagingQuantity),
              validators: [REQUIRED, NUMBER_VALUES, MIN_VALUE(line.packagingQuantity ? line.packagingQuantity : 1)],
              readOnly: backorder.method === 'CROSS_DOCK',
            })}
          </Table.Cell>
        );
      case 'price':
        return <Table.Cell key={column.name}>{parsePrice(line[column.name])}</Table.Cell>;
      case 'referenceId':
        return (
          <Table.Cell key={column.name}>
            <Link to={`/product/${line[column.name]}`}>{line[column.name]}</Link>
          </Table.Cell>
        );
      case 'confirmedQuantity':
        return (
          <Table.Cell key={column.name} textAlign="right">
            {line[column.name]}
            {line.confirmedDocuments.length > 0 ? listConfirmedDocuments(line.confirmedDocuments || []) : <></>}
          </Table.Cell>
        );
      case 'receivedQuantity':
        return (
          <Table.Cell key={column.name} textAlign="right">
            {line[column.name]}
            {line.receivedDocuments.length > 0 ? listReceivedDocuments(line.receivedDocuments || []) : <></>}
          </Table.Cell>
        );
      case 'receptionQuantity':
        return (
          <Table.Cell key={column.name} textAlign="right">
            {line[column.name]}
            {line.receptionDocuments.length > 0 ? listReceptionDocuments(line.receptionDocuments || []) : <></>}
          </Table.Cell>
        );
    }
    return <Table.Cell key={column.name}>{line[column.name]}</Table.Cell>;
  };

  return (
    <SmartWrapper
      key={line.number}
      endpoint={`/stock/backorders/${backorder.id}/lines/${line.number}`}
      model={line}
      onUpdate={handleLineUpdate}
    >
      <Table.Row key={line.number}>
        {columns.map(getCell)}
        {editMode && (
          <Table.Cell textAlign="center">
            <Button basic compact circular icon="delete" onClick={handleRemoveLine} disabled={loading || !editMode} />
          </Table.Cell>
        )}
      </Table.Row>
    </SmartWrapper>
  );
};

const mapDispatchToProps: TReduxActions = {};

export default connect(null, mapDispatchToProps)(BackorderItem);
