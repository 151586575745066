import { Form, FormFieldProps } from 'semantic-ui-react'
import { SmartPopup } from './SmartPopup'
import { TBaseProps } from './lib/types'
import { FormInputNumber } from '~/components/InputNumber/InputNumber'
import React from 'react'
import './Style.scss'
import { useLabelFormatter } from "~/components/SmartField/hooks/useLabelFormatter";
import { useSmartFieldLogic } from "~/components/SmartField/hooks/useSmartFieldLogic";
import { getFieldIcon } from "~/components/SmartField/lib/utils";

/**
 * Base TextField component with automatic saving.
 */
export const SmartField: React.FC<TBaseProps> = (props) => {

    const {
        currentValue,
        modified,
        loading,
        success,
        error,
        handleEdit,
        handleSave,
        handleKeyPress,
        getPopupContent,
        isRequired
    } = useSmartFieldLogic(props);

    const handleSpecificEdit = (ev: any, el: FormFieldProps): void => {
        handleEdit(el.value)
    }

    const { formatLabel } = useLabelFormatter(props)

    if (props.type === 'number') {
        return <SmartPopup
            description={getPopupContent()}
        >
            <FormInputNumber
                fluid
                required={isRequired}
                style={props.style}
                className="super-field"
                label={formatLabel()}
                value={currentValue}
                onChange={handleSpecificEdit}
                onBlur={handleSave}
                onKeyPress={handleKeyPress}
                loading={loading}
                error={!!error}
                icon={getFieldIcon(error, modified, success)}
                width={props.width}
                disabled={props.disabled}
                readOnly={props.readOnly}
                min={props.min}
                step={props.step ? props.step : 1}
                precision={props.precision}
            />
        </SmartPopup>
    }
    return (
        <SmartPopup
            description={getPopupContent()}
        >
            <Form.Input
                fluid
                input={{ type: props.type || "text" }}
                required={isRequired}
                style={props.style}
                className="super-field"
                label={formatLabel()}
                value={currentValue}
                onChange={handleSpecificEdit}
                onBlur={handleSave}
                onKeyPress={handleKeyPress}
                loading={loading}
                error={!!error}
                icon={getFieldIcon(error, modified, success)}
                width={props.width}
                disabled={props.disabled}
                readOnly={props.readOnly}
                min={props.min}
                step={props.step ? props.step : 1}
            />
        </SmartPopup>
    )
}
