import { MessageValue } from 'react-intl'
import { iconType, toastr } from 'react-redux-toastr'

import translations from './translations'

export function toastError(title: string, message: string, args?: { [key: string]: MessageValue }): void {
    lightToast(title, message, args, "error", 0)
}

export function toastWarn(title: string, message: string, args?: { [key: string]: MessageValue }): void {
    lightToast(title, message, args, "warning", 0)
}

export function toastInfo(title: string, message: string, args?: { [key: string]: MessageValue }): void {
    lightToast(title, message, args, "info")
}

export function toastSuccess(title: string, message: string, args?: { [key: string]: MessageValue }): void {
    lightToast(title, message, args, "success")
}

function lightToast(title: string, message: string, args = {}, type: iconType, timeOut = 4000): void {
    toastr.light(
        translations.format(title, args),
        translations.format(message, args),
        {
            status: type,
            icon: type,
            removeOnHover: timeOut !== 0,
            timeOut
        }
    )
}
