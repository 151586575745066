import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { Tab } from "semantic-ui-react";
import { localStorageService } from "~/services/LocalStorage";
import { ApplicationState } from "~/store";
import { exportFP0536ToCsv, fetchUsers } from "~/store/users/actions";
import { DictionaryName } from "~/store/dictionaries/types";
import {
  fetchDictionariesNames,
  fetchDictionariesNamesDetails,
} from "~/store/dictionaries/actions";
import { exportUserListToXls } from "~/store/users/actions";
import React, { Fragment, PureComponent, SyntheticEvent } from "react";
import AddUser from "./lib/AddUser";
import AddDictionaryItem from "./lib/Dictionaries/Add";
import Speeder from "./lib/Speeder/Speeder";
import PageHeader from "~/components/PageHeader/PageHeader";
import ImportFactorLimits from "~/pages/Administration/lib/LimitImport/ImportFactorLimits";
import {
  AppProps,
  BREADCRUMB_ID,
  COLUMNS,
  State,
  storageUsersColumns,
} from "~/pages/Administration/constants";
import UserTableWrapper from "~/pages/Administration/lib/UserTableWrapper";
import Dictionaries from "~/pages/Administration/lib/Dictionaries";
import { AppContext } from "~/context/AuthContext";
import { AVAILABLE_ROLE_ENUM } from "~/store/users/types";
import HazardousGoods from "./lib/HazardousGoods/HazardousGoods";
import translations from "~/utils/translations";

class Users extends PureComponent<AppProps, State> {
  private userHaveLdcUiAdministrationViewRole =
    this.context?.keycloak?.hasResourceRole(
      AVAILABLE_ROLE_ENUM.LDC_UI_ADMINISTRATION_VIEW
    );

  private userHaveLdcUiAdministrationUsersRole =
    this.context?.keycloak?.hasResourceRole(
      AVAILABLE_ROLE_ENUM.LDC_UI_ADMINISTRATION_USERS
    );

  private userHaveLdcUiAdministrationDictRole =
    this.context?.keycloak?.hasResourceRole(
      AVAILABLE_ROLE_ENUM.LDC_ADMINISTRATION_DICT
    );

  private userHaveLdcUiAdministrationSpeederRole =
    this.context?.keycloak?.hasResourceRole(
      AVAILABLE_ROLE_ENUM.LDC_ADMINISTRATION_SPEEDER
    );

  private userHaveLdcUiAdministrationImportFactorLimitRole =
    this.context?.keycloak?.hasResourceRole(
      AVAILABLE_ROLE_ENUM.LDC_ADMINISTRATION_IMPORT_FACTOR_LIMIT
    );

  private getBreadcrumbs = () => [
    {
      id: BREADCRUMB_ID.users,
      text: "Użytkownicy",
      clickAction: () => this.clickAction(BREADCRUMB_ID.users),
      visible: this.userHaveLdcUiAdministrationViewRole,
    },
    {
      id: BREADCRUMB_ID.dict,
      text: "Słowniki",
      clickAction: () => this.clickAction(BREADCRUMB_ID.dict),
      visible: this.userHaveLdcUiAdministrationViewRole,
    },
    {
      id: BREADCRUMB_ID.speeder,
      text: "Speeder",
      clickAction: () => this.clickAction(BREADCRUMB_ID.speeder),
      visible: this.userHaveLdcUiAdministrationSpeederRole,
    },
    {
      id: BREADCRUMB_ID.import,
      text: "Import limitów faktora",
      clickAction: () => this.clickAction(BREADCRUMB_ID.import),
      visible: this.userHaveLdcUiAdministrationImportFactorLimitRole,
    },
    {
      id: BREADCRUMB_ID.hazardousGoods,
      text: "Części niebezpieczne",
      clickAction: () => this.clickAction(BREADCRUMB_ID.hazardousGoods),
      visible: this.userHaveLdcUiAdministrationViewRole,
    },
  ];

  state: State = {
    columns: COLUMNS,
    modalOpen: false,
    confirmType: "",
    actionId: "",
    actionUserName: "",
    request: false,
    tabIndex: 0,
    name: "",
    openAddUserModal: false,
    openAddDictionaryItemModal: false,
    breadcrumbs: this.getBreadcrumbs(),
  };

  componentDidMount(): void {
    document.title = translations.format("LDC_ADMINISTRATION")
    this.props.fetchUsers();
    this.props.fetchDictionariesNames();
    this.setColumnsFromLocalStorage();
  }

  componentDidUpdate(prevProps: AppProps, prevState: State): void {
    if (this.state.request && !this.props.loading && prevProps.loading) {
      this.props.fetchUsers();
      this.setState({ request: false });
    }

    if (this.state.tabIndex !== prevState.tabIndex) {
      this.refresh();
    }
  }

  private createdHandle = (): void => {
    this.props.fetchUsers();
  };

  private setColumnsFromLocalStorage(): void {
    const columnsLS: storageUsersColumns[] =
      localStorageService.get("usersColumns");

    if (columnsLS) {
      let { columns } = this.state;

      for (const columnFilter of columnsLS) {
        columns = columns.map((column) =>
          column.name === columnFilter.name
            ? {
                ...column,
                projection: columnFilter.shouldDisplay,
              }
            : { ...column }
        );
      }

      this.setState({ columns });
    }
  }

  private changeState = (newState: State) => {
    this.setState(newState);
  };

  private renderUsers = (): JSX.Element => (
    <UserTableWrapper state={this.state} setState={this.changeState} />
  );

  private changeName = (event: SyntheticEvent, data: any): void => {
    this.setState({ name: data.value });
    this.props.fetchDictionariesNamesDetails(data.value);
  };

  private renderDictionaries = (): JSX.Element => {
    return (
      <Dictionaries
        changeName={this.changeName}
        name={this.state.name}
        refresh={this.refresh}
      />
    );
  };

  private renderSpeeder = (): JSX.Element => {
    return <Speeder />;
  };

  private renderImportFactorLimit = (): JSX.Element => {
    return <ImportFactorLimits />;
  };

  private renderHazardousGoods = (): JSX.Element => {
    return <HazardousGoods exportFP0536ToCsv={this.props.exportFP0536ToCsv} loading={this.props.downloadFP0536pending}/>;
  };

  private getPanes = () => {
    const panes = [
      {
        menuItem: "Użytkownicy",
        render: this.renderUsers,
        visible: this.userHaveLdcUiAdministrationViewRole,
      },
      {
        menuItem: "Słowniki",
        render: this.renderDictionaries,
        visible: this.userHaveLdcUiAdministrationViewRole,
      },
      {
        menuItem: "Speeder",
        render: this.renderSpeeder,
        visible: this.userHaveLdcUiAdministrationSpeederRole,
      },
      {
        menuItem: "Import limitów faktora",
        render: this.renderImportFactorLimit,
        visible: this.userHaveLdcUiAdministrationImportFactorLimitRole,
      },
      {
        menuItem: "renderHazardousGoods",
        render: this.renderHazardousGoods,
        visible: this.userHaveLdcUiAdministrationViewRole,
      },
    ];

    return panes.filter((item) => item.visible);
  };

  private changeTab = (event: SyntheticEvent, data: any): void => {
    this.setState({ tabIndex: data.activeIndex });
  };

  private refresh = (): void => {
    switch (this.state.tabIndex) {
      case 0:
        this.props.fetchUsers();
        break;
      case 1:
        this.props.fetchDictionariesNames();
        this.state.name !== "" &&
          this.props.fetchDictionariesNamesDetails(this.state.name);
        break;
      default:
        break;
    }
  };

  private openModals = (modal: string): void => {
    switch (modal) {
      case "user":
        this.setState(
          {
            openAddUserModal: true,
          },
          () => {
            this.setState({
              openAddUserModal: false,
            });
          }
        );
        break;
      case "dictionary":
        this.setState(
          {
            openAddDictionaryItemModal: true,
          },
          () => {
            this.setState({
              openAddDictionaryItemModal: false,
            });
          }
        );
        break;
      default:
        break;
    }
  };

  private refreshActions = () =>
    [2, 3].includes(this.state.tabIndex)
      ? undefined
      : () => {
          this.refresh();
        };

  private downloadFile = () => {
    this.props.exportUserListToXls("Lista użytkowników");
  };

  private clickAction = (id: BREADCRUMB_ID) => {
    this.setState((prevState) => {
      const activeItems = prevState.breadcrumbs.filter((item) => item.visible);

      const foundIndex = activeItems.findIndex((item) => item.id === id);

      return {
        ...prevState,
        tabIndex: foundIndex !== -1 ? foundIndex : prevState.tabIndex,
      };
    });
  };

  render() {
    return (
      <Fragment>
        <PageHeader
          icon="settings"
          title="Administracja"
          breadcrumb={this.state.breadcrumbs}
          buttons={[
            {
              icon: "download",
              content: <FormattedMessage id="app.button.exportData" />,
              onClick: this.downloadFile,
              primary: true,
              visible: this.state.tabIndex === 0,
            },
            {
              icon: "add",
              content: <FormattedMessage id="app.button.add" />,
              onClick: () => this.openModals("user"),
              primary: true,
              visible: this.state.tabIndex === 0,
            },
            {
              icon: "add",
              content: <FormattedMessage id="app.button.add" />,
              onClick: () => this.openModals("dictionary"),
              primary: true,
              visible:
                this.state.tabIndex === 1 &&
                this.userHaveLdcUiAdministrationDictRole,
              disabled: this.state.name === "",
            },
          ]}
          refreshAction={this.refreshActions()}
          breadCrumbTab={this.state.tabIndex}
          loading={this.props.loading}
        />
        {this.userHaveLdcUiAdministrationUsersRole && (
          <AddUser
            triggerOpen={this.state.openAddUserModal}
            createdSuccess={this.createdHandle}
          />
        )}
        <AddDictionaryItem
          triggerOpen={this.state.openAddDictionaryItemModal}
          name={this.state.name as DictionaryName}
          createdSuccess={this.refresh}
          details={this.props.dictionaries.details}
        />
        <Tab
          className="hide-tab-menu"
          menu={{ secondary: true, pointing: true }}
          panes={this.getPanes()}
          onTabChange={this.changeTab}
          activeIndex={this.state.tabIndex}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = ({ users, dictionaries }: ApplicationState) => ({
  users: users.list,
  loading: users.loadingUsers,
  downloadFP0536pending: users.downloadFP0536pending,
  dictionaries,
});

const mapDispatchToProps = {
  fetchUsers,
  fetchDictionariesNames,
  fetchDictionariesNamesDetails,
  exportUserListToXls,
  exportFP0536ToCsv
};

Users.contextType = AppContext;

export default connect(mapStateToProps, mapDispatchToProps)(Users);
