import { Icon, Popup, Table } from 'semantic-ui-react';
import UberSearch from '~/components/UberSearch/UberSearch';
import React from 'react';
import { SparePartPricing } from '~/store/price-list/types';
import { ReferenceTableCellProps } from '~/pages/Orders/lib/Add/Table/TableBody/constants';

const ReferenceTableCell = ({ line, summary, changeReferenceId }: ReferenceTableCellProps) => {
  const mapProducts = (products: SparePartPricing[]): any[] => {
    return products.map((product) => ({
      product,
      key: product.referenceId,
      price: `${product.pvpPrice.toFixed(2)} PLN`,
      title: product.referenceId,
      description: product.description,
    }));
  };

  return (
    <Table.Cell style={{ overflow: 'visible', maxWidth: 'none' }}>
      <div style={{ width: 200, display: 'inline-block' }}>
        {line.orderLineType === 'REPLACEMENT' && <Icon name="recycle" color="grey" style={{ marginRight: 5 }} />}
        {line.orderLineType === 'REPLACEMENT_ORIGINAL' && <Icon name="undo" color="grey" style={{ marginRight: 5 }} />}
        {!!line.provisionReferenceId && (
          <Icon name="money bill alternate outline" color="grey" style={{ marginRight: 10 }} />
        )}
        {!summary &&
        line.orderLineType !== 'PROVISION' &&
        line.orderLineType !== 'REPLACEMENT' &&
        line.orderLineType !== 'REPLACEMENT_ORIGINAL' ? (
          <UberSearch
            key={line.referenceId}
            fluid
            style={{ display: 'inline-block' }}
            aligned="left"
            endpoint="/spare-parts-price-list"
            mapper={(response) => mapProducts(response.slice(0, 10))}
            onResultSelect={(selected: any) => changeReferenceId(line.lineNumber, selected)}
            value={line.referenceId}
          />
        ) : (
          line.referenceId
        )}
        {line.orderLineType === 'REPLACEMENT' && (
          <Popup
            content={`Zamiennik dla ${line.replacementReferenceId}`}
            trigger={<Icon name="info circle" color="grey" size="large" fitted style={{ float: 'right' }} />}
          />
        )}
        {line.orderLineType === 'REPLACEMENT_ORIGINAL' && (
          <Popup
            content={`Poprzednik dla ${line.replacementReferenceId}`}
            trigger={<Icon name="info circle" color="grey" size="large" fitted style={{ float: 'right' }} />}
          />
        )}
        {!!line.provisionReferenceId && (
          <Popup
            content={`Dodana kaucja dla ${line.provisionReferenceId}`}
            trigger={<Icon name="info circle" color="grey" size="large" fitted style={{ float: 'right' }} />}
          />
        )}
      </div>
    </Table.Cell>
  );
};

export default ReferenceTableCell;
