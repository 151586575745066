import { Reducer } from 'redux'
import { ErrorActionTypes } from '~/store/error/types'
import {
    InvoicesActionTypes,
    InvoicesState,
    PaginableGoodsDispatchNotes,
    PaginableGoodsReceivedNotes,
    PaginableInternalConsumptionNotes,
    PaginableGoodsDispatchNotePostponed,
    PaginableInvoice,
    PaginableInvoiceErp,
    PaginableICollectiveCorrections,
    PaginableICollectiveDebitNoteCorrections,
    PaginableIInvoiceLines,
    IInvoiceSchedulers,
    IInvoiceLinesSchedulers,
    PaginableIMM
} from "./types"

export const initialInvoicesState: InvoicesState = {
    loadingInvoices: false,
    loadingInvoice: false,
    list: [],
    totalRecords: {
        total: 0,
        filtered: 0
    },
    meta: {
        page: 1,
        size: 15,
        pages: 1
    },
    corrections: {
        list: [],
        meta: {
            page: 1,
            size: 15,
            pages: 1
        },
        loading: false,
        totalRecords: {
            total: 0,
            filtered: 0
        }
    },
    goodsDispatchNotes: {
        list: [],
        meta: {
            page: 1,
            size: 15,
            pages: 1
        },
        loading: false,
        totalRecords: {
            total: 0,
            filtered: 0
        }
    },
    goodsReceivedNotes: {
        list: [],
        meta: {
            page: 1,
            size: 15,
            pages: 1
        },
        loading: false,
        totalRecords: {
            total: 0,
            filtered: 0
        }
    },
    internalConsumptionNote: {
        list: [],
        meta: {
            page: 1,
            size: 15,
            pages: 1
        },
        loading: false,
        totalRecords: {
            total: 0,
            filtered: 0
        }
    },
    invoicesErp: {
        list: [],
        meta: {
            page: 1,
            size: 15,
            pages: 1
        },
        loading: false,
        totalRecords: {
            total: 0,
            filtered: 0
        }
    },
    goodsDispatchNotePostponed: {
        list: [],
        meta: {
            page: 1,
            size: 15,
            pages: 1
        },
        loading: false,
        totalRecords: {
            total: 0,
            filtered: 0
        }
    },
    collectiveCorrectionsList: {
        list: [],
        meta: {
            page: 1,
            size: 15,
            pages: 1
        },
        loading: false,
        totalRecords: {
            total: 0,
            filtered: 0
        }
    },
    mmDataResponse: {
        list: [],
        meta: {
            page: 1,
            size: 15,
            pages: 1
        },
        loading: false,
        totalRecords: {
            total: 0,
            filtered: 0
        }
    },
    collectiveDebitNoteCorrections: {
        list: [],
        meta: {
            page: 1,
            size: 15,
            pages: 1
        },
        loading: false,
        totalRecords: {
            total: 0,
            filtered: 0
        }
    },
    invoicesWithProvision: {
        list: [],
        loading: false
    },
    debitNotes: {
        list: [],
        meta: {
            page: 1,
            size: 15,
            pages: 1
        },
        loading: false,
        totalRecords: {
            total: 0,
            filtered: 0
        }
    },
    debitNotesCorrections: {
        list: [],
        meta: {
            page: 1,
            size: 15,
            pages: 1
        },
        loading: false,
        totalRecords: {
            total: 0,
            filtered: 0
        }
    },
    invoicesWithDebitNotes: {
        list: [],
        meta: {
            page: 1,
            size: 15,
            pages: 1
        },
        loading: false,
        totalRecords: {
            total: 0,
            filtered: 0
        }
    },
    invoiceLines: {
        list: [],
        meta: {
            page: 1,
            size: 15,
            pages: 1
        },
        loading: false,
        totalRecords: {
            total: 0,
            filtered: 0
        }
    },
    gdnPositionsSchedulers: {
        stock: undefined,
        turboPro: undefined,
        urgent: undefined,
        loading: false
    },
    invoiceLinesSchedulers: {
        stock: undefined,
        turboPro: undefined,
        urgent: undefined,
        loading: false
    },
    created: false,
    creating: false,
    selectedFinalInvoice: undefined,
    collectiveCorrections: [],
    loadingCollectiveCorrections: false,
    loadingConfirmCollectiveCorrections: false,
    createdCollectiveCorretion: false,
    loadingCollectiveCorretions: false,
    loadingCollectiveDebitNoteCorrections: false,
    loadingDebitNotes: false,
    loadingDebitNotesCorrections: false,
    collectiveCorrectionsErrorBody: undefined,
    loadingInvoicesWithDebitNotes: false,
    loadingInvoiceLines: false,
    isOpenChooseTypeOfExportDataModal: false,
    selectedTypeOfDataToExport: undefined,
    quantityRedux: undefined,
    loadingGoodsDispatchNotePostponed: false,
    loadingMM: false
}

const reducer: Reducer<InvoicesState> = (state = initialInvoicesState, action): InvoicesState => {
    switch (action.type) {
        case InvoicesActionTypes.FETCH_INVOICES: {
            return { ...state, loadingInvoices: true }
        }
        case InvoicesActionTypes.FETCH_INVOICES_SUCCESS: {
            const invoices = action.payload.invoices as PaginableInvoice
            return {
                ...state,
                loadingInvoices: false,
                list: invoices.content,
                meta: { page: invoices.number + 1, pages: invoices.totalPages, size: invoices.size },
                totalRecords: { total: invoices.totalElements, filtered: invoices.filteredElements }
            }
        }
        case InvoicesActionTypes.FETCH_INVOICE_LINES: {
            return {
                ...state,
                invoiceLines: { ...state.invoiceLines, loading: true },
                loadingInvoiceLines: true
            }
        }
        case InvoicesActionTypes.FETCH_INVOICE_LINES_SUCCESS: {
            const invoiceLines = action.payload.invoiceLine as PaginableIInvoiceLines
            return {
                ...state,
                invoiceLines: {
                    loading: false,
                    list: invoiceLines.content,
                    meta: { page: invoiceLines.number + 1, pages: invoiceLines.totalPages, size: invoiceLines.size },
                    totalRecords: { total: invoiceLines.totalElements, filtered: invoiceLines.filteredElements }
                },
                loadingInvoiceLines: false
            }
        }
        case InvoicesActionTypes.FETCH_INVOICE_SCHEDULERS: {
            return { ...state, gdnPositionsSchedulers: { ...state.gdnPositionsSchedulers, loading: true } }
        }
        case InvoicesActionTypes.FETCH_INVOICE_SCHEDULERS_SUCCESS: {
            const schedulers = action.payload.schedulers as IInvoiceSchedulers
            return {
                ...state,
                gdnPositionsSchedulers: {
                    stock: schedulers.stock,
                    turboPro: schedulers.turboPro,
                    urgent: schedulers.urgent,
                    loading: false,
                },
                loadingInvoice: false
            }
        }
        case InvoicesActionTypes.FETCH_INVOICE_LINES_SCHEDULERS: {
            return { ...state, invoiceLinesSchedulers: { ...state.invoiceLinesSchedulers, loading: true } }
        }
        case InvoicesActionTypes.FETCH_INVOICE_LINES_SCHEDULERS_SUCCESS: {
            const schedulers = action.payload.schedulers as IInvoiceLinesSchedulers
            return {
                ...state,
                invoiceLinesSchedulers: {
                    stock: schedulers.stock,
                    turboPro: schedulers.turboPro,
                    urgent: schedulers.urgent,
                    loading: false,
                },
                loadingInvoiceLines: false
            }
        }
        case InvoicesActionTypes.FETCH_INVOICES_WITH_PROVISION: {
            return { ...state, invoicesWithProvision: { ...state.invoicesWithProvision, loading: true } }
        }
        case InvoicesActionTypes.FETCH_INVOICES_WITH_PROVISION_SUCCESS: {
            return {
                ...state,
                invoicesWithProvision: { list: action.payload.invoicesWithProvision.content, loading: false }
            }
        }
        case InvoicesActionTypes.FETCH_INVOICES_CORRECTIONS: {
            return { ...state, corrections: { ...state.corrections, loading: true } }
        }
        case InvoicesActionTypes.FETCH_INVOICES_CORRECTIONS_SUCCESS: {
            const correction = action.payload.correction as PaginableInvoice
            return {
                ...state,
                corrections: {
                    loading: false,
                    list: correction.content,
                    meta: { page: correction.number + 1, pages: correction.totalPages, size: correction.size },
                    totalRecords: { total: correction.totalElements, filtered: correction.filteredElements }
                }
            }
        }
        case InvoicesActionTypes.FETCH_GOODS_DISPATCH_NOTES: {
            return { ...state, goodsDispatchNotes: { ...state.goodsDispatchNotes, loading: true } }
        }
        case InvoicesActionTypes.FETCH_GOODS_DISPATCH_NOTES_SUCCESS: {
            const goodsDispatchNotes = action.payload.goodsDispatchNotes as PaginableGoodsDispatchNotes
            return {
                ...state,
                goodsDispatchNotes: {
                    loading: false,
                    list: goodsDispatchNotes.content,
                    meta: { page: goodsDispatchNotes.number + 1, pages: goodsDispatchNotes.totalPages, size: goodsDispatchNotes.size },
                    totalRecords: { total: goodsDispatchNotes.totalElements, filtered: goodsDispatchNotes.filteredElements }
                }
            }
        }
        case InvoicesActionTypes.FETCH_GOODS_RECEIVED_NOTES: {
            return { ...state, goodsReceivedNotes: { ...state.goodsReceivedNotes, loading: true } }
        }
        case InvoicesActionTypes.FETCH_GOODS_RECEIVED_NOTES_SUCCESS: {
            const goodsReceivedNotes = action.payload.goodsReceivedNotes as PaginableGoodsReceivedNotes
            return {
                ...state,
                goodsReceivedNotes: {
                    loading: false,
                    list: goodsReceivedNotes.content,
                    meta: { page: goodsReceivedNotes.number + 1, pages: goodsReceivedNotes.totalPages, size: goodsReceivedNotes.size },
                    totalRecords: { total: goodsReceivedNotes.totalElements, filtered: goodsReceivedNotes.filteredElements }
                }
            }
        }
        case InvoicesActionTypes.FETCH_INTERNAL_CONSUMPTION_NOTES: {
            return { ...state, internalConsumptionNote: { ...state.internalConsumptionNote, loading: true } }
        }
        case InvoicesActionTypes.FETCH_INTERNAL_CONSUMPTION_NOTES_SUCCESS: {
            const internalConsumptionNote = action.payload.internalConsumptionNote as PaginableInternalConsumptionNotes
            return {
                ...state,
                internalConsumptionNote: {
                    loading: false,
                    list: internalConsumptionNote.content,
                    meta: { page: internalConsumptionNote.number + 1, pages: internalConsumptionNote.totalPages, size: internalConsumptionNote.size },
                    totalRecords: { total: internalConsumptionNote.totalElements, filtered: internalConsumptionNote.filteredElements }
                }
            }
        }
        case InvoicesActionTypes.FETCH_INVOICES_ERP: {
            return { ...state, invoicesErp: { ...state.invoicesErp, loading: true } }
        }
        case InvoicesActionTypes.FETCH_GOOD_DISPATCH_NOTE_POSTPONED: {
            return {
                ...state,
                goodsDispatchNotePostponed: { ...state.goodsDispatchNotePostponed, loading: true },
                loadingGoodsDispatchNotePostponed: true
            }
        }
        case InvoicesActionTypes.UPLOAD_COLLECTIVE_CORRECTIONS: {
            return {
                ...state,
                loadingCollectiveCorrections: true
            }
        }
        case InvoicesActionTypes.LOAD_COLLECTIVE_CORRECTIONS: {
            return {
                ...state,
                collectiveCorrections: action.payload,
                loadingCollectiveCorrections: false,
                collectiveCorrectionsErrorBody: undefined
            }
        }
        case InvoicesActionTypes.CONFIRM_COLLECTIVE_CORRECTIONS: {
            return {
                ...state,
                loadingConfirmCollectiveCorrections: true
            }
        }
        case InvoicesActionTypes.CONFIRM_COLLECTIVE_CORRECTIONS_SUCCESS: {
            return {
                ...state,
                loadingConfirmCollectiveCorrections: false,
                createdCollectiveCorretion: true
            }
        }
        case InvoicesActionTypes.FETCH_COLLECTIVE_CORRECTIONS: {
            return {
                ...state,
                loadingCollectiveCorretions: true
            }
        }
        case InvoicesActionTypes.FETCH_COLLECTIVE_CORRECTIONS_SUCCESS: {
            const collectiveCorrections = action.payload.collectiveCorrections as PaginableICollectiveCorrections
            return {
                ...state,
                collectiveCorrectionsList: {
                    loading: false,
                    list: collectiveCorrections.content,
                    meta: { page: collectiveCorrections.number + 1, pages: collectiveCorrections.totalPages, size: collectiveCorrections.size },
                    totalRecords: { total: collectiveCorrections.totalElements, filtered: collectiveCorrections.filteredElements }
                },
                loadingCollectiveCorretions: false
            }
        }
        case InvoicesActionTypes.FETCH_DEBIT_NOTES: {
            return {
                ...state,
                loadingDebitNotes: true
            }
        }
        case InvoicesActionTypes.FETCH_DEBIT_NOTES_SUCCESS: {
            const debitNotes = action.payload.debitNotes as PaginableInvoice
            return {
                ...state,
                debitNotes: {
                    loading: false,
                    list: debitNotes.content,
                    meta: { page: debitNotes.number + 1, pages: debitNotes.totalPages, size: debitNotes.size },
                    totalRecords: { total: debitNotes.totalElements, filtered: debitNotes.filteredElements }
                },
                loadingDebitNotes: false
            }
        }
        case InvoicesActionTypes.FETCH_DEBIT_NOTE_CORRECTIONS: {
            return {
                ...state,
                loadingDebitNotesCorrections: true
            }
        }
        case InvoicesActionTypes.FETCH_DEBIT_NOTE_CORRECTIONS_SUCCESS: {
            const debitNotesCorrections = action.payload.debitNotesCorrections as PaginableInvoice
            return {
                ...state,
                debitNotesCorrections: {
                    loading: false,
                    list: debitNotesCorrections.content,
                    meta: { page: debitNotesCorrections.number + 1, pages: debitNotesCorrections.totalPages, size: debitNotesCorrections.size },
                    totalRecords: { total: debitNotesCorrections.totalElements, filtered: debitNotesCorrections.filteredElements }
                },
                loadingDebitNotesCorrections: false
            }
        }
        case InvoicesActionTypes.FETCH_INVOICES_WITH_DEBIT_NOTES: {
            return {
                ...state,
                loadingInvoicesWithDebitNotes: true
            }
        }
        case InvoicesActionTypes.FETCH_INVOICES_WITH_DEBIT_NOTES_SUCCESS: {
            const invoicesWithDebitNotes = action.payload.invoicesWithDebitNotes as PaginableInvoice
            return {
                ...state,
                invoicesWithDebitNotes: {
                    loading: false,
                    list: invoicesWithDebitNotes.content,
                    meta: { page: invoicesWithDebitNotes.number + 1, pages: invoicesWithDebitNotes.totalPages, size: invoicesWithDebitNotes.size },
                    totalRecords: { total: invoicesWithDebitNotes.totalElements, filtered: invoicesWithDebitNotes.filteredElements }
                },
                loadingInvoicesWithDebitNotes: false
            }
        }
        case InvoicesActionTypes.CLEAR_COLLECTIVE_CORRECTIONS: {
            return {
                ...state,
                collectiveCorrections: [],
                loadingCollectiveCorrections: false,
                createdCollectiveCorretion: false,
                collectiveCorrectionsErrorBody: undefined,
                loadingConfirmCollectiveCorrections: false
            }
        }
        case InvoicesActionTypes.LOAD_COLLECTIVE_CORRECTIONS_ERROR: {
            return {
                ...state,
                loadingCollectiveCorrections: false,
                collectiveCorrectionsErrorBody: action.payload
            }
        }
        case InvoicesActionTypes.FETCH_COLLECTIVE_DEBIT_NOTE_CORRECTIONS: {
            return {
                ...state,
                loadingCollectiveDebitNoteCorrections: true
            }
        }
        case InvoicesActionTypes.FETCH_COLLECTIVE_DEBIT_NOTE_CORRECTIONS_SUCCESS: {
            const collectiveDebitNoteCorrections = action.payload.collectiveDebitNoteCorrections as PaginableICollectiveDebitNoteCorrections
            return {
                ...state,
                collectiveDebitNoteCorrections: {
                    loading: false,
                    list: collectiveDebitNoteCorrections.content,
                    meta: { page: collectiveDebitNoteCorrections.number + 1, pages: collectiveDebitNoteCorrections.totalPages, size: collectiveDebitNoteCorrections.size },
                    totalRecords: { total: collectiveDebitNoteCorrections.totalElements, filtered: collectiveDebitNoteCorrections.filteredElements }
                },
                loadingCollectiveDebitNoteCorrections: false
            }
        }
        case InvoicesActionTypes.FETCH_INVOICES_ERP_SUCCESS: {
            const invoicesErp = action.payload.invoicesErp as PaginableInvoiceErp
            return {
                ...state,
                invoicesErp: {
                    loading: false,
                    list: invoicesErp.content,
                    meta: { page: invoicesErp.number + 1, pages: invoicesErp.totalPages, size: invoicesErp.size },
                    totalRecords: { total: invoicesErp.totalElements, filtered: invoicesErp.filteredElements }
                }
            }
        }
        case InvoicesActionTypes.FETCH_GOOD_DISPATCH_NOTE_POSTPONED_SUCCESS: {
            const goodsDispatchNotePostponed = action.payload.goodsDispatchNotePostponed as PaginableGoodsDispatchNotePostponed
            return {
                ...state,
                goodsDispatchNotePostponed: {
                    loading: false,
                    list: goodsDispatchNotePostponed.content,
                    meta: { page: goodsDispatchNotePostponed.number + 1, pages: goodsDispatchNotePostponed.totalPages, size: goodsDispatchNotePostponed.size },
                    totalRecords: { total: goodsDispatchNotePostponed.totalElements, filtered: goodsDispatchNotePostponed.filteredElements }
                },
                loadingGoodsDispatchNotePostponed: false
            }
        }
        case InvoicesActionTypes.FETCH_INVOICE_FINAL: {
            return { ...state }
        }
        case InvoicesActionTypes.CLEAR_FINAL_INVOICE: {
            return { ...state, selectedFinalInvoice: undefined }
        }
        case InvoicesActionTypes.FETCH_INVOICE_FINAL_SUCCESS: {
            return { ...state, selectedFinalInvoice: action.payload }
        }
        case InvoicesActionTypes.CREATE_INVOICE_CORRECTION:
        case InvoicesActionTypes.CREATE_INVOICE_TRANSPORT: {
            return { ...state, created: false, creating: true }
        }
        case InvoicesActionTypes.CREATE_INVOICE_CORRECTION_SUCCESS:
        case InvoicesActionTypes.CREATE_INVOICE_TRANSPORT_SUCCESS: {
            return { ...state, created: true, creating: false, selectedFinalInvoice: undefined }
        }
        case InvoicesActionTypes.CLEAR_CREATED: {
            return { ...state, created: false, creating: false }
        }
        case ErrorActionTypes.HANDLE_ERROR: {
            return { ...state, creating: false, loadingInvoices: false }
        }
        case InvoicesActionTypes.SELECT_GOODS_DISPATCH_NOTE: {
            return { ...state, goodsDispatchNotes: { ...state.goodsDispatchNotes, loading: true } }
        }
        case InvoicesActionTypes.GOODS_DISPATCH_NOTE_SELECTED: {
            return { ...state, goodsDispatchNotes: { ...state.goodsDispatchNotes, loading: false, selected: action.payload } }
        }
        case InvoicesActionTypes.SELECT_GOODS_RECEIVED_NOTE: {
            return { ...state, goodsReceivedNotes: { ...state.goodsReceivedNotes, loading: true } }
        }
        case InvoicesActionTypes.GOODS_RECEIVED_NOTE_SELECTED: {
            return { ...state, goodsReceivedNotes: { ...state.goodsReceivedNotes, loading: false, selected: action.payload } }
        }
        case InvoicesActionTypes.SELECT_INTERNAL_CONSUMPTION_NOTE: {
            return { ...state, internalConsumptionNote: { ...state.internalConsumptionNote, loading: true } }
        }
        case InvoicesActionTypes.INTERNAL_CONSUMPTION_NOTE_SELECTED: {
            return { ...state, internalConsumptionNote: { ...state.internalConsumptionNote, loading: false, selected: action.payload } }
        }
        case InvoicesActionTypes.FETCH_MM_DATA: {
            return {
                ...state,
                loadingMM: true
            }
        }
        case InvoicesActionTypes.FETCH_MM_DATA_SUCCESS: {
            const mmData = action.payload.mmData as PaginableIMM
            return {
                ...state,
                mmDataResponse: {
                    loading: false,
                    list: mmData.content,
                    meta: { page: mmData.number + 1, pages: mmData.totalPages, size: mmData.size },
                    totalRecords: { total: mmData.totalElements, filtered: mmData.filteredElements }
                },
                loadingMM: false
            }
        }
        case InvoicesActionTypes.SELECT_MM: {
            return { ...state, mmDataResponse: { ...state.mmDataResponse, loading: true } }
        }
        case InvoicesActionTypes.MM_SELECTED: {
            return { ...state, mmDataResponse: { ...state.mmDataResponse, loading: false, selected: action.payload } }
        }
        case InvoicesActionTypes.SELECT_INVOICE_ERP: {
            return { ...state, invoicesErp: { ...state.invoicesErp, loading: true } }
        }
        case InvoicesActionTypes.INVOICE_ERP_SELECTED: {
            return { ...state, invoicesErp: { ...state.invoicesErp, loading: false, selected: action.payload } }
        }
        case InvoicesActionTypes.SELECT_GOOD_DISPATCH_NOTE_POSTPONED: {
            return { ...state, goodsDispatchNotePostponed: { ...state.goodsDispatchNotePostponed, loading: true } }
        }
        case InvoicesActionTypes.GOOD_DISPATCH_NOTE_POSTPONED_SELECTED: {
            return { ...state, goodsDispatchNotePostponed: { ...state.goodsDispatchNotePostponed, loading: false, selected: action.payload } }
        }
        case InvoicesActionTypes.SET_TYPE_OF_EXPORT_DATA: {
            return { ...state, selectedTypeOfDataToExport: action.payload }
        }
        case InvoicesActionTypes.OPEN_CHOOSE_EXPORT_DATA_MODAL: {
            return { ...state, isOpenChooseTypeOfExportDataModal: action.payload }
        }
        case InvoicesActionTypes.SAVE_QUANTITY_TO_REDUX: {
            return { ...state, quantityRedux: action.payload }
        }
    }
    return state
}

export { reducer as invoicesReducer }
