import { TClaimLineColumn } from "../Lines";

export enum COLUMN_NAME {
  lineNumber = "lineNumber",
  referenceId = "referenceId",
  referenceName = "referenceName",
  toReceiveQuantity = "toReceiveQuantity",
  receivedQuantityFullValue = "receivedQuantityFullValue",
  receivedQuantityDefective = "receivedQuantityDefective",
  claimedQuantity = "claimedQuantity",
  statusNotes = "statusNotes",
  select = "select",
  acceptedQuantity = "acceptedQuantity",
  requestReturnQuantity = "requestReturnQuantity",
  rejectQuantity = "rejectQuantity",
  orderNumber = "orderNumber",
  status = "status",
  wmsComment = "wmsComment",
  statusDate = "statusDate",
  packageNumber = "packageNumber",
}

export const verificationColumns: TClaimLineColumn[] = [
  { name: COLUMN_NAME.lineNumber, label: "Nr", width: 50 },
  { name: COLUMN_NAME.referenceId, label: "Referencja" },
  { name: COLUMN_NAME.referenceName, label: "Nazwa referencji" },
  {
    name: COLUMN_NAME.claimedQuantity,
    label: "Ilość sztuk reklamowana",
    width: 50,
  },
  {
    name: COLUMN_NAME.acceptedQuantity,
    label: "Ilość sztuk do akceptacji",
    width: 50,
  },
  {
    name: COLUMN_NAME.requestReturnQuantity,
    label: "Ilość sztuk do zwrotu",
    width: 50,
  },
  {
    name: COLUMN_NAME.rejectQuantity,
    label: "Ilość sztuk do odrzucenia",
    width: 50,
  },
  { name: COLUMN_NAME.statusNotes, label: "Komentarz" },
  { name: COLUMN_NAME.select, label: "Edycja" },
];

export const decisionColumns: TClaimLineColumn[] = [
  { name: COLUMN_NAME.lineNumber, label: "Nr", width: 50 },
  { name: COLUMN_NAME.referenceId, label: "Referencja" },
  { name: COLUMN_NAME.referenceName, label: "Nazwa referencji" },
  { name: COLUMN_NAME.toReceiveQuantity, label: "Ilość do zwrotu", width: 50 },
  {
    name: COLUMN_NAME.receivedQuantityFullValue,
    label: "Ilość zwrócona pełnowartościowa",
    width: 50,
  },
  {
    name: COLUMN_NAME.receivedQuantityDefective,
    label: "Ilość zwrócona niepełnowartościowa ",
    width: 50,
  },
  {
    name: COLUMN_NAME.claimedQuantity,
    label: "Ilość sztuk reklamowana",
    width: 50,
  },
  { name: COLUMN_NAME.statusNotes, label: "Komentarz" },
  { name: COLUMN_NAME.select, label: "Edycja" },
];

export const editFormColumns: TClaimLineColumn[] = [
  { name: "akcja", label: "Akcja" },
  { name: "claimedQuantity", label: "Ilość sztuk reklamowana", width: 50 },
];

export enum ClaimTransitionAction {
  VERIFICATION = "VERIFICATION",
  DECISION = "DECISION",
  REFUND = "REFUND",
  RETURN_TO_SUPPLIER = "RETURN_TO_SUPPLIER",
  MARK_CLOSED_WITH_STOCKTAKING_REQUEST = "MARK_CLOSED_WITH_STOCKTAKING_REQUEST",
  MARK_CLOSED_WITH_TRANSPORTATION_FEE = "MARK_CLOSED_WITH_TRANSPORTATION_FEE",
  MARK_CLOSED = "MARK_CLOSED",
}
export enum FormCategory {
  RECEIVED_DEFECTIVE = "RECEIVED_DEFECTIVE",
  RECEIVED_FULL_VALUE = "RECEIVED_FULL_VALUE",
}

export enum ClaimTransitionLineActionType {
  VERIFICATION_ACCEPTED = "VERIFICATION_ACCEPTED",
  VERIFICATION_REQUEST_RETURN = "VERIFICATION_REQUEST_RETURN",
  VERIFICATION_REJECTED = "VERIFICATION_REJECTED",
  DECISION_ACCEPTED = "DECISION_ACCEPTED",
  DECISION_ACCEPTED_WITHOUT_CORRECTION = "DECISION_ACCEPTED_WITHOUT_CORRECTION",
  DECISION_TO_DESTROY = "DECISION_TO_DESTROY",
  DECISION_TO_DESTROY_WITHOUT_CORRECTION = "DECISION_TO_DESTROY_WITHOUT_CORRECTION",
  DECISION_REJECTED_TO_CLIENT = "DECISION_REJECTED_TO_CLIENT",
}

export const actionTypes = {
  VERIFICATION_ACCEPTED: "Akceptuj z korektą",
  VERIFICATION_REQUEST_RETURN: "Do zwrotu",
  VERIFICATION_REJECTED: "Odrzuć",
  DECISION_ACCEPTED: " Akceptuj z korektą",
  DECISION_ACCEPTED_WITHOUT_CORRECTION: "Akceptuj bez korekty",
  DECISION_TO_DESTROY: "Zniszcz z korektą",
  DECISION_TO_DESTROY_WITHOUT_CORRECTION: "Zniszcz bez korekty",
  DECISION_REJECTED_TO_CLIENT: "Odeślij do klienta",
};

export const optionsVerification = [
  {
    key: ClaimTransitionLineActionType.DECISION_ACCEPTED,
    value: ClaimTransitionLineActionType.DECISION_ACCEPTED,
    text: actionTypes.DECISION_ACCEPTED,
  },

  {
    key: ClaimTransitionLineActionType.VERIFICATION_REQUEST_RETURN,
    value: ClaimTransitionLineActionType.VERIFICATION_REQUEST_RETURN,
    text: actionTypes.VERIFICATION_REQUEST_RETURN,
  },

  {
    key: ClaimTransitionLineActionType.VERIFICATION_REJECTED,
    value: ClaimTransitionLineActionType.VERIFICATION_REJECTED,
    text: actionTypes.VERIFICATION_REJECTED,
  },
];

export const optionsDecision = [
  {
    key: ClaimTransitionLineActionType.DECISION_ACCEPTED,
    value: ClaimTransitionLineActionType.DECISION_ACCEPTED,
    text: actionTypes.DECISION_ACCEPTED,
  },
  {
    key: ClaimTransitionLineActionType.DECISION_ACCEPTED_WITHOUT_CORRECTION,
    value: ClaimTransitionLineActionType.DECISION_ACCEPTED_WITHOUT_CORRECTION,
    text: actionTypes.DECISION_ACCEPTED_WITHOUT_CORRECTION,
  },
  {
    key: ClaimTransitionLineActionType.DECISION_TO_DESTROY,
    value: ClaimTransitionLineActionType.DECISION_TO_DESTROY,
    text: actionTypes.DECISION_TO_DESTROY,
  },
  {
    key: ClaimTransitionLineActionType.DECISION_TO_DESTROY_WITHOUT_CORRECTION,
    value: ClaimTransitionLineActionType.DECISION_TO_DESTROY_WITHOUT_CORRECTION,
    text: actionTypes.DECISION_TO_DESTROY_WITHOUT_CORRECTION,
  },
  {
    key: ClaimTransitionLineActionType.DECISION_REJECTED_TO_CLIENT,
    value: ClaimTransitionLineActionType.DECISION_REJECTED_TO_CLIENT,
    text: actionTypes.DECISION_REJECTED_TO_CLIENT,
  },
];

// ACCEPT, allExcept(RK1_ZRPN, RK2_ZRNF),
// REQUEST_RETURN, allExcept(RK1_ZNRE, RK2_ZRNG),
// REJECT, allExcept(RK1_ZRPN)

// ACCEPT, ALL_ALLOWED,
// ACCEPT_WITHOUT_CORRECTION, allExcept(RK1_ZRPN),
// DESTROY, only(RK1_ZRDP, RK1_ZRPN, RK1_ZRVE),
// DESTROY_WITHOUT_CORRECTION, only(RK1_ZRDP, RK1_ZRPN, RK1_ZRVE),
// REJECT_TO_CLIENT, ALL_ALLOWED

// 'RK1_ZNRE' , 'RK1_ZRDP' , 'RK1_ZRVE' , 'RK1_ZRPN' ,     'RK2_ZRNG' , 'RK2_ZRNF'   ,'RK4_P1'

const ALL_ALLOWED = [
  "RK1_ZNRE",
  "RK1_ZRDP",
  "RK1_ZRVE",
  "RK1_ZRPN",
  "RK2_ZRNG",
  "RK2_ZRNF",
  "RK4_P1",
  "RK4_P2",
  "RK5_P1",
  "RK5_B1",
];

const allExcept = (...items: string[]) => {
  let newArray = [...ALL_ALLOWED];
  items.forEach((item) => {
    newArray = newArray.filter((f) => f !== item);
  });
  return newArray;
};

export const claimCategoriesToDisplayInputs: { [key: string]: any } = {
  VERIFICATION: {
    VERIFICATION_ACCEPTED: allExcept("RK1_ZRPN", "RK2_ZRNF"),
    VERIFICATION_REQUEST_RETURN: allExcept("RK1_ZNRE", "RK2_ZRNG"),
    VERIFICATION_REJECTED: allExcept("RK1_ZRPN"),
  },

  RECEIVED_FULL_VALUE: {
    DECISION_ACCEPTED: ALL_ALLOWED,
    DECISION_ACCEPTED_WITHOUT_CORRECTION: allExcept(
      "RK1_ZRPN",
      "RK1_ZRVE",
      "RK1_ZRDP"
    ),
    DECISION_TO_DESTROY: [],
    DECISION_TO_DESTROY_WITHOUT_CORRECTION: [],
    DECISION_REJECTED_TO_CLIENT: allExcept("RK1_ZRPN", "RK1_ZRVE", "RK1_ZRDP"),
  },

  RECEIVED_DEFECTIVE: {
    DECISION_ACCEPTED: [],
    DECISION_ACCEPTED_WITHOUT_CORRECTION: allExcept(
      "RK1_ZRPN",
      "RK1_ZRVE",
      "RK1_ZRDP"
    ),
    DECISION_TO_DESTROY: ["RK1_ZRPN", "RK1_ZRVE", "RK1_ZRDP"],
    DECISION_TO_DESTROY_WITHOUT_CORRECTION: ["RK1_ZRPN"],
    DECISION_REJECTED_TO_CLIENT: ALL_ALLOWED,
  },
};
