import React, { useCallback } from 'react';
import { Button, Table } from 'semantic-ui-react';
import { TClaimLine } from '~/store/claims/types';

import { ClaimTransitionAction, COLUMN_NAME, decisionColumns, verificationColumns } from './transitionColumns.conf';

const ClaimLinesTable = ({
  transitionLines,
  handleEdit,
  transitionActionStatus,
  claimCategory,
}: {
  transitionLines: TClaimLine[];
  handleEdit: any;
  transitionActionStatus: string;
  claimCategory: string;
}) => {
  const columns = transitionActionStatus === ClaimTransitionAction.DECISION ? decisionColumns : verificationColumns;

  const getCell = useCallback(
    (line: TClaimLine, name: COLUMN_NAME, index: number): JSX.Element | null => {
      const isRed =
        !!line.isNotValidQuantity ||
        (line['RECEIVED_FULL_VALUE'] && !!line['RECEIVED_FULL_VALUE'].isNotValidQuantity) ||
        (line['RECEIVED_DEFECTIVE'] && !!line['RECEIVED_DEFECTIVE'].isNotValidQuantity);

      if (name === COLUMN_NAME.lineNumber) {
        return (
          <Table.Cell key={name}>
            {!line.claimReturnedPart?.lineNumber ? line[name] : line.claimReturnedPart.lineNumber}
          </Table.Cell>
        );
      }

      if (name === COLUMN_NAME.referenceId) {
        return (
          <Table.Cell key={name}>{!line.claimReturnedPart?.id ? line[name] : line.claimReturnedPart.id}</Table.Cell>
        );
      }

      if (name === COLUMN_NAME.referenceName) {
        return (
          <Table.Cell key={name}>{!line.claimReturnedPart?.name ? line[name] : line.claimReturnedPart.name}</Table.Cell>
        );
      }

      if (
        name === COLUMN_NAME.select &&
        Number(line.receivedQuantityDefective) + Number(line.receivedQuantityFullValue) === 0 &&
        transitionActionStatus === ClaimTransitionAction.DECISION
      ) {
        return <Table.Cell key={name}></Table.Cell>;
      } else if (name === COLUMN_NAME.select) {
        return (
          <Table.Cell key={name}>
            <Button content="Edytuj" className={isRed ? 'red' : ''} basic onClick={() => handleEdit(index)} />
          </Table.Cell>
        );
      }

      if (name === COLUMN_NAME.acceptedQuantity) {
        if (['RK1_ZRPN', 'RK2_ZRNF'].includes(claimCategory)) return null;

        return (
          <Table.Cell key={name}>
            {line.acceptedQuantity == null ? line.claimedQuantity : line.acceptedQuantity}
          </Table.Cell>
        );
      }

      if (name === COLUMN_NAME.requestReturnQuantity) {
        if (['RK1_ZNRE', 'RK2_ZRNG'].includes(claimCategory)) return null;

        return <Table.Cell key={name}>{line[`${name}`] === undefined ? 0 : line[`${name}`]}</Table.Cell>;
      }

      if (name === COLUMN_NAME.statusNotes) {
        return <Table.Cell key={name}>{line.statusNotes}</Table.Cell>;
      }

      if (
        name === COLUMN_NAME.toReceiveQuantity ||
        name === COLUMN_NAME.receivedQuantityFullValue ||
        name === COLUMN_NAME.receivedQuantityDefective
      ) {
        return <Table.Cell key={name}>{line[`${name}`] === null ? 0 : line[`${name}`]}</Table.Cell>;
      }

      // @ts-ignore: string as object key
      return <Table.Cell key={name}>{line[name]}</Table.Cell>;
      // eslint-disable-next-line
    },
    [claimCategory, handleEdit, transitionActionStatus]
  );

  const rows = transitionLines.map((line, index): JSX.Element | null => {
    if (
      !(line.toReceiveQuantity && line.toReceiveQuantity > 0) &&
      transitionActionStatus === ClaimTransitionAction.DECISION
    )
      return null;
    return (
      <Table.Row key={index}>{columns.map((column) => getCell(line, column.name as COLUMN_NAME, index))}</Table.Row>
    );
  });

  return (
    <Table>
      <Table.Header>
        <Table.Row>
          {columns.map((column) => {
            if (['RK1_ZRPN', 'RK2_ZRNF'].includes(claimCategory) && column.name === 'acceptedQuantity') return null;
            if (['RK1_ZNRE', 'RK2_ZRNG'].includes(claimCategory) && column.name === 'requestReturnQuantity')
              return null;
            return (
              <Table.HeaderCell key={column.name} style={{ width: column.width }}>
                {column.label}
              </Table.HeaderCell>
            );
          })}
        </Table.Row>
      </Table.Header>
      <Table.Body>{rows}</Table.Body>
    </Table>
  );
};

export default ClaimLinesTable;
