import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects'
import { callApi } from '~/utils/api'
import { handleError } from '~/store/error/actions'
import { ApplicationState } from '~/store'
import {
    actionDictionaryItemSuccess,
    addDictionaryItem,
    fetchDictionariesNamesDetails,
    fetchDictionariesNamesDetailsSuccess,
    fetchDictionariesNamesSuccess,
    fetchSuccess,
    removeDictionaryItem,
    updateDictionaryItem
} from './actions'
import {
    DictionariesActionTypes,
    DictionariesMappers,
    DictionaryItem,
    FetchAction,
    TDetails,
    DictionaryName,
    DictionaryFetchRes,
    DictionariesState
} from './types'

import translations from '~/utils/translations'

const MAPPERS: DictionariesMappers = {
    [DictionaryName.customerCategoryZone] : (data) => ({ key: data.id, value: data.key, text: `${data.type}: ${data.label}` }),
    [DictionaryName.paymentMethod]: (data) => ({ key: data.key, value: data.key, text: data.label }),
    [DictionaryName.paymentDue]: (data) => ({ key: data.id, value: data.key, text: data.label }),
    [DictionaryName.paymentFactor]: (data) => ({ key: data.key, value: data.key, text: data.value, agreementNo: data.agreementNo }),
    [DictionaryName.customerCategoryOrganization]: (data) => ({ key: data.id, value: data.key, text: data.label }),
    [DictionaryName.customerInformationAddressRegion]: (data) => ({ key: data.id, value: data.key, text: data.label }),
    [DictionaryName.customerPaymentCurrency]: (data) => ({ key: data.id, value: data.key, text: data.label }),
    [DictionaryName.sparePartCategoryAdv]: (data) => ({ key: data.key, value: data.key, text: data.label }),
    [DictionaryName.sparePartCategoryOrigin]: (data) => ({ key: data.key, value: data.key, text: data.label }),
    [DictionaryName.sparePartTaxCategory]: (data) => ({ key: data.key, value: data.key, valueOrgin: data.value, text: data.label }),
    [DictionaryName.sparePartDiscountPcd]: (data) => ({ key: data.key, value: data.key, text: data.label, buy: data.buy, sell: data.sell }),
    [DictionaryName.sparePartDiscountFca]: (data) => ({ key: data.key, value: data.key, text: data.label, buy: data.buy, sell: data.sell }),
    [DictionaryName.sparePartDiscountIam]: (data) => ({ key: data.key, value: data.key, text: data.label, buy: data.buy, sell: data.sell }),
    [DictionaryName.sparePartState]: (data) => ({ key: data.key, value: data.key, text: data.label }),
    [DictionaryName.spareParReplacementCategory]: (data) => ({ key: data.key, value: data.key, text: data.label }),
    [DictionaryName.sparePartBrand]: (data) => ({ key: data.key, value: data.key, text: data.label }),
    [DictionaryName.sparePartHierarchyFamily]: (data) => ({ key: data.key, value: data.key, text: data.label }),
    [DictionaryName.sparePartHierarchySlot]: (data) => ({ key: data.key, value: data.key, text: data.label }),
    [DictionaryName.sparePartHierarchySegment]: (data) => ({ key: data.key, value: data.key, text: data.label }),
    [DictionaryName.sparePartHierarchyIndex]: (data) => ({ key: data.key, value: data.key, text: data.label }),
    [DictionaryName.sparePartProvisionCategory]: (data) => ({ key: data.key, value: data.key, text: data.label }),
    [DictionaryName.sparePartProvisionForceCategory]: (data) => ({ key: data.key, value: data.key, text: data.label }),
    [DictionaryName.gtuCodeFormula]: (data) => ({ key: data.key, value: data.key, text: data.label }),
    [DictionaryName.sparePartGtuCode]: (data) => ({ key: data.key, value: data.key, text: data.label }),
    [DictionaryName.backorderState]: (data) => ({ key: data.key, value: data.key, text: data.label }),
    [DictionaryName.orderDeliveryType]: (data) => ({ key: data.key, value: data.key, text: data.label }),
    [DictionaryName.orderType]: (data) => ({ key: data.id, value: data.key, text: data.label }),
    [DictionaryName.orderStatus]: (data) => ({ key: data.key, value: data.key, text: data.label }),
    [DictionaryName.orderSourceType]: (data) => ({ key: data.key, value: data.key, text: data.label }),
    [DictionaryName.anomalyType]: (data) => ({ key: data.value, value: data.value, text: data.label }),
    [DictionaryName.anomalyStatus]: (data) => ({ key: data.value, value: data.value, text: data.label }),
    [DictionaryName.grnType]: (data) => ({ key: data.value, value: data.value, text: data.label }),
    [DictionaryName.gdnType]: (data) => ({ key: data.value, value: data.value, text: data.label }),
    [DictionaryName.warehouseMovementStatus]: (data) => ({ key: data.value, value: data.value, text: data.label }),
    [DictionaryName.claimType]: (data) => ({ key: data.key, value: data.key, text: data.label }),
    [DictionaryName.claimCategory]: (data) => ({ key: data.key, value: data.key, text: data.label }),
    [DictionaryName.claimGroup]: (data) => ({ key: data.key, value: data.key, text: data.label }),
    [DictionaryName.claimStatus]: (data) => ({ key: data.key, value: data.key, text: data.label }),
    [DictionaryName.claimLineStatus]: (data) => ({ key: data.key, value: data.key, text: data.label }),
    [DictionaryName.itemToInvoiceStatus]: (data) => ({ key: data.key, value: data.key, text: data.label }),
    [DictionaryName.itemToInvoiceCategory]: (data) => ({ key: data.key, value: data.key, text: data.label }),
    [DictionaryName.financingReason]: (data) => ({ key: data.key, value: data.value, text: data.label }),
    [DictionaryName.gdnPostponedPartStatus]: (data) => ({ key: data.key, value: data.key, text: data.label }),
    [DictionaryName.sparePartReplenishmentMode]: (data) => ({ key: data.key, value: data.key, text: data.label }),
    [DictionaryName.customerSapClass]: (data) => ({ key: data.key, value: data.key, text: data.label }),
    [DictionaryName.transportType]: (data) => ({ key: data.key, value: data.value, text: data.label}),
    [DictionaryName.internalConsumptionNoteStatus]: (data) => ({ key: data.key, value: data.value, text: data.label}),
    [DictionaryName.sincoms]: (data) => (data),
}

const dictionariesSelector = (state: ApplicationState) => state.dictionaries

const removeDoubledKeys = (item: DictionaryItem): DictionaryItem => {
    if (item.text && item.text.indexOf(" - ") !== -1) {
        const textParts = item.text.split(" - ")
        if (textParts[0] === item.value) {
            item.text = textParts[1]
        }
    }
    return item
}

function* handleSingleFetch(name: DictionaryName) {
    try {
        const environmentDictionaries: DictionaryName[] = [DictionaryName.sincoms];
        if (environmentDictionaries.includes(name)) {
            let res: DictionaryFetchRes[] = yield call(callApi, 'get', `/environment/dictionaries/${name}`)
            if (res) {
                let data = res[0][name]
                Object.keys(data).forEach(key => {
                    data[key] = data[key].map((value: string) => ({key: value, value: value, text: value}))
                });
                yield put(fetchSuccess(name, data))
            } else {
                throw new Error()
            }   
        } else {
            let res: DictionaryFetchRes = yield call(callApi, 'get', `/dictionaries/${name}`)
            if (res && res[name]) {
                yield put(fetchSuccess(name, res[name].map(MAPPERS[name]).map(removeDoubledKeys)))
            } else {
                throw new Error()
            }   
        }
    } catch (error) {
        yield put(handleError(error as Error, false,
            translations.format("app.error.dictionaries.load", { name })))
    }
}

function* handleFetch(action: FetchAction) {
    const dictionaries:DictionariesState = yield select(dictionariesSelector)
    for (let name of action.payload) {
        if (!dictionaries[name] || dictionaries[name].length === 0) {
            yield fork(handleSingleFetch, name)
        } else {
            yield put(fetchSuccess(name))
        }
    }
}

function* handleFetchDictionariesNames() {
    try {
        const names:string[] = yield call(callApi, 'get', `/dictionaries/names`)
        yield put(fetchDictionariesNamesSuccess(names))
    } catch (error) {
        yield put(handleError(error as Error, false, 'Błąd przy pobieraniu listy słowników'))
    }
}

function* handleFetchDictionariesNamesDetails(action: ReturnType<typeof fetchDictionariesNamesDetails>) {
    try {
        const details:TDetails = yield call(callApi, 'get', `/dictionaries/${action.payload}/details`)
        const data:Response = yield call(callApi, 'get', `/dictionaries/${action.payload}`)
        yield put(fetchDictionariesNamesDetailsSuccess(details, data))
    } catch (error) {
        yield put(handleError( error as Error, false, 'Błąd przy pobieraniu szczegółów słownika'))
    }
}

function* handleAddDictionaryItem(action: ReturnType<typeof addDictionaryItem>) {
    try {
        yield call(callApi, 'post', `/dictionaries/${action.payload.name}`, action.payload.item)
        yield put(actionDictionaryItemSuccess(action.payload.name))
    } catch (e) {
        const error = e as any
        if (error.status === 409) {
            yield put(handleError(error, false, 'Dodanie słownika niemożliwe', 'Istnieje już słownik o takiej samej wartości. Edytuj istniejący.'))
        }
        else {
            yield put(handleError(error, false, error.message ? error.message : 'Błąd przy dodawaniu elementu do słownika'))
        }
    }
}

function* handleUpdateDictionaryItem(action: ReturnType<typeof updateDictionaryItem>) {
    try {
        yield call(callApi, 'PATCH', `/dictionaries/${action.payload.name}/${action.payload.key}`, action.payload.item)
        yield put(actionDictionaryItemSuccess(action.payload.name))
    } catch (e) {
        const error = e as any
        yield put(handleError(error, false, error.message ? error.message : 'Błąd przy edycji elementu słownika', "Nie udało się zaktualizować elementu słownika"))
    }
}

function* handleRemoveDictionaryItem(action: ReturnType<typeof removeDictionaryItem>) {
    try {
        yield call(callApi, 'DELETE', `/dictionaries/${action.payload.name}/${action.payload.key}`)
        yield put(actionDictionaryItemSuccess(action.payload.name))
    } catch (e) {
        const error = e as any
        yield put(handleError(error, false, error.message ? error.message : 'Błąd przy edycji elementu słownika', "Nie udało się usunąć elementu słownika"))
    }
}

function* watchFetchRequest() {
    yield takeEvery(DictionariesActionTypes.FETCH_REQUEST, handleFetch)
}

function* watchFetchDictionariesNames() {
    yield takeEvery(DictionariesActionTypes.FETCH_DICTIONARIES_NAMES, handleFetchDictionariesNames)
}

function* watchFetchDictionariesNamesDetails() {
    yield takeEvery(DictionariesActionTypes.FETCH_DICTIONARIES_NAMES_DETAILS, handleFetchDictionariesNamesDetails)
}

function* watchAddDictionaryItem() {
    yield takeEvery(DictionariesActionTypes.ADD_DICTIONARY_ITEM, handleAddDictionaryItem)
}

function* watchUpdateDictionaryItem() {
    yield takeEvery(DictionariesActionTypes.UPDATE_DICTIONARY_ITEM, handleUpdateDictionaryItem)
}

function* watchRemoveDictionaryItem() {
    yield takeEvery(DictionariesActionTypes.REMOVE_DICTIONARY_ITEM, handleRemoveDictionaryItem)
}

function* dictionariesSaga() {
    yield all([
        fork(watchFetchRequest),
        fork(watchFetchDictionariesNames),
        fork(watchFetchDictionariesNamesDetails),
        fork(watchAddDictionaryItem),
        fork(watchUpdateDictionaryItem),
        fork(watchRemoveDictionaryItem)
    ])
}

export default dictionariesSaga
