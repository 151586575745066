import { Table } from 'semantic-ui-react';
import React from 'react';

const LinesTableHeaders = () => (
  <Table.Header>
    <Table.Row>
      <Table.HeaderCell collapsing>Nr</Table.HeaderCell>
      <Table.HeaderCell>Referencja</Table.HeaderCell>
      <Table.HeaderCell>Ilość</Table.HeaderCell>
      <Table.HeaderCell>Kodowanie</Table.HeaderCell>
      <Table.HeaderCell>Nazwa</Table.HeaderCell>
      <Table.HeaderCell>UC</Table.HeaderCell>
      <Table.HeaderCell>Stock</Table.HeaderCell>
      <Table.HeaderCell>Stany magazynowe</Table.HeaderCell>
      <Table.HeaderCell>Cena PVP</Table.HeaderCell>
      <Table.HeaderCell>Cena PNR</Table.HeaderCell>
      <Table.HeaderCell>Kod rabatu</Table.HeaderCell>
      <Table.HeaderCell>Rabat (%)</Table.HeaderCell>
      <Table.HeaderCell>Wartość DRZ</Table.HeaderCell>
      <Table.HeaderCell>Cena sprzedaży</Table.HeaderCell>
      <Table.HeaderCell width="1">Wartość netto</Table.HeaderCell>
      <Table.HeaderCell width="1">VAT</Table.HeaderCell>
      <Table.HeaderCell width="1">Wartość brutto</Table.HeaderCell>
      <Table.HeaderCell>Promocja</Table.HeaderCell>
      <Table.HeaderCell>Przyczyna odrzucenia</Table.HeaderCell>
      <Table.HeaderCell />
    </Table.Row>
  </Table.Header>
);

export default LinesTableHeaders;
