import React, { SyntheticEvent, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Grid, GridColumn, Icon, Modal, Popup, Table } from 'semantic-ui-react';
import { ApplicationState } from '~/store';
import { DictionaryItem } from '~/store/dictionaries/types';
import {
  addOrderLine,
  cancelLines,
  deleteOrderLine,
  fetchOrderLines,
  linesOperationFinished,
  selectOrder,
  updateOrderLine,
} from '~/store/orders/actions';
import { getInvoicePdf } from '~/store/invoices/actions';
import { Order, OrderLine } from '~/store/orders/types';
import { SparePartPricing } from '~/store/price-list/types';
import { PaginationMeta } from '~/store/types';
import { parsePrice } from '~/utils/parsePrice';
import { useReplacementsModal } from '~/pages/Backorders/Details/lib/Modals';
import { useRemoveLineModal } from '../Modals';
import CommonLoader from '~/components/Loaders/CommonLoader';
import useCodesModal from '~/components/Modals/hooks/useCodesModal';
import GoodsDispatchNotesDetails from '~/pages/Accounting/Details/GoodsDispatchNotes';
import InvoiceErp from '~/pages/Accounting/Details/InvoiceErp';
import OrderItem from './Item';
import TablePaginationFooter from '~/components/TablePaginationFooter/TablePaginationFooter';
import EnterSearch from '~/components/EnterSearch/EnterSearch';
import { AVAILABLE_ROLE_ENUM } from '~/store/users/types';
import { AppContext } from '~/context/AuthContext';

type TReduxState = {
  lines: OrderLine[];
  meta: PaginationMeta;
  loading: boolean;
  loadingLines: boolean;
  operationSuccess: boolean;
  orderId: string;
  orderIn?: Order;
};

type TReduxActions = {
  fetchOrderLines: typeof fetchOrderLines;
  selectOrder: typeof selectOrder;
  addOrderLine: typeof addOrderLine;
  updateOrderLine: typeof updateOrderLine;
  getInvoicePdf: typeof getInvoicePdf;
  deleteOrderLine: typeof deleteOrderLine;
  linesOperationFinished: typeof linesOperationFinished;
  cancelLines: typeof cancelLines;
};

type TProps = {
  limit?: number;
  editMode: boolean;
  orderType: string;
  orderStatus: string;
  totalValue: number;
  creditLimit: number;
  editModeOrigin?: boolean;
};

export type TOrderColumn = {
  name: keyof OrderLine;
  label: string | JSX.Element;
  width?: number;
  dictionary?: DictionaryItem[];
  textAlign?: 'left' | 'center' | 'right';
  projection?: boolean;
};

type TOrderLinesProps = TReduxState & TReduxActions & TProps;

const OrderLines: React.FC<TOrderLinesProps> = ({
  limit,
  lines,
  meta,
  loading,
  loadingLines,
  operationSuccess,
  editMode,
  editModeOrigin,
  orderId,
  orderType,
  orderStatus,
  totalValue,
  creditLimit,
  fetchOrderLines,
  addOrderLine,
  updateOrderLine,
  getInvoicePdf,
  deleteOrderLine,
  cancelLines,
  orderIn,
  selectOrder,
}) => {
  const columns = useMemo(
    (): TOrderColumn[] => [
      { name: 'selected', label: ' ', width: 50 },
      { name: 'lineNo', label: 'Nr', width: 50 },
      { name: 'referenceID', label: 'Referencja' },
      { name: 'quantity', label: 'Ilość', width: 120 },
      { name: 'codes', label: 'Kodowanie', width: 140 },
      { name: 'description', label: 'Nazwa' },
      { name: 'packagingQuantity', label: 'UC', width: 60 },
      { name: 'stock', label: 'Stock', width: 60 },
      { name: 'stockLevel', label: 'Stany magazynowe', width: 60 },
      {
        name: 'netPriceListPrice',
        label: 'Cena PVP',
        width: 120,
        textAlign: 'right',
      },
      { name: 'pricePNR', label: 'Cena PNR', width: 120, textAlign: 'right' },
      { name: 'priceReductionDiscountCode', label: 'Kod rabatu', width: 60 },
      { name: 'bonus', label: 'Rabat %', width: 120 },
      { name: 'priceDRZ', label: 'Wartość DRZ', width: 120 },
      {
        name: 'netPrice',
        label: 'Cena sprzedaży',
        width: 120,
        textAlign: 'right',
      },
      {
        name: 'totalNetPrice',
        label: 'Wartość netto',
        width: 120,
        textAlign: 'right',
      },
      { name: 'totalTaxValue', label: 'VAT', width: 120, textAlign: 'right' },
      {
        name: 'totalGrossPrice',
        label: 'Wartość brutto',
        width: 120,
        textAlign: 'right',
      },
      { name: 'promoCode', label: 'Promocja', width: 120 },
      {
        name: 'rejectedQuantity',
        label: 'Distrigo odrzucone',
        textAlign: 'right',
      },
      {
        name: 'rejectionReasons',
        label: 'Przyczyna odrzucenia',
        textAlign: 'right',
      },
      {
        name: 'awaitingForReplenishment',
        label: 'Oczekujące na replenishment',
        textAlign: 'right',
      },
      { name: 'packageNumbers', label: 'Numery paczek', width: 120 },
      {
        name: 'confirmedQuantity',
        label: 'Distrigo potwierdzone',
        textAlign: 'right',
      },
      {
        name: 'orderedCrossDock',
        label: 'Cross dock zlecone',
        textAlign: 'right',
      },
      {
        name: 'confirmedCrossDock',
        label: 'Cross dock potwierdzone',
        textAlign: 'right',
      },
      {
        name: 'manualOrderDocument',
        label: 'Zam. manualne zlecone',
        textAlign: 'right',
      },
      {
        name: 'backorderReplenishmentDocuments',
        label: 'Zamówienia hurtowe Distrigo',
        textAlign: 'right',
      },
      { name: 'sapInvoices', label: 'Faktury SAP', textAlign: 'right' },
      { name: 'invoiceIdNumbers', label: 'Faktury LDC', textAlign: 'right' },
    ],
    []
  );

  const appContext = useContext(AppContext);

  const [openModal, setOpenModal] = useState(false);
  const [noteId, setNoteId] = useState<string>('');
  const [openPreviewModal, setOpenPreviewModal] = useState<boolean>(false);
  const [openInvoiceErpModal, setOpenInvoiceErpModal] = useState<boolean>(false);
  const [orderLinesQueryFilter, setOrderLinesQueryFilter] = useState<string>('');

  const [selectedLine, setSelectedLine] = useState<OrderLine>();
  const [newLine, setNewLine] = useState<OrderLine>();
  const [pageSize, setPageSize] = useState(50);
  const [maximized, setMaximized] = useState(false);

  const tableEl = useRef<HTMLDivElement>(null);
  const [referenceIds, setReferenceIds] = useState<string[]>([]);
  const [openCancelLinesModal, setCancelLinesOpenModal] = useState<boolean>(false);
  const [disableCancelLinesButton, setDisableCancelButton] = useState<boolean>(true);

  const userHaveLdcUiOrderCloseUnrealizedRole = useMemo(
    () => appContext?.keycloak.hasResourceRole(AVAILABLE_ROLE_ENUM.LDC_ORDER_CLOSE_UNREALIZED),
    [appContext]
  );

  useEffect(() => {
    fetchOrderLines(orderId, { page: 1, size: pageSize });
  }, [pageSize, fetchOrderLines, orderId]);

  useEffect(() => {
    if (operationSuccess) {
      resetModal();
    }
  }, [operationSuccess]);

  useEffect(() => {
    if (openPreviewModal) {
      setOpenPreviewModal(false);
    }
  }, [openPreviewModal]);

  useEffect(() => {
    if (tableEl.current && newLine) {
      tableEl.current.scrollTop = 9999;
    }
  }, [newLine]);

  const pageChange = useCallback(
    (page: number): void => {
      fetchOrderLines(orderId, { page, size: pageSize });
    },
    [fetchOrderLines, orderId, pageSize]
  );

  const pageSizeChange = (size: number): void => {
    setPageSize(size);
  };

  const toggleMaximized = (): void => {
    setMaximized(!maximized);
  };

  const handleCancelInvoices = (): void => {
    cancelLines(orderId, referenceIds);
    setTimeout(() => {
      fetchOrderLines(orderId, meta);
      selectOrder(orderId);
    }, 500);

    setCancelLinesOpenModal(false);
    setDisableCancelButton(true);
  };

  const handleDisableButton = (disableButton: boolean): void => {
    setDisableCancelButton(disableButton);
  };

  const countRecordsToCancel = (): void => {
    setCancelLinesOpenModal(true);
    setReferenceIds([]);
    let lineReference: string[] = [];
    lines.map((line) => {
      if (line.selected) {
        lineReference.push(line.referenceID);
      }
      return line;
    });
    setReferenceIds(lineReference);
  };

  const handleCloseModal = (): void => {
    setCancelLinesOpenModal(false);
  };

  const resetModal = (): void => {
    setCurrentModal(undefined);
    setOpenModal(false);
  };

  const handleAddCoding = (line: OrderLine): void => {
    setSelectedLine(line);
    setCurrentModal('CODES');
    setOpenModal(true);
  };

  const handleCancelLine = (line: OrderLine): void => {
    setNewLine(undefined);
  };

  const handleGetInvoice = useCallback(
    (invoiceNo: string, invoiceMask: string): void => {
      getInvoicePdf(invoiceNo, `${invoiceMask}.pdf`);
    },
    [getInvoicePdf]
  );

  const handleRemoveLine = (line: OrderLine): void => {
    setSelectedLine(line);
    setCurrentModal('REMOVE_LINE');
    setOpenModal(true);
  };

  const handleDocumentPreview = (document: string): void => {
    setNoteId(document);
    setOpenPreviewModal(true);
  };

  const handleInvoiceErpPreview = (document: string): void => {
    setNoteId(document);
    setOpenInvoiceErpModal(true);
  };

  const handleAddLine = (): void => {
    setNewLine({ lineNo: (meta.total || 1) + 1 } as OrderLine);
  };

  const handleSaveNewLineReplacements = (product: SparePartPricing): void => {
    product.replacements
      .map((replacement) => replacement.sparePart)
      .forEach((reference) => {
        handleSaveNewLine(reference.referenceId);
      });
  };

  const handleSaveNewLine = useCallback(
    (referenceId: string): void => {
      setNewLine(undefined);
      addOrderLine(orderId, {
        referenceID: referenceId,
        quantity: 1,
      } as OrderLine);
    },
    [addOrderLine, orderId]
  );

  const handleCodingSave = (modifiedLine: OrderLine): void => {
    const codes: any = {};
    Object.entries(modifiedLine.codes || {})
      .filter(([code, entry]) => !!entry)
      .forEach(([code, entry]) => {
        codes[code] = entry && { value: entry.value };
      });
    updateOrderLine(orderId, {
      lineNo: modifiedLine.lineNo,
      codes,
    } as OrderLine);
  };

  const getReplacements = useCallback(
    (lineNumber: number): OrderLine[] => {
      const replacements = lines.filter((line) => line.replacedLineNo === lineNumber);
      const innerReplacements = replacements.map((line) => getReplacements(line.lineNo)).flat();
      return [...replacements, ...innerReplacements];
    },
    [lines]
  );

  const handleLineUpdate = useCallback(
    (line: OrderLine): void => {
      const replacements = getReplacements(line.lineNo);
      const provision = lines.find((line) => line.provisioningLineNo === line.lineNo);
      if (replacements.length > 0 || provision) {
        setTimeout(() => {
          fetchOrderLines(orderId, meta);
        }, 500);
      }
    },
    [fetchOrderLines, getReplacements, lines, meta, orderId]
  );

  const getOrderLinesWithQueryFilter = (): void => {
    fetchOrderLines(orderId, { page: 1, size: pageSize }, [{ name: 'query', value: orderLinesQueryFilter }]);
  };

  const handleQuickSearchChange = (event: SyntheticEvent, data: any): void => {
    const { value } = data;
    if (value) {
      setOrderLinesQueryFilter(value);
    } else {
      setOrderLinesQueryFilter('');
      fetchOrderLines(orderId, { page: 1, size: pageSize });
    }
  };

  const renderEnterSearch = (): JSX.Element =>
    editModeOrigin ? (
      <div style={{ maxWidth: 'fit-content', margin: '10px 0' }}>
        <EnterSearch onSearchChange={handleQuickSearchChange} onSearchAction={getOrderLinesWithQueryFilter} />
      </div>
    ) : (
      <></>
    );
  const renderedOrderLines: JSX.Element = useMemo(() => {
    lines
      .filter((line) => line.awaitingForReplenishment === undefined)
      .filter((line) => line.awaitingForReplenishment === null)
      .map((line) => (line.awaitingForReplenishment = 0));
    const rows = lines.map((line) => {
      return (
        <OrderItem
          limit={limit}
          key={`${line.lineNo}-${line.referenceID}-${line.rejectionReasons}-${line.rejectionReason}`}
          orderId={orderId}
          loading={loadingLines}
          columns={columns}
          lineIn={line}
          lines={lines}
          orderType={orderType}
          orderStatus={orderStatus}
          isNew={false}
          editMode={editMode}
          ldcOrderId={orderId}
          getInvoice={handleGetInvoice}
          addCodingHandler={handleAddCoding}
          saveNewLineHandler={handleSaveNewLine}
          updateLineHandler={handleLineUpdate}
          removeLineHandler={handleRemoveLine}
          documentPreviewHandler={handleDocumentPreview}
          invoiceErpPreviewHandler={handleInvoiceErpPreview}
          setDisableCancelLinesButton={handleDisableButton}
        />
      );
    });

    if (newLine) {
      rows.push(
        <OrderItem
          key="newitem"
          orderId={orderId}
          loading={loadingLines}
          columns={columns}
          lineIn={newLine}
          lines={lines}
          isNew={true}
          editMode={editMode}
          orderType={orderType}
          orderStatus={orderStatus}
          ldcOrderId={orderId}
          getInvoice={handleGetInvoice}
          addCodingHandler={handleAddCoding}
          saveNewLineHandler={handleSaveNewLine}
          updateLineHandler={handleLineUpdate}
          removeLineHandler={handleCancelLine}
          documentPreviewHandler={handleDocumentPreview}
          invoiceErpPreviewHandler={handleInvoiceErpPreview}
          setDisableCancelLinesButton={handleDisableButton}
        />
      );
    }

    return (
      <div
        className="uber-table no-wrap"
        ref={tableEl}
        style={{
          maxHeight: maximized ? '100%' : 'calc(100vh - 300px)',
          minHeight: 100,
          height: maximized ? 'auto' : 'auto',
          overflowX: 'scroll',
          marginBottom: 100,
        }}
      >
        <GoodsDispatchNotesDetails noteId={noteId} triggerOpen={openPreviewModal} />
        <InvoiceErp
          noteId={noteId}
          triggerOpen={openInvoiceErpModal}
          closeHandler={() => setOpenInvoiceErpModal(false)}
        />
        <Table className={'table--text-right'}>
          <Table.Header>
            <Table.Row>
              {columns.map((column) => {
                return (
                  <Table.HeaderCell
                    key={column.name}
                    style={
                      column.textAlign
                        ? {
                            textAlign: column.textAlign,
                            width: column.width,
                          }
                        : { width: column.width }
                    }
                  >
                    {column.label}
                  </Table.HeaderCell>
                );
              })}
              {editMode && <Table.HeaderCell style={{ width: 75 }} />}
            </Table.Row>
          </Table.Header>
          <Table.Body>{rows}</Table.Body>
          <TablePaginationFooter
            meta={meta}
            totalRecords={undefined}
            onPageChange={pageChange}
            onPageSizeChange={pageSizeChange}
          />
        </Table>
        <CommonLoader loading={loadingLines && !loading} extraUp />
      </div>
    );
  }, [
    lines,
    newLine,
    maximized,
    noteId,
    openPreviewModal,
    openInvoiceErpModal,
    columns,
    editMode,
    meta,
    pageChange,
    loadingLines,
    loading,
    limit,
    orderId,
    orderType,
    orderStatus,
    handleGetInvoice,
    handleSaveNewLine,
    handleLineUpdate,
  ]);

  const [currentModal, setCurrentModal] = useState<
    'IMPORT_XLS' | 'IMPORT_CSV' | 'REMOVE_LINE' | 'CODES' | 'REPLACEMENTS'
  >();

  const removeLineModal = useRemoveLineModal(
    openModal,
    resetModal,
    loadingLines,
    orderId,
    deleteOrderLine,
    selectedLine
  );

  const codesModal = useCodesModal(
    openModal,
    resetModal,
    // @ts-ignore: incompatible handleCodingSave model
    handleCodingSave,
    loadingLines,
    selectedLine
  );

  const replacementsModal = useReplacementsModal(openModal, resetModal, loadingLines, handleSaveNewLineReplacements);

  const getCurrentModal = (): JSX.Element => {
    switch (currentModal) {
      case 'REMOVE_LINE':
        return removeLineModal;
      case 'CODES':
        return codesModal;
      case 'REPLACEMENTS':
        return replacementsModal;
      default:
        return <></>;
    }
  };

  return (
    <>
      {getCurrentModal()}
      <Grid columns={2} style={{ marginTop: 0 }}>
        <Grid.Row>
          <GridColumn>
            {editMode && (
              <Popup
                trigger={<Button circular basic icon="add" onClick={handleAddLine} disabled={loading || !editMode} />}
                content="Dodaj kolejną linię do zamówienia"
              />
            )}
            Ilość: <b>{meta.total}</b>
            {renderEnterSearch()}
          </GridColumn>
          <GridColumn textAlign="right">
            <div>
              <label>Całkowita wartość zamówienia: </label>
              <strong style={{ color: 'red' }}>{parsePrice(totalValue)}</strong>
            </div>
            <div>
              <label>Dostępny limit: </label>
              <strong>{parsePrice(creditLimit || 0)}</strong>
            </div>
            <Popup
              trigger={
                <Button
                  circular
                  basic
                  icon={maximized ? 'window restore outline' : 'expand arrows alternate'}
                  onClick={toggleMaximized}
                />
              }
              position="bottom right"
              content={maximized ? 'Minimalizuj widok tabeli' : 'Maksymalizuj widok tabeli'}
            />
          </GridColumn>
        </Grid.Row>
      </Grid>
      <Grid columns="2" style={{ marginBottom: 0 }}>
        <Grid.Row>
          <Grid.Column verticalAlign="bottom"></Grid.Column>
          <Grid.Column textAlign="right">
            {userHaveLdcUiOrderCloseUnrealizedRole && (
              <Modal
                size={'mini'}
                trigger={
                  <Button
                    primary
                    icon
                    labelPosition="right"
                    style={{ minWidth: 135 }}
                    onClick={countRecordsToCancel}
                    disabled={disableCancelLinesButton}
                  >
                    Anuluj wybrane linie
                    <Icon name="arrow right" />
                  </Button>
                }
                open={openCancelLinesModal}
              >
                <Modal.Header>Linie do anulowania</Modal.Header>
                <Modal.Content>
                  <p>Czy jesteś pewien anulowania {referenceIds.length} linii?</p>
                  <ul>
                    {referenceIds.map((id) => (
                      <li>{id}</li>
                    ))}
                  </ul>
                </Modal.Content>
                <Modal.Actions>
                  <Button content="Nie" onClick={handleCloseModal} />
                  <Button content="Tak" icon="trash" labelPosition="right" primary onClick={handleCancelInvoices} />
                </Modal.Actions>
              </Modal>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {orderLinesQueryFilter && lines.length === 0 ? (
        <p>
          Brak wyników wyszukiwania dla frazy <b>{orderLinesQueryFilter}</b>.
        </p>
      ) : (
        <>
          <div className="table-footer-up">
            <Table>
              <TablePaginationFooter
                meta={meta}
                totalRecords={undefined}
                pageSizeVisible={false}
                onPageChange={pageChange}
                onPageSizeChange={pageSizeChange}
              />
            </Table>
          </div>
          {renderedOrderLines}
        </>
      )}
    </>
  );
};

const mapStateToProps: (state: ApplicationState) => TReduxState = ({ orders, users }: ApplicationState) => {
  return {
    orderIn: orders.selected,
    orderId: orders.selected ? orders.selected.id : '',
    orderStatus: orders.selected ? orders.selected.status : '',
    lines: orders.selectedLines || [],
    meta: orders.linesMeta,
    loading: orders.loadingOrder,
    loadingLines: orders.loadingLines,
    operationSuccess: orders.operationSuccess,
    userRoles: users.userRoles,
  };
};

const mapDispatchToProps: TReduxActions = {
  fetchOrderLines,
  selectOrder,
  addOrderLine,
  updateOrderLine,
  getInvoicePdf,
  deleteOrderLine,
  linesOperationFinished,
  cancelLines,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderLines);
