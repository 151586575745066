import React from 'react';
import { Button, GridColumn, Popup } from 'semantic-ui-react';
import { AddNewLineProps } from '~/pages/Orders/lib/Add/Lines/constants';

const AddNewLine = ({ order, summary, addLine }: AddNewLineProps) => (
  <GridColumn>
    <Popup
      trigger={<Button disabled={!order.type || summary} circular basic icon="add" onClick={addLine} />}
      content="Dodaj kolejną linię do zamówienia"
    />
  </GridColumn>
);

export default AddNewLine;
