import React, { Fragment, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import CommonLoader from "~/components/Loaders/CommonLoader";
import PageHeader from "~/components/PageHeader/PageHeader";
import { DataGridTable } from "~/components/Table/DataGridTable";
import { TColumn } from "~/components/Table/lib/types";
import { ApplicationState } from "~/store";
import { fetchDistrigoWarehouses } from "~/store/distrigo-warehouses/actions";
import { DistrigoWarehouse } from "~/store/distrigo-warehouses/types";
import { DistrigoWarehousesList } from "./List";
import { useWarehousesColumns } from "./hooks/useWarehousesColumns";
import { useCells } from "~/services/useCells";
import { DictionariesState } from "~/store/dictionaries/types";

type PropsFromState = {
  loading: boolean;
  distrigoWarehouses: DistrigoWarehouse[];
};

type PropsFromDispatch = {
  fetchDistrigoWarehouses: typeof fetchDistrigoWarehouses;
};

type DistrigoWarehousesProps = PropsFromState & PropsFromDispatch;

const DistrigoWarehouses: React.FC<DistrigoWarehousesProps> = ({
  fetchDistrigoWarehouses,
  loading,
  distrigoWarehouses,
}) => {
  const { columnsConfig } = useWarehousesColumns();
  const [columns, setColumns] = useState<TColumn[]>(columnsConfig);
  const [tableKey] = useState<string>("distrigoWarehouses");

  const { completeColumnDictionaries } = useCells({
    tableKey,
    dictionaries: {} as DictionariesState,
  });

  useEffect(() => {
    setColumns((items) => completeColumnDictionaries(items));
  }, [completeColumnDictionaries]);

  return (
    <Fragment>
      <PageHeader
        icon="warehouse"
        title="Magazyny Distrigo"
        breadcrumb={[{ text: <FormattedMessage id="app.list" /> }]}
        buttons={[]}
        refreshAction={() => fetchDistrigoWarehouses()}
        loading={loading}
      />
      <DataGridTable
        columns={columns}
        fetchMethod={fetchDistrigoWarehouses}
        loading={loading}
        tableKey={tableKey}
        setColumns={setColumns}
        initSortColumn={undefined}
        initSortDirection={undefined}
        isAdvancedSearchOpen={false}
        noSearch={true}
      >
        <DistrigoWarehousesList
          distrigoWarehouses={distrigoWarehouses}
          columns={columns}
        />
      </DataGridTable>
      <CommonLoader loading={loading} />
    </Fragment>
  );
};

const mapStateToProps = ({ distrigoWarehouses }: ApplicationState) => ({
  distrigoWarehouses: distrigoWarehouses.list,
  loading: distrigoWarehouses.loadingDistrigoWarehouses,
});

const mapDispatchToProps = {
  fetchDistrigoWarehouses,
};

export default connect(mapStateToProps, mapDispatchToProps)(DistrigoWarehouses);
