import React, { Fragment, useCallback, useEffect, useState } from "react";
import { TDataGridTableProps } from "./lib/types";
import { Table } from "semantic-ui-react";
import TablePaginationFooter from "../TablePaginationFooter/TablePaginationFooter";
import CommonLoader from "../Loaders/CommonLoader";
import TableHeaderSortingCell from "../TableHeaderSortingCell/TableHeaderSortingCell";
import TableSortSession from "~/utils/tableSortSession";
import FilterLabels from "../TableFilter/lib/FilterLabels";
import TableFilter, { IRefObject } from "../TableFilter/TableFilter";
import ColumnToggler from "../ColumnToggler/ColumnToggler";
import { useDataGridTable } from "./lib/useDataGridTable";
import { FormattedMessage } from "react-intl";

export const DataGridTable: React.FC<TDataGridTableProps> = ({
  isAdvancedSearchOpen,
  columns,
  meta,
  loading,
  totalRecords,
  fetchMethod,
  children,
  initSortColumn,
  initSortDirection,
  tableKey,
  setColumns,
  noSearch,
  customHeight,
}) => {
  const tableFilter = React.createRef<IRefObject>();
  const {
    toggleProductColumn,
    sorting,
    setSorting,
    filterLabels,
    handleAddFilter,
    removeFilterLabel,
    clearAllFilter,
    initFilterLabels,
  } = useDataGridTable({
    tableKey,
    setColumns,
    initSortColumn,
    initSortDirection,
    tableFilter,
    columns,
  });

  const [height, setHeight] = useState<string>(
    customHeight || "calc(91vh - 150px)"
  );
  const [page, setPage] = useState<number>(1);
  const [size, setSize] = useState<number>(15);
  const [firstRender, setFirstRender] = useState<boolean>(true);

  useEffect(() => {
    setHeight(
      isAdvancedSearchOpen
        ? "calc(63vh - 150px)"
        : customHeight || "calc(91vh - 150px)"
    );
  }, [isAdvancedSearchOpen, customHeight]);

  useEffect(() => {
    if (loading) {
      initFilterLabels();
    }
  }, [loading, initFilterLabels]);

  useEffect(() => {
    if (!loading) {
      fetchMethod({
        size,
        page,
        sortColumn: sorting.sortColumn,
        sortDirection: sorting.sortDirection,
        filters: filterLabels,
      });
      setFirstRender(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [size, sorting, page]);

  useEffect(() => {
    if (!loading && !firstRender) {
      setPage(1);
      fetchMethod({
        size,
        page: 1,
        sortColumn: sorting.sortColumn,
        sortDirection: sorting.sortDirection,
        filters: filterLabels,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterLabels]);

  const pageChange = (pageToChange: number): void => {
    setPage(pageToChange);
  };

  const pageSizeChange = (size: number): void => {
    setPage(1);
    setSize(size);
  };

  const handleSort = (name: string): void => {
    let sortToSet = "";
    if (sorting.sortColumn !== name) {
      setSorting({
        sortColumn: name,
        sortDirection: "ASC",
      });
      sortToSet = "ASC";
    } else {
      setSorting((state) => {
        sortToSet = state.sortDirection === "ASC" ? "DESC" : "ASC";
        return {
          sortDirection: state.sortDirection === "ASC" ? "DESC" : "ASC",
          sortColumn: name,
        };
      });
    }
    TableSortSession.setSoring({
      key: "orders",
      value: name,
      direction: sortToSet,
    });
  };

  const formatMessage = useCallback((id: string) => {
    return <FormattedMessage id={`app.${id}`} defaultMessage={id} />;
  }, []);

  return (
    <div className="uber-table no-wrap" style={{ height: height }}>
      <FilterLabels
        filterLabels={filterLabels}
        removeFilterLabel={removeFilterLabel}
        clearAllFilter={clearAllFilter}
      />
      <div className="docked">
        <ColumnToggler
          onToggle={toggleProductColumn.bind(this)}
          columns={columns}
          storageKey={tableKey}
        />
      </div>
      <Table selectable>
        <Table.Header>
          <Table.Row>
            {columns.map((column) =>
              column.projection ? (
                <Fragment key={column.name}>
                  <TableHeaderSortingCell
                    name={column.name}
                    label={formatMessage(column.label)}
                    onSort={handleSort.bind(this, column.name)}
                    sortColumn={sorting.sortColumn}
                    sortDirection={sorting.sortDirection}
                  />
                </Fragment>
              ) : null
            )}
            <Table.HeaderCell collapsing />
          </Table.Row>
          {!noSearch ? (
            <TableFilter
              ref={tableFilter}
              initValues={filterLabels}
              columns={columns}
              onAddFilter={handleAddFilter}
              icon="right"
            />
          ) : null}
        </Table.Header>
        <Table.Body>{children}</Table.Body>
        {meta && totalRecords && (
          <TablePaginationFooter
            meta={meta}
            totalRecords={totalRecords}
            onPageChange={pageChange}
            onPageSizeChange={pageSizeChange}
          />
        )}
      </Table>
      <CommonLoader loading={loading} />
    </div>
  );
};
