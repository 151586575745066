import React, { useCallback, useMemo } from "react";
import { TColumn } from "~/components/Table/lib/types";
import dictionariesStatic from "~/store/dictionaries/static";
import { DictionaryItem, DictionaryName } from "~/store/dictionaries/types";
import translations from "~/utils/translations";
import { TableCellName } from "../constants";
import { Table } from "semantic-ui-react";
import ChangePayerCell from "../Cell/ChangePayerCell";
import CategoryCompanyCell from "../Cell/CategoryCompanyCell";
import { Customer } from "~/store/customer/types";
import OrderCategoryCell from "../Cell/OrderCalegoryCell";
import { parsePrice } from "~/utils/parsePrice";
import BlockedCell from "../Cell/BlockedCell";
import { CustomerType } from "../lib/AddContractor";
import { useCellsRenderer } from "~/services/useCellRenderer";

export const useCustomersColumns = () => {
  const { getTextCell } = useCellsRenderer();

  const categoryCompanyDictionary: DictionaryItem[] = useMemo(
    () => [
      { key: "true", value: "true", text: "Firma" },
      { key: "false", value: "false", text: "Osoba" },
    ],
    []
  );

  const getCustomerTypeCell = useCallback(
    (customer: any, column: TColumn) =>
      customer && customer.customerType ? (
        <Table.Cell key={column.name}>
          {
            dictionariesStatic.CUSTOMER_TYPE.filter(
              (entry) => entry.key === customer.customerType
            )[0].text
          }
        </Table.Cell>
      ) : (
        <Table.Cell key={column.name}>-</Table.Cell>
      ),
    []
  );

  const getChangePayerCell = useCallback(
    (customer: Customer) => (
      <Table.Cell key={"changePayerCell"}>
        <ChangePayerCell changePayer={!!customer.currentAccountingCustomer} />
      </Table.Cell>
    ),
    []
  );

  const getCategoryCompanyCell = useCallback(
    (customer: Customer) => (
      <Table.Cell key={"categoryCompanyCell"}>
        <CategoryCompanyCell categoryCompany={customer.categoryCompany} />
      </Table.Cell>
    ),
    []
  );

  const getBlockedCell = useCallback(
    (customer: Customer) => (
      <Table.Cell key={"blockedCell"}>
        <BlockedCell
          blocked={customer.blocked}
          paymentCreditBlocked={customer.paymentCreditBlocked}
        />
      </Table.Cell>
    ),
    []
  );

  const getCurrentTotalLimitCell = useCallback(
    (customer: Customer) =>
      customer.customerType === CustomerType.PAYER ? (
        <Table.Cell key={"currentTotalLimit"}>
          {parsePrice(
            customer.currentTotalLimit ? customer.currentTotalLimit : 0
          )}
        </Table.Cell>
      ) : (
        <Table.Cell key={"currentTotalLimit"}>
          <i>Brak limitu</i>
        </Table.Cell>
      ),
    []
  );

  const getCurrentPaymentMethod = useCallback(
    (customer: Customer, column: TColumn) =>
      column.dictionary &&
      customer.customerType === CustomerType.PAYER &&
      customer.currentPaymentMethod &&
      customer.currentPaymentMethod.type ? (
        <Table.Cell key={"currentPaymentMethod"}>
          {
            column.dictionary.filter(
              (entry) => entry.key === customer.currentPaymentMethod?.type
            )[0].text
          }
        </Table.Cell>
      ) : (
        <Table.Cell key={"currentPaymentMethod"}>
          <p>Nieokreślona</p>
        </Table.Cell>
      ),
    []
  );

  const getAvailableFactorTotalLimit = useCallback(
    (customer: Customer) =>
      customer.customerType === CustomerType.PAYER ? (
        <Table.Cell key={"availableFactorTotalLimit"}>
          {customer.customerType === CustomerType.PAYER &&
          customer.currentPaymentMethodLimit
            ? parsePrice(customer.currentPaymentMethodLimit)
            : parsePrice(0)}
        </Table.Cell>
      ) : (
        <Table.Cell key={"availableFactorTotalLimit"}>
          <i>Brak limitu</i>
        </Table.Cell>
      ),
    []
  );

  const getSupplierSystemCell = useCallback(
    (customer: any, column: any, fieldName: string): JSX.Element => (
      <Table.Cell key={column.name}>
        {customer.supplierSystem ? customer.supplierSystem[0][fieldName] : ""}
      </Table.Cell>
    ),
    []
  );

  const getOrderCategoryCell = useCallback(
    (customer: Customer) => (
      <Table.Cell key={"orderCategoryCell"}>
        <OrderCategoryCell deliveryType={customer.orderType} />
      </Table.Cell>
    ),
    []
  );

  const columnsConfig: TColumn[] = [
    {
      name: TableCellName.customerType,
      dictionary: dictionariesStatic.CUSTOMER_TYPE,
      label: "Typ kontrahenta",
      noKey: true,
      projection: true,
      getCell: (document, column) => getCustomerTypeCell(document, column),
    },
    {
      name: TableCellName.rrdi,
      label: "Kod klienta",
      projection: true,
      getCell: (document, column) => getTextCell(document, column),
    },
    {
      name: TableCellName.clientId,
      label: "Płatnik",
      projection: true,
      getCell: (document, column) => getTextCell(document, column),
    },
    {
      name: TableCellName.ddsDeliveryId,
      label: "OV odbiorca",
      projection: true,
      getCell: (document, column) => getTextCell(document, column),
    },
    {
      name: "supplierSystem.businessSector",
      label: translations.format("app.table.supplierSystem.bussinesSystem"),
      projection: false,
      hide: true,
      filteringBlocked: true,
      getCell: (document, column) =>
        getSupplierSystemCell(document, column, "businessSector"),
    },
    {
      name: "supplierSystem.clientId",
      label: translations.format("app.table.supplierSystem.clientId"),
      projection: false,
      hide: true,
      filteringBlocked: true,
      getCell: (document, column) =>
        getSupplierSystemCell(document, column, "clientId"),
    },
    {
      name: "supplierSystem.deliveryId",
      label: translations.format("app.table.supplierSystem.deliveryId"),
      projection: false,
      hide: true,
      filteringBlocked: true,
      getCell: (document, column) =>
        getSupplierSystemCell(document, column, "deliveryId"),
    },
    {
      name: TableCellName.accountingVatId,
      label: "NIP",
      projection: true,
      getCell: (document, column) => getTextCell(document, column),
    },
    {
      name: TableCellName.informationAddressCompany,
      label: "Nazwa",
      projection: true,
      width: "100%",
      getCell: (document, column) => getTextCell(document, column),
    },
    {
      name: TableCellName.payerChange,
      label: "Zmiana płatnika",
      noKey: true,
      dictionary: dictionariesStatic.CUSTOMER_CHANGE_PAYER,
      projection: true,
      getCell: (document) => getChangePayerCell(document),
    },
    {
      name: TableCellName.informationAddressCity,
      label: "Miasto",
      projection: false,
      getCell: (document, column) => getTextCell(document, column),
    },
    {
      name: TableCellName.address,
      label: "Adres",
      projection: false,
      getCell: (document, column) => getTextCell(document, column),
    },
    {
      name: TableCellName.categoryCompany,
      dictionary: categoryCompanyDictionary,
      label: "Typ klienta",
      noKey: true,
      projection: true,
      getCell: (document) => getCategoryCompanyCell(document),
    },
    {
      name: TableCellName.deliveryType,
      label: "Dostawa",
      projection: true,
      dictionaryName: DictionaryName.orderDeliveryType,
      noKey: true,
      getCell: (document, column) => getOrderCategoryCell(document),
    },
    {
      name: TableCellName.currentPaymentMethodType,
      dictionaryName: DictionaryName.paymentMethod,
      label: "Metoda Płatności",
      projection: true,
      noKey: true,
      getCell: (document, column) => getCurrentPaymentMethod(document, column),
    },
    {
      name: TableCellName.currentTotalLimit,
      label: "Limit sumaryczny",
      projection: true,
      getCell: (document) => getCurrentTotalLimitCell(document),
    },
    {
      name: TableCellName.availableFactorTotalLimit,
      label: "Limit zamówienia",
      projection: false,
      getCell: (document) => getAvailableFactorTotalLimit(document),
    },
    {
      name: TableCellName.blocked,
      dictionary: dictionariesStatic.CUSTOMER_BLOCKED_STATUS,
      label: "Blokada",
      noKey: true,
      projection: true,
      getCell: (document) => getBlockedCell(document),
    },
  ];

  return { columnsConfig };
};
