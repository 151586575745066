import React from "react";
import { TBaseProps } from "~/components/SmartField/lib/types";
import { useSmartFieldLogic } from "~/components/SmartField/hooks/useSmartFieldLogic";
import { Container, Form, FormFieldProps } from "semantic-ui-react";
import { useLabelFormatter } from "~/components/SmartField/hooks/useLabelFormatter";
import { SmartPopup } from "~/components/SmartField/SmartPopup";
import { getTextAreaIcon } from "~/components/SmartField/lib/utils";

/**
 * Similar to TextField but it has more space to write something.
 */
export const SmartTextArea: React.FC<TBaseProps> = (props) => {

    const {
        currentValue,
        modified,
        loading,
        success,
        error,
        handleEdit,
        handleSave,
        getPopupContent,
        isRequired
    } = useSmartFieldLogic(props);

    const { formatLabel } = useLabelFormatter(props)

    const handleSpecificEdit = (ev: any, el: FormFieldProps): void => {
        handleEdit(el.value)
    }

    return (
        <SmartPopup
            description={getPopupContent()}>
            <Container fluid>
                <Form.Group className={"no-margin-bottom " + (error ? "toggle-error" : "")}>
                    <Form.Field label={formatLabel()}/>
                    <Form.Group className="float-right">
                        {getTextAreaIcon(loading, error, modified, success)}
                    </Form.Group>
                </Form.Group>
                <Form.TextArea
                    required={isRequired}
                    style={props.style}
                    fluid="true"
                    className="super-field"
                    value={currentValue}
                    onChange={handleSpecificEdit}
                    onBlur={handleSave}
                    loading={"" + loading}
                    error={!!error}
                    width={props.width}
                    rows={props.rows || 10}
                    disabled={props.disabled}
                    readOnly={props.readOnly}
                />
            </Container>
        </SmartPopup>
    )
}