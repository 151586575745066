import {SemanticICONS, SemanticCOLORS} from 'semantic-ui-react'

export interface DictionaryItem<T = any> {
    /**
     * Unique key of item for React.
     */
    key: string,
    /**
     * Value that will be send to server.
     */
    value: T,
    /**
     * Label visible for user.
     */
    text: string,

    agreementNo?: string
    /**
     * Optional icon to visualize dictionary value.
     */
    icon?: SemanticICONS,
    /**
     * Oryginal value from dictionary, may be will be send to server.
     */
    valueOrgin?: T,
    /**
     * Color that may be used as a label backround or something.
     */
    color?: SemanticCOLORS
    /**
     * Sell and buy are discount dictionaries values
     */
    sell?: number
    buy?: number
}

export enum DictionariesActionTypes {
    FETCH_REQUEST = '@@dictionaries/FETCH_REQUEST',
    FETCH_SUCCESS = '@@dictionaries/FETCH_SUCCESS',
    FETCH_DICTIONARIES_NAMES = '@@dictionaries/FETCH_DICTIONARIES_NAMES',
    FETCH_DICTIONARIES_NAMES_SUCCESS = '@@dictionaries/FETCH_DICTIONARIES_NAMES_SUCCESS',
    FETCH_DICTIONARIES_NAMES_DETAILS = '@@dictionaries/FETCH_DICTIONARIES_NAMES_DETAILS',
    FETCH_DICTIONARIES_NAMES_DETAILS_SUCCESS = '@@dictionaries/FETCH_DICTIONARIES_NAMES_DETAILS_SUCCESS',
    ADD_DICTIONARY_ITEM = '@@dictionaries/ADD_DICTIONARY_ITEM',
    ACTION_DICTIONARY_ITEM_SUCCESS = '@@dictionaries/ADD_DICTIONARY_ITEM_SUCCESS',
    CLEAR_CREATED = '@@dictionaries/CLEAR_CREATED',
    UPDATE_DICTIONARY_ITEM = '@@dictionaries/UPDATE_DICTIONARY_ITEM',
    REMOVE_DICTIONARY_ITEM = '@@dictionaries/REMOVE_DICTIONARY_ITEM'
}

export enum DictionaryName {
    paymentMethod = 'payment-method',// DICT_PAYMENTS
    paymentFactor = 'payment-factor',
    anomalyStatus = "anomaly-status",// DICT_ANOMALY_STATUS
    anomalyType = "anomaly-type", // DICT_ANOMALY_TYPE
    backorderState = 'backorder-state',// DICT_BACKORDER_STATUS
    claimCategory = 'claim-category',// DICT_CLAIM_CATEGORY
    claimGroup = 'claim-group',// DICT_CLAIM_GROUP
    claimLineStatus = 'claim-line-status',// DICT_CLAIM_LINE_STATUS
    claimStatus = 'claim-status', // DICT_CLAIM_STATUS
    claimType = 'claim-type',// DICT_CLAIM_TYPE
    customerCategoryOrganization = 'customer-category-organization',// DICT_SALES_ORG
    customerCategoryZone = 'customer-category-zone',// DICT_ZONE
    customerInformationAddressRegion = 'customer-information-address-region',// DICT_REGION_STATES
    customerPaymentCurrency = 'customer-payment-currency',// DICT_CURRENCY
    customerSapClass = 'customer-sap-class',//DICT_CUSTOMER_SAP_CLASS
    financingReason = 'financing-reason',//DICT_FINANCING_REASON
    gdnPostponedPartStatus = 'gdn-postponed-part-status', // DICT_GDN_POSTPONED_PART_STATUS
    gdnType = 'gdn-type',// DICT_GDN_TYPE
    grnType = 'grn-type',// DICT_GRN_TYPE
    gtuCodeFormula = 'gtu-code-formula', // DICT_GTU_CODE_FORMULA
    internalConsumptionNoteStatus = 'internal-consumption-note-status',
    itemToInvoiceCategory = 'item-to-invoice-category',//DICT_INVOICE_CATEGORY
    itemToInvoiceStatus = 'item-to-invoice-status',//DICT_INVOICE_STATUS
    orderDeliveryType = 'order-delivery-type',// DICT_ORDER_DELIVERY_TYPE
    orderSourceType = 'order-source-type',
    orderStatus = 'order-status',// DICT_ORDER_STATUS
    orderType = "order-type", // DICT_ORDER_TYPE
    paymentDue = 'payment-due',// DICT_PAYMENTS
    sparePartBrand = 'spare-part-brand', // DICT_BRAND
    sparePartCategoryAdv = 'spare-part-category-adv', // DICT_ADV_STATUS
    sparePartCategoryOrigin = 'spare-part-category-origin', // DICT_PART_ORIGIN
    sparePartDiscountIam = 'spare-part-discount-iam', // DICT_DISCOUNT_IAM
    sparePartDiscountPcd = 'spare-part-discount-pcd', // DICT_DISCOUNT_PCD
    sparePartDiscountFca = 'spare-part-discount-fca',  // DICT_DISCOUNT_FCA
    sparePartGtuCode = 'spare-part-gtu-code', //DICT_GTU_CODE_SELECTS
    sparePartHierarchyFamily = 'spare-part-hierarchy-family',// DICT_REF_FAMILY
    sparePartHierarchyIndex = 'spare-part-hierarchy-index',// DICT_REF_INDEX
    sparePartHierarchySegment = 'spare-part-hierarchy-segment',// DICT_REF_SEGMENT
    sparePartHierarchySlot = 'spare-part-hierarchy-slot',// DICT_REF_SLOT
    sparePartProvisionCategory = 'spare-part-provision-category',// DICT_PROVISION
    sparePartProvisionForceCategory = 'spare-part-provision-force-category',// DICT_DISTRIGO_PROVISION
    spareParReplacementCategory = 'spare-part-replacement-category', // DICT_REPLACEMENT_SOLUTION
    sparePartReplenishmentMode = 'spare-part-replenishment-mode',//DICT_REPLENISHMENT_MODE
    sparePartState = 'spare-part-state',// DICT_DISTRIGO_STATUS
    sparePartTaxCategory = 'spare-part-tax-category',// DICT_VAT_TYPE
    transportType = 'transport-type',
    warehouseMovementStatus = 'warehouse-movement-status', //DICT_WAREHOUSE_MOVEMENT_STATUS
    sincoms = 'sincoms',
    suppliers = 'suppliers' // not supported by BE yet
}

export type TDetailsFieldType = 'STRING' | 'NUMBER' | 'INTEGER' | 'DATE' | 'UNKNOWN'

export type TDetailsField = {
    name: string
    type: TDetailsFieldType
}

export type TDetails = {
    name: string
    fields: TDetailsField[]
    data: any[]
}

export type DictionariesMappers = {
    [key in DictionaryName]: (data: any) => DictionaryItem
}

export type DictionaryFetchRes = {
    [key in DictionaryName]: any
}

export type DictionariesState = {
    [key in DictionaryName]: DictionaryItem[]
} & {
    sincoms: DynamicSincoms
    loadingNames: DictionaryName[]
    loading: boolean
    names: string[]
    details: TDetails
    created?: boolean
    creating?: boolean
}

export type FetchAction = {
    type: DictionariesActionTypes
    payload: DictionaryName[]
}

export type DynamicSincoms = {
    [name: string]: string[]
}