import React, { useMemo } from "react";
import { Table } from "semantic-ui-react";
import { TColumn } from "~/components/Table/lib/types";
import { useCellsRenderer } from "~/services/useCellRenderer";
import { DictionaryName } from "~/store/dictionaries/types";

export const useCorrectionsColumns = () => {
  const {
    getAmountCell,
    getCustomCell,
    getDateCell,
    getDictionaryCell,
    getLinkCell,
    getTextCell,
  } = useCellsRenderer();

  const getSupplierSystemCell = useMemo(
    () =>
      (document: any, column: any, fieldName: string): JSX.Element => {
        return (
          <Table.Cell key={column.name}>
            {document.orderCustomer.supplierSystem
              ? document.orderCustomer.supplierSystem[0][fieldName]
              : ""}
          </Table.Cell>
        );
      },
    []
  );

  const columnsConfig: TColumn[] = useMemo(
    () => [
      {
        name: "documentMask",
        label: "Numer",
        projection: true,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "invoiceDate",
        label: "Data wystawienia",
        projection: true,
        type: "date",
        getCell: (document, column) => getDateCell(document, column, true),
      },
      {
        name: "accountingCustomer.id",
        label: "Płatnik",
        projection: true,
        getCell: (document, column) =>
          getLinkCell(
            document,
            column,
            `/customer/${document.accountingCustomer.id}`
          ),
      },
      {
        name: "orderCustomer.id",
        label: "Odbiorca",
        projection: true,
        getCell: (document, column) =>
          getLinkCell(
            document,
            column,
            `/customer/${document.orderCustomer.id}`
          ),
      },
      {
        name: "orderCustomer.ddsDeliveryId",
        label: "OV odbiorca",
        projection: true,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "orderCustomer.supplierSystem.businessSector",
        label: "Dostawca",
        projection: false,
        hide: true,
        filteringBlocked: true,
        getCell: (document, column) =>
          getSupplierSystemCell(document, column, "businessSector"),
      },
      {
        name: "orderCustomer.supplierSystem.clientId",
        label: "Sincom",
        projection: false,
        hide: true,
        filteringBlocked: true,
        getCell: (document, column) =>
          getCustomCell(() =>
            getSupplierSystemCell(document, column, "clientId")
          ),
      },
      {
        name: "orderCustomer.supplierSystem.deliveryId",
        label: "Punkt dostawy",
        projection: false,
        hide: true,
        filteringBlocked: true,
        getCell: (document, column) =>
          getCustomCell(() =>
            getSupplierSystemCell(document, column, "deliveryId")
          ),
      },
      {
        name: "invoiceDocumentMaskToCorrect",
        label: "Do faktury",
        projection: true,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "netAmount",
        label: "Kwota netto",
        projection: true,
        getCell: (document, column) => getAmountCell(document, column),
      },
      {
        name: "taxAmount",
        label: "Kwota VAT",
        projection: true,
        getCell: (document, column) => getAmountCell(document, column),
      },
      {
        name: "grossAmount",
        label: "Kwota brutto",
        projection: true,
        getCell: (document, column) => getAmountCell(document, column),
      },
      {
        name: "paymentMethodType",
        dictionaryName: DictionaryName.paymentMethod,
        label: "Metoda płatności",
        projection: true,
        getCell: (document, column) => getDictionaryCell(document, column),
      },
      {
        name: "accountingCustomer.taxCode",
        label: "NIP płatnika",
        projection: true,
        getCell: (document, column) =>
          getLinkCell(
            document,
            column,
            `/customer/${document.accountingCustomer.id}`
          ),
      },
      {
        name: "invoiceLines.serviceBoxOrderNo",
        label: "Nr zam Service Box",
        projection: false,
        hide: true,
        disableSort: true,
        filteringBlocked: true,
        getCell: (document, column) =>
          getTextCell(
            document,
            column,
            document.invoiceLines[0].serviceBoxOrderNo
          ),
      },
      {
        name: "invoiceLines.orderNumber",
        label: "Nr zamówienia",
        projection: false,
        hide: true,
        disableSort: true,
        filteringBlocked: true,
        getCell: (document, column) =>
          document.invoiceLines[0].orderID
            ? getLinkCell(
                document,
                column,
                `/order/${document.invoiceLines[0].orderID}`,
                document.invoiceLines
                  ? document.invoiceLines[0].orderNumber
                  : ""
              )
            : getTextCell(
                document,
                column,
                document.invoiceLines
                  ? document.invoiceLines[0].orderNumber
                  : ""
              ),
      },
    ],
    [
      getAmountCell,
      getCustomCell,
      getDateCell,
      getDictionaryCell,
      getLinkCell,
      getTextCell,
      getSupplierSystemCell,
    ]
  );

  const columnsCorrectionsWithInvoiceLine: TColumn[] = useMemo(
    () => [
      { name: "documentMask", label: "", projection: true },
      { name: "invoiceDate", label: "", projection: true, type: "date" },
      { name: "accountingCustomer.id", label: "", projection: true },
      { name: "orderCustomer.id", label: "", projection: true },
      { name: "invoiceDocumentMaskToCorrect", label: "", projection: true },
      { name: "netAmount", label: "", projection: true },
      { name: "taxAmount", label: "", projection: true },
      { name: "grossAmount", label: "", projection: true },
      {
        name: "paymentMethodType",
        dictionaryName: DictionaryName.paymentMethod,
        label: "",
        projection: true,
      },
      { name: "accountingCustomer.taxCode", label: "", projection: true },
      {
        name: "serviceBoxOrderNo",
        label: "",
        projection: false,
        hide: true,
        disableSort: true,
      },
      { name: "orderNumber", label: "", projection: false, hide: true },
      {
        name: "unitPriceNetAfterDiscount",
        label: "",
        projection: false,
        hide: true,
      },
      { name: "taxRate", label: "", projection: false, hide: true },
      { name: "referenceId", label: "", projection: false, hide: true },
      {
        name: "quantity",
        label: "",
        projection: false,
        hide: true,
        disableSort: true,
      },
    ],
    []
  );

  return { columnsConfig, columnsCorrectionsWithInvoiceLine };
};
