import { TModelInputProps } from "~/components/ModelForm/lib/types";
import React, { useMemo } from "react";
import { Form } from "semantic-ui-react";
import { REQUIRED } from "~/components/SmartField/lib/validator";
import { SmartPopup } from "~/components/SmartField/SmartPopup";
import { useFormElement } from "~/components/ModelForm/hooks/useFormElement";

export const ModelInput = <T, >(props: TModelInputProps<T>) => {
    const {validators} = props;

    const {value, validation, handleChange, handleBlur, formatPopupContent} = useFormElement(props)

    const hasRequiredValidator = useMemo(() => validators?.indexOf(REQUIRED) !== -1, [validators]);

    return (
        <SmartPopup description={formatPopupContent()}>
            <Form.Input
                required={hasRequiredValidator}
                {...props}
                value={value}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!validation[props.name]}
            />
        </SmartPopup>
    )
}