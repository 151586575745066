import DetailCard from '~/components/DetailCard/DetailCard';
import { Grid } from 'semantic-ui-react';
import ImportXlsPopup from '~/pages/Orders/lib/Add/Lines/ImportXlsPopup';
import ImportClipboardPopup from '~/pages/Orders/lib/Add/Lines/ImportClipboardPopup';
import MaximizedPopup from '~/pages/Orders/lib/Add/Lines/MaximizedPopup';
import LinesTable from '~/pages/Orders/lib/Add/Table';
import AddNewLine from '~/pages/Orders/lib/Add/Lines/AddNewLine';
import TotalAmount from '~/pages/Orders/lib/Add/Lines/TotalAmount';
import React, { useRef, useState } from 'react';
import { useUploadCsvModal, useUploadXlsModal } from '~/pages/Orders/lib/Modals';
import useCodesModal from '~/components/Modals/hooks/useCodesModal';
import { TLine } from '~/pages/Orders/lib/Add/constants';
import { calculateGross } from '~/pages/Orders/lib/Add/utility';
import { ApplicationState } from '~/store';
import { loadLinesCsv, loadLinesExcel, loadLinesExcelSpecial, loadLinesLoaded } from '~/store/orders/actions';
import { connect } from 'react-redux';
import { CardDetailsLinesProps, TReduxActions, TReduxState } from '~/pages/Orders/lib/Add/Lines/constants';

const CardDetailsLines = ({
  order,
  summary,
  loadLinesExcel,
  loadLinesExcelSpecial,
  loadLinesCsv,
  loadingLines,
  openModal,
  lines,
  setOpenModal,
  closeModal,
  setLines,
  setMaximized,
  maximized,
  calculateSellPrice,
  tableEl,
  deliveryPoint,
  currLimit,
  setLineAdded,
  limit,
}: CardDetailsLinesProps) => {
  const [selectedLine, setSelectedLine] = useState<TLine>();
  const modalType = useRef<'CODES' | 'IMPORT_XLS' | 'IMPORT_CSV'>();

  const addLine = (): void => {
    if (lines.length > 0 && !lines[lines.length - 1].referenceId) {
      return;
    }
    const newLines = [...lines];
    newLines.push({
      lineNumber: lines.length === 0 ? 1 : lines[lines.length - 1].lineNumber + 1,
      referenceId: '',
      quantity: 1,
      description: '',
      packingQuantity: 1,
      packingForceQuantity: 1,
      priceReductionDiscountCode: '',
      bonus: 0,
      stock: 0,
      pnrPrice: 0,
      pvpPrice: 0,
      sellPrice: 0,
      codes: null,
      priceTaxCode: '1',
      stockLevel: null,
      priceDRZ: null,
    });
    setLines(newLines);
    setLineAdded(true);
  };

  const updateLine = (updatedLine: TLine): void => {
    setLines((lines) =>
      lines.map((line) => {
        return line.referenceId === updatedLine.referenceId ? { ...updatedLine } : { ...line };
      })
    );
    closeModal();
  };

  const importXlsModal = useUploadXlsModal(
    openModal,
    closeModal,
    loadingLines,
    lines.length > 0,
    loadLinesExcel,
    loadLinesExcelSpecial,
    order.type
  );

  const importCsvModal = useUploadCsvModal(openModal, closeModal, loadingLines, lines.length > 0, loadLinesCsv);

  const codesModal = useCodesModal(openModal, closeModal, updateLine, false, selectedLine);

  const getCurrentModal = () => {
    switch (modalType.current) {
      case 'IMPORT_XLS':
        return importXlsModal;
      case 'IMPORT_CSV':
        return importCsvModal;
      case 'CODES':
        return codesModal;
      default:
        return <></>;
    }
  };

  const toggleMaximized = (): void => {
    setMaximized(!maximized);
  };

  const handleImportXls = (): void => {
    modalType.current = 'IMPORT_XLS';
    setOpenModal(true);
  };

  const handleImportClipboard = (): void => {
    modalType.current = 'IMPORT_CSV';
    setOpenModal(true);
  };

  const getTotalAmount = (): string => {
    return String(
      lines
        .filter((line) => line.orderLineType !== 'REPLACED')
        .reduce((sum: number, line: TLine) => sum + calculateGross(line), 0)
        .toFixed(2)
    );
  };

  return (
    <DetailCard title="app.order.lines" id="lines" key="lines" minHeight={40} width={16}>
      {getCurrentModal()}
      <Grid columns="2" style={{ marginBottom: 0 }}>
        <Grid.Row>
          <Grid.Column verticalAlign="bottom">Ilość: {lines.length}</Grid.Column>
          <Grid.Column textAlign="right">
            <ImportXlsPopup handleImportXls={handleImportXls} order={order} summary={summary} />
            <ImportClipboardPopup handleImportClipboard={handleImportClipboard} order={order} summary={summary} />
            <MaximizedPopup maximized={maximized} toggleMaximized={toggleMaximized} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <div
        className="uber-table no-wrap"
        ref={tableEl}
        style={{
          maxHeight: `calc(100vh - ${maximized ? 300 : 585}px)`,
          minHeight: 200,
        }}
      >
        <LinesTable
          limit={limit}
          lines={lines}
          summary={summary}
          setLines={setLines}
          calculateSellPrice={calculateSellPrice}
          setSelectedLine={setSelectedLine}
          setOpenModal={setOpenModal}
          modalType={modalType}
          order={order}
        />
      </div>
      <Grid columns={2} style={{ marginTop: 0 }}>
        <Grid.Row>
          <AddNewLine order={order} summary={summary} addLine={addLine} />
          <TotalAmount deliveryPoint={deliveryPoint} currLimit={currLimit} getTotalAmount={getTotalAmount} />
        </Grid.Row>
      </Grid>
    </DetailCard>
  );
};

const mapStateToProps: (state: ApplicationState) => TReduxState = ({ orders }: ApplicationState) => ({
  loadingLines: orders.loadingLines,
});

const mapDispatchToProps: TReduxActions = {
  loadLinesExcel,
  loadLinesExcelSpecial,
  loadLinesCsv,
  loadLinesLoaded,
};

export default connect(mapStateToProps, mapDispatchToProps)(CardDetailsLines);
