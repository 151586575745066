import React, { useEffect, useState } from "react"
import { Segment } from "semantic-ui-react"
import './dropdown-file-area.scss'
import DropdownFileArea from "~/components/DropdownFileArea/DropdownFileArea";

export type FileUploaderProps = {
    save: boolean
    additionalParam?: any
    onSaveClick: (...params: any) => void
    onSaveAfter?: () => void
}
// TODO export the logic to custom hook
const FileUploader: React.FC<FileUploaderProps> = ({
    onSaveClick,
    save,
    additionalParam,
    onSaveAfter
}) => {

    let fileUploaderInput: HTMLElement | null = null

    const [dragging, setDragging] = useState<boolean>(false)
    const [files, setFiles] = useState<File[] | FileList>([])
    const [dragEventCounter, setDragEventCounter] = useState<number>(0)

    useEffect(() => {
        window.addEventListener('dragover', (event: Event) => {
            overrideEventDefaults(event)
        })
        window.addEventListener('drop', (event: Event) => {
            overrideEventDefaults(event)
        })
    }, [])

    useEffect(() => {
        if (save) {
            onSaveClick(additionalParam, files)
            onSaveAfter && onSaveAfter()
        }
    }, [save, additionalParam, files, onSaveAfter, onSaveClick])

    const dragenterListener = (event: React.DragEvent<HTMLDivElement>): void => {
        overrideEventDefaults(event)
        setDragEventCounter(dragEventCounter + 1)
        if (event.dataTransfer.items && event.dataTransfer.items[0]) {
            setDragging(true)
        } else if (event.dataTransfer.types && event.dataTransfer.types[0] === 'Files') {
            setDragging(true)
        }
    }

    const dragleaveListener = (event: React.DragEvent<HTMLDivElement>): void => {
        overrideEventDefaults(event)
        setDragEventCounter(dragEventCounter - 1)

        if (dragEventCounter === 0) {
            setDragging(false)
        }
    }

    const dropListener = (event: React.DragEvent<HTMLDivElement>): void => {
        overrideEventDefaults(event)
        setDragEventCounter(0)
        setDragging(false)
        if (event.dataTransfer.files && event.dataTransfer.files[0]) {
            setFiles(event.dataTransfer.files)
        }
    }

    const overrideEventDefaults = (event: Event | React.DragEvent<HTMLDivElement>): void => {
        event.preventDefault()
        event.stopPropagation()
    }

    const onSelectFileClick = (): void => {
        fileUploaderInput && fileUploaderInput.click()
    }

    const onFileChanged = (event: React.ChangeEvent<HTMLInputElement>): boolean => {
        if (event.target.files && event.target.files[0]) {
            setFiles(event.target.files)
        }
        return false
    }

    return (
        <DropdownFileArea
            dragging={dragging}
            files={files}
            onSelectFileClick={onSelectFileClick}
            onDrag={overrideEventDefaults}
            onDragStart={overrideEventDefaults}
            onDragEnd={overrideEventDefaults}
            onDragOver={overrideEventDefaults}
            onDragEnter={dragenterListener}
            onDragLeave={dragleaveListener}
            onDrop={dropListener}
        >
            <Segment placeholder style={{ display: 'none' }}>
                <input
                    ref={el => (fileUploaderInput = el)}
                    type='file'
                    multiple
                    className='dropdown-file-area__input'
                    onChange={onFileChanged}
                />
            </Segment>
        </DropdownFileArea>
    )
}

export default FileUploader;
