import InfoIcon from '~/pages/Orders/Details/lib/InfoIcon';
import { Button, Table } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import React from 'react';
import { TableCellType } from '~/pages/Orders/Details/lib/Lines/Item/TableCell/constants';

export const CrossDockTableCell = ({ line, column }: TableCellType) => (
  <Table.Cell key={column.name} textAlign="right">
    {line[column.name]}
    <InfoIcon
      documents={line.backorderDocuments}
      clickableItem={(document) => (
        <Button style={{ display: 'block' }} compact basic as={Link} to={`/backorder/${document}`}>
          Podgląd zamówienia hurtowego
        </Button>
      )}
      noResultsMessage="Brak zamówień hurtowych"
    />
  </Table.Cell>
);
