import { connectRouter, RouterState } from "connected-react-router";
import { History } from "history";
import { reducer as toastrReducer, ToastrState } from "react-redux-toastr";
import { Action, AnyAction, combineReducers, Dispatch } from "redux";
import { all, fork } from "redux-saga/effects";
import { anomaliesReducer } from "./anomalies/reducer";
import { AnomaliesState } from "./anomalies/types";
import { backordersReducer } from "./backorder/reducer";
import { BackordersState } from "./backorder/types";
import { customersReducer } from "./customer/reducer";
import { CustomersState } from "./customer/types";
import { dictionariesReducer } from "./dictionaries/reducer";
import { DictionariesState } from "./dictionaries/types";
import { distrigoWarehousesReducer } from "./distrigo-warehouses/reducer";
import { DistrigoWarehousesState } from "./distrigo-warehouses/types";
import { WarehousesState } from "./warehouses-in-calendar/types";
import { errorsReducer } from "./error/reducer";
import { TErrorsState } from "./error/types";
import { hierarchyReducer } from "./hierarchy/reducer";
import { HierarchyState } from "./hierarchy/types";
import { ordersReducer } from "./orders/reducer";
import { VersionState } from "./version/types";
import { versionReducer } from "./version/reducer";
import { InvoicesState } from "./invoices/types";
import { invoicesReducer } from "./invoices/reducer";
import { ClaimsState } from "./claims/types";
import { claimsReducer } from "./claims/reducer";
import { OrdersState } from "./orders/types";
import { pricingReducer } from "./price-list/reducer";
import { PriceListState } from "./price-list/types";
import { promotionsReducer } from "./promotions/reducer";
import { SparePartsState } from "./spare-parts/types";
import { usersReducer } from "./users/reducer";
import { PromotionsState } from "./promotions/types";
import { sparePartsReducer } from "./spare-parts/reducer";
import { UsersState } from "./users/types";
import { NotificationsState } from "./notifications/types";
import { notificationsReducer } from "./notifications/reducer";
import { SpeederState } from "./speeder/types";
import { speederReducer } from "./speeder/reducer";
import { replenishmentReducer } from "./replenishment/reducer";
import { paymentMethodsReducer } from "./payment-methods/reducer";
import { warehousesReducer } from "./warehouses-in-calendar/reducer";
import { CodedPartsState } from "./coded-parts/types"

import anomaliesSaga from "./anomalies/sagas";
import backordersSaga from "./backorder/sagas";
import customersSaga from "./customer/sagas";
import dictionariesSaga from "./dictionaries/sagas";
import distrigoWarehousesSaga from "./distrigo-warehouses/sagas";
import ordersSaga from "./orders/sagas";
import priceListSaga from "./price-list/sagas";
import promotionsSaga from "./promotions/sagas";
import sparePartsSaga from "./spare-parts/sagas";
import usersSaga from "./users/sagas";
import hierarchySaga from "./hierarchy/sagas";
import claimsSaga from "./claims/sagas";
import invoicesSaga from "./invoices/sagas";
import notificationsSaga from "./notifications/sagas";
import speederSaga from "./speeder/sagas";
import versionSaga from "./version/sagas";
import warehousesSaga from "./warehouses-in-calendar/sagas";
import replenishmentSaga from "./replenishment/sagas";
import paymentMethodsSaga from "./payment-methods/sagas";
import { ReplenishmentState } from "./replenishment/types";
import { PaymentMethodState } from "./payment-methods/types";
// import { FactorState } from './factors/types'
import { factorReducer } from "./factors/reducer";
import factorSaga from "./factors/sagas";
import distrigoCreditSaga from "./distrigo-credit/sagas";
import { distrigoCreditReducer } from "./distrigo-credit/reducer";
import { DistrigoCreditState } from "~/store/distrigo-credit/types";
import { CustomerPaymentFactorState } from "~/store/factors/types";
import { codedPartsReducer } from "./coded-parts/reducer";
import codedPartsSaga from "./coded-parts/sagas";

export interface ApplicationState {
  customers: CustomersState;
  spareParts: SparePartsState;
  orders: OrdersState;
  backorders: BackordersState;
  toastr: ToastrState;
  pricing: PriceListState;
  dictionaries: DictionariesState;
  distrigoWarehouses: DistrigoWarehousesState;
  notifications: NotificationsState;
  users: UsersState;
  anomalies: AnomaliesState;
  promotions: PromotionsState;
  hierarchy: HierarchyState;
  claims: ClaimsState;
  invoices: InvoicesState;
  errors: TErrorsState;
  speeder: SpeederState;
  version: VersionState;
  warehouses_in_calendar: WarehousesState;
  router: RouterState;
  replenishment: ReplenishmentState;
  factor: CustomerPaymentFactorState;
  paymentMethods: PaymentMethodState;
  distrigoCredit: DistrigoCreditState;
  codedParts: CodedPartsState
}

export interface ConnectedReduxProps<A extends Action = AnyAction> {
  dispatch: Dispatch<A>;
}

export const createRootReducer = (history: History) =>
  combineReducers<ApplicationState>({
    customers: customersReducer,
    spareParts: sparePartsReducer,
    orders: ordersReducer,
    backorders: backordersReducer,
    toastr: toastrReducer,
    pricing: pricingReducer,
    dictionaries: dictionariesReducer,
    distrigoWarehouses: distrigoWarehousesReducer,
    notifications: notificationsReducer,
    users: usersReducer,
    anomalies: anomaliesReducer,
    promotions: promotionsReducer,
    hierarchy: hierarchyReducer,
    claims: claimsReducer,
    errors: errorsReducer,
    invoices: invoicesReducer,
    speeder: speederReducer,
    version: versionReducer,
    warehouses_in_calendar: warehousesReducer,
    replenishment: replenishmentReducer,
    paymentMethods: paymentMethodsReducer,
    factor: factorReducer,
    distrigoCredit: distrigoCreditReducer,
    codedParts: codedPartsReducer,
    router: connectRouter(history),
  });

export function* rootSaga() {
  yield all([
    fork(sparePartsSaga),
    fork(customersSaga),
    fork(ordersSaga),
    fork(backordersSaga),
    fork(priceListSaga),
    fork(dictionariesSaga),
    fork(distrigoWarehousesSaga),
    fork(promotionsSaga),
    fork(hierarchySaga),
    fork(usersSaga),
    fork(anomaliesSaga),
    fork(claimsSaga),
    fork(invoicesSaga),
    fork(notificationsSaga),
    fork(speederSaga),
    fork(versionSaga),
    fork(warehousesSaga),
    fork(replenishmentSaga),
    fork(factorSaga),
    fork(paymentMethodsSaga),
    fork(distrigoCreditSaga),
    fork(codedPartsSaga)
  ]);
}
