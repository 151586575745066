import DetailCard from '~/components/DetailCard/DetailCard';
import { Form } from 'semantic-ui-react';
import UberSearch from '~/components/UberSearch/UberSearch';
import { SmartLabel } from '~/components/SmartField/SmartLabel';
import React from 'react';
import { CardDetailCustomerProps } from '~/pages/Orders/lib/Add/Customer/constants';
import translations from '~/utils/translations';

const CardDetailCustomer = ({
  shrink,
  order,
  summary,
  mapDelivery,
  selectDeliveryPoint,
  deliveryDetails,
  formatDeliveryAddress,
}: CardDetailCustomerProps) => (
  <DetailCard
    title="app.customer.delivery"
    id="client"
    key="client"
    width={shrink ? 3.5 : 4}
    smallWidth={5.33}
    style={{ zIndex: 30 }}
  >
    <Form.Field disabled={!order.type || summary}>
      <UberSearch
        placeholder="Wyszukaj..."
        fluid
        endpoint="/customers/delivery-points"
        mapper={(response) => mapDelivery(response)}
        onResultSelect={(selected: any) => selectDeliveryPoint(selected)}
      />
    </Form.Field>
    {!!order.deliveryCustomerId && (
      <>
        <SmartLabel label="Nazwa" value={deliveryDetails && deliveryDetails.company} />
        <SmartLabel label="Adres" value={deliveryDetails && formatDeliveryAddress(deliveryDetails)} />
        <SmartLabel label="Płatnik" value={order.customerId} />
        <SmartLabel label="PCD płatnik" value={deliveryDetails!.sapClientId} />
        <SmartLabel
          label={translations.format('app.customer.FLHAP-Payer')}
          value={
            deliveryDetails &&
            deliveryDetails.supplierSystem &&
            deliveryDetails.supplierSystem.findIndex((sys) => sys.businessSector === 'FLHAP') >= 0
              ? deliveryDetails.supplierSystem[
                  deliveryDetails.supplierSystem.findIndex((sys) => sys.businessSector === 'FLHAP')
                ].clientId
              : '-'
          }
        />
        <SmartLabel
          label={translations.format('app.customer.CJD-Payer')}
          value={
            deliveryDetails &&
            deliveryDetails.supplierSystem &&
            deliveryDetails.supplierSystem.findIndex((sys) => sys.businessSector === 'CJD') >= 0
              ? deliveryDetails.supplierSystem[
                  deliveryDetails.supplierSystem.findIndex((sys) => sys.businessSector === 'CJD')
                ].clientId
              : '-'
          }
        />
        {deliveryDetails?.discountDRZPercentage ? (
          <SmartLabel label="Rabat DRZ" value={deliveryDetails.discountDRZPercentage + '%'} />
        ) : (
          ''
        )}
      </>
    )}
  </DetailCard>
);

export default CardDetailCustomer;
