import React, { Fragment } from "react";
import ColumnToggler from "~/components/ColumnToggler/ColumnToggler";
import ModalConfirm from "~/components/Modals/ModalConfirm";
import CommonLoader from "~/components/Loaders/CommonLoader";
import {
  ACTION_TYPE_ENUM,
  UserTableWrapperProps,
} from "~/pages/Administration/lib/UserTableWrapper/constants";
import { ApplicationState } from "~/store";
import { enableUser, removeUser } from "~/store/users/actions";
import { connect } from "react-redux";
import { State } from "~/pages/Administration/constants";
import UserTable from "~/pages/Administration/lib/UserTableWrapper/UserTable";

const UserTableWrapper = ({
  loading,
  setState,
  state,
  enableUser,
  removeUser,
}: UserTableWrapperProps) => {
  const toggleUserColumn = (name: string, checked: boolean): void => {
    setState((state: State) => ({
      columns: state.columns.map((column) => {
        return column.name === name
          ? { ...column, projection: checked }
          : { ...column };
      }),
    }));
  };

  const handleCancelClick = (): void => {
    setState({ modalOpen: false });
  };

  const handleConfirmClick = (): void => {
    const { confirmType, actionId } = state;

    switch (confirmType) {
      case ACTION_TYPE_ENUM.ACTIVATE_USER:
        enableUser(actionId, true);
        break;
      case ACTION_TYPE_ENUM.DEACTIVATE_USER:
        enableUser(actionId, false);
        break;
      case ACTION_TYPE_ENUM.REMOVE_USER:
        removeUser(actionId);
        break;
      default:
        break;
    }
    setState({ modalOpen: false, request: true });
  };

  const getConfirmText = (): string => {
    const { confirmType, actionUserName } = state;

    switch (confirmType) {
      case ACTION_TYPE_ENUM.ACTIVATE_USER:
        return `Czy na pewno chcesz aktywować użytkownika ${actionUserName}?`;
      case ACTION_TYPE_ENUM.DEACTIVATE_USER:
        return `Czy na pewno chcesz dezaktywować użytkownika ${actionUserName}?`;
      case ACTION_TYPE_ENUM.REMOVE_USER:
        return `Czy na pewno chcesz usunąć użytkownika ${actionUserName}?`;
      default:
        return "";
    }
  };

  return (
    <Fragment>
      <div className="uber-table">
        <div className="docked">
          <ColumnToggler
            onToggle={toggleUserColumn}
            columns={state.columns}
            storageKey={"users"}
          />
        </div>
        <UserTable setState={setState} state={state} />
      </div>
      <ModalConfirm
        modalOpen={state.modalOpen}
        contentText={getConfirmText()}
        headerIcon="question circle"
        headerText="app.confirmOperation"
        onCancelClick={handleCancelClick}
        onConfirmClick={handleConfirmClick}
      />
      <CommonLoader loading={loading} />
    </Fragment>
  );
};

const mapStateToProps = ({ users }: ApplicationState) => ({
  loading: users.loadingUsers,
});

const mapDispatchToProps = {
  enableUser,
  removeUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserTableWrapper);
