import React, { useCallback, useState } from "react";
import TableFilterSession from "~/utils/tableFilterSession";
import TableSortSession, { TTableSortSession } from "~/utils/tableSortSession";
import { TColumn, TTableSorting } from "./types";
import { IRefObject } from "../../TableFilter/TableFilter";

type Props = {
  tableKey: string;
  setColumns: React.Dispatch<React.SetStateAction<TColumn[]>>;
  initSortColumn: string | undefined | TTableSortSession;
  initSortDirection: "ASC" | "DESC" | undefined | TTableSortSession;
  tableFilter: React.RefObject<IRefObject>;
  columns: TColumn[];
};

type SupplierSystemSearch = {
  selectedBusinessSector: string | undefined;
  clientIdValue: string | undefined;
  deliveryIdValue: string | undefined;
};

export const useDataGridTable = ({
  tableKey,
  setColumns,
  initSortColumn,
  initSortDirection,
  tableFilter,
  columns,
}: Props) => {
  const getTableSortSession = (
    table: boolean,
    name?: string
  ): undefined | TTableSortSession => {
    if (table) {
      return TableSortSession.getSorting(tableKey);
    } else {
      //@ts-ignore: no-undefined-result
      return TableSortSession.getSorting(tableKey)[name];
    }
  };

  const getTableFilterSession = useCallback(
    () => TableFilterSession.getFilters(tableKey),
    [tableKey]
  );

  const [sorting, setSorting] = useState<TTableSorting>({
    sortColumn: getTableSortSession(true)
      ? getTableSortSession(false, "value")
      : initSortColumn,
    sortDirection: TableSortSession.getSorting(tableKey)
      ? getTableSortSession(false, "direction")
      : initSortDirection,
  });
  const [filterLabels, setFilterLabels] = useState<any[]>(
    getTableFilterSession()
  );

  const initFilterLabels = useCallback(() => {
    setFilterLabels(getTableFilterSession());
  }, [setFilterLabels, getTableFilterSession]);

  const toggleProductColumn = (name: string, checked: boolean): void => {
    if (!checked) {
      removeFilterLabel(name);
    }
    setColumns((columns) =>
      columns.map((column) => {
        return column.name === name
          ? { ...column, projection: checked }
          : { ...column };
      })
    );
  };

  // probably needed for advanced search
  // eslint-disable-next-line
  const [unwid, setUnwid] = useState<boolean>(false);
  // eslint-disable-next-line
  const [supplierSystemSearch, setSupplierSystemSearch] =
    useState<SupplierSystemSearch>({
      selectedBusinessSector: undefined,
      clientIdValue: undefined,
      deliveryIdValue: undefined,
    });

  const turnOffUnwidProcess = () => {
    setSorting({
      sortColumn: initSortColumn,
      sortDirection: initSortDirection,
    });
    TableSortSession.setSoring({
      key: tableKey,
      value: String(initSortColumn),
      direction: String(initSortDirection),
    });
  };

  const removeFilterLabel = (name: string): void => {
    // to refactor with advanced search: 'unwind' etc.
    if (name === "unwind") {
      setUnwid(false);
      toggleProductColumn(
        "deliveryCustomer.supplierSystem.businessSector",
        false
      );
      toggleProductColumn("deliveryCustomer.supplierSystem.clientId", false);
      toggleProductColumn("deliveryCustomer.supplierSystem.deliveryId", false);
      turnOffUnwidProcess();
    }
    if (name === "deliveryCustomer.supplierSystem") {
      setSupplierSystemSearch({
        selectedBusinessSector: undefined,
        clientIdValue: "",
        deliveryIdValue: "",
      });
    }
    // the code below should stay
    setFilterLabels((filterLabels) =>
      filterLabels.filter((label) => label.name !== name)
    );
    TableFilterSession.clearFilter({ name, table: tableKey, value: "" });
    if (tableFilter.current) {
      tableFilter.current.clearFilter(name);
    }
  };

  const addUpdateFilterLabel = (
    name: string,
    value: string,
    text?: string,
    key?: string
  ): void => {
    const found = filterLabels.find((label) => label.name === name);
    const hiddenKey = columns.find((x) => x.name === name);

    if (text === undefined) {
      const foundUndefinedOption = columns.find((x) => x.name === name);
      if (foundUndefinedOption) {
        text = `${foundUndefinedOption.label} - nieokreślone`;
        value = "";
      }
    }

    setFilterLabels(() =>
      found
        ? filterLabels.map((label) =>
            label.name === name ? { name, value, text, key } : { ...label }
          )
        : [
            ...filterLabels,
            {
              name,
              value,
              text,
              key,
              visibleKey: !!(hiddenKey && hiddenKey.projection),
            },
          ]
    );
    if (name && value) {
      TableFilterSession.setFilters({
        name,
        value,
        text,
        key,
        table: tableKey,
      });
    }
  };

  const handleAddFilter = (
    name: string,
    value: string,
    text?: string,
    key?: string
  ): void => {
    let checkIsInFilterTable = false;
    filterLabels.forEach((filter) => {
      if (filter.value === value && filter.name === name) {
        checkIsInFilterTable = true;
      }
    });
    if (!checkIsInFilterTable) {
      value !== ""
        ? addUpdateFilterLabel(name, value, text, key)
        : removeFilterLabel(name);
    }
  };

  const clearAllFilter = (): void => {
    removeFilterLabel("unwind");
    setSupplierSystemSearch({
      selectedBusinessSector: undefined,
      clientIdValue: "",
      deliveryIdValue: "",
    });
    setFilterLabels([]);
    if (tableFilter.current) {
      tableFilter.current.clearAllFilter();
    }
    TableFilterSession.clearFilter(undefined, tableKey);
  };

  return {
    getTableSortSession,
    getTableFilterSession,
    toggleProductColumn,
    sorting,
    setSorting,
    filterLabels,
    handleAddFilter,
    removeFilterLabel,
    clearAllFilter,
    initFilterLabels,
  };
};
