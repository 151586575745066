import TableCellPopup from '~/components/TableCellPopup/TableCellPopup';
import React from 'react';
import { TableCellType } from '~/pages/Orders/Details/lib/Lines/Item/TableCell/constants';

export const DescriptionTableCell = ({ column, line }: TableCellType) => {
  const getSapStatus = (status?: string): JSX.Element => {
    let text = '';

    switch (status) {
      case 'PROCESSED_BY_SAP':
        text = 'Zrealizowane przez SAP';
        break;
      case 'REPLACED_BY_SAP':
        text = 'Zmienione przez SAP';
        break;
      case 'ADDED_BY_SAP':
        text = 'Dodane przez SAP';
        break;
      case 'REJECTED':
        text = 'Anulowane ręcznie';
        break;
      default:
        text = 'Niezrealizowane przez SAP';
        break;
    }
    return <span style={{ fontSize: 10 }}>({text})</span>;
  };

  return (
    <TableCellPopup key={column.name}>
      {line[column.name]} {getSapStatus(line.sapStatus)}
    </TableCellPopup>
  );
};
