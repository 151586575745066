import React from 'react';
import { Form, Table, TextArea } from 'semantic-ui-react';
import DetailCard from '~/components/DetailCard/DetailCard';
import { actionTypes, claimCategoriesToDisplayInputs, editFormColumns } from './transitionColumns.conf';

const VerificationEditLine = ({ transitionLineIndex, transitionLines, changeLine, claimCategory }: any) => {
  if (transitionLineIndex === null) return null;

  const {
    referenceName,
    referenceId,
    requestReturnQuantity,
    rejectQuantity,
    statusNotes,
    acceptedQuantity,
    isNotValidQuantity,
    claimReturnedPart,
  } = transitionLines[transitionLineIndex];

  const getCardTitle = () => {
    return claimReturnedPart?.id && claimReturnedPart?.name
      ? `${claimReturnedPart.id} (${claimReturnedPart.name})`
      : `${referenceName} (${referenceId})`;
  };

  const onlyReturning = () =>
    !claimCategoriesToDisplayInputs['VERIFICATION']['VERIFICATION_ACCEPTED'].includes(claimCategory) &&
    !claimCategoriesToDisplayInputs['VERIFICATION']['VERIFICATION_REJECTED'].includes(claimCategory);

  return (
    <>
      <DetailCard title={getCardTitle()} id="status" key="status" minHeight={100} width={16}>
        <Table>
          <Table.Header>
            <Table.Row>
              {editFormColumns.map((column) => (
                <Table.HeaderCell key={column.name} style={{ width: column.width }}>
                  {column.label}
                </Table.HeaderCell>
              ))}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {claimCategoriesToDisplayInputs['VERIFICATION']['VERIFICATION_ACCEPTED'].includes(claimCategory) ? (
              <Table.Row>
                <Table.Cell>{actionTypes['VERIFICATION_ACCEPTED']}</Table.Cell>
                <Table.Cell>
                  <Form.Input
                    type="number"
                    min="0"
                    error={isNotValidQuantity}
                    value={acceptedQuantity}
                    onChange={(e, d) => changeLine(e, d, 'acceptedQuantity')}
                  />
                </Table.Cell>
              </Table.Row>
            ) : null}
            {claimCategoriesToDisplayInputs['VERIFICATION']['VERIFICATION_REQUEST_RETURN'].includes(claimCategory) ? (
              <Table.Row>
                <Table.Cell>{actionTypes['VERIFICATION_REQUEST_RETURN']}</Table.Cell>
                <Table.Cell>
                  <Form.Input
                    type="number"
                    min="0"
                    error={isNotValidQuantity}
                    value={requestReturnQuantity}
                    onChange={(e, d) => changeLine(e, d, 'requestReturnQuantity', '', onlyReturning())}
                  />
                </Table.Cell>
              </Table.Row>
            ) : null}
            {claimCategoriesToDisplayInputs['VERIFICATION']['VERIFICATION_REJECTED'].includes(claimCategory) ? (
              <Table.Row>
                <Table.Cell>{actionTypes['VERIFICATION_REJECTED']}</Table.Cell>
                <Table.Cell>
                  <Form.Input
                    type="number"
                    min="0"
                    error={isNotValidQuantity}
                    value={rejectQuantity}
                    onChange={(e, d) => changeLine(e, d, 'rejectQuantity')}
                  />
                </Table.Cell>
              </Table.Row>
            ) : null}
          </Table.Body>
        </Table>
        <Form>
          <Form.Field>
            <label>Komentarz do pozycji</label>
            <TextArea
              className="textarea-field"
              placeholder={statusNotes}
              value={statusNotes || ''}
              onChange={(e, d) => changeLine(e, d, 'statusNotes')}
            />
          </Form.Field>
        </Form>
      </DetailCard>
    </>
  );
};

export default VerificationEditLine;
