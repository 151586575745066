import { Button, Table } from "semantic-ui-react";
import { DictionaryName, TDetailsField } from "~/store/dictionaries/types";
import UpdateDictionaryItem from "~/pages/Administration/lib/Dictionaries/Table/Update";
import RemoveDictionaryItem from "~/pages/Administration/lib/Dictionaries/Table/Remove";
import React, { useContext, useMemo } from "react";
import { ApplicationState } from "~/store";
import { connect } from "react-redux";
import { TableBodyProps } from "~/pages/Administration/lib/Dictionaries/Table/constants";
import { AVAILABLE_ROLE_ENUM } from "~/store/users/types";
import { AppContext } from "~/context/AuthContext";

const TableBody = ({
  dictionaries,
  refresh,
  name,
  filterDictionaryHeader,
}: TableBodyProps) => {
  const appContext = useContext(AppContext);

  const userHaveLdcUiAdministrationDictRole = useMemo(
    () =>
      appContext?.keycloak.hasResourceRole(
        AVAILABLE_ROLE_ENUM.LDC_ADMINISTRATION_DICT
      ),
    [appContext]
  );

  return (
    <Table.Body>
      {dictionaries.details.data.map((dict: any) => (
        <Table.Row key={`${dict.id}-${dict.key}`}>
          {dictionaries.details.fields
            .filter((field: TDetailsField) => filterDictionaryHeader(field))
            .map((detail: TDetailsField) => (
              <Table.Cell key={detail.name}>{dict[detail.name]}</Table.Cell>
            ))}
          {userHaveLdcUiAdministrationDictRole && (
            <Table.Cell>
              <UpdateDictionaryItem
                name={name as DictionaryName}
                itemKey={dict.key}
                itemData={dict}
                updatedSuccess={refresh}
                details={dictionaries.details}
              >
                <Button compact basic circular icon="edit" size="small" />
              </UpdateDictionaryItem>
              {dictionaries.details.name === "payment-method" ? null : (
                <RemoveDictionaryItem
                  name={name as DictionaryName}
                  itemKey={dict.key}
                  removedSuccess={refresh}
                >
                  <Button compact basic circular icon="remove" size="small" />
                </RemoveDictionaryItem>
              )}
            </Table.Cell>
          )}
        </Table.Row>
      ))}
    </Table.Body>
  );
};

const mapStateToProps = ({ dictionaries }: ApplicationState) => ({
  dictionaries,
});

export default connect(mapStateToProps)(TableBody);
