import { Button, Form, Header, Icon, Input, Message, Modal, Segment, Table, TextAreaProps } from 'semantic-ui-react';
import {
  loadLinesCsv,
  loadLinesExcel,
  loadLinesExcelSpecial,
  uploadBonusOrders,
  uploadCancelLines,
} from '~/store/orders/actions';
import { OrderBonusImportLine } from '~/store/orders/types';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ExampleCancelLineFile from '~/assets/files/examples/example_cancel_lines.csv';
import ExampleOrderBonusXls from '~/assets/images/examples/order-bonus-xls.png';
import ExampleOrderLineBonusXls from '~/assets/images/examples/order-bonus-line-xls.png';
import PreviewImage from '~/components/PreviewImage/PreviewImage';
import ModalMessageHeader from '~/components/Modals/ModalMessageHeader';

type loadLinesExcelType = typeof loadLinesExcel;
type loadLinesExcelSpecialType = typeof loadLinesExcelSpecial;

export const useUploadXlsModal = (
  open: boolean,
  closeHandler: () => void,
  loading: boolean,
  overwriteWarn: boolean,
  loadLinesExcel: loadLinesExcelType,
  loadLinesExcelSpecial: loadLinesExcelSpecialType,
  orderType: 'NORMAL' | 'WARRANTY' | 'BONUS' | 'SPECIAL' | 'BONUS_PLUS' | undefined
): JSX.Element => {
  const [file, setFile] = useState<File>();

  useEffect(() => {
    if (open) {
      setFile(undefined);
    }
  }, [open]);

  const handleLoad = useCallback((): void => {
    if (file) {
      if (orderType === 'SPECIAL') {
        loadLinesExcelSpecial(file);
      } else {
        loadLinesExcel(file);
      }
    }
  }, [file, loadLinesExcel, loadLinesExcelSpecial, orderType]);

  const handleFileChange = (ev: any, el: any): void => {
    setFile(ev.target.files[0]);
  };

  return useMemo(
    () => (
      <Modal open={open} onClose={closeHandler} size="small" closeOnDimmerClick={false}>
        <Header icon="file archive" content="Wstawianie linii zamówienia" />
        <Modal.Content>
          <Message icon>
            <Message.Content>
              Załaduj plik XLSX, XLS, CSV według wzorcowego formatu.
              <br />
              Po wczytaniu pliku rekordy zostaną dodane do zamówienia.
              <br />
              <span style={{ color: 'gray', fontSize: '10px' }}>*Maksymalny rozmiar pliku 15MB.</span>
              {overwriteWarn && <div style={{ color: 'red' }}>Istniejące linie zamówienia zostaną nadpisane!</div>}
            </Message.Content>
          </Message>
          <PreviewImage src={ExampleOrderLineBonusXls} />
          <Segment placeholder>
            {/* TODO Make whole segment as a drop-zone (use external lib) */}
            <Header icon>
              <Icon name="file archive outline" />
              {file ? file.name : 'Załaduj plik'}
            </Header>
            <Input type="file" onChange={handleFileChange} />
          </Segment>
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            content="Wczytaj"
            icon="upload"
            labelPosition="right"
            loading={loading}
            disabled={loading || !file}
            onClick={handleLoad}
          />
          <Button content="Zamknij" disabled={loading} onClick={closeHandler} />
        </Modal.Actions>
      </Modal>
    ),
    [open, loading, file, closeHandler, handleLoad, overwriteWarn]
  );
};

type loadLinesCsvType = typeof loadLinesCsv;

export const useUploadCsvModal = (
  open: boolean,
  closeHandler: () => void,
  loading: boolean,
  overwriteWarn: boolean,
  loadLinesCsv: loadLinesCsvType
): JSX.Element => {
  const [lines, setLines] = useState('');

  useEffect(() => {
    if (open) {
      setLines('');
    }
  }, [open]);

  const editLines = (ev: any, el: TextAreaProps): void => {
    setLines(el.value as string);
  };

  const linesCounter = lines === '' ? 0 : lines.split(/\r\n|\r|\n/).length;

  const handleLoad = useCallback((): void => {
    if (lines) {
      loadLinesCsv(lines);
    }
  }, [lines, loadLinesCsv]);

  return useMemo(
    () => (
      <Modal open={open} onClose={closeHandler} size="small" closeOnDimmerClick={false}>
        <Header icon="paste" content="Wstawianie linii zamówienia" />
        <Modal.Content>
          <Message icon>
            <Icon name="info circle" />
            <Message.Content>
              Wklej do poniższego pola listę referencji według poniższego wzorca. <br />
              Po wczytaniu pliku rekordy zostaną dodane do zamówienia.
              {overwriteWarn && <div style={{ color: 'red' }}>Istniejące linie zamówienia zostaną nadpisane!</div>}
            </Message.Content>
          </Message>
          <Form>
            <Form.TextArea
              label="Wzorzec"
              readOnly
              style={{ resize: 'none' }}
              value={`nr_linii;referencja_1;ilość_1\nnr_linii;referencja_2;ilość_2\nnr_linii;referencja_3;ilość_3`}
            ></Form.TextArea>
            <Form.TextArea label={`Liczba linii: ${linesCounter}`} rows="15" value={lines} onChange={editLines} />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            content="Wczytaj"
            icon="upload"
            labelPosition="right"
            loading={loading}
            disabled={loading || !lines}
            onClick={handleLoad}
          />
          <Button content="Zamknij" disabled={loading} onClick={closeHandler} />
        </Modal.Actions>
      </Modal>
    ),
    [open, loading, lines, closeHandler, handleLoad, linesCounter, overwriteWarn]
  );
};

type TReduxActions = {
  uploadBonusOrders?: typeof uploadBonusOrders;
  uploadCancelLines?: typeof uploadCancelLines;
};

type TProps = {
  open: boolean;
  loading: boolean;
  closeHandler: () => void;
  orderBonusLines?: OrderBonusImportLine[];
};

type TUploadBonusOrdersModalProps = TProps & TReduxActions;

export const UploadBonusOrdersModal: React.FC<TUploadBonusOrdersModalProps> = ({
  open,
  loading,
  uploadBonusOrders,
  closeHandler,
  orderBonusLines,
}): JSX.Element => {
  const [file, setFile] = useState<File>();
  const [fileUploaded, setFileUploaded] = useState<string>();

  useEffect(() => {
    if (open) {
      setFile(undefined);
    }
  }, [open]);

  const handleLoad = useCallback((): void => {
    if (file) {
      if (uploadBonusOrders) {
        uploadBonusOrders(file);
      }
      setFileUploaded(file.name);
    }
  }, [file, setFileUploaded, uploadBonusOrders]);

  const handleFileChange = (ev: any, el: any): void => {
    setFile(ev.target.files[0]);
  };

  const showUploadedOrderBonusLines = useCallback((): JSX.Element[] => {
    return orderBonusLines
      ? orderBonusLines.map((orderBonusLine) => {
          return (
            <Table.Row key={orderBonusLine.orderNumber}>
              <Table.Cell>{orderBonusLine.orderID}</Table.Cell>
              <Table.Cell>{orderBonusLine.orderNumber}</Table.Cell>
            </Table.Row>
          );
        })
      : [];
  }, [orderBonusLines]);

  return useMemo(
    () => (
      <Modal open={open} onClose={closeHandler} size="small" closeOnDimmerClick={false}>
        <Header icon="file archive" content="Dodawanie pliku z bonusowymi zamówieniami" />
        <Modal.Content>
          <ModalMessageHeader
            format
            additionalMessage="Po wczytaniu pliku, rekordy zostaną dodane do listy zamówień."
          />
          <PreviewImage src={ExampleOrderBonusXls} />
          <Segment placeholder>
            <div
              className="bonus-order-import-table-wrapper"
              style={{ display: orderBonusLines.length > 0 ? 'block' : 'none' }}
            >
              <span>
                Ilość wgranych zamówień: <strong>{orderBonusLines.length}</strong>
              </span>
              <Table>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>ID zamówienia</Table.HeaderCell>
                    <Table.HeaderCell>Numer zamówienia</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>{showUploadedOrderBonusLines()}</Table.Body>
              </Table>
            </div>
            <Header icon>
              <Icon name="file archive outline" />
              {file ? file.name : 'Załaduj plik'}
            </Header>
            <Input type="file" onChange={handleFileChange} />
          </Segment>
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            content="Wczytaj"
            icon="upload"
            labelPosition="right"
            loading={loading}
            disabled={loading || !file || (fileUploaded === file.name && orderBonusLines && orderBonusLines.length > 0)}
            onClick={handleLoad}
          />
          <Button content="Zamknij" disabled={loading} onClick={closeHandler} />
        </Modal.Actions>
      </Modal>
    ),
    [open, loading, file, orderBonusLines, closeHandler, fileUploaded, handleLoad, showUploadedOrderBonusLines]
  );
};

export const UploadLinesToCancel: React.FC<TUploadBonusOrdersModalProps> = ({
  open,
  loading,
  uploadCancelLines,
  closeHandler,
}): JSX.Element => {
  const [file, setFile] = useState<File>();
  const [fileUploaded, setFileUploaded] = useState<string>();

  useEffect(() => {
    if (open) {
      setFile(undefined);
    }
  }, [open]);

  const handleLoadUploadCancelLines = useCallback((): void => {
    if (file) {
      if (uploadCancelLines) {
        uploadCancelLines(file);
      }
      setFileUploaded(file.name);
    }
  }, [file, setFileUploaded, uploadCancelLines]);

  const handleFileChange = (ev: any, el: any): void => {
    setFile(ev.target.files[0]);
  };

  return useMemo(
    () => (
      <Modal open={open} onClose={closeHandler} size="small" closeOnDimmerClick={false}>
        <Header icon="file archive" content="Dodawanie pliku z liniami do anulowania" />
        <Modal.Content>
          <ModalMessageHeader format additionalMessage="Po wczytaniu pliku, rekordy zostaną anulowane." />
          <Button href={ExampleCancelLineFile} content="Pobierz przykładowy plik" icon="download" />
          <Segment placeholder>
            <Header icon>
              <Icon name="file archive outline" />
              {file ? file.name : 'Załaduj plik'}
            </Header>
            <Input type="file" onChange={handleFileChange} />
          </Segment>
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            content="Wczytaj"
            icon="upload"
            labelPosition="right"
            loading={loading}
            disabled={loading || !file || fileUploaded === file.name}
            onClick={handleLoadUploadCancelLines}
          />
          <Button content="Zamknij" disabled={loading} onClick={closeHandler} />
        </Modal.Actions>
      </Modal>
    ),
    [open, loading, file, closeHandler, fileUploaded, handleLoadUploadCancelLines]
  );
};
