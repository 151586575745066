import { Icon, Table, Checkbox, CheckboxProps } from 'semantic-ui-react'
import { DictionaryItem } from '~/store/dictionaries/types'

import React, { forwardRef, useImperativeHandle, useState } from 'react'
import InputFilter from './lib/InputFilter'
import DropdownFilter from './lib/DropdownFilter'

import './tableFilter.scss'

type Props = {
    columns: {
        name: string
        dictionary?: DictionaryItem[]
        projection: boolean
        type?: string,
        filteringBlocked?: boolean
    }[]
    initValues?: {
        name: string
        value: string
    }[]
    onAddFilter: (name: string, value: string, text?: string, key?: string) => void
    icon?: 'right' | 'left'
    checkboxes?: boolean
    checkboxesDisabled?: boolean
    checkboxChecked?: boolean
    checkboxesSelectAll?: () => void
}

export interface IRefObject {
    clearFilter(name: string): void
    clearAllFilter(): void
    changeTableFilter(name: string, value: string, key: string, text: string): void
    clearMultipleFilter(names: string[]): void
}

const TableFilter = forwardRef<IRefObject, Props>(({ columns, initValues = [], icon, checkboxes, checkboxChecked, checkboxesDisabled, checkboxesSelectAll, onAddFilter }, ref) => {

    const initState = {}

    columns.forEach(column => {
        const initValue = initValues.find(init => init.name === column.name)
        Object.assign(initState, { [column.name]: initValue ? initValue.value : '' })
    })

    const [filterValues, setFilterValues] = useState<{ [key: string]: string }>(initState)

    const changeFilter = (name: string, value: string, text?: string, key?: string): void => {
        const trimmedValue = value.trim()
        setFilterValues({
            ...filterValues,
            [name]: trimmedValue
        })
        onAddFilter(name, trimmedValue, text, key)
    }

    const changeInputValue = (name: string, value: string): void => {
        setFilterValues({
            ...filterValues,
            [name]: value
        })
    }

    useImperativeHandle(ref, () => ({
        clearFilter(name: string): void {
            setFilterValues({
                ...filterValues,
                [name]: ''
            })
        },
        clearMultipleFilter(names: string[]): void {
            names.map(key => filterValues[key] = '')
            setFilterValues({ ...filterValues })
        },
        clearAllFilter(): void {
            Object.keys(filterValues).map(key => filterValues[key] = '')
            setFilterValues({ ...filterValues })
        },
        changeTableFilter(name: string, value: string, text: string, key: string): void {
            changeFilter(name, value, text, key)
        }
    }))

    return (
        <Table.Row>
            {icon === 'left' && <Table.HeaderCell collapsing><Icon name='filter'/></Table.HeaderCell>}
            {checkboxes &&
            <Table.HeaderCell collapsing><Checkbox checked={checkboxChecked} disabled={!checkboxesDisabled}
                                                   className={!checkboxesDisabled ? 'checkboxes-disabled' : ''}
                                                   onClick={(ev, props: CheckboxProps) => checkboxesSelectAll && checkboxesSelectAll()}/></Table.HeaderCell>}
            {columns.map(column => {
                if (column.projection) {
                    if (column.filteringBlocked) {
                        return <Table.HeaderCell key={column.name}></Table.HeaderCell>
                    }
                    if (column.dictionary) {
                        if (column.name === 'invoiceLines.orderNumber' || column.name === 'sapInvoiceLines.sapOrderNumber'|| column.name === 'invoiceLines.serviceBoxOrderNo' ) {
                            return <Table.HeaderCell key={column.name}>
                                <DropdownFilter value={filterValues[column.name]} column={column}
                                                onChangeFilter={changeFilter} disabled={true}/>
                            </Table.HeaderCell>
                        } else {
                            return <Table.HeaderCell key={column.name}>
                                <DropdownFilter value={filterValues[column.name]} column={column}
                                                onChangeFilter={changeFilter} disabled={false}/>
                            </Table.HeaderCell>
                        }
                    }
                    if (column.name === 'invoiceLines.orderNumber' || column.name === 'sapInvoiceLines.sapOrderNumber' || column.name === 'invoiceLines.serviceBoxOrderNo' ) {
                        return <Table.HeaderCell key={column.name}>
                            <InputFilter value={filterValues[column.name]} column={column} type={column.type}
                                         onChangeFilter={changeFilter}
                                         onChangeInputValue={changeInputValue} disabled={true}/>
                        </Table.HeaderCell>
                    } else {
                        return <Table.HeaderCell key={column.name}>
                            <InputFilter value={filterValues[column.name]} column={column} type={column.type}
                                         onChangeFilter={changeFilter}
                                         onChangeInputValue={changeInputValue} disabled={false}/>
                        </Table.HeaderCell>
                    }
                } else {
                    filterValues[column.name] = ''
                }
                return null
            })}
            {icon === 'right' ? <Table.HeaderCell collapsing><Icon name='filter'/></Table.HeaderCell> :
                <Table.HeaderCell collapsing/>}
        </Table.Row>
    )
})

export default TableFilter
