import {
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { callApi } from "~/utils/api";
import { handleError } from "~/store/error/actions";
import translations from "~/utils/translations";
import { SearchProps } from "semantic-ui-react";
import { TProps } from "~/components/UberSearch/lib/types";

export const useUberSearch = (props: TProps) => {
  const [query, setQuery] = useState(props.value || "");
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState<any>([]);
  const lastQuery = useRef<string>(props.value || "");

  const {
    endpoint,
    searchParameterName = "query",
    queryParams = [],
    mapper,
    onResultSelect,
  } = props;

  const call = useCallback(async (): Promise<void> => {
    try {
      if (lastQuery.current === query) return;

      const res = await callApi(
        "GET",
        `${endpoint}?${searchParameterName}=${query}${queryParams
          .map((param) => `&${param.name}=${param.value}`)
          .join("")}`
      );
      setResults(mapper(res));
    } catch (error) {
      handleError(
        error,
        false,
        translations.format("app.error.order.select-spare-part")
      );
    }
    lastQuery.current = query;
    setLoading(false);
  }, [endpoint, mapper, query, queryParams, searchParameterName]);

  useEffect(() => {
    if (loading && query.length > 0) {
      (async () => call())();
    }
    if (loading && query.length === 0) {
      setResults([]);
      setLoading(false);
    }
    if (!loading && query.length > 0 && query !== lastQuery.current) {
      setLoading(true);
    }
  }, [call, loading, query]);

  const searchChangeHandler = (
    event: SyntheticEvent,
    data: SearchProps
  ): void => {
    setQuery(data.value || "");
    setLoading(true);
  };

  const resultSelectHandler = (
    event: SyntheticEvent,
    data: SearchProps
  ): void => {
    setQuery(onResultSelect(data.result));
  };

  return {
    searchChangeHandler,
    resultSelectHandler,
    loading,
    results,
    query,
  };
};
