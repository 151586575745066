import { connect } from "react-redux";
import {
  Button,
  Checkbox,
  Grid,
  Header,
  List,
  ListItem,
  Modal,
} from "semantic-ui-react";
import { ApplicationState } from "~/store";
import {
  changeUserRolesAndLimit,
  fetchAvailableRoles,
  fetchUserById,
  fetchUserRoles,
  resetUser,
} from "~/store/users/actions";

import React, {
  SyntheticEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import CommonLoader from "~/components/Loaders/CommonLoader";
import {
  ExtendedRole,
  ExtendedRoleGroup,
  TAddContractorProps,
  TReduxActions,
  TReduxState,
  VIEW,
} from "~/pages/Administration/lib/MenageUserRole/constants";
import RoleList from "./RoleList";
import {
  AVAILABLE_ROLE_ENUM,
  AvailableRole,
  AvailableRolesInGroups,
} from "~/store/users/types";
import "./style.scss";
import Limit from "~/pages/Administration/lib/MenageUserRole/Limit";
import { AppContext } from "~/context/AuthContext";
import WarningModal from "~/pages/Administration/lib/MenageUserRole/WarningModal";
import translations from "~/utils/translations";

const MenageUserRole: React.FC<TAddContractorProps> = ({
  children,
  availableRoles,
  userRoles,
  fetchAvailableRoles,
  fetchUserRoles,
  changeUserRolesAndLimit,
  loading,
  userId,
  userName,
  loadingAvailableRoles,
  loadingUserRoles,
  loadingChangeUserRole,
  loadingChangeLimit,
  fetchUserById,
  loadingUser,
  resetUser,
}) => {
  const [open, setOpen] = useState(false);
  const [openWarningModal, setOpenWarningModal] = useState(false);
  const [selectedRoles, setSelectedRoles] = useState<ExtendedRole[]>([]);
  const [extendedRoleGroups, setExtendedRoleGroups] =
    useState<ExtendedRoleGroup>({} as ExtendedRoleGroup);
  const [limit, setLimit] = useState(0);

  const appContext = useContext(AppContext);

  useEffect(() => {
    if (open && userId) {
      fetchAvailableRoles(userId);
      fetchUserRoles(userId);
      fetchUserById(userId);
    }
  }, [open, userId, fetchAvailableRoles, fetchUserRoles, fetchUserById]);

  const findGroupInObject = useCallback(
    (
      groupName: AVAILABLE_ROLE_ENUM,
      object: AvailableRolesInGroups | ExtendedRoleGroup
    ) => Object.keys(object).find((group) => group === groupName),
    []
  );

  const findRoleInUserRoles = useCallback(
    (group: string, roleId: string) =>
      userRoles[group].find((role: AvailableRole) => roleId === role.id),
    [userRoles]
  );

  const isCheckedNestedList = useCallback(
    (roleId: string, groupName: AVAILABLE_ROLE_ENUM): boolean => {
      const foundGroup = findGroupInObject(groupName, userRoles);

      return foundGroup ? !!findRoleInUserRoles(foundGroup, roleId) : false;
    },
    [findGroupInObject, findRoleInUserRoles, userRoles]
  );

  const createExtendedRoles = useCallback(
    (key: AVAILABLE_ROLE_ENUM, values: AvailableRole[]) =>
      values
        .map((value: AvailableRole) => ({
          ...value,
          isToAdd: false,
          isToRemove: false,
          isSelected: isCheckedNestedList(value.id, key),
        }))
        .sort((a: any, b: any) => {
          if (a.description < b.description) {
            return -1;
          }

          if (a.description > b.description) {
            return 1;
          }

          return 0;
        }),
    [isCheckedNestedList]
  );

  const createExtendedRoleGroups = useCallback(() => {
    const extendedRoleGroup: ExtendedRoleGroup = {} as ExtendedRoleGroup;

    Object.entries<AvailableRole[]>(availableRoles).forEach(([key, values]) => {
      extendedRoleGroup[key as AVAILABLE_ROLE_ENUM] = createExtendedRoles(
        key as AVAILABLE_ROLE_ENUM,
        values
      );
    });

    return extendedRoleGroup;
  }, [availableRoles, createExtendedRoles]);

  useEffect(() => {
    const extendedRoleGroup = createExtendedRoleGroups();

    setExtendedRoleGroups(extendedRoleGroup);
  }, [createExtendedRoleGroups]);

  const checkSelectedRole = (roles: ExtendedRole[]) =>
    roles.filter(
      (role) =>
        (role.name === AVAILABLE_ROLE_ENUM.LDC_ADMINISTRATION_USERS &&
          role.isToRemove) ||
        (role.name === AVAILABLE_ROLE_ENUM.LDC_ADMINISTRATION_VIEW &&
          role.isToRemove)
    );

  const handleOpen = useCallback((): void => {
    setOpen(true);
  }, []);

  const createDataToSend = useCallback(() => {
    let rolesToAdd: AvailableRole[] = [];
    let rolesToRemove: AvailableRole[] = [];

    const roles = Object.values<ExtendedRole[]>(extendedRoleGroups);

    roles.forEach((roles) => {
      roles.forEach((role: ExtendedRole) => {
        const { isToAdd, isSelected, isToRemove, ...other } = role;

        if (isToAdd) {
          rolesToAdd.push(other);
        }

        if (isToRemove) {
          rolesToRemove.push(other);
        }
      });
    });

    return {
      usersDiscountLimit: limit,
      rolesToAdd,
      rolesToRemove,
    };
  }, [extendedRoleGroups, limit]);

  const handleCancel = useCallback((): void => {
    setOpen(false);
    resetUser();
  }, [resetUser]);

  const handleRemove = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      changeUserRolesAndLimit(userId, createDataToSend(), () => {
        handleCancel();

        const isLoggedUser = userId === appContext?.keycloak.userInfo?.sub;

        if (isLoggedUser) {
          appContext?.changeKeycloak();
        }
      });
    },
    [
      appContext,
      changeUserRolesAndLimit,
      createDataToSend,
      handleCancel,
      userId,
    ]
  );

  const onSubmit = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
      e.preventDefault();

      const roles = Object.values<ExtendedRole[]>(extendedRoleGroups);
      const selectedExtendedRoles = checkSelectedRole(
        roles.flatMap((role) => role)
      );

      if (selectedExtendedRoles.length) {
        setSelectedRoles(selectedExtendedRoles);
        setOpenWarningModal(true);
      } else {
        handleRemove(e);
      }
    },
    [extendedRoleGroups, handleRemove]
  );

  const findViewRole = useCallback(
    (array: ExtendedRole[]) =>
      array.find((role: ExtendedRole) => role.name.includes(VIEW)),
    []
  );

  const isChecked = useCallback(
    (groupName: AVAILABLE_ROLE_ENUM): boolean => {
      const found = findGroupInObject(groupName, extendedRoleGroups);

      if (found) {
        const allRolesInGroup = extendedRoleGroups[groupName];
        const viewRole = findViewRole(allRolesInGroup);

        if (viewRole) {
          return viewRole.isSelected;
        }
      }

      return false;
    },
    [extendedRoleGroups, findGroupInObject, findViewRole]
  );

  const getUpdatedExtendedRoles = useCallback(
    (roles: ExtendedRole[], role: ExtendedRole, data: any) =>
      roles.map((value: ExtendedRole) => {
        if (value.name === role.name) {
          return {
            ...value,
            isToAdd: data.checked,
            isToRemove: !data.checked,
            isSelected: data.checked,
          };
        }

        if (
          role.name === AVAILABLE_ROLE_ENUM.LDC_CUSTOMER_EDIT &&
          value.name === AVAILABLE_ROLE_ENUM.LDC_CUSTOMER_MANAGEMENT &&
          value.isSelected
        ) {
          return {
            ...value,
            isToAdd: false,
            isToRemove: true,
            isSelected: false,
          };
        }

        return value;
      }),
    []
  );

  const getUpdatedExtendedRoleGroups = useCallback(
    (
      prevExtendedRoleGroups: ExtendedRoleGroup,
      role: ExtendedRole,
      data: any
    ) => {
      const newObject: ExtendedRoleGroup = {} as ExtendedRoleGroup;

      Object.entries(prevExtendedRoleGroups).forEach(([key, values]) => {
        newObject[key as AVAILABLE_ROLE_ENUM] = getUpdatedExtendedRoles(
          values,
          role,
          data
        );
      });

      return newObject;
    },
    [getUpdatedExtendedRoles]
  );

  const change = useCallback(
    (
      event: SyntheticEvent,
      data: any,
      roles: ExtendedRole[],
      isMainGroup?: boolean
    ): void => {
      event.preventDefault();
      let rolesToChange = roles;

      if (isMainGroup && data.checked) {
        rolesToChange = rolesToChange.filter((role) =>
          role.name.includes(VIEW)
        );
      }

      rolesToChange.forEach((role) => {
        setExtendedRoleGroups((prevExtendedRoleGroups) =>
          getUpdatedExtendedRoleGroups(prevExtendedRoleGroups, role, data)
        );
      });
    },
    [getUpdatedExtendedRoleGroups]
  );

  const closeWarningModal = useCallback(() => {
    setSelectedRoles([]);
    setOpenWarningModal(false);
    handleOpen();
  }, [handleOpen]);

  const confirmWarningModal = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();

      setOpenWarningModal(false);
      handleRemove(e);
    },
    [handleRemove]
  );

  return (
    <>
      <Modal
        size="fullscreen"
        trigger={children}
        open={open}
        onOpen={handleOpen}
        onClose={handleCancel}
      >
        <Header icon="group" content="Zmiana praw użytkownika" />
        <Modal.Content>
          <p>Zarządzaj prawami użytkownika {userName}</p>
          <CommonLoader
            loading={
              loading ||
              loadingAvailableRoles ||
              loadingUserRoles ||
              loadingChangeUserRole ||
              loadingChangeLimit ||
              loadingUser
            }
          />
          <List relaxed>
            <Grid columns={4}>
              {Object.entries(extendedRoleGroups).map(([key, value]) => (
                <Grid.Column key={key}>
                  <ListItem className="listItem">
                    <Checkbox
                      label={{
                        children: (
                          <>
                            <strong>
                              {translations.format(key).toUpperCase()}
                            </strong>
                            {translations.format("userRoleDisplay")}
                          </>
                        ),
                      }}
                      checked={isChecked(key as AVAILABLE_ROLE_ENUM)}
                      onChange={(e, d) => change(e, d, value, true)}
                    />
                    {key === AVAILABLE_ROLE_ENUM.LDC_ORDER && (
                      <Limit limit={limit} setLimit={setLimit} />
                    )}
                    <RoleList
                      extendedRoleGroups={extendedRoleGroups}
                      roles={value}
                      change={change}
                      groupName={key}
                      mainRoleIsChecked={isChecked(key as AVAILABLE_ROLE_ENUM)}
                    />
                  </ListItem>
                </Grid.Column>
              ))}
            </Grid>
          </List>
        </Modal.Content>
        <Modal.Actions>
          <Button
            content={translations.format("app.cancel")}
            onClick={handleCancel}
          />
          <Button
            primary
            content={translations.format("app.save")}
            onClick={onSubmit}
          />
        </Modal.Actions>
      </Modal>
      <WarningModal
        open={openWarningModal}
        handleCancel={closeWarningModal}
        roles={selectedRoles}
        handleRemove={confirmWarningModal}
        userName={userName}
      />
    </>
  );
};

const mapStateToProps: (state: ApplicationState) => TReduxState = ({
  users,
}: ApplicationState) => {
  return {
    availableRoles: users.availableRoles,
    userRoles: users.userRoles,
    loading: users.loadingGroups,
    loadingUserRoles: users.loadingUserRoles,
    loadingAvailableRoles: users.loadingAvailableRoles,
    loadingChangeUserRolesAndLimit: users.loadingChangeUserRolesAndLimit,
    loadingUser: users.loadingUser,
  };
};

const mapDispatchToProps: TReduxActions = {
  fetchAvailableRoles,
  fetchUserRoles,
  changeUserRolesAndLimit,
  fetchUserById,
  resetUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(MenageUserRole);
