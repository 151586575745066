import {
    Button,
    Divider,
    Form,
    Grid,
    Header,
    Modal,
    Label,
    Icon
  } from "semantic-ui-react";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import UberSearch from "~/components/UberSearch/UberSearch";
import { ClassAndBrands, CustomerClasses } from "~/store/customer/types";

import "./style.scss";
import { fetchClassesAndBrands } from "~/store/customer/actions";
import translations from "~/utils/translations";
import CommonLoader from "~/components/Loaders/CommonLoader";
  
  type TProps = {
    triggerButton?: ReactElement;
    categoryType: string[];
    categoryOrganisation: string[];
    ruleRrdis: string[] | null;
    handleSaveModal: (data: {
        classList: string[];
        brandList: string[];
        rrdiList: string[];
      }) => void,
    fetchClassesAndBrands: typeof fetchClassesAndBrands,
    classesAndBrands?: ClassAndBrands[],
    classesAndBrandsLoading: boolean
  };

  type InputResponse = {
    rrdi: string,
    name: string,
    informationAddressStreet: string,
    informationAddressNumber: string,
    informationAddressCity: string
  }

  type CustomerBrand = {
    name: string,
    checked: boolean,
    brandRequired: boolean
  }

  type CustomerClass = {
    name: string,
    checked: boolean,
    brands: CustomerBrand[]
  }
  
  const CustomerModal: React.FC<TProps> = ({
    triggerButton,
    categoryType,
    categoryOrganisation,
    ruleRrdis,
    handleSaveModal,
    fetchClassesAndBrands,
    classesAndBrands,
    classesAndBrandsLoading
  }) => {
  
    const [rrdis, setRrdis] = useState<string[]>(ruleRrdis || [])
    const [classes, setClasses] = useState<CustomerClass[]>()
    const [brands, setBrands] = useState<CustomerBrand[]>([])
    const [open, setOpen] = useState<boolean>(false)
    const [validateErrorRRdis, setValidateEroorRrdis] = useState<boolean>(false)
    const [validateErrorClasses, setValidateEroorClasses] = useState<boolean>(false)
    const [validateErrorBrands, setValidateEroorBrands] = useState<boolean>(false)
  
    const mapBrands = (classesToMap: any[]) => {
      const newBrands:any[] = []
      classesToMap.forEach(customerClass => {
        if (customerClass.checked) {
          newBrands.push(customerClass.brands.map((brand: any) => ({...brand})))
        }
      })
      const flatBrands = newBrands.flat()
      const ids = flatBrands.map(({ name }) => name);
      const filtered = flatBrands.filter(({ name }, index) =>
      !ids.includes(name, index + 1));
      setBrands(filtered)
    }
  
    const mapClasses = useCallback((data: ClassAndBrands[] | undefined) => {
      if (!data) return
      const dataToReturn = data.map(item => ({
        name: item.name,
        checked: !!categoryType.find(classItem => classItem === item.name), 
        brands: item.brands.map((brand: any) => ({
          name: brand, 
          checked: !!categoryOrganisation?.find(item => item === brand) || item.brandRequired,
          brandRequired: item.name === CustomerClasses.AUTHORIZED_SERVICE ? false : item.brandRequired
        }
        ))}))
        mapBrands(dataToReturn)
        setClasses(dataToReturn)
    }, [categoryOrganisation, categoryType])

    useEffect(() => {
      if (open && !classesAndBrands) {
        fetchClassesAndBrands()
      }
    }, [open, classesAndBrands, fetchClassesAndBrands])
  
    useEffect(() => {
      if (open && classesAndBrands) {
        mapClasses(classesAndBrands)
      }
    }, [open, mapClasses, classesAndBrands])
  
    const handleCancel = (): void => {
      setOpen(false);
    };
  
    const handleOpen = (): void => {
      setOpen(true);
    };

    const handleSave = (): void => {
        const data = prepareData()
        const isValid = validate()
        if (!data || !isValid) {
            return
        } else {
          handleSaveModal(data)
        }
    }
  
    const classClickHandle = (className: string): void => { 
      if (!classes) return
      resetValidationErrors(true, true, true)
      const index = classes.findIndex(item => item.name === className)
      const updatedClasses = [...classes]
      updatedClasses[index].checked = !updatedClasses[index].checked;
      setClasses(updatedClasses)
      const newBrands:any[] = []

    updatedClasses.forEach(customerClass => {
      if (customerClass.checked) {
        newBrands.push(customerClass.brands.map((brand) => (
          {
            ...brand, 
            checked: true,
          }
        )))
      }
    })
    const flatBrands = newBrands.flat()
    const ids = flatBrands.map(({ name }) => name);
    const filtered = flatBrands.filter(({ name }, index) =>
    !ids.includes(name, index + 1));
    setBrands(filtered)
    }

    const resetValidationErrors = (rrdis?: boolean, classes?: boolean, brands?: boolean): void => {
      if (rrdis) {
        setValidateEroorRrdis(false)
      }
      if (classes) {
        setValidateEroorClasses(false)
      }
      if (brands) {
        setValidateEroorBrands(false)
      }
    }
  
    const brandClickHandle = (brandName: string, event?: any): void => {
      
      if (!event || (event && event.target.type === 'text') ) return
      const index = brands.findIndex(item => item.name === brandName)
      if (brands[index].brandRequired) return
      const updatedBrands = [...brands]
      updatedBrands[index].checked = !updatedBrands[index].checked;
      setBrands(updatedBrands)
      resetValidationErrors(false, false, true)
    }

    const validate = (): boolean => {
      if (rrdis && rrdis.length) {
        return true
      } 
      if (!rrdis.length && !classes?.find(item => item.checked)) {
        setValidateEroorRrdis(true)
        setValidateEroorClasses(true)
        return false
      }     
      if (classes?.find(item => item.name === CustomerClasses.AUTHORIZED_SERVICE && item.checked)) {
        if (!brands.filter(item => !!brandsToValidate().find(brand => brand === item.name && item.checked)).length) {
          setValidateEroorBrands(true)
          return false
        } else {
          return true
        }
      } else {
        return true
      }
    }
  
    const prepareData = (): {classList: string[], brandList: string[], rrdiList: string[]} | undefined => {
        if (!classes) return undefined

        const classesToSend = classes.filter(customerClass => customerClass.checked).map(customerClass => customerClass.name)
        const brandsToSend = brands.filter(brand => brand.checked || brand.brandRequired).map(brand => brand.name)

        return {
            classList: classesToSend || [],
            brandList: brandsToSend || [],
            rrdiList: rrdis || []
        }
    }
  
    const renderDescription = (): JSX.Element => (
        <div style={{fontStyle: "italic", minHeight: 40}}>
            {rrdis.length > 0 ? <>Kontrahenci {rrdis.map((rrdi) =>
                <Label key={rrdi} style={{ margin: 1 }}>{rrdi}
                    <Icon name="delete" onClick={() => {
                        setRrdis(rrdis.filter((id) => id !== rrdi))
                    }} />
                </Label>
            )
            }</> : <>Wszyscy kontrahenci</>}
            {classes && classes.filter(item => item.checked).length ? <>
                {classes.filter(item => item.checked).length > 1 ? " z klasami: " : " z klasą "} 
                <span style={{fontWeight: 700}}>{classes.filter(item => item.checked).map(item => translations.format(`app.customer-class.${item.name}`)).join(", ")}</span>
            </> : <> z dowolną klasą</>}
            {classes && classes.filter(item => item.checked).length && brands ? <> 
                {brands.filter(item => item.checked).length > 1 ? " i obsługiwanymi markami: " : " i obsługiwaną marką "}
                <span style={{fontWeight: 700}}>{brands.filter(item => item.checked).map(item => translations.format(`app.customer-brand.${item.name}`)).join(", ")}.</span>
            </> : <> i dowolną obsługiwaną marką.</>}
        </div>
    )

    const brandsToValidate = (): string[] => {
        return classesAndBrands?.find(item => item.name === CustomerClasses.AUTHORIZED_SERVICE)!.brands.map(item => item) || []
    }

    const brandButtonStyleClasses = (brandName: string): string => {
        if (brandsToValidate().find(item => item === brandName)) {
            return `radio-button${validateErrorBrands ? ' validate-error' : ''}` 
        } else {
            return "radio-button"
        }
    }
  
    return (
      <Modal
        size="small"
        scrollable
        trigger={
          triggerButton || (
            <Button
              style={{position: "absolute", bottom: -40, right: 0}}
              color="orange"
              content="Edytuj"
            />
          )
        }
        open={open}
        onOpen={handleOpen}
        onClose={handleCancel}
        closeOnDimmerClick={false}
      >
        <Header icon="edit" content="Edytuj warunki - kontrahenci" />
        <Modal.Content scrolling style={{maxHeight: "calc(80vh - 3em)"}}>
            <CommonLoader loading={classesAndBrandsLoading} />
            {renderDescription()}
            <Divider/>
            <Form>
            <div className="validate-error-container">
              <h5>Kod klienta:</h5>
              <span className="validate-error-message" style={{display: validateErrorRRdis ? "inline" : "none"}}> Przynajmniej jeden kontrahent lub klasa klienta powinna być zaznaczona</span>
            </div>
                <div className="ui field" style={{width: 350}}>
                    <UberSearch
                        endpoint="/customers/delivery-points/promo"
                        input={{ fluid: true, placeholder: 'Dodaj...' }}
                        mapper={(response) => response.content.map((res: InputResponse) => ({
                            key: res.rrdi,
                            title: `${res.rrdi} ${res.name}`,
                            description: `${res.informationAddressCity}, ${res.informationAddressStreet} ${res.informationAddressNumber}`
                        }))}
                        onResultSelect={(selected: any) => {
                            if (rrdis.indexOf(selected.key) === -1) {
                                setRrdis([...rrdis, selected.key])
                                resetValidationErrors(true, true, true)
                            }
                            return ''
                        }}
                        className={`customer-search ${validateErrorClasses ? 'validate-error' : ''}`} 
                    />
                </div>
          </Form>
          <Divider></Divider>
          <div className="validate-error-container">
            <h5>Klasy:</h5>
            <span className="validate-error-message" style={{display: validateErrorClasses ? "inline" : "none"}}></span>
          </div>
          <Grid style={{ marginBottom: "unset", marginLeft: 0 }} stretched>
            {classes && classes.map(customerClass => (
              <Button
                key={customerClass.name}
                fluid
                basic={true}
                onClick={() => classClickHandle(customerClass.name)}
                className={`radio-button ${validateErrorClasses ? 'validate-error' : ''}`} 
              >
                <Form.Radio
                    label={translations.format(`app.customer-class.${customerClass.name}`)}
                    checked={customerClass.checked}
                    value={customerClass.name}
                />
              </Button>
            ))}
          </Grid>
          <Divider></Divider>
          <div className="validate-error-container">
            <h5>Marki:</h5>
            <span className="validate-error-message" style={{display: validateErrorBrands ? "inline" : "none"}}> Przynajmniej jedna z wyróżnionych marek musi być zaznaczona</span>
          </div>
          <Grid style={{ marginBottom: "unset",  marginLeft: 0, minHeight: 200, alignContent: "flex-start" }} stretched>
            {brands.map((brand) => (
              <Button
                key={brand.name}
                fluid
                basic={true}
                onClick={(event: any) => brandClickHandle(brand.name, event)}
                className={brandButtonStyleClasses(brand.name)}
              >
                <Form.Radio
                    label={translations.format(`app.customer-brand.${brand.name}`)}
                    checked={brand.brandRequired || brand.checked}
                    value={brand.name}
                    disabled={brand.brandRequired}
                    onClick={() => brandClickHandle(brand.name)}
                />
              </Button>
            ))}
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button content="Zapisz" primary onClick={() => {handleSave()}} />
          <Button content="Zamknij" onClick={handleCancel} />
        </Modal.Actions>
      </Modal>
    );
  };
  
  export default CustomerModal;  