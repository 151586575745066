import { useContext } from 'react';
import { AppContext } from '~/context/AuthContext';
import { AVAILABLE_ROLE_ENUM } from '~/store/users/types';

export const useClaimsRolesRights = () => {
  const context = useContext(AppContext);

  const userHaveLdcUiClaimsProcessRole: boolean = context?.keycloak?.hasResourceRole(
    AVAILABLE_ROLE_ENUM.LDC_CLAIMS_PROCESS
  );

  return { userHaveLdcUiClaimsProcessRole };
};
