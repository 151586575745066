import { Link } from "react-router-dom";
import { Dropdown, Table } from "semantic-ui-react";
import { SparePart } from "~/store/spare-parts/types";

import React from "react";
import TableNoResults from "~/components/TableNoResults/TableNoResults";
import TableMoreDropdown from "~/components/TableMoreDropdown/TableMoreDropdown";
import { TColumn } from "~/components/Table/lib/types";
import { useSparePartsRolesRights } from "./hooks/useSparePartsRolesRights";

type TSparePartsListProps = {
  spareParts: SparePart[];
  columns: TColumn[];
};

const SparePartsList: React.FC<TSparePartsListProps> = ({
  spareParts,
  columns,
}) => {
  const { userHaveLdcUiProductEditRole } = useSparePartsRolesRights();

  const productListDropdown = (sparePart: SparePart): JSX.Element => {
    return (
      <TableMoreDropdown>
        <Dropdown.Item
          as={Link}
          to={`/product/${sparePart.referenceId}`}
          text="Podgląd danych produktu"
        />
        {userHaveLdcUiProductEditRole && (
          <Dropdown.Item
            as={Link}
            to={`/product/${sparePart.referenceId}/edit`}
            text="Edytuj produkt"
          />
        )}
      </TableMoreDropdown>
    );
  };

  if (spareParts && !spareParts.length) {
    return <TableNoResults />;
  }

  return (
    <>
      {spareParts &&
        spareParts.length &&
        spareParts.map((sparePart) => (
          <Table.Row key={sparePart.referenceId}>
            {columns.map(
              (column) =>
                column.projection &&
                column.getCell &&
                column.getCell(sparePart, column)
            )}
            <Table.Cell className="col-dropdown-menu-sticky">
              {productListDropdown(sparePart)}
            </Table.Cell>
          </Table.Row>
        ))}
    </>
  );
};

export default SparePartsList;
