import { connect } from 'react-redux';
import { Button, Table } from 'semantic-ui-react';
import { ApplicationState } from '~/store';
import { DictionariesState } from '~/store/dictionaries/types';
import { OrderLine } from '~/store/orders/types';
import { SparePartPricing } from '~/store/price-list/types';
import { TOrderColumn } from '../index';

import React, { SyntheticEvent, useEffect, useState } from 'react';
import SmartWrapper from '~/components/SmartField/SmartWrapper';
import UberSearch from '~/components/UberSearch/UberSearch';
import '../../styles.scss';

import { LinesTableCell } from '~/pages/Orders/Details/lib/Lines/Item/TableCell';

type TReduxState = {
  dictionaries: DictionariesState;
};

type TReduxActions = {};

type TCompProps = {
  orderId: string;
  loading: boolean;
  columns: TOrderColumn[];
  lineIn: OrderLine;
  lines: OrderLine[];
  isNew: boolean;
  editMode: boolean;
  orderType: string;
  orderStatus: string;
  limit?: any;
  ldcOrderId?: string;
  getInvoice: (invoiceNo: string, documentMask: string) => void;
  addCodingHandler: (line: OrderLine) => void;
  saveNewLineHandler: (referenceId: string) => void;
  updateLineHandler: (line: OrderLine) => void;
  removeLineHandler: (line: OrderLine) => void;
  documentPreviewHandler: (document: string) => void;
  invoiceErpPreviewHandler: (document: string) => void;
  setDisableCancelLinesButton: (disableButton: boolean) => void;
};

type TOrderLineProps = TReduxState & TReduxActions & TCompProps;

type ProductOrderItem = {
  key: string;
  title: string;
  price: string;
  product: SparePartPricing;
};

const OrderItem: React.FC<TOrderLineProps> = ({
  limit,
  orderId,
  loading,
  columns,
  lineIn,
  lines = [],
  isNew,
  editMode,
  orderType,
  orderStatus,
  ldcOrderId,
  getInvoice,
  documentPreviewHandler,
  invoiceErpPreviewHandler,
  addCodingHandler,
  saveNewLineHandler,
  updateLineHandler,
  removeLineHandler,
  setDisableCancelLinesButton,
}) => {
  const [line, setLine] = useState(lineIn);
  const [selectedLine, setSelectedLine] = useState<OrderLine[]>(lines);

  useEffect(() => {
    setLine(lineIn);
  }, [lineIn]);

  const handleRemoveLine = (): void => {
    removeLineHandler(line);
  };

  if (isNew) {
    const handleAddNewLine = (selected: any) => {
      saveNewLineHandler(selected.key);
      return '';
    };

    const mapProducts = (products: SparePartPricing[]): ProductOrderItem[] => {
      return products.map((product) => ({
        key: product.referenceId,
        title: product.description,
        price: product.referenceId,
        product: product,
      }));
    };

    return (
      <Table.Row>
        <Table.Cell>{line.lineNo}</Table.Cell>
        <Table.Cell colSpan="2" style={{ overflow: 'visible' }}>
          <UberSearch
            fluid
            input={{ placeholder: 'Wyszukiwanie referencji' }}
            endpoint="/spare-parts-price-list"
            mapper={(response) => mapProducts(response.slice(0, 10))}
            onResultSelect={handleAddNewLine}
          />
        </Table.Cell>
        <Table.Cell colSpan="16" style={{ borderLeft: 'none' }} />
        <Table.Cell textAlign="center" className="col-dropdown-menu-sticky">
          <Button basic compact circular icon="delete" onClick={handleRemoveLine} disabled={loading || !editMode} />
        </Table.Cell>
      </Table.Row>
    );
  }

  const handleLineUpdate = (line: OrderLine, response: any): void => {
    const newLine = {
      ...line,
      totalNetPrice: line.netPrice * line.quantity,
      totalGrossPrice: (line.netPrice + line.taxValue) * line.quantity,
    };
    setLine(newLine);
    updateLineHandler(newLine);
  };

  const getColorBySapStatus = (status?: string): string => {
    switch (status) {
      case 'PROCESSED_BY_SAP':
        return 'rgba(0,0,0, 0.4)';
      case 'REPLACED_BY_SAP':
        return 'rgba(0,0,0, 0.6)';
      case 'REJECTED':
        return 'rgba(255, 0, 0, 0.25)';
      case 'ADDED_BY_SAP':
        return 'rgba(0,0,0, 0.8)';
      default:
        return '';
    }
  };

  const getCell = (column: TOrderColumn): JSX.Element => (
    <LinesTableCell
      selectLine={selectLine}
      orderStatus={orderStatus}
      loading={loading}
      lines={lines}
      editMode={editMode}
      addCodingHandler={addCodingHandler}
      orderType={orderType}
      limit={limit}
      documentPreviewHandler={documentPreviewHandler}
      invoiceErpPreviewHandler={invoiceErpPreviewHandler}
      getInvoice={getInvoice}
      column={column}
      line={line}
      ldcOrderId={ldcOrderId}
    />
  );

  const selectLine = (lineNumber: any, event: SyntheticEvent, data: any): void => {
    let lineReference: string[] = [];
    setSelectedLine(
      lines.map((line) => {
        if (line.referenceID === lineNumber) {
          line.selected = data.checked;
          return { ...line, selected: data.checked };
        }
        return { ...line };
      })
    );
    lines.map((line) => {
      if (line.selected) {
        lineReference.push(line.referenceID);
      }
      return line;
    });
    if (selectedLine && lineReference.length > 0) {
      setDisableCancelLinesButton(false);
    } else {
      setDisableCancelLinesButton(true);
    }
  };

  return (
    <SmartWrapper
      key={line.lineNo}
      endpoint={`/orders/${orderId}/lines/${line.lineNo}`}
      model={line}
      onUpdate={handleLineUpdate}
    >
      <Table.Row
        key={line.lineNo}
        className={
          line.type === 'REPLACED' ||
          line.type === 'REPLACED_TO_ORIGINAL' ||
          (line.rejectionReasons !== null && line.rejectionReasons.length > 0)
            ? 'text-red'
            : ''
        }
        style={{ color: getColorBySapStatus(line.sapStatus) }}
      >
        {columns.map(getCell)}
        {editMode && (
          <Table.Cell textAlign="center" className="col-dropdown-menu-sticky">
            {line.type !== 'PROVISION' && line.type !== 'REPLACEMENT' && line.type !== 'REPLACEMENT_ORIGINAL' && (
              <Button basic compact circular icon="delete" onClick={handleRemoveLine} disabled={loading || !editMode} />
            )}
          </Table.Cell>
        )}
      </Table.Row>
    </SmartWrapper>
  );
};

const mapStateToProps: (state: ApplicationState) => TReduxState = ({
  orders,
  dictionaries,
  pricing,
}: ApplicationState) => {
  return {
    dictionaries,
  };
};

const mapDispatchToProps: TReduxActions = {};

export default connect(mapStateToProps, mapDispatchToProps)(OrderItem);
