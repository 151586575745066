import { DropdownItemProps } from "semantic-ui-react"
import { SmartField } from '../SmartField'
import { TBaseOptProps, TLabelOptProps, TSearchOptProps } from '../lib/types'
import { extractValue } from '~/utils/model'

import React from 'react'
import { SmartSelect } from "~/components/SmartField/SmartSelect";
import { SmartSearch } from "~/components/SmartField/SmartSearch";
import { SmartToggle } from "~/components/SmartField/SmartToggle";
import { SmartLabel } from "~/components/SmartField/SmartLabel";
import { SmartTextArea } from "~/components/SmartField/SmartTextArea";

export const useRenderingFunctions = <T,>(model: T = {} as T, editMode: boolean) => {

    const defaultValue = (value: any, defaultValue: any): any => {
        return value != null ? value : defaultValue
    }

    const getValue = (name: string): any => {
        if (name.indexOf('.') !== -1) {
            return extractValue(model, name)
        } else {
            return model[name as keyof T]
        }
    }

    const renderField = (name: keyof T | string, optionals?: TBaseOptProps): JSX.Element => {
        const { readOnly, ...optionalsLite } = optionals || {}
        return (
            <SmartField
                name={name as string}
                value={defaultValue(getValue(name as string), "")}
                readOnly={!editMode || readOnly}
                {...optionalsLite}
            />
        )
    }

    const renderNumber = (name: keyof T | string, optionals?: TBaseOptProps): JSX.Element => {
        const { readOnly, ...optionalsLite } = optionals || {}
        return (
            <SmartField
                min={0}
                name={name as string}
                value={defaultValue(getValue(name as string), 0)}
                type="number"
                readOnly={!editMode || readOnly}
                {...optionalsLite}
            />
        )
    }

    const renderSelect = (name: keyof T | string, options: DropdownItemProps[], optionals?: TBaseOptProps): JSX.Element => {
        const { readOnly, ...optionalsLite } = optionals || {}
        return (
            <SmartSelect
                name={name as string}
                value={defaultValue(getValue(name as string), "")}
                options={options}
                readOnly={!editMode || readOnly}
                {...optionalsLite}
            />
        )
    }

    const renderSearch = (name: keyof T, results: any[], updateSearch: (searchText: string) => void, optionals?: TSearchOptProps): JSX.Element => {
        const { readOnly, ...optionalsLite } = optionals || {}
        return (
            <SmartSearch
                name={name as string}
                value={defaultValue(getValue(name as string), "")}
                results={results}
                updateSearch={updateSearch}
                readOnly={!editMode || readOnly}
                {...optionalsLite}
            />
        )
    }

    const renderToggle = (name: keyof T, optionals?: TBaseOptProps): JSX.Element => {
        const { readOnly, ...optionalsLite } = optionals || {}
        return (
            <SmartToggle
                name={name as string}
                value={defaultValue(getValue(name as string), false)}
                readOnly={!editMode || readOnly}
                {...optionalsLite}
            />
        )
    }

    const renderTextArea = (name: keyof T | string, optionals?: TBaseOptProps): JSX.Element => {
        const { readOnly, ...optionalsLite } = optionals || {}
        return (
            <SmartTextArea
                name={name as string}
                value={defaultValue(getValue(name as string), "")}
                readOnly={!editMode || readOnly}
                {...optionalsLite}
            />
        )
    }

    const renderLabel = (label: string | JSX.Element, value?: any, optionals?: TLabelOptProps): JSX.Element => {
        return (
            <SmartLabel
                label={label}
                value={value}
                {...optionals}
            />
        )
    }

    return {
        renderField, renderNumber, renderSelect, renderSearch, renderToggle, renderTextArea, renderLabel
    }
}
