import { QuerySearchRequest } from "~/store/types";
import { action } from "typesafe-actions";
import {
  CustomerBase,
  CustomersActionTypes,
  CustomerSelected,
  PaginableCustomer,
  DeliveryLocation,
  TPreImport,
  Customer,
  CurrentAccountingCustomer,
  AdditionalDiscountSaveDTO,
  AdditionalDiscountHistory,
} from "./types";

export const fetchCustomers = (params: any) =>
  action(CustomersActionTypes.FETCH_REQUEST, params);
export const fetchSuccess = (data: PaginableCustomer) =>
  action(CustomersActionTypes.FETCH_SUCCESS, data);

export const fetchSearchSuccess = (data: PaginableCustomer) =>
  action(CustomersActionTypes.FETCH_SEARCH_SUCCESS, data);
export const fetchCustomersSearch = (params: any) =>
  action(CustomersActionTypes.FETCH_SEARCH_REQUEST, params);

export const filterCustomers = (query: string) =>
  action(CustomersActionTypes.FILTER_CUSTOMERS, query);

export const fetchDeliveryPoints = (params: QuerySearchRequest) =>
  action(CustomersActionTypes.FETCH_DELIVERY_POINTS, params);
export const fetchDeliveryPointsSuccess = (data: DeliveryLocation[]) =>
  action(CustomersActionTypes.FETCH_DELIVERY_POINTS_SUCCESS, data);

export const selectCustomer = (customer_id: string) =>
  action(CustomersActionTypes.SELECT_CUSTOMER, customer_id);
export const customerSelected = (customer: CustomerSelected) =>
  action(CustomersActionTypes.SELECTED, customer);
export const clearSelected = () => action(CustomersActionTypes.CLEAR_SELECTED);

export const addCustomer = (customer: CustomerBase) =>
  action(CustomersActionTypes.ADD_CUSTOMER, customer);
export const addSuccess = (rrdi: string) =>
  action(CustomersActionTypes.ADD_SUCCESS, rrdi);

export const removeCustomerWarehouse = (rrdi: string, warehouseId: string) =>
  action(CustomersActionTypes.DELETE_CUSTOMER_WAREHOUSE, { rrdi, warehouseId });

export const preImportCustomers = (file: File) =>
  action(CustomersActionTypes.PRE_IMPORT, file);
export const preImportCustomersSuccess = (result: TPreImport) =>
  action(CustomersActionTypes.PRE_IMPORT_SUCCESS, result);

export const importCustomersAdd = (file: File) =>
  action(CustomersActionTypes.IMPORT_ADD, file);
export const importCustomersAddSuccess = () =>
  action(CustomersActionTypes.IMPORT_ADD_SUCCESS);

export const importCustomersUpdate = (file: File) =>
  action(CustomersActionTypes.IMPORT_UPDATE, file);
export const importCustomersUpdateSuccess = () =>
  action(CustomersActionTypes.IMPORT_UPDATE_SUCCESS);

export const clearImportCustomers = () =>
  action(CustomersActionTypes.CLEAR_IMPORT);

export const fetchPayerList = () =>
  action(CustomersActionTypes.FETCH_PAYER_LIST);
export const fetchPayerListSuccess = (payerList: Customer[]) =>
  action(CustomersActionTypes.FETCH_PAYER_LIST_SUCCESS, payerList);
export const fetchPayerListFailed = () =>
  action(CustomersActionTypes.FETCH_PAYER_LIST_FAILED);

export const fetchCurrentPayer = (customerId: string) =>
  action(CustomersActionTypes.FETCH_CURRENT_PAYER, customerId);
export const fetchCurrentPayerSuccess = (
  currentPayer: CurrentAccountingCustomer
) => action(CustomersActionTypes.FETCH_CURRENT_PAYER_SUCCESS, currentPayer);
export const fetchCurrentPayerFailed = () =>
  action(CustomersActionTypes.FETCH_CURRENT_PAYER_FAILED);

export const fetchPayerHistoryList = (customerId: string) =>
  action(CustomersActionTypes.FETCH_PAYER_HISTORY_LIST, customerId);
export const fetchPayerHistoryListSuccess = (
  payerHistoryList: CurrentAccountingCustomer[]
) =>
  action(
    CustomersActionTypes.FETCH_PAYER_HISTORY_LIST_SUCCESS,
    payerHistoryList
  );
export const fetchPayerHistoryListFailed = () =>
  action(CustomersActionTypes.FETCH_PAYER_HISTORY_LIST_FAILED);

export const addNewPayer = (customerId: string, data: any, cb: () => void) =>
  action(CustomersActionTypes.ADD_NEW_PAYER, { customerId, data, cb });
export const addNewPayerActionStop = () =>
  action(CustomersActionTypes.ADD_NEW_PAYER_ACTION_STOP);

export const downloadProductGroup = (customerId: string, groupId: string) => action(CustomersActionTypes.DOWNLOAD_PRODUCTS_GRUP, {customerId, groupId});
export const downloadProductGroupSuccess = () => action(CustomersActionTypes.DOWNLOAD_PRODUCTS_GRUP_SUCCESS);
export const downloadProductGroupError = () => action(CustomersActionTypes.DOWNLOAD_PRODUCTS_GRUP_ERROR);

export const saveAdditionalDiscount = (customerId: string, DTO: AdditionalDiscountSaveDTO) => action(CustomersActionTypes.SAVE_ADDITIONAL_DISCOUNT, {customerId, DTO});
export const saveAdditionalDiscountSuccess = (currentDiscount: AdditionalDiscountHistory) => action(CustomersActionTypes.SAVE_ADDITIONAL_DISCOUNT_SUCCESS, {currentDiscount});
export const saveAdditionalDiscountError = () => action(CustomersActionTypes.SAVE_ADDITIONAL_DISCOUNT_ERROR);

export const fetchClassesAndBrands = () => action(CustomersActionTypes.FETCH_CLASSES_AND_BRANDS);
export const fetchClassesAndBrandsSuccess = (classesAndBrands: any) => action(CustomersActionTypes.FETCH_CLASSES_AND_BRANDS_SUCCESS, classesAndBrands);
export const fetchClassesAndBrandsError = () => action(CustomersActionTypes.FETCH_CLASSES_AND_BRANDS_ERROR);

export const updateCustomer = (DTO: {customerClasses: string[], customerBrands: any[], rrdi: string}) => action(CustomersActionTypes.UPDATE_CUSOMER, DTO);
export const updateCustomerSuccess = (updatedFields: {customerClasses: string[], customerBrands: any[]}) => action(CustomersActionTypes.UPDATE_CUSOMER_SUCCESS, updatedFields);
export const updateCustomerError = () => action(CustomersActionTypes.UPDATE_CUSOMER_ERROR);

export const fetchCustomerClasifications = () => action(CustomersActionTypes.FETCH_CUSTOMER_CLASSIFICATIONS);
export const fetchCustomerClasificationsSuccess = (classifications: string[]) => action(CustomersActionTypes.FETCH_CUSTOMER_CLASSIFICATIONS_SUCCESS, classifications);
