export interface User extends ApiResponse {
  access: {
    [key: string]: boolean;
  };
  attributes: {
    bonusSpecialOrderDiscountLimit?: number[];
    locale: string[];
  };
  createdTimestamp: number;
  disableableCredentialTypes: string[];
  email: string;
  emailVerified: boolean;
  enabled: boolean;
  firstName: string;
  lastName: string;
  id: string;
  notBefore: number;
  requiredActions: string[];
  totp: boolean;
  username: string;
}

export type TCredentials = {
  type: string;
  value: string;
};

export interface UserBase {
  credentials: TCredentials[];
  email: string;
  emailVerified: boolean;
  enabled: boolean;
  firstName: string;
  lastName: string;
  username: string;
  password?: string;
}

export interface Group {
  id: string;
  name: string;
  path: string;
  subGroups: any[];
}

export interface AvailableRolesInGroups {
  [key: string]: AvailableRole[];
}

export interface AvailableRole {
  id: string;
  name: string;
  description: string;
  containerId: string;
  clientRole: boolean;
  composite: boolean;
}

export type ApiResponse = Record<string, any>;

export enum UsersActionTypes {
  RESET_USER = "@@users/RESET_USER",
  FETCH_USER_BY_ID = "@@users/FETCH_USER_BY_ID",
  FETCH_USER_BY_ID_SUCCESS = "@@users/FETCH_USER_BY_ID_SUCCESS",
  FETCH_USER_BY_ID_FAILED = "@@users/FETCH_USER_BY_ID_FAILED",
  FETCH_AVAILABLE_ROLES_FAILED = "@@users/FETCH_AVAILABLE_ROLES_FAILED",
  FETCH_USER_ROLES_FAILED = "@@users/FETCH_USER_ROLES_FAILED",
  CHANGE_USER_ROLES_AND_LIMIT_SUCCESS = "@@users/CHANGE_USER_ROLES_AND_LIMIT_SUCCESS",
  FETCH_USERS_REQUEST = "@@users/FETCH_USERS_REQUEST",
  FETCH_USERS_SUCCESS = "@@users/FETCH_USERS_SUCCESS",
  ADD_USER = "@@users/ADD_USER",
  ADD_SUCCESS = "@@users/ADD_SUCCESS",
  CLEAR_CREATED = "@@users/CLEAR_CREATED",
  ENABLE_USER = "@@users/ENABLE_USER",
  REMOVE_USER = "@@users/REMOVE_USER",
  FETCH_AVAILABLE_ROLES = "@@users/FETCH_AVAILABLE_ROLES",
  FETCH_AVAILABLE_ROLES_SUCCESS = "@@users/FETCH_AVAILABLE_ROLES_SUCCESS",
  FETCH_USER_ROLES = "@@users/FETCH_USER_ROLES",
  FETCH_USER_ROLES_SUCCESS = "@@users/FETCH_USER_ROLES_SUCCESS",
  CHANGE_USER_ROLES_AND_LIMIT = "@@users/CHANGE_USER_ROLES_AND_LIMIT",
  ACTION_SUCCESS = "@users/ACTION_SUCCESS",
  FETCH_PASSWORD_POLICIES = "@@users/FETCH_PASSWORD_POLICIES",
  FETCH_PASSWORD_POLICIES_SUCCESS = "@@users/FETCH_PASSWORD_POLICIES_SUCCESS",
  EXPORT_USER_LIST_TO_XLS = "@@users/EXPORT_USER_LIST_TO_XLS",
  DOWNLOAD_FP0536_CSV = "@@users/DOWNLOAD_FP0536_CSV",
  DOWNLOAD_FP0536_CSV_SUCCESS = "@@users/DOWNLOAD_FP0536_CSV_SUCCESS",
  DOWNLOAD_FP0536_CSV_FAILED = "@@users/DOWNLOAD_FP0536_CSV_FAILED",
}

export type PolicyType =
  | "LENGTH"
  | "SPECIAL_CHARACTERS"
  | "UPPERCASE"
  | "LOWERCASE"
  | "DIGITS"
  | "NOT_USERNAME"
  | "FORCE_PASSWORD_CHANGE";

export type PasswordPolicy = {
  type: PolicyType;
  max: number;
  min: number;
};

export interface UsersState {
  readonly loadingChangeUserRolesAndLimit: boolean;
  readonly loadingUsers: boolean;
  readonly loadingGroups: boolean;
  readonly passwordPolicies: PasswordPolicy[];
  readonly list: User[];
  readonly availableRoles: AvailableRolesInGroups;
  readonly userRoles: AvailableRolesInGroups;
  readonly created?: boolean;
  readonly creating?: boolean;
  readonly loadingAvailableRoles: boolean;
  readonly loadingUserRoles: boolean;
  readonly loadingUser: boolean;
  readonly user: User | null;
  readonly downloadFP0536pending: boolean;
}

export enum AVAILABLE_ROLE_ENUM {
  LDC_UI_CUSTOMER_PAYER_EDIT = "LDC_UI_CUSTOMER_PAYER_EDIT",
  LDC_CUSTOMER_PAYER_EDIT = "LDC_CUSTOMER_PAYER_EDIT",
  LDC_ORDER_NORMAL = "LDC_ORDER_NORMAL",
  LDC_ORDER_WARRANTY = "LDC_ORDER_WARRANTY",
  LDC_ORDER_VIEW = "LDC_UI_ORDER_VIEW",
  LDC_ORDER_ACCEPT_REJECT = "LDC_UI_ORDER_ACCEPT_REJECT",
  LDC_ORDER_CLOSE_UNREALIZED = "LDC_UI_ORDER_CLOSE_UNREALIZED",
  LDC_CLAIMS_VIEW = "LDC_UI_CLAIMS_VIEW",
  LDC_CLAIMS_PROCESS = "LDC_UI_CLAIMS_PROCESS",
  LDC_ORDER = "LDC_ORDER",
  LDC_CLAIMS = "LDC_UI_CLAIMS",
  LDC_UI_CUSTOMER = "LDC_UI_CUSTOMER",
  LDC_CUSTOMER = "LDC_CUSTOMER",
  LDC_UI_CUSTOMER_EDIT = "LDC_UI_CUSTOMER_EDIT",
  LDC_CUSTOMER_EDIT = "LDC_CUSTOMER_EDIT",
  LDC_CUSTOMER_VIEW = "LDC_UI_CUSTOMER_VIEW",
  LDC_ORDER_UI_NORMAL = "LDC_UI_ORDER_NORMAL",
  LDC_ORDER_UI_WARRANTY = "LDC_UI_ORDER_WARRANTY",
  LDC_ORDER_UI_SPECIAL = "LDC_UI_ORDER_SPECIAL",
  LDC_ORDER_UI_BONUS = "LDC_UI_ORDER_BONUS",
  LDC_ORDER_UI_BONUS_PLUS = "LDC_UI_ORDER_BONUS_PLUS",
  LDC_ORDER_SPECIAL = "LDC_ORDER_SPECIAL",
  LDC_ORDER_BONUS = "LDC_ORDER_BONUS",
  LDC_UI_CUSTOMER_MANAGEMENT = "LDC_UI_CUSTOMER_MANAGEMENT",
  LDC_CUSTOMER_MANAGEMENT = "LDC_CUSTOMER_MANAGEMENT",
  LDC_ACCOUNTING_VIEW = "LDC_UI_ACCOUNTING_VIEW",
  LDC_ACCOUNTING = "LDC_UI_ACCOUNTING",
  LDC_WAREHOUSE_VIEW = "LDC_UI_WAREHOUSE_VIEW",
  LDC_WAREHOUSE = "LDC_UI_WAREHOUSE",
  LDC_WAREHOUSE_EDIT = "LDC_UI_WAREHOUSE_EDIT",
  LDC_ANOMALY = "LDC_UI_ANOMALY",
  LDC_ANOMALY_VIEW = "LDC_UI_ANOMALY_VIEW",
  LDC_ANOMALY_PROCESS = "LDC_UI_ANOMALY_PROCESS",
  LDC_BACKORDER_VIEW = "LDC_UI_BACKORDER_VIEW",
  LDC_BACKORDER = "LDC_UI_BACKORDER",
  LDC_BACKORDER_PROCESS = "LDC_UI_BACKORDER_PROCESS",
  LDC_ACCOUNTING_INVOICE_CORRECTION = "LDC_UI_ACCOUNTING_INVOICE_CORRECTION",
  LDC_ACCOUNTING_COLLECTIVE_INVOICE_CORRECTION = "LDC_UI_ACCOUNTING_COLLECTIVE_INVOICE_CORRECTION",
  LDC_PROMOTION_VIEW = "LDC_UI_PROMOTION_VIEW",
  LDC_PROMOTION = "LDC_UI_PROMOTION",
  LDC_PROMOTION_PROCESS = "LDC_UI_PROMOTION_PROCESS",
  LDC_PRODUCT_VIEW = "LDC_UI_PRODUCT_VIEW",
  LDC_PRODUCT = "LDC_UI_PRODUCT",
  LDC_PRODUCT_EDIT = "LDC_UI_PRODUCT_EDIT",
  LDC_REPORTS_VIEW = "LDC_UI_REPORTS_VIEW",
  LDC_UI_ADMINISTRATION_VIEW = "LDC_UI_ADMINISTRATION_VIEW",
  LDC_ADMINISTRATION_VIEW = "LDC_ADMINISTRATION_VIEW",
  LDC_ADMINISTRATION_USERS = "LDC_ADMINISTRATION_USERS",
  LDC_ADMINISTRATION = "LDC_UI_ADMINISTRATION",
  LDC_UI_ADMINISTRATION_USERS = "LDC_UI_ADMINISTRATION_USERS",
  LDC_ADMINISTRATION_DICT = "LDC_UI_ADMINISTRATION_DICT",
  LDC_ADMINISTRATION_SPEEDER = "LDC_UI_ADMINISTRATION_SPEEDER",
  LDC_ADMINISTRATION_CALENDAR = "LDC_UI_ADMINISTRATION_CALENDAR",
  LDC_ADMINISTRATION_IMPORT_FACTOR_LIMIT = "LDC_UI_ADMINISTRATION_IMPORT_FACTOR_LIMIT",
  LDC_UI_ACCOUNTING_INVOICE_CORRECTION_POSITIVE = "LDC_UI_ACCOUNTING_INVOICE_CORRECTION_POSITIVE",
  LDC_UI_CUSTOMER_ADDITIONAL_DISCOUNT_EDIT = "LDC_UI_CUSTOMER_ADDITIONAL_DISCOUNT_EDIT"
}
