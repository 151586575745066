import { PaginationMeta, TotalRecords } from '~/store/types'

type Designation = {
    name: string
    number: number
}

export type ReplacementPart = {
    sparePartID: string
    quantity: number
}

export type TReplacementHistory = {
    referenceId: string
    description: string
    replacementDate: string
    replacements?: TReplacementHistory[] | null
}

export interface SparePart extends ApiResponse {
    applicationDate: string
    businessBrand: string
    businessCode: string
    categoryAdv: string
    categoryBasket: string
    categoryHierarchyFamily: string
    categoryHierarchyIndex: string
    categoryHierarchySegment: string
    categoryHierarchySlot: string
    categoryIam: boolean
    categoryIdentifier: string
    categoryModification: string
    categoryOrigin: string
    description: string
    designations: Designation[]
    distributionDate: string
    distributionType: string
    iamCode: string
    packingCtu: string
    packingDimensionsHeight: number
    packingDimensionsLength: number
    packingDimensionsVolume: number
    packingDimensionsWidth: number
    unitDimensionsHeight: number
    unitDimensionsLength: number
    unitDimensionsVolume: number
    unitDimensionsWidth: number
    packingForceQuantity: any
    packingGtin: string
    packingQuantity: number
    packingTaric: string
    packingWeight: number
    unitWeight: number
    sendUnitDimensions: boolean
    packingCapacity: number
    packingIsSent100: boolean
    packingIsLargeSize : boolean
    priceCategory: string
    priceListType: string
    pricePromo: number
    pricePurchase: number
    priceReductionDiscountIam: string
    priceReductionDiscountPcd: string | null
    priceReductionDiscountFca: string | null
    priceReductionRebate: string
    priceSelling: number
    pricePNR: number
    pricePND: number
    priceTax: string
    provisionCategory: string
    provisionForceCategory: number
    provisionId: string
    provisionValue: number
    referenceId: string
    replacementCategory: string
    replacementListId: string
    replacements: ReplacementPart[]
    replacementHistory: TReplacementHistory
    state: number
    stock: number
    storageDuration: any
    storageLimit: boolean
    thresholdMonit: number
    thresholdStop: number
    tyreCategory: string
    tyreDimensionsDiameter: any
    tyreDimensionsHeight: any
    tyreDimensionsR: any
    tyreDimensionsSection: any
    tyreName: string
    tyreParametersDrivingNoise: string
    tyreParametersFuelEfficiency: string
    tyreParametersLoadIndex: any
    tyreParametersRoadHolding: string
    tyreParametersSoundClass: string
    tyreParametersSpeedRating: any
    updateTime: string
    gtuCode: string
    gtuAutomation: boolean
    isHazardousGoods: boolean | null
}

export type ApiResponse = Record<string, any>

export enum SparePartsActionTypes {
    FETCH_SPAREPARTS_REQUEST = '@@spareparts/FETCH_SPAREPARTS_REQUEST',
    FETCH_SPAREPARTS_SEARCH_REQUEST = '@@spareparts/FETCH_SPAREPARTS_SEARCH_REQUEST',
    FETCH_SPAREPARTS_SUCCESS = '@@spareparts/FETCH_SPAREPARTS_SUCCESS',
    FETCH_SPAREPARTS_SEARCH_SUCCESS = '@@spareparts/FETCH_SPAREPARTS_SEARCH_SUCCESS',
    SELECT_SPAREPART = '@@spareparts/SELECT_SPAREPART',
    SPAREPART_SELECTED = '@@spareparts/SPAREPART_SELECTED',
    IMPORT_REFERENCES = '@@spareparts/IMPORT_REFERENCES',
    IMPORT_FINISHED = '@@spareparts/IMPORT_FINISHED'
}

export interface SparePartsState {
    readonly list: SparePart[]
    readonly searchList: SparePart[]
    readonly meta: PaginationMeta
    readonly totalRecords: TotalRecords
    readonly loadingSpareParts: boolean
    readonly loadingSparePart: boolean
    readonly importing: boolean
    readonly imported?: boolean
    readonly selected?: SparePart
}
