import React from "react";
import { Form } from "semantic-ui-react";
import { DictionaryItem } from "~/store/dictionaries/types";
import CommonLoader from "~/components/Loaders/CommonLoader";
import translations from "~/utils/translations";
import { ApplicationState } from "~/store";
import { connect } from "react-redux";
import { DictionariesProps } from "~/pages/Administration/lib/Dictionaries/constants";
import DictionariesTable from "~/pages/Administration/lib/Dictionaries/Table";

const Dictionaries = ({
  dictionaries,
  name,
  refresh,
  changeName,
}: DictionariesProps) => {
  const mapNames = (names: string[]): DictionaryItem[] => {
    return names.map((name) => ({
      key: name,
      text: translations.format("app.dictionary.name." + name),
      value: name,
    }));
  };

  return (
    <>
      <Form.Dropdown
        placeholder="Wybierz słownik"
        fluid
        selection
        style={{ width: "300px", marginBottom: "10px" }}
        value={name}
        onChange={changeName}
        loading={dictionaries.loading}
        options={mapNames(dictionaries.names)}
      />
      {name && (
        <div style={{ overflowX: "auto", height: "calc(100vh - 250px)" }}>
          <DictionariesTable refresh={refresh} name={name} />
        </div>
      )}
      <CommonLoader loading={dictionaries.loading} />
    </>
  );
};

const mapStateToProps = ({ dictionaries }: ApplicationState) => ({
  dictionaries,
});

export default connect(mapStateToProps)(Dictionaries);
