import { connect } from "react-redux";
import { Button, Form, Header, Modal } from "semantic-ui-react";
import { ModelFormWrapper } from "~/components/ModelForm/ModelFormWrapper";
import { SubmitButton } from "~/components/ModelForm/SubmitButton";
import { validateDateToFrom } from "~/components/SmartField/lib/validator";
import { ApplicationState } from "~/store";
import {
  updateDictionaryItem,
  clearCreated,
} from "~/store/dictionaries/actions";
import { toastError } from "~/utils/toast";

import React, { useState, useCallback } from "react";
import { InputWrapper } from "~/pages/Administration/lib/Dictionaries/InputWrapper";
import {
  FieldNameEnum,
  TReduxState,
  TReduxUpdateActions,
  TUpdateDictionaryItemProps,
} from "~/pages/Administration/lib/Dictionaries/constants";
import { DictionaryName } from "~/store/dictionaries/types";

const UpdateDictionaryItem: React.FC<TUpdateDictionaryItemProps> = ({
  children,
  created,
  creating,
  updateDictionaryItem,
  updatedSuccess,
  clearCreated,
  details,
  name,
  itemKey,
  itemData,
}) => {
  const [open, setOpen] = useState(false);

  const handleConfirm = useCallback(
    (item: any): void => {
      if (!validateDateToFrom(item.start, item.end)) {
        return toastError(
          "Błąd podczas zapisywania słownika",
          "Data zakończenia musi być późniejsza niż jej rozpoczęcia."
        );
      }

      if (!creating) {
        updateDictionaryItem(
          name,
          itemKey,
          Object.assign(
            {},
            {
              key: item.key,
              label: item.label,
              value: item.value !== "" ? item.value : undefined,
              gui: item.gui !== "" ? item.gui : undefined,
              start: item.start !== null ? item.start : undefined,
              end: item.end !== null ? item.end : undefined,
              sell: item.sell !== null ? item.sell : undefined,
              buy: item.buy !== null ? item.buy : undefined,
              gtu: item.gtu !== null ? item.gtu : undefined,
              formula: item.formula !== null ? item.formula : undefined,
              type: item.type !== null ? item.type : undefined,
              factoring: item.factoring !== null ? item.factoring : undefined,
              dfs: item.dfs !== null ? item.dfs : undefined,
              distrigo: item.distrigo !== null ? item.distrigo : undefined,
              days: item.days !== null && item.days > 0 ? item.days : undefined,
              stock: item.stock !== null ? item.stock : undefined,
              urgent: item.urgent !== null ? item.urgent : undefined,
              turboPro: item.turboPro !== null ? item.turboPro : undefined,
              agreementNo:
                item.agreementNo !== null ? item.agreementNo : undefined,
              footer: item.footer !== null ? item.footer : undefined,
              sapCode: item.sapCode !== null ? item.sapCode : undefined,
              defaultFooter:
                item.defaultFooter !== null ? item.defaultFooter : undefined,
              defaultSapCode:
                item.defaultSapCode !== null ? item.defaultSapCode : undefined,
            }
          )
        );
      }
    },
    [creating, name, itemKey, updateDictionaryItem]
  );

  const handleCancel = useCallback((): void => {
    clearCreated();
    setOpen(false);
  }, [clearCreated]);

  const handleOpen = useCallback((): void => {
    setOpen(true);
  }, []);

  if (created && open) {
    clearCreated();
    setOpen(false);
    updatedSuccess();
  }

  return (
    <Modal
      size="tiny"
      trigger={children}
      open={open}
      onOpen={handleOpen}
      onClose={handleCancel}
      closeOnDimmerClick={false}
    >
      <ModelFormWrapper onSubmit={handleConfirm} model={itemData}>
        <Header icon="edit" content={`Edytuj element słownika: ${name}`} />
        <Modal.Content>
          <Form style={{ padding: 10 }}>
            {details.fields
              .filter((field) => field.name !== FieldNameEnum.id)
              .filter((field) => {
                if (
                  name === DictionaryName.paymentMethod &&
                  field.name === FieldNameEnum.value
                ) {
                  return null;
                } else if (
                  name === DictionaryName.paymentFactor &&
                  field.name === FieldNameEnum.label
                ) {
                  return null;
                }
                return field;
              })
              .map((field) => (
                <InputWrapper
                  key={field.name}
                  name={field.name}
                  type={field.type}
                  dictionaryName={name}
                />
              ))}
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <SubmitButton
            content="Zapisz"
            icon="save"
            labelPosition="right"
            primary
            loading={creating}
          />
          <Button content="Anuluj" onClick={handleCancel} />
        </Modal.Actions>
      </ModelFormWrapper>
    </Modal>
  );
};

const mapStateToProps: (state: ApplicationState) => TReduxState = ({
  dictionaries,
}: ApplicationState) => ({
  created: dictionaries.created,
  creating: dictionaries.creating,
});

const mapDispatchToProps: TReduxUpdateActions = {
  updateDictionaryItem,
  clearCreated,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateDictionaryItem);
