import { connect } from "react-redux";
import {
  Button,
  Form,
  Header,
  Modal,
  Dropdown,
  TextArea,
  Input,
  Checkbox,
  Table,
  Popup,
  Search,
  Icon,
} from "semantic-ui-react";
import { ApplicationState } from "~/store";
import { createClaim, clearCreated } from "~/store/claims/actions";
import {
  fetchInvoices,
  fetchInvoicesWithProvision,
  fetchInvoicesWithDebitNotes,
} from "~/store/invoices/actions";
import { IClaimCreate } from "~/store/claims/types";
import { IInvoice } from "~/store/invoices/types";
import { fetchOrders } from "~/store/orders/actions";
import { selectCustomer } from "~/store/customer/actions";
import { fetchPriceList } from "~/store/price-list/actions";
import { SparePartPricing } from "~/store/price-list/types";
import { Order } from "~/store/orders/types";
import { PaginableCustomer, DeliveryLocation } from "~/store/customer/types";
import {
  DictionaryItem,
  DictionariesState,
  DictionaryName,
} from "~/store/dictionaries/types";
import { fetchDictionary } from "~/store/dictionaries/actions";
import { FormattedMessage } from "react-intl";

import React, { useState, SyntheticEvent, useEffect, useCallback } from "react";
import UberSearch from "~/components/UberSearch/UberSearch";
import { reducer } from "~/utils/reducer";
import claimTypeMap from "./claimTypeMap.conf";
// @ts-ignore
import debounce from "lodash.debounce";
import translations from "~/utils/translations";
import { FormInputNumber } from "~/components/InputNumber/InputNumber";
import InfoIcon from "~/pages/Orders/Details/lib/InfoIcon";
import { toastSuccess } from "~/utils/toast";
import { useSearch } from "./useSearch";

const _ = require("lodash");

type TReduxState = {
  invoices: IInvoice[];
  invoicesWithProvision: IInvoice[];
  loadingInvoices: boolean;
  loadingInvoicesWithProvision: boolean;
  orders: Order[];
  loadingOrders: boolean;
  loadingCustomers: boolean;
  deliveryAddresses: DeliveryLocation[];
  products: SparePartPricing[];
  loadingProducts: boolean;
  created?: boolean;
  creating?: boolean;
  dictionaries: DictionariesState;
  invoicesWithDebitNotes: IInvoice[];
  loadingInvoicesWithDebitNotes: boolean;
};

type TReduxActions = {
  createClaim: typeof createClaim;
  clearCreated: typeof clearCreated;
  fetchInvoices: typeof fetchInvoices;
  fetchInvoicesWithProvision: typeof fetchInvoicesWithProvision;
  fetchOrders: typeof fetchOrders;
  selectCustomer: typeof selectCustomer;
  fetchPriceList: typeof fetchPriceList;
  fetchDictionary: typeof fetchDictionary;
  fetchInvoicesWithDebitNotes: typeof fetchInvoicesWithDebitNotes;
};

type TProps = {
  triggerOpen: boolean;
  createdSuccess: () => void;
  openHandler: React.Dispatch<React.SetStateAction<boolean>>;
};

type TLine = {
  lineNumber: number;
  provisionId?: string;
  referenceId?: string;
  quantity: number;
  quantityOrgin?: number;
  selected?: boolean;
  orderNumber?: string;
  packingForceQuantity: number | null;
  orderedQuantity?: number;
  categoryOrigin?: string;
  pvpPrice?: number;
  pnrPrice?: number;
  packageNumber?: string;
};

type TAddClaimProps = TReduxState & TReduxActions & TProps;

export enum ClaimType {
  "unset" = "",
  "realtedToInvoice" = "RK1",
  "notRealtedToInvoice" = "RK2",
  "RK3" = "RK3",
  "provision" = "RK4",
  "withoutInvoice" = "RK5",
}

export enum ClaimCategory {
  "quantityClaim" = "RK1_ZNRE",
  "qualityClaim" = "RK1_ZRDP",
  "newPartReturn" = "RK1_ZRPN",
  "transportClaim" = "RK1_ZRVE",
  "surplusWithoutReturn" = "RK2_ZRNG",
  "surplusWithReturn" = "RK2_ZRNF",
  "lackClaim" = "RK3_Z1",
  "orderIntention" = "RK3_Z2",
  "depositReturn" = "RK4_P1",
  "tractionBatteryReturn" = "RK4_P2",
  "coreWithoutInvoice" = "RK5_P1",
  "batteryWithoutInvoice" = "RK5_B1",
}

export enum SearchType {
  documentMask = "documentMask",
  packageNumber = "packageNumber",
}

const AddClaim: React.FC<TAddClaimProps> = ({
  children,
  created,
  creating,
  invoicesWithProvision,
  loadingInvoicesWithDebitNotes,
  loadingInvoicesWithProvision,
  loadingCustomers,
  deliveryAddresses,
  dictionaries,
  triggerOpen,
  invoicesWithDebitNotes,
  fetchInvoicesWithDebitNotes,
  fetchDictionary,
  selectCustomer,
  createClaim,
  createdSuccess,
  clearCreated,
  fetchInvoicesWithProvision,
  openHandler,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [group, setGroup] = useState<ClaimType>(ClaimType.unset);
  const [invoice, setInvoice] = useState<string>("");
  const [order, setOrder] = useState<string>("");
  const [category, setCategory] = useState<string>("");
  const [type, setType] = useState<string>("");
  const [customer, setCustomer] = useState<string>("");
  const [delivery, setDelivery] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [vin, setVin] = useState<string>("");
  const [bin, setBin] = useState<string>("");
  const [customInvoiceNumber, setCustomInvoiceNumber] = useState("");
  const [pamir, setPamir] = useState<string>("");
  const [vehicleModel, setVehicleModel] = useState<string>("");
  const [rentalCar, setRentalCar] = useState<boolean>(false);
  const [lines, setLines] = useState<TLine[]>([]);
  // const [allLines, setAllLines] = useState<TInvoiceLine[]>([]);
  const [openCollectiveTask, setOpenCollectiveTask] = useState<boolean>(false);
  const [processAutomatically, setProcessAutomatically] =
    useState<boolean>(false);
  const [tableData, setTableData] = React.useReducer(reducer, {
    data: lines,
    direction: null,
    loading: false,
    checkedLine: null,
    selected: null,
    column: null,
  });

  const { column, direction, loading } = tableData;
  const timeoutRef = React.useRef();

  useEffect(() => {
    if (triggerOpen) {
      setOpen(true);
      setTableData({ type: "INIT_TABLE", column: null, data: [] });
    }
  }, [triggerOpen]);

  useEffect(() => {
    openHandler(open);
  }, [open, openHandler]);

  useEffect(() => {
    open &&
      fetchDictionary(
        DictionaryName.claimType,
        DictionaryName.claimCategory,
        DictionaryName.claimGroup
      );
  }, [open, fetchDictionary]);

  useEffect(() => {
    if (openCollectiveTask) {
      setOpenCollectiveTask(false);
    }
  }, [openCollectiveTask]);

  const changeGroup = (event: SyntheticEvent, data: any): void => {
    switch (data.value) {
      case ClaimType.realtedToInvoice:
        setOrder("");
        setDelivery("");
        setCustomer("");
        setCategory("");
        setTableData({ type: "INIT_TABLE", column: null, data: [] });
        setProcessAutomatically(true);
        break;
      case ClaimType.notRealtedToInvoice:
        setProcessAutomatically(false);
        setOrder("");
        setInvoice("");
        setDelivery("");
        setCategory("");
        setTableData({ type: "INIT_TABLE", column: null, data: [] });
        break;
      case "RK3":
        setProcessAutomatically(false);
        setInvoice("");
        setDelivery("");
        setCustomer("");
        setCategory("");
        setTableData({ type: "INIT_TABLE", column: null, data: [] });
        break;
      case ClaimType.provision:
        setProcessAutomatically(false);
        setOrder("");
        setInvoice("");
        setDelivery("");
        setCategory("RK4_P1");
        setTableData({ type: "INIT_TABLE", column: null, data: [] });
        setProcessAutomatically(true);
        break;
      case ClaimType.withoutInvoice:
        setProcessAutomatically(false);
        setOrder("");
        setInvoice("");
        setDelivery("");
        setCategory("");
        setTableData({ type: "INIT_TABLE", column: null, data: [] });
        setProcessAutomatically(true);
        break;
      default:
        break;
    }
    setLines([]);
    setGroup(data.value);
  };

  const resetAfterSearchType = () => {
    setInvoice("");
    setTableData({ type: "INIT_TABLE", column: null, data: [] });
    setLines([]);
  };

  const changeInvoice = (event: SyntheticEvent, data: any): void => {
    setInvoice(data.value);
    const invoicesList =
      group === ClaimType.provision
        ? invoicesWithProvision
        : invoicesWithDebitNotes;
    const foundInvoice = invoicesList.find(
      (invoice) => invoice.invoiceNo === data.value
    );
    let invoiceLines = foundInvoice?.invoiceLines || [];
    if (group === ClaimType.provision) {
      const noProvisionFilteredLines = invoiceLines.filter((line, index) => {
        if (
          index !== invoiceLines.length - 1 &&
          line.provisionId &&
          line.provisionId === invoiceLines[index + 1].referenceId
        ) {
          return line;
        } else {
          return false;
        }
      });
      invoiceLines = noProvisionFilteredLines;
    }
    // setAllLines(invoiceLines)
    // @ts-ignore: undefined possible no chance
    const linesToSet = invoiceLines;
    // @ts-ignore: undefined possible no chance
    const mappedLines = linesToSet.map((line: TLine) => {
      return {
        lineNumber: line.lineNumber,
        referenceId: line.referenceId,
        quantity: group === ClaimType.provision ? 1 : line.quantity,
        quantityOrgin:
          line.quantity || line.quantityOrgin || line.orderedQuantity,
        orderedQuantity: line.orderedQuantity,
        orderNumber: line.orderNumber,
        quantityCopy: line.quantity,
        packingForceQuantity: line.packingForceQuantity || 1,
        selected: false,
        pvpPrice: isDFSCategoryOrigin(line) ? line.pvpPrice : undefined,
        pnrPrice: isDFSCategoryOrigin(line) ? line.pnrPrice : undefined,
        packageNumber: line.packageNumber ? [line.packageNumber] : undefined,
      };
    });
    foundInvoice && setLines(mappedLines);
    setTableData({
      type: "INIT_TABLE",
      column: "lineNumber",
      data: mappedLines.map((line) => {
        if (line.orderedQuantity && line.quantity > line.orderedQuantity) {
          return {
            ...line,
            quantityOrgin: line.quantity,
            orderedQuantity: line.quantity,
          };
        } else {
          return line;
        }
      }),
    });
  };

  const filterType = (
    types: DictionaryItem[],
    category: string
  ): DictionaryItem[] => {
    return types.filter((type) => {
      // @ts-ignore
      return (
        category &&
        claimTypeMap[`${category}`] &&
        claimTypeMap[`${category}`].includes(type.key)
      );
    });
  };

  const changeCategory = (event: SyntheticEvent, data: any): void => {
    setCategory(data.value);
    setVin("");
    setBin("");
    setPamir("");
    setVehicleModel("");
    setCustomInvoiceNumber("");
    setRentalCar(false);
    const type = filterType(dictionaries["claim-type"], data.value);
    if (type.length === 1) {
      setType(type[0].value);
    } else {
      setType("");
    }
  };

  const changeType = (event: SyntheticEvent, data: any): void => {
    setType(data.value);
  };

  const changeCustomer = (data: any): string => {
    setCustomer(data.value);
    setDelivery("");
    selectCustomer(data.value);
    return data.title;
  };

  const changeDelivery = (event: SyntheticEvent, data: any): void => {
    setDelivery(data.value);
  };

  const inputDescription = (event: SyntheticEvent, data: any): void => {
    setDescription(data.value);
  };

  const changeVin = (event: SyntheticEvent, data: any): void => {
    if (data.value.length > 17) return;
    setVin(data.value);
  };

  const changeBin = (event: SyntheticEvent, data: any): void => {
    if (data.value.length > 100) return;
    setBin(data.value);
  };

  const changePamir = (event: SyntheticEvent, data: any): void => {
    if (data.value.length > 100) return;
    setPamir(data.value);
  };

  const changeCustomerInvoiceNumber = (
    event: SyntheticEvent,
    data: any
  ): void => {
    if (data.value.length > 20) return;
    setCustomInvoiceNumber(data.value);
  };

  // eslint-disable-next-line
  const debouncedSearchInvoice = useCallback(
    debounce((nextValue: any) => getInvoicesWithDebitNotes(nextValue), 1000),
    []
  );

  const searchInvoice = (event: SyntheticEvent, data: any): void => {
    debouncedSearchInvoice({
      filters: [{ name: searchType, key: data.searchQuery }],
    });
  };

  // eslint-disable-next-line
  const debouncedSearchProvision = useCallback(
    debounce((nextValue: any) => getInvoicesWithProvision(nextValue), 1000),
    []
  );

  const searchInvoiceProvision = (event: SyntheticEvent, data: any): void => {
    debouncedSearchProvision({
      filters: [{ name: searchType, key: data.searchQuery }],
    });
  };

  const changeProcessAutomatically = (
    event: SyntheticEvent,
    data: any
  ): void => {
    setProcessAutomatically(data.checked);
  };

  const mapCustomers = (response: PaginableCustomer): any => {
    return response.content.map((c) => ({
      key: c.rrdi,
      title: `${c.rrdi} ${c.name}`,
      description: `${c.informationAddressCity}, ${c.informationAddressStreet} ${c.informationAddressNumber}`,
      value: c.rrdi,
    }));
  };

  const mapDelivery = (
    deliveryAddresses: DeliveryLocation[]
  ): DictionaryItem[] => {
    return deliveryAddresses.map((deliveryAddress) => ({
      key: deliveryAddress.rrdi,
      text: `${deliveryAddress.rrdi} ${deliveryAddress.company || ""}, ${deliveryAddress.city || ""}`,
      value: deliveryAddress.rrdi,
    }));
  };

  const mapProducts = (products: SparePartPricing[]): any[] => {
    return products.map((product) => ({
      product,
      key: product.referenceId,
      title: product.referenceId,
      description: product.description,
    }));
  };

  const filterCategory = (
    categories: DictionaryItem[],
    group: string
  ): DictionaryItem[] => {
    return categories.filter((category) => {
      const categoryKeyArray = category.key.split("_");
      return categoryKeyArray[0] === group;
    });
  };

  const handleConfirm = (): void => {
    let claim: IClaimCreate;
    if (group === ClaimType.realtedToInvoice) {
      claim = {
        processAutomatically,
        invoiceNumber: invoice,
        claimGroup: group,
        claimCategory: category,
        description: description,
        claimType: type,
        claimLines: lines
          .filter((line) => line.selected)
          .map((line) => ({
            lineNumber: line.lineNumber,
            claimedQuantity: line.quantity,
          })),
      };
    } else if (
      group === ClaimType.notRealtedToInvoice ||
      group === ClaimType.withoutInvoice
    ) {
      claim = {
        claimGroup: group,
        claimCategory: category,
        claimType: type.length ? type : null,
        orderCustomerID: delivery,
        accountingCustomerID: customer,
        description: description,
        claimLines: lines
          .filter((line) => line.selected)
          .map((line) => {
            if (isDFSCategoryOrigin(line)) {
              return {
                lineNumber: line.lineNumber,
                referenceID: line.referenceId,
                claimedQuantity: line.quantity,
                pvpPrice: line.pvpPrice,
                pnrPrice: line.pnrPrice,
                categoryOrigin: line.categoryOrigin,
              };
            } else {
              return {
                lineNumber: line.lineNumber,
                referenceID: line.referenceId,
                claimedQuantity: line.quantity,
              };
            }
          }),
      };
      if (category === ClaimCategory.batteryWithoutInvoice) {
        Object.assign(claim, {
          vin,
          bin,
          pamir,
          customInvoiceNumber,
          processAutomatically,
        });
      }
      if (category === ClaimCategory.coreWithoutInvoice) {
        Object.assign(claim, { customInvoiceNumber, processAutomatically });
      }
    } else if (group === "RK3") {
      claim = {
        claimGroup: group,
        claimCategory: category,
        orderNumber: order,
        description: description,
        claimLines: lines
          .filter((line) => line.selected)
          .map((line) => ({
            lineNumber: line.lineNumber,
            claimedQuantity: line.quantity,
          })),
      };
      if (category === "RK3_Z1") {
        Object.assign(claim, {
          vin: vin,
          vehicleModel: vehicleModel,
          rentalCar: rentalCar,
        });
      }
      if (category !== "RK3_Z1" && category !== "RK3_Z2") {
        Object.assign(claim, { claimType: type });
      }
    } else if (group === ClaimType.provision) {
      claim = {
        processAutomatically,
        claimGroup: group,
        claimCategory: category,
        invoiceNumber: invoice,
        description: description,
        claimLines: lines
          .filter((line) => line.selected)
          .map((line) => ({
            lineNumber: line.lineNumber,
            referenceID: line.referenceId,
            claimedQuantity: line.quantity,
          })),
      };
      if (category === "RK4_P2") {
        Object.assign(claim, { vin, bin, pamir });
      }
    } else {
      return;
    }

    createClaim(claim);
  };

  const getInvoicesWithDebitNotes = (params?: Object): void => {
    const paramsObj = {
      page: 1,
      size: 13,
      filters: [],
      sortColumn: null,
      sortDirection: null,
    };
    fetchInvoicesWithDebitNotes(Object.assign(paramsObj, params));
  };

  const getInvoicesWithProvision = (params?: Object): void => {
    const paramsObj = {
      page: 1,
      size: 13,
      filters: [],
      sortColumn: null,
      sortDirection: null,
    };
    fetchInvoicesWithProvision(Object.assign(paramsObj, params));
  };

  const clearForm = (): void => {
    setGroup(ClaimType.unset);
    setInvoice("");
    setOrder("");
    setCategory("");
    setType("");
    setCustomer("");
    setDelivery("");
    setDescription("");
    setVin("");
    setBin("");
    setPamir("");
    setVehicleModel("");
    setRentalCar(false);
    setLines([]);
    setCustomInvoiceNumber("");
    setProcessAutomatically(false);
  };

  const handleCancel = (): void => {
    clearForm();
    if (!creating) {
      setOpen(false);
    }
  };

  const handleOpen = (): void => {
    setOpen(true);
  };

  if (created && open) {
    clearCreated();
    clearForm();
    setOpen(false);
    createdSuccess();
  }

  const validNumber = (value: any): boolean => {
    if (isNaN(value)) {
      return false;
    }
    return typeof value === "number";
  };

  const changeQuantity = (
    lineNumber: number,
    event: SyntheticEvent,
    data: any
  ): void => {
    setLines((prevLines) =>
      prevLines.map((line) => {
        if (line.lineNumber === lineNumber) {
          line.quantity = parseInt(data.value);
          line.selected = true;
          tableData.data = prevLines;
        }
        return { ...line };
      })
    );
  };

  const changePVPPrice = (
    lineNumber: number,
    event: SyntheticEvent,
    data: any
  ): void => {
    setLines((prevLines) =>
      prevLines.map((line) => {
        if (line.lineNumber === lineNumber) {
          line.pvpPrice = parseFloat(data.value);
          line.selected = true;
          tableData.data = prevLines;
        }
        return { ...line };
      })
    );
  };

  const changePNRPrice = (
    lineNumber: number,
    event: SyntheticEvent,
    data: any
  ): void => {
    setLines((prevLines) =>
      prevLines.map((line) => {
        if (line.lineNumber === lineNumber) {
          line.pnrPrice = parseFloat(data.value);
          line.selected = true;
          tableData.data = prevLines;
        }
        return { ...line };
      })
    );
  };

  const changeReferenceId = (lineNumber: number, data: any): string => {
    setLines(
      lines.map((line) => {
        if (line.lineNumber === lineNumber) {
          if (
            group === ClaimType.notRealtedToInvoice ||
            ClaimType.withoutInvoice
          ) {
            return {
              ...line,
              referenceId: data.key,
              selected: true,
              pvpPrice: data.product.pvpPrice,
              pnrPrice: undefined,
              categoryOrigin: data.product.categoryOrigin,
            };
          } else {
            return { ...line, referenceId: data.key, selected: true };
          }
        }
        return { ...line };
      })
    );
    return `${data.title} ${data.description}`;
  };

  const selectAll = (event: SyntheticEvent, data: any): void => {
    setLines(
      lines.map((line) => ({
        ...line,
        selected: data.checked,
      }))
    );
    setTableData({
      type: "SELECT_ALL",
      column: "lineNumber",
      data: lines,
      selected: data.checked,
    });
  };

  const isCheckedAll = (tableData: any): boolean => {
    return !(
      tableData.data === null ||
      tableData.data.length === 0 ||
      tableData.data.some((line: { selected: boolean }) => !line.selected)
    );
  };

  const selectLine = (
    lineNumber: number,
    event: SyntheticEvent,
    data: any
  ): void => {
    setLines((prevLines) =>
      prevLines.map((line) => {
        if (line.lineNumber === lineNumber) {
          setTableData({
            type: "SELECT",
            column: "lineNumber",
            data: prevLines,
            checkedLine: lineNumber,
            selected: data.checked,
          });
          return { ...line, selected: data.checked };
        }
        return { ...line };
      })
    );
  };

  const isInvalidLine = (line: any): boolean | string => {
    if (group === ClaimType.provision) {
      return line.quantity < 0 || (line.selected && !line.quantity);
    }
    if (isNaN(line.quantity) || line.quantity === 0) {
      return true;
    }

    if (category === "RK1_ZRPN") {
      const packingForceQuantity =
        line.packingForceQuantity === null ? 1 : line.packingForceQuantity;
      if (line.quantity % packingForceQuantity !== 0) {
        return `Możliwość zwrotu tylko w paczkach (${packingForceQuantity} sztuk)`;
      }
    }

    return (
      line.quantityOrgin &&
      group !== ClaimType.notRealtedToInvoice &&
      line.quantity > line.quantityOrgin &&
      `Przekroczono dozwoloną ilość ${line.quantityOrgin}`
    );
  };

  const isInvalidPNRPrice = (line: any): boolean => {
    return (
      group === ClaimType.notRealtedToInvoice &&
      (!line.pnrPrice || line.pnrPrice > line.pvpPrice)
    );
  };

  const renderPNRErrorMessage = (line: any): string => {
    if (!line.pnrPrice) {
      return "Wartość powinna być większa od 0";
    } else if (line.pnrPrice > line.pvpPrice) {
      return "Cena PNR nie może być większa od ceny PVP";
    } else {
      return "";
    }
  };

  const isInvalidPVPPrice = (line: any): boolean | string => {
    return group === ClaimType.notRealtedToInvoice && !line.pvpPrice;
  };

  const addLine = (): void => {
    const newLines = [...lines];
    newLines.push({
      lineNumber:
        lines.length === 0 ? 1 : lines[lines.length - 1].lineNumber + 1,
      referenceId: "",
      quantity: 1,
      quantityOrgin: 1,
      selected: true,
      packingForceQuantity: null,
      pvpPrice: 0,
      pnrPrice: 0,
      categoryOrigin: "0",
    });
    setLines(newLines);
  };

  const removeLine = (lineNumber: number): void => {
    setLines(
      lines
        .filter((line) => line.lineNumber !== lineNumber)
        .map((line, index) => ({ ...line, lineNumber: index + 1 }))
    );
  };

  const validLines = (): boolean => {
    if (lines.filter((item) => item.referenceId?.length).length === 0) {
      return false;
    }
    if (
      group === ClaimType.provision &&
      lines.filter((item) => item.selected).length > 1
    ) {
      return false;
    }

    const someInvalid: boolean = lines.some((line) => {
      if (
        line.selected &&
        group === ClaimType.notRealtedToInvoice &&
        line.referenceId === ""
      ) {
        return true;
      }
      return !!(line.selected && isInvalidLine(line));
    });

    return someInvalid ? false : lines.some((line) => line.selected);
  };

  const validPrices = (): boolean => {
    let resoult = true;
    lines.forEach((line) => {
      const isDFSCategory =
        line.categoryOrigin && ["2", "4", "5"].includes(line.categoryOrigin);
      if (isDFSCategory && (!line.pvpPrice || !line.pnrPrice)) {
        resoult = false;
      }
      if (isDFSCategory && isInvalidPNRPrice(line)) {
        resoult = false;
      }
    });
    return resoult;
  };

  const validClaimesQuantityRealted = (): boolean => {
    return !!lines.find(
      (line) => line.quantityOrgin && line.quantity > line.quantityOrgin
    );
  };

  const validFormMessage = (): string | undefined => {
    if (
      group === ClaimType.realtedToInvoice ||
      group === ClaimType.notRealtedToInvoice
    ) {
      if (category === "") {
        return "Wymagane wybranie kategorii";
      } else if (type === "") {
        return "Wymagane wybranie typu";
      } else if (description === "") {
        return "Opis nie może być pusty";
      } else if (!!lines.find((line) => !line.quantity)) {
        return "Reklamowana ilość powinna być większa od zera";
      }
    }

    switch (group) {
      case ClaimType.realtedToInvoice:
        if (invoice === "") {
          return "Wymagane wybranie faktury";
        } else if (category === "") {
          return "Wymagane wybranie kategorii";
        } else if (validClaimesQuantityRealted()) {
          return "Reklamowana ilość jest niewłaściwa";
        } else if (!validLines()) {
          return "Przynajmniej jedna linia reklamacji musi być zaznaczona";
        }
        break;
      case ClaimType.notRealtedToInvoice:
        if (customer === "") {
          return "Wybranie płatnika jest wymagane";
        } else if (delivery === "") {
          return "Wybranie dostawy jest wymagane";
        } else if (!validLines()) {
          return "Przynajmniej jedna linia reklamacji musi być zaznaczona";
        } else if (!validPrices()) {
          return "Wartości cen PVP/PNR są nieprawidłowe";
        }
        break;
      case "RK3":
        if (order === "") {
          return "Wymagane wybranie powiązanego zamówienia";
        } else if (category === "") {
          return "Wymagane wybranie kategorii";
        } else if (description === "") {
          return "Opis nie może być pusty";
        } else if (
          category === "RK3_Z1" &&
          (vin === "" || vehicleModel === "")
        ) {
          return "Wymagane wypełnienie danych samochodu";
        } else if (!validLines()) {
          return "Przynajmniej jedna linia reklamacji musi być zaznaczona";
        }
        break;
      case ClaimType.provision:
        if (category === "") {
          return "Wymagane wybranie kategorii";
        } else if (description === "") {
          return "Opis nie może być pusty";
        } else if (
          category === ClaimCategory.tractionBatteryReturn &&
          vin.length < 17
        ) {
          return "Numer VIN jest wymagany i musi składać się z 17 znaków";
        } else if (
          category === ClaimCategory.tractionBatteryReturn &&
          !bin.length
        ) {
          return "Numer BIN jest wymagany";
        } else if (
          category === ClaimCategory.tractionBatteryReturn &&
          !pamir.length
        ) {
          return "Numer PAMIR jest wymagany";
        } else if (
          lines.filter((line) => line.selected === true).length === 0
        ) {
          return "Zaznaczenie jednej linii reklamacji jest wymagane";
        } else if (lines.filter((line) => line.selected === true).length > 10) {
          return "Maksymalna ilość reklamacji nie może przekraczać 10 linii";
        }
        break;
      case ClaimType.withoutInvoice:
        if (customer === "") {
          return "Wybranie płatnika jest wymagane";
        } else if (delivery === "") {
          return "Wybranie dostawy jest wymagane";
        } else if (category === "") {
          return "Wymagane wybranie kategorii";
        } else if (description === "") {
          return "Opis nie może być pusty";
        } else if (
          category === ClaimCategory.batteryWithoutInvoice &&
          vin.length < 17
        ) {
          return "Numer VIN jest wymagany i musi składać się z 17 znaków";
        } else if (
          category === ClaimCategory.batteryWithoutInvoice &&
          !bin.length
        ) {
          return "Numer BIN jest wymagany";
        } else if (
          category === ClaimCategory.batteryWithoutInvoice &&
          !pamir.length
        ) {
          return "Numer PAMIR jest wymagany";
        } else if (
          lines.filter((line) => line.selected === true).length === 0
        ) {
          return "Zaznaczenie jednej linii reklamacji jest wymagane";
        } else if (lines.filter((line) => line.selected === true).length > 10) {
          return "Maksymalna ilość reklamacji nie może przekraczać 10 linii";
        }
        break;
      default:
        return "Wymagane wybranie rodzaju składanej reklamacji";
    }
  };

  const isValidForm = (): boolean => {
    if (
      type === "" &&
      group !== ClaimType.provision &&
      group !== ClaimType.withoutInvoice
    ) {
      return false;
    }
    if (group === "") {
      return false;
    } else if (group === ClaimType.realtedToInvoice) {
      return invoice === "" || category === "" || description === ""
        ? false
        : validLines();
    } else if (group === ClaimType.notRealtedToInvoice) {
      return customer === "" ||
        delivery === "" ||
        category === "" ||
        description === ""
        ? false
        : validLines() && validPrices();
    } else if (group === "RK3") {
      if (order === "" || category === "" || description === "") {
        return false;
      } else if (
        category !== "RK3_Z1" &&
        category !== "RK3_Z2" &&
        type === ""
      ) {
        return false;
      } else if (category === "RK3_Z1") {
        return vin === "" || vehicleModel === "" ? false : validLines();
      } else if (category === "RK3_Z2") {
        return validLines();
      } else {
        return true;
      }
    } else if (group === ClaimType.provision) {
      if (
        category === ClaimCategory.tractionBatteryReturn &&
        (vin.length < 17 || !bin.length || !pamir.length)
      ) {
        return false;
      }

      if (
        invoice === "" ||
        category === "" ||
        description === "" ||
        !validLines()
      ) {
        return false;
      } else return true;
    } else if (group === ClaimType.withoutInvoice) {
      if (
        delivery === "" ||
        category === "" ||
        description === "" ||
        !validLines()
      ) {
        return false;
      }

      if (
        category === ClaimCategory.batteryWithoutInvoice &&
        (vin.length < 17 || !bin.length || !pamir.length)
      ) {
        return false;
      }
      return true;
    } else {
      return true;
    }
  };

  const getTableData = (group: string): any => {
    if (
      group === ClaimType.notRealtedToInvoice ||
      group === ClaimType.withoutInvoice
    ) {
      return lines;
    } else {
      return tableData.data;
    }
  };

  const errorMessage = validFormMessage();

  const [searchValue, setSearchValue] = useState("");
  const [searchPackageNr, setSearchPackageNr] = useState("");

  const handleInputChange = useCallback(
    (e, data, searchType: "search" | "searchPackageNr") => {
      if (searchType === "search") {
        setSearchValue(data.value);
      } else if (searchType === "searchPackageNr") {
        setSearchPackageNr(data.value);
      }
    },
    []
  );

  const handleSearchChange = useCallback(() => {
    setTableData({ type: "START_SEARCH", query: "query" });
    timeoutRef.current = setTimeout(() => {
      if (searchValue.length === 0 && searchPackageNr.length === 0) {
        setTableData({ type: "INIT_TABLE", data: lines });
        return;
      }
      const re = new RegExp(_.escapeRegExp(searchValue), "i");
      const rePackageNr = new RegExp(_.escapeRegExp(searchPackageNr), "i");
      const isMatch = (result: any) => {
        return (
          re.test(result.referenceId + result.orderNumber) &&
          rePackageNr.test(result.packageNumber)
        );
      };
      setTableData({
        type: "FINISH_SEARCH",
        results: _.filter(lines, isMatch),
        data: _.filter(lines, isMatch),
      });
    }, 300) as any;
  }, [lines, searchValue, searchPackageNr]);

  useEffect(() => {
    handleSearchChange();
  }, [searchValue, searchPackageNr, handleSearchChange]);

  React.useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  const isDFSCategoryOrigin = (line: any): boolean => {
    return line.categoryOrigin && ["2", "4", "5"].includes(line.categoryOrigin);
  };

  const copyToClipboard = (text: string): void => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    textArea.style.position = "fixed";
    textArea.style.top = "-50px";
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand("copy");
      toastSuccess("Numer paczki został skopiowany", text);
    } catch (err) {
      console.error("Nie udało się skopiować numeru paczki");
    }
    document.body.removeChild(textArea);
  };

  const { SearchFormField, searchType } = useSearch({
    group,
    invoice,
    loadingInvoicesWithDebitNotes,
    loadingInvoicesWithProvision,
    invoicesWithDebitNotes,
    invoicesWithProvision,
    changeInvoice,
    searchInvoice,
    searchInvoiceProvision,
    resetAfterSearchType,
  });

  return (
    <Modal
      trigger={children}
      open={open}
      onOpen={handleOpen}
      onClose={handleCancel}
      closeOnDimmerClick={false}
    >
      <Header
        icon="exclamation triangle"
        content="Dodaj reklamacje lub zwrot"
      />
      <Modal.Content>
        <Form>
          <Form.Field>
            <label>Rodzaj składanej reklamacji lub zwrotu*</label>
            <Dropdown
              placeholder="Wybierz rodzaj"
              fluid
              selection
              value={group}
              onChange={changeGroup}
              options={dictionaries["claim-group"].filter(
                (group) => group.key !== "RK3"
              )}
              noResultsMessage={<FormattedMessage id="app.noResultsMessage" />}
            />
          </Form.Field>
          <Form.Field disabled={group === ClaimType.notRealtedToInvoice}>
            <Checkbox
              label="Procesuj automatyczne"
              checked={processAutomatically}
              onChange={changeProcessAutomatically}
            />
          </Form.Field>
          {SearchFormField}
          <Form.Group widths="equal">
            <Form.Field
              disabled={
                group === ClaimType.realtedToInvoice ||
                group === ClaimType.provision
              }
            >
              <label>Kod RRDI płatnika za zamówienie*</label>
              <UberSearch
                fluid
                placeholder="Wybierz płatnika"
                endpoint="/customers"
                mapper={mapCustomers}
                queryParams={[
                  {
                    name: "onlyPayers",
                    value: "true",
                  },
                ]}
                onResultSelect={changeCustomer}
              />
            </Form.Field>
            <Form.Field
              disabled={
                group === ClaimType.realtedToInvoice ||
                group === ClaimType.provision ||
                customer === ""
              }
            >
              <label>Kod RRDI klienta do którego nastąpiła dostawa*</label>
              <Dropdown
                placeholder="Wybierz klienta"
                fluid
                selection
                value={delivery}
                style={{ display: "inline-grid" }}
                onChange={changeDelivery}
                loading={loadingCustomers}
                options={mapDelivery(deliveryAddresses)}
                noResultsMessage={
                  <FormattedMessage id="app.noResultsMessage" />
                }
              />
            </Form.Field>
          </Form.Group>
          <Form.Field disabled={group === ""}>
            <label>Kategoria składanej reklamacji lub zwrotu*</label>
            <Dropdown
              placeholder="Wybierz kategorię"
              fluid
              selection
              value={category}
              onChange={changeCategory}
              options={filterCategory(dictionaries["claim-category"], group)}
              noResultsMessage={<FormattedMessage id="app.noResultsMessage" />}
            />
          </Form.Field>
          <Form.Field
            disabled={
              category === "" ||
              category === ClaimCategory.lackClaim ||
              category === ClaimCategory.orderIntention ||
              group === ClaimType.provision ||
              group === ClaimType.withoutInvoice
            }
          >
            <label>Typ składanej reklamacji lub zwrotu*</label>
            <Dropdown
              placeholder="Wybierz typ"
              fluid
              selection
              value={type}
              onChange={changeType}
              options={filterType(dictionaries["claim-type"], category)}
              noResultsMessage={<FormattedMessage id="app.noResultsMessage" />}
            />
          </Form.Field>
          <Form.Field>
            <label>Opis reklamacji na podstawie informacji od klienta*</label>
            <TextArea
              placeholder="Wypełnij opis"
              value={description}
              onInput={inputDescription}
            />
          </Form.Field>
          {group === ClaimType.withoutInvoice ? (
            <Form.Group widths={3}>
              <Form.Field>
                <label>Nr faktury</label>
                <Input
                  placeholder="Wypełnij Nr faktury"
                  value={customInvoiceNumber}
                  onChange={changeCustomerInvoiceNumber}
                />
              </Form.Field>
            </Form.Group>
          ) : null}
          {category === ClaimCategory.tractionBatteryReturn ||
          category === ClaimCategory.batteryWithoutInvoice ? (
            <Form.Group widths="equal">
              <Form.Field required>
                <label>Numer VIN*</label>
                <Input
                  placeholder="Wypełnij VIN"
                  value={vin}
                  onChange={changeVin}
                />
              </Form.Field>
              <Form.Field required>
                <label>Numer BIN*</label>
                <Input
                  placeholder="Wypełnij BIN"
                  value={bin}
                  onChange={changeBin}
                />
              </Form.Field>
              <Form.Field required>
                <label>Numer PAMIR*</label>
                <Input
                  placeholder="Wypełnij PAMIR"
                  value={pamir}
                  onChange={changePamir}
                />
              </Form.Field>
            </Form.Group>
          ) : null}
          <div style={{ display: "flex", gap: 20 }}>
            <div>
              <Form.Field>
                <label>Lista linii do reklamacji</label>
              </Form.Field>
              {group !== ClaimType.notRealtedToInvoice &&
              group !== ClaimType.withoutInvoice ? (
                <Search
                  loading={loading}
                  open={false}
                  onSearchChange={(e, d) => handleInputChange(e, d, "search")}
                  placeholder={"Referencja | Nr zamówienia"}
                />
              ) : null}
            </div>
            <div>
              <Form.Field>
                <label style={{ visibility: "hidden" }}>Numer paczki</label>
              </Form.Field>
              {group !== ClaimType.notRealtedToInvoice &&
              group !== ClaimType.withoutInvoice ? (
                <Search
                  loading={loading}
                  open={false}
                  onSearchChange={(e, d) =>
                    handleInputChange(e, d, "searchPackageNr")
                  }
                  placeholder={"Numer paczki"}
                />
              ) : null}
            </div>
          </div>
          <Table
            sortable={
              group !== ClaimType.notRealtedToInvoice &&
              group !== ClaimType.withoutInvoice
            }
            celled
            basic
          >
            <Table.Header key={"line"}>
              <Table.Row>
                <Table.HeaderCell width={1}>
                  {group !== ClaimType.provision &&
                  group !== ClaimType.notRealtedToInvoice &&
                  group !== ClaimType.withoutInvoice ? (
                    <Checkbox
                      checked={isCheckedAll(tableData)}
                      disabled={lines.length === 0}
                      onChange={selectAll}
                    />
                  ) : null}
                </Table.HeaderCell>
                <Table.HeaderCell
                  width={2}
                  sorted={column === "lineNumber" ? direction : null}
                  onClick={() =>
                    setTableData({ type: "CHANGE_SORT", column: "lineNumber" })
                  }
                >
                  {translations.format("app.claim.line.number")}
                </Table.HeaderCell>
                <Table.HeaderCell
                  width={4}
                  sorted={column === "referenceId" ? direction : null}
                  onClick={() =>
                    setTableData({ type: "CHANGE_SORT", column: "referenceId" })
                  }
                >
                  {translations.format("app.reference")}
                </Table.HeaderCell>
                {group !== ClaimType.notRealtedToInvoice &&
                group !== ClaimType.withoutInvoice ? (
                  <Table.HeaderCell
                    sorted={
                      column ===
                      (group === ClaimType.realtedToInvoice
                        ? "orderedQuantity"
                        : "quantityOrgin")
                        ? direction
                        : null
                    }
                    onClick={() =>
                      setTableData({
                        type: "CHANGE_SORT",
                        column:
                          group === ClaimType.realtedToInvoice
                            ? "orderedQuantity"
                            : "quantityOrgin",
                      })
                    }
                  >
                    {translations.format("app.claim.ordered-quantity")}
                  </Table.HeaderCell>
                ) : null}
                {group === ClaimType.notRealtedToInvoice ||
                group === ClaimType.withoutInvoice ? (
                  <>
                    <Table.HeaderCell
                      width={1}
                      textAlign={"center"}
                      sorted={column === "categoryOrigin" ? direction : null}
                      onClick={() =>
                        setTableData({
                          type: "CHANGE_SORT",
                          column: "categoryOrigin",
                        })
                      }
                    >
                      {translations.format("DFS")}
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      width={2}
                      sorted={column === "pvpPrice" ? direction : null}
                      onClick={() =>
                        setTableData({
                          type: "CHANGE_SORT",
                          column: "pvpPrice",
                        })
                      }
                    >
                      {translations.format("app.claim.pvpPrice")}
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      width={2}
                      sorted={column === "pnrPrice" ? direction : null}
                      onClick={() =>
                        setTableData({
                          type: "CHANGE_SORT",
                          column: "pnrPrice",
                        })
                      }
                    >
                      {translations.format("app.claim.pnrPrice")}
                    </Table.HeaderCell>
                  </>
                ) : null}
                <Table.HeaderCell
                  sorted={column === "quantity" ? direction : null}
                  onClick={() =>
                    setTableData({ type: "CHANGE_SORT", column: "quantity" })
                  }
                >
                  {translations.format("app.claim.quantity")}
                </Table.HeaderCell>
                {group === ClaimType.realtedToInvoice && (
                  <Table.HeaderCell
                    sorted={column === "orderNumber" ? direction : null}
                    onClick={() =>
                      setTableData({
                        type: "CHANGE_SORT",
                        column: "orderNumber",
                      })
                    }
                  >
                    {translations.format("app.claim.order.number")}
                  </Table.HeaderCell>
                )}
                {group === ClaimType.provision && (
                  <Table.HeaderCell
                    sorted={column === "orderNumber" ? direction : null}
                    onClick={() =>
                      setTableData({
                        type: "CHANGE_SORT",
                        column: "orderNumber",
                      })
                    }
                  >
                    {translations.format("app.claim.order.number")}
                  </Table.HeaderCell>
                )}
                {group === ClaimType.notRealtedToInvoice ||
                group === ClaimType.withoutInvoice ? (
                  <Table.HeaderCell />
                ) : null}
                {group === ClaimType.realtedToInvoice && (
                  <Table.HeaderCell
                    sorted={column === "packageNumber" ? direction : null}
                    onClick={() =>
                      setTableData({
                        type: "CHANGE_SORT",
                        column: "packageNumber",
                      })
                    }
                  >
                    {translations.format("app.claim.order.packageNumber")}
                  </Table.HeaderCell>
                )}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {getTableData(group).map((line: any) => (
                <Table.Row key={line.lineNumber}>
                  <Table.Cell>
                    <Checkbox
                      checked={line.selected}
                      onChange={(e, d) => selectLine(line.lineNumber, e, d)}
                    />
                  </Table.Cell>
                  <Table.Cell>{line.lineNumber}</Table.Cell>
                  <Table.Cell>
                    {group === ClaimType.notRealtedToInvoice ||
                    group === ClaimType.withoutInvoice ? (
                      <UberSearch
                        fluid
                        value={line.referenceId}
                        input={{ placeholder: "Wybierz produkt" }}
                        endpoint="/spare-parts-price-list"
                        queryParams={[
                          { name: "isClaim", value: "true" },
                          {
                            name: "withProvisionId",
                            value:
                              group === ClaimType.withoutInvoice
                                ? "true"
                                : "false",
                          },
                        ]}
                        mapper={(response) =>
                          mapProducts(response.slice(0, 10))
                        }
                        onResultSelect={(product: any) =>
                          changeReferenceId(line.lineNumber, product)
                        }
                      />
                    ) : (
                      line.referenceId
                    )}
                  </Table.Cell>
                  {group !== ClaimType.notRealtedToInvoice &&
                  group !== ClaimType.withoutInvoice ? (
                    <Table.Cell>
                      {line.quantityOrgin
                        ? line.quantityOrgin
                        : line.orderedQuantity}
                    </Table.Cell>
                  ) : null}
                  {group === ClaimType.notRealtedToInvoice ||
                  group === ClaimType.withoutInvoice ? (
                    <>
                      <Table.Cell textAlign={"center"}>
                        {isDFSCategoryOrigin(line) ? (
                          <Icon name="check" color="grey" fitted />
                        ) : (
                          <Icon name="x" color="grey" fitted />
                        )}
                      </Table.Cell>
                      <Table.Cell collapsing>
                        {isDFSCategoryOrigin(line) ? (
                          <Popup
                            content={
                              !isInvalidPVPPrice(line)
                                ? `${translations.format("app.claim.pvpPrice")}: ${line.pvpPrice}`
                                : `Wartość powinna być większa od 0`
                            }
                            trigger={
                              <FormInputNumber
                                min={0}
                                precision={2}
                                placeholder="..."
                                fluid
                                value={line.pvpPrice}
                                error={isInvalidPVPPrice(line)}
                                style={{ width: 100, opacity: 1 }}
                                onChange={(e, d) =>
                                  changePVPPrice(line.lineNumber, e, d)
                                }
                              />
                            }
                          ></Popup>
                        ) : null}
                      </Table.Cell>
                    </>
                  ) : null}
                  {group === ClaimType.notRealtedToInvoice ||
                  group === ClaimType.withoutInvoice ? (
                    <Table.Cell collapsing>
                      {isDFSCategoryOrigin(line) ? (
                        <Popup
                          content={
                            !isInvalidPNRPrice(line)
                              ? `${translations.format("app.claim.pnrPrice")}: ${line.pnrPrice}`
                              : renderPNRErrorMessage(line)
                          }
                          trigger={
                            <FormInputNumber
                              min={0}
                              precision={2}
                              placeholder="..."
                              fluid
                              value={line.pnrPrice}
                              error={isInvalidPNRPrice(line)}
                              style={{ width: 100, opacity: 1 }}
                              onChange={(e, d) =>
                                changePNRPrice(line.lineNumber, e, d)
                              }
                            />
                          }
                        ></Popup>
                      ) : null}
                    </Table.Cell>
                  ) : null}
                  <Table.Cell collapsing>
                    <Popup
                      content={
                        !isInvalidLine(line)
                          ? `${translations.format("app.claim.quantity")}: ${line.quantity}`
                          : "Wartość powinna być większa od zera"
                      }
                      trigger={
                        <FormInputNumber
                          min={0}
                          placeholder="..."
                          fluid
                          value={
                            validNumber(line.quantity) ? line.quantity : ""
                          }
                          error={isInvalidLine(line)}
                          style={{ width: 100, opacity: 1 }}
                          max={group === ClaimType.provision ? 1 : undefined}
                          onChange={(e, d) =>
                            changeQuantity(line.lineNumber, e, d)
                          }
                          disabled={group === ClaimType.withoutInvoice}
                        />
                      }
                    ></Popup>
                  </Table.Cell>
                  {group === ClaimType.realtedToInvoice && (
                    <Table.Cell>{line.orderNumber}</Table.Cell>
                  )}
                  {group === ClaimType.provision && (
                    <Table.Cell>{line.orderNumber}</Table.Cell>
                  )}
                  {group === ClaimType.notRealtedToInvoice ||
                  group === ClaimType.withoutInvoice ? (
                    <Table.HeaderCell>
                      <Popup
                        trigger={
                          <Button
                            circular
                            basic
                            icon="remove"
                            onClick={() => removeLine(line.lineNumber)}
                          />
                        }
                        content="Usuń linię z reklamacji"
                      />
                    </Table.HeaderCell>
                  ) : null}
                  {group === ClaimType.realtedToInvoice ? (
                    <Table.Cell textAlign="center">
                      {!line.packageNumber && "-"}
                      {line.packageNumber && (
                        <InfoIcon
                          documents={line.packageNumber || []}
                          position="top right"
                          clickableItem={(document) => (
                            <Button
                              style={{ display: "block", width: "100%" }}
                              compact
                              basic
                              onClick={() => {
                                copyToClipboard(document);
                              }}
                            >
                              {document}
                            </Button>
                          )}
                          noResultsMessage="Brak numeru paczki"
                        />
                      )}
                    </Table.Cell>
                  ) : null}
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
          {group === ClaimType.notRealtedToInvoice ||
          (group === ClaimType.withoutInvoice && lines.length < 1) ? (
            <Popup
              trigger={<Button circular basic icon="add" onClick={addLine} />}
              content="Dodaj kolejną linię do reklamacji"
            />
          ) : null}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Popup
          trigger={
            <span>
              <Button
                content="Zatwierdź"
                icon="add"
                labelPosition="left"
                primary
                loading={creating}
                onClick={handleConfirm}
                disabled={!isValidForm() || creating}
              />{" "}
            </span>
          }
          content={errorMessage}
          disabled={isValidForm()}
        />
        <Button content="Anuluj" onClick={handleCancel} />
      </Modal.Actions>
    </Modal>
  );
};

// @ts-ignore: redux types
const mapStateToProps: (state: ApplicationState) => TReduxState = ({
  claims,
  invoices,
  orders,
  customers,
  pricing,
  dictionaries,
}: ApplicationState) => ({
  invoices: invoices.list,
  orders: orders.list,
  products: pricing.list,
  invoicesWithProvision: invoices.invoicesWithProvision.list,
  invoicesWithDebitNotes: invoices.invoicesWithDebitNotes.list,
  invoiceLines: invoices.invoiceLines.list,
  loadingProducts: pricing.loading || dictionaries.loading,
  loadingInvoices: invoices.loadingInvoices || dictionaries.loading,
  loadingInvoicesWithDebitNotes: invoices.loadingInvoicesWithDebitNotes,
  loadingInvoicesWithProvision:
    invoices.invoicesWithProvision.loading || dictionaries.loading,
  loadingOrders: orders.loadingOrders || dictionaries.loading,
  loadingCustomers: customers.loading || dictionaries.loading,
  deliveryAddresses: customers.selected
    ? customers.selected.deliveryAddresses
    : [],
  created: claims.created,
  creating: claims.creating,
  dictionaries,
});

const mapDispatchToProps: TReduxActions = {
  createClaim,
  clearCreated,
  fetchInvoices,
  fetchInvoicesWithProvision,
  fetchOrders,
  selectCustomer,
  fetchPriceList,
  fetchDictionary,
  fetchInvoicesWithDebitNotes,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddClaim);
