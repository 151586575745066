import { Reducer } from "redux";
import { ErrorActionTypes } from "~/store/error/types";
import { UsersActionTypes, UsersState } from "./types";

export const initialUsersState: UsersState = {
  loadingUsers: false,
  loadingGroups: false,
  loadingAvailableRoles: false,
  loadingUserRoles: false,
  loadingChangeUserRolesAndLimit: false,
  passwordPolicies: [],
  list: [],
  availableRoles: {},
  userRoles: {},
  created: false,
  creating: false,
  loadingUser: false,
  downloadFP0536pending: false,
  user: null,
};

const reducer: Reducer<UsersState> = (state = initialUsersState, action) => {
  switch (action.type) {
    case UsersActionTypes.FETCH_USERS_REQUEST: {
      return { ...state, loadingUsers: true, fetching: true };
    }
    case UsersActionTypes.FETCH_USERS_SUCCESS: {
      return {
        ...state,
        loadingUsers: false,
        list: action.payload.users,
      };
    }
    case UsersActionTypes.ADD_USER: {
      return { ...state, created: false, creating: true };
    }
    case UsersActionTypes.ADD_SUCCESS: {
      return { ...state, created: true, creating: false };
    }
    case UsersActionTypes.CLEAR_CREATED: {
      return { ...state, created: false, creating: false };
    }
    case UsersActionTypes.ENABLE_USER:
    case UsersActionTypes.REMOVE_USER: {
      return { ...state, loadingUsers: true };
    }
    case UsersActionTypes.ACTION_SUCCESS: {
      return {
        ...state,
        loadingUsers: false,
        loadingGroups: false,
        loadingUser: false,
        loadingChangeUserRolesAndLimit: false,
      };
    }
    case UsersActionTypes.FETCH_AVAILABLE_ROLES: {
      return { ...state, loadingAvailableRoles: true };
    }
    case UsersActionTypes.FETCH_AVAILABLE_ROLES_SUCCESS: {
      return {
        ...state,
        loadingAvailableRoles: false,
        availableRoles: action.payload.availableRoles,
      };
    }
    case UsersActionTypes.FETCH_AVAILABLE_ROLES_FAILED: {
      return {
        ...state,
        loadingAvailableRoles: false,
      };
    }
    case UsersActionTypes.FETCH_USER_ROLES: {
      return { ...state, loadingUserRoles: true };
    }
    case UsersActionTypes.FETCH_USER_ROLES_SUCCESS: {
      return {
        ...state,
        loadingUserRoles: false,
        userRoles: action.payload.userRoles,
      };
    }
    case UsersActionTypes.FETCH_USER_ROLES_FAILED: {
      return {
        ...state,
        loadingUserRoles: false,
      };
    }
    case UsersActionTypes.CHANGE_USER_ROLES_AND_LIMIT: {
      return { ...state, loadingChangeUserRolesAndLimit: true };
    }
    case UsersActionTypes.RESET_USER: {
      return { ...state, user: null };
    }
    case UsersActionTypes.FETCH_USER_BY_ID: {
      return { ...state, loadingUser: true };
    }
    case UsersActionTypes.FETCH_USER_BY_ID_SUCCESS: {
      return { ...state, loadingUser: false, user: action.payload.user };
    }
    case UsersActionTypes.FETCH_USER_BY_ID_FAILED: {
      return { ...state, loadingUser: false };
    }
    case UsersActionTypes.CHANGE_USER_ROLES_AND_LIMIT_SUCCESS: {
      return { ...state, loadingChangeUserRolesAndLimit: false };
    }
    case UsersActionTypes.FETCH_PASSWORD_POLICIES_SUCCESS: {
      return { ...state, passwordPolicies: action.payload.passwordPolicies };
    }
    case ErrorActionTypes.HANDLE_ERROR: {
      return {
        ...state,
        loadingGroups: false,
        loadingUsers: false,
        loadingChangeUserRolesAndLimit: false,
        loadingUserRoles: false,
        loadingAvailableRoles: false,
        creating: false,
      };
    }
    case UsersActionTypes.DOWNLOAD_FP0536_CSV: {
      return {...state, downloadFP0536pending: true}
    }
    case UsersActionTypes.DOWNLOAD_FP0536_CSV_SUCCESS: {
      return {...state, downloadFP0536pending: false}
    }
    case UsersActionTypes.DOWNLOAD_FP0536_CSV_FAILED: {
      return {...state, downloadFP0536pending: false}
    }
  }

  return state;
};

export { reducer as usersReducer };
