import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  AvailableRolesInGroups,
  PasswordPolicy,
  User,
  UsersActionTypes,
} from "./types";
import {
  fetchUsersSuccess,
  addSuccess,
  enableUser,
  removeUser,
  fetchAvailableRoles,
  fetchUserRoles,
  actionSuccess,
  fetchAvailableRolesSuccess,
  fetchUserRolesSuccess,
  fetchPasswordPoliciesSuccess,
  exportUserListToXls,
  changeUserRolesAndLimitSuccess,
  fetchUserRolesFailed,
  fetchAvailableRolesFailed,
  fetchUserById,
  fetchUserByIdSuccess,
  fetchUserByIdFailed,
  changeUserRolesAndLimit,
  exportFP0536ToCsv,
  exportFP0536ToCsvSuccesss,
  exportFP0536ToCsvFailed,
} from "./actions";
import { callApi, callApiDownload } from "~/utils/api";
import { toastError, toastSuccess } from "~/utils/toast";
import { handleError } from "~/store/error/actions";
import { downloadFile } from "~/utils/downloadFile";

function* handleFetchUserById(action: ReturnType<typeof fetchUserById>) {
  try {
    const user: User = yield call(
      callApi,
      "get",
      `/users/${action.payload.id}`
    );
    yield put(fetchUserByIdSuccess(user));
  } catch (error) {
    yield put(fetchUserByIdFailed());
    toastError("app.error.common.load", "app.error.user.load");
  }
}

function* handleFetchUsers() {
  try {
    const users: User[] = yield call(callApi, "get", "/users");
    yield put(fetchUsersSuccess(users));
  } catch (error) {
    yield put(handleError(error as Error, true, "Błąd pobierania listy użytkowników"));
  }
}

function* handleAddUser(action: any) {
  try {
    yield call(callApi, "post", `/users`, action.payload);
    yield put(addSuccess());
  } catch (error) {
    yield put(handleError(error as Error, false, "Błąd podczas dodawania użytkownika"));
  }
}

function* handleEnableUser(action: ReturnType<typeof enableUser>) {
  try {
    yield call(callApi, "put", `/users/${action.payload.userId}/status`, {
      enabled: action.payload.enable,
    });
    yield put(actionSuccess());
  } catch (error) {
    yield put(actionSuccess());
    toastError(
      "app.error.common.update",
      action.payload.enable
        ? "app.error.user.enable"
        : "app.error.user.disenable"
    );
  }
}

function* handleRemoveUser(action: ReturnType<typeof removeUser>) {
  try {
    yield call(callApi, "delete", `/users/${action.payload}`);
    yield put(actionSuccess());
  } catch (error) {
    yield put(actionSuccess());
    toastError("app.error.common.delete", "app.error.user.remove");
  }
}

function* handleFetchAvailableRoles(
  action: ReturnType<typeof fetchAvailableRoles>
) {
  try {
    const availableRoles: AvailableRolesInGroups = yield call(
      callApi,
      "get",
      `/users/${action.payload}/role-mappings/realm/available`
    );
    yield put(fetchAvailableRolesSuccess(availableRoles));
  } catch (error) {
    yield put(fetchAvailableRolesFailed());
    toastError(
      "app.error.common.load",
      "app.error.users.availableUserRoles.load"
    );
  }
}

function* handleFetchUserRoles(action: ReturnType<typeof fetchUserRoles>) {
  try {
    const userRoles: AvailableRolesInGroups = yield call(
      callApi,
      "get",
      `/users/${action.payload}/role-mappings/realm`
    );
    yield put(fetchUserRolesSuccess(userRoles));
  } catch (error) {
    yield put(fetchUserRolesFailed());
    toastError("app.error.common.load", "app.error.users.userRoles.load");
  }
}

function* handleChangeUserRolesAndLimit(
  action: ReturnType<typeof changeUserRolesAndLimit>
) {
  const userId = action.payload.userId;
  try {
    yield call(
      callApi,
      "put",
      `/users/${userId}/role-mappings/realm`,
      action.payload.dataToSend
    );
    yield put(changeUserRolesAndLimitSuccess());
    yield put(fetchUserRoles(userId));
    yield put(fetchAvailableRoles(userId));

    action.payload.cb();

    toastSuccess(
      "app.user.change.roles.and.limit.success",
      "app.info.common.success",
      { timeOut: 7000 }
    );
  } catch (error) {
    yield put(changeUserRolesAndLimitSuccess());
    toastError(
      "app.error.common.load",
      "app.error.users.change.roles.and.limit"
    );
  }
}

function* handleFetchPasswordPolicies() {
  try {
    const passwordPolicies: PasswordPolicy[] = yield call(
      callApi,
      "get",
      "/users/passwordPolicy"
    );
    yield put(fetchPasswordPoliciesSuccess(passwordPolicies));
  } catch (error) {
    toastError("app.error.password-policies", "app.error.users.load");
  }
}

function* handleExportUserListToXls(
  action: ReturnType<typeof exportUserListToXls>
) {
  try {
    const response: File = yield call(
      callApiDownload,
      "get",
      "/users/generate",
      action.payload
    );

    downloadFile(
      response,
      action.payload,
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;"
    );
  } catch (error) {
    toastError("app.error.export-to-xls-file", "app.error.users.export");
  }
}

function* handleDownloadFP0536ToCsv(
  action: ReturnType<typeof exportFP0536ToCsv>
) {
  try {

    const response: File = yield call(
      callApiDownload,
      "get",
      "/hazardous-goods/generate/csv",
      action.payload
    );
    yield put(exportFP0536ToCsvSuccesss());
    downloadFile(
      response,
      action.payload + '.csv',
    );
  } catch (error) {
    yield put(exportFP0536ToCsvFailed());
    toastError("app.error.export-to-csv-file", "app.error.fp0536.export");
  }
}

function* watchFetchUserById() {
  yield takeEvery(UsersActionTypes.FETCH_USER_BY_ID, handleFetchUserById);
}

function* watchFetchUsersRequest() {
  yield takeEvery(UsersActionTypes.FETCH_USERS_REQUEST, handleFetchUsers);
}

function* watchAddUser() {
  yield takeEvery(UsersActionTypes.ADD_USER, handleAddUser);
}

function* watchEnableUser() {
  yield takeEvery(UsersActionTypes.ENABLE_USER, handleEnableUser);
}

function* watchRemoveUser() {
  yield takeEvery(UsersActionTypes.REMOVE_USER, handleRemoveUser);
}

function* watchFetchUserRoles() {
  yield takeEvery(UsersActionTypes.FETCH_USER_ROLES, handleFetchUserRoles);
}

function* watchFetchAvailableRoles() {
  yield takeEvery(
    UsersActionTypes.FETCH_AVAILABLE_ROLES,
    handleFetchAvailableRoles
  );
}

function* watchChangeUserToRoleAndLimit() {
  yield takeEvery(
    UsersActionTypes.CHANGE_USER_ROLES_AND_LIMIT,
    handleChangeUserRolesAndLimit
  );
}

function* watchFetchPasswordPolicies() {
  yield takeEvery(
    UsersActionTypes.FETCH_PASSWORD_POLICIES,
    handleFetchPasswordPolicies
  );
}

function* watchExportUserListToXls() {
  yield takeEvery(
    UsersActionTypes.EXPORT_USER_LIST_TO_XLS,
    handleExportUserListToXls
  );
}

function* watchexportFP0536ToCsv() {
  yield takeEvery(
    UsersActionTypes.DOWNLOAD_FP0536_CSV,
    handleDownloadFP0536ToCsv
  );
}

function* usersSaga() {
  yield all([
    fork(watchFetchUserById),
    fork(watchChangeUserToRoleAndLimit),
    fork(watchFetchUsersRequest),
    fork(watchAddUser),
    fork(watchEnableUser),
    fork(watchRemoveUser),
    fork(watchFetchAvailableRoles),
    fork(watchFetchUserRoles),
    fork(watchFetchPasswordPolicies),
    fork(watchExportUserListToXls),
    fork(watchexportFP0536ToCsv),
  ]);
}

export default usersSaga;
