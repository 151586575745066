import React, { useCallback, useState, SyntheticEvent, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Form, Header, Modal, Divider, TextArea, Dropdown } from 'semantic-ui-react';
import { ModelFormWrapper } from '~/components/ModelForm/ModelFormWrapper';
import { SubmitButton } from '~/components/ModelForm/SubmitButton';
import { ApplicationState } from '~/store';
import { createClaimTransition } from '~/store/claims/actions';
import { TClaimLine } from '~/store/claims/types';
import { clearTransitionClaim } from '~/store/claims/actions';
import VerificationEditLine from './Transition/VerificationEditLine';
import ClaimLinesTable from './Transition/ClaimLinesTable';
import TransitionChange from './Transition/TransitionChange';
import AwaitingDecisionEditLine from './Transition/AwaitingDecisionEditLine';
import { FormattedMessage } from 'react-intl';
import {
  claimCategoriesToDisplayInputs,
  ClaimTransitionAction,
  ClaimTransitionLineActionType,
  FormCategory,
  optionsVerification,
} from './Transition/transitionColumns.conf';

type TReduxActions = {
  createClaimTransition: typeof createClaimTransition;
  clearTransitionClaim: typeof clearTransitionClaim;
};

interface PropsFromState {
  transitionChanged: boolean;
}

interface ClaimTransitionLine {
  statusNotes: string;
  actions: any;
  lineNumber: number;
}

type Props = {
  triggerOpen: boolean;
  claimNumber: string;
  lines: TClaimLine[];
  statusNotes: string;
  createdSuccess: () => void;
  claimStatus: string;
  claimGroup: string;
  claimCategory: string;
  grn?: string;
};

type TClaimTransitionProps = TReduxActions & Props & PropsFromState;

const ClaimTransition: React.FC<TClaimTransitionProps> = ({
  children,
  triggerOpen,
  claimNumber,
  lines,
  statusNotes,
  createClaimTransition,
  transitionChanged,
  createdSuccess,
  clearTransitionClaim,
  claimStatus,
  claimGroup,
  claimCategory,
  grn,
}) => {
  const initTransitionAction = claimStatus === 'AWAITING_DECISION' ? ClaimTransitionAction.DECISION : claimStatus;

  const [open, setOpen] = useState<boolean>(false);
  const [transitionLines, setTransitionLines] = useState<TClaimLine[]>(lines);
  const [transitionLineIndex, setTransitionLineIndex] = useState<number | null>(null);
  const [claimNote, setClaimNotes] = useState<string>(statusNotes);
  const [transitionAction, setTransitionAction] = useState<string>('');
  const [confirmOption, setConfirmOption] = useState<string>('');
  const [transitionActionStatus] = useState<string>(initTransitionAction);
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);

  useEffect(() => {
    if (triggerOpen) {
      setOpen(true);
    }
  }, [triggerOpen]);

  useEffect(() => {
    let newLines = [...lines];

    if (transitionActionStatus === ClaimTransitionAction.DECISION) {
      newLines.forEach((line) => {
        line[FormCategory.RECEIVED_FULL_VALUE] = {
          claimedQuantity: line.receivedQuantityFullValue,
          acceptedQuantity: line.receivedQuantityFullValue,
          decisionAcceptedWithoutCorrectionQuantity: 0,
          decisionRejectedToClientQuantity: 0,
          decisionToDestroyQuantity: 0,
          decisionToDestroyWithoutCorrectionQuantity: 0,
          isNotValidQuantity: false,
        };

        line[FormCategory.RECEIVED_DEFECTIVE] = {
          claimedQuantity: line.receivedQuantityDefective,
          acceptedQuantity: 0,
          decisionAcceptedWithoutCorrectionQuantity: 0,
          decisionRejectedToClientQuantity: 0,
          decisionToDestroyQuantity: 0,
          decisionToDestroyWithoutCorrectionQuantity: 0,
          isNotValidQuantity: line.receivedQuantityDefective
            ? `Suma musi byc równa ${line.receivedQuantityDefective}`
            : false,
        };
      });
    }

    if (transitionActionStatus === ClaimTransitionAction.VERIFICATION) {
      newLines = newLines.map((line) => {
        return {
          ...line,
          acceptedQuantity: claimCategoriesToDisplayInputs['VERIFICATION']['VERIFICATION_ACCEPTED'].includes(
            claimCategory
          )
            ? line.claimedQuantity
            : 0,
          requestReturnQuantity: 0,
          rejectQuantity: 0,
          isNotValidQuantity: claimCategoriesToDisplayInputs['VERIFICATION']['VERIFICATION_ACCEPTED'].includes(
            claimCategory
          )
            ? false
            : `Suma musi byc równa ${line.claimedQuantity}`,
        };
      });
    }

    if (['RK1_ZRPN', 'RK2_ZRNF'].includes(claimCategory)) {
      newLines = newLines.map((line) => {
        return {
          ...line,
          acceptedQuantity: 0,
          rejectQuantity: 0,
          requestReturnQuantity: line.claimedQuantity,
          isNotValidQuantity: false,
        };
      });
    }

    setTransitionLines(newLines);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (transitionActionStatus === ClaimTransitionAction.VERIFICATION) {
      const isValidForm = transitionLines.every((line) => line.isNotValidQuantity === false);

      if (isValidForm) {
        setDisableSubmit(false);
      } else {
        setDisableSubmit(true);
      }
    }

    if (transitionActionStatus === ClaimTransitionAction.DECISION) {
      const isValidForm = transitionLines.every(
        (line) =>
          line[FormCategory.RECEIVED_FULL_VALUE].isNotValidQuantity === false &&
          line[FormCategory.RECEIVED_DEFECTIVE].isNotValidQuantity === false
      );
      if (isValidForm) {
        setDisableSubmit(false);
      } else {
        setDisableSubmit(true);
      }
    }
    // eslint-disable-next-line
  }, [transitionLines]);

  const changeLine = useCallback(
    (event: SyntheticEvent, data: any, type: string, formCategory: string, onlyReturning: boolean): void => {
      if (transitionLineIndex === null) return;

      const isValueType = type !== 'statusNotes';
      const value = isValueType ? parseInt(data.value) : data.value;

      if (isValueType && value < 0) return;

      let newLines = [...transitionLines];

      if (formCategory === FormCategory.RECEIVED_FULL_VALUE || formCategory === FormCategory.RECEIVED_DEFECTIVE) {
        let {
          claimedQuantity,
          acceptedQuantity,
          decisionAcceptedWithoutCorrectionQuantity,
          decisionToDestroyQuantity,
          decisionToDestroyWithoutCorrectionQuantity,
          decisionRejectedToClientQuantity,
        } = transitionLines[transitionLineIndex][`${formCategory}`];

        if (
          type === 'acceptedQuantity' &&
          value >
            claimedQuantity -
              decisionAcceptedWithoutCorrectionQuantity -
              decisionToDestroyQuantity -
              decisionToDestroyWithoutCorrectionQuantity -
              decisionRejectedToClientQuantity
        )
          return;

        if (
          type === 'decisionAcceptedWithoutCorrectionQuantity' &&
          value >
            claimedQuantity -
              acceptedQuantity -
              decisionToDestroyQuantity -
              decisionToDestroyWithoutCorrectionQuantity -
              decisionRejectedToClientQuantity
        )
          return;
        if (
          type === 'decisionToDestroyQuantity' &&
          value >
            claimedQuantity -
              acceptedQuantity -
              decisionAcceptedWithoutCorrectionQuantity -
              decisionToDestroyWithoutCorrectionQuantity -
              decisionRejectedToClientQuantity
        )
          return;
        if (
          type === 'decisionToDestroyWithoutCorrectionQuantity' &&
          value >
            claimedQuantity -
              acceptedQuantity -
              decisionAcceptedWithoutCorrectionQuantity -
              decisionToDestroyQuantity -
              decisionRejectedToClientQuantity
        )
          return;
        if (
          type === 'decisionRejectedToClientQuantity' &&
          value >
            claimedQuantity -
              acceptedQuantity -
              decisionAcceptedWithoutCorrectionQuantity -
              decisionToDestroyQuantity -
              decisionToDestroyWithoutCorrectionQuantity
        )
          return;

        let formValue: any = {
          ...transitionLines[transitionLineIndex][`${formCategory}`],
          [`${type}`]: value,
        };

        const isValidQuantity =
          claimedQuantity -
            formValue.acceptedQuantity -
            formValue.decisionAcceptedWithoutCorrectionQuantity -
            formValue.decisionToDestroyQuantity -
            formValue.decisionToDestroyWithoutCorrectionQuantity -
            formValue.decisionRejectedToClientQuantity ===
          0;
        formValue = {
          ...formValue,
          isNotValidQuantity: isValidQuantity !== true ? `Suma musi byc równa ${claimedQuantity}` : false,
        };

        newLines[transitionLineIndex] = {
          ...transitionLines[transitionLineIndex],
          [`${formCategory}`]: formValue,
        };

        setTransitionLines(newLines);
      } else {
        let { claimedQuantity, requestReturnQuantity, rejectQuantity, acceptedQuantity } =
          transitionLines[transitionLineIndex];

        if (typeof value === 'number' && value > claimedQuantity) {
          return;
        } else if (type === 'acceptedQuantity') {
          if (acceptedQuantity === claimedQuantity && value >= claimedQuantity) return;
          // DECREASING - move from accpeted to rejected
          if (value < acceptedQuantity) {
            newLines[transitionLineIndex] = {
              ...transitionLines[transitionLineIndex],
              acceptedQuantity: value,
              rejectQuantity: claimedQuantity - requestReturnQuantity - value,
            };
            // INCREASING - takes first from rejected, then from returned
          } else if (acceptedQuantity >= 0 && value > acceptedQuantity) {
            if (requestReturnQuantity === 0 && rejectQuantity === 0) return;
            const incrementBy = value - acceptedQuantity;
            if (rejectQuantity - incrementBy < 0) {
              newLines[transitionLineIndex] = {
                ...transitionLines[transitionLineIndex],
                acceptedQuantity: value,
                rejectQuantity: 0,
                requestReturnQuantity: claimedQuantity - value,
              };
            } else if (rejectQuantity > 0) {
              newLines[transitionLineIndex] = {
                ...transitionLines[transitionLineIndex],
                rejectQuantity: claimedQuantity - requestReturnQuantity - value,
                acceptedQuantity: value,
              };
            } else if (rejectQuantity === 0 && requestReturnQuantity > 0) {
              newLines[transitionLineIndex] = {
                ...transitionLines[transitionLineIndex],
                requestReturnQuantity: claimedQuantity - requestReturnQuantity - value,
                acceptedQuantity: value,
              };
            }
          }
        } else if (type === 'requestReturnQuantity') {
          if (requestReturnQuantity === claimedQuantity && value >= claimedQuantity) return;
          // DECREASING - move from accpeted to rejected
          if (value < requestReturnQuantity) {
            if (!onlyReturning) {
              newLines[transitionLineIndex] = {
                ...transitionLines[transitionLineIndex],
                requestReturnQuantity: value,
                rejectQuantity: claimedQuantity - acceptedQuantity - value,
              };
            } else {
              newLines[transitionLineIndex] = {
                ...transitionLines[transitionLineIndex],
                requestReturnQuantity: value,
              };
            }
            // INCREASING - takes first from rejected, then from returned
          } else if (requestReturnQuantity >= 0 && value > requestReturnQuantity) {
            if (acceptedQuantity + rejectQuantity === claimedQuantity && requestReturnQuantity === claimedQuantity)
              return;
            if (onlyReturning) {
              newLines[transitionLineIndex] = {
                ...transitionLines[transitionLineIndex],
                requestReturnQuantity: value,
              };
            }
            const incrementBy = value - requestReturnQuantity;
            if (!onlyReturning && rejectQuantity - incrementBy < 0) {
              newLines[transitionLineIndex] = {
                ...transitionLines[transitionLineIndex],
                requestReturnQuantity: value,
                rejectQuantity: 0,
                acceptedQuantity: claimedQuantity - value,
              };
            } else if (!onlyReturning && rejectQuantity > 0) {
              newLines[transitionLineIndex] = {
                ...transitionLines[transitionLineIndex],
                rejectQuantity: claimedQuantity - acceptedQuantity - value,
                requestReturnQuantity: value,
              };
            } else if (!onlyReturning && rejectQuantity === 0 && acceptedQuantity > 0) {
              newLines[transitionLineIndex] = {
                ...transitionLines[transitionLineIndex],
                acceptedQuantity: claimedQuantity - acceptedQuantity - value,
                requestReturnQuantity: value,
              };
            }
          }
        } else if (type === 'rejectQuantity') {
          if (rejectQuantity === claimedQuantity && value >= claimedQuantity) return;
          // DECREASING - move from accpeted to rejected
          if (value < rejectQuantity) {
            newLines[transitionLineIndex] = {
              ...transitionLines[transitionLineIndex],
              rejectQuantity: value,
              acceptedQuantity: claimedQuantity - requestReturnQuantity - value,
            };
            // INCREASING - takes first from rejected, then from returned
          } else if (rejectQuantity >= 0 && value > rejectQuantity) {
            if (requestReturnQuantity === 0 && acceptedQuantity === 0) return;
            const incrementBy = value - rejectQuantity;
            if (acceptedQuantity - incrementBy < 0) {
              newLines[transitionLineIndex] = {
                ...transitionLines[transitionLineIndex],
                rejectQuantity: value,
                acceptedQuantity: 0,
                requestReturnQuantity: claimedQuantity - value,
              };
            } else if (acceptedQuantity > 0) {
              newLines[transitionLineIndex] = {
                ...transitionLines[transitionLineIndex],
                acceptedQuantity: claimedQuantity - requestReturnQuantity - value,
                rejectQuantity: value,
              };
            } else if (acceptedQuantity === 0 && requestReturnQuantity > 0) {
              newLines[transitionLineIndex] = {
                ...transitionLines[transitionLineIndex],
                requestReturnQuantity: claimedQuantity - requestReturnQuantity - value,
                rejectQuantity: value,
              };
            }
          }
        } else {
          newLines[transitionLineIndex] = {
            ...transitionLines[transitionLineIndex],
            [`${type}`]: value,
          };
        }

        const isValidQuantity =
          claimedQuantity -
            (newLines[transitionLineIndex].acceptedQuantity || 0) -
            (newLines[transitionLineIndex].rejectQuantity || 0) -
            (newLines[transitionLineIndex].requestReturnQuantity || 0) ===
          0;

        newLines[transitionLineIndex] = {
          ...newLines[transitionLineIndex],
          isNotValidQuantity: isValidQuantity !== true ? `Suma musi byc równa ${claimedQuantity}` : false,
        };

        setTransitionLines(newLines);
      }
      // eslint-disable-next-line
    },
    [transitionLines, transitionLineIndex]
  );

  const transitionLinesMapped = (
    acceptAllLines: boolean = false,
    type: string = ''
  ): ClaimTransitionLine[] | undefined => {
    if (transitionAction === ClaimTransitionAction.MARK_CLOSED) return;

    if (transitionActionStatus === ClaimTransitionAction.VERIFICATION && acceptAllLines === false) {
      return transitionLines.map((line) => {
        const actions = [];
        if (line.acceptedQuantity !== 0 && !['RK1_ZRPN', 'RK2_ZRNF'].includes(claimCategory))
          actions.push({
            type: ClaimTransitionLineActionType.VERIFICATION_ACCEPTED,
            quantity: line.acceptedQuantity || line.claimedQuantity,
          });

        if (line.requestReturnQuantity)
          actions.push({
            type: ClaimTransitionLineActionType.VERIFICATION_REQUEST_RETURN,
            quantity: line.requestReturnQuantity,
          });

        if (line.rejectQuantity)
          actions.push({
            type: ClaimTransitionLineActionType.VERIFICATION_REJECTED,
            quantity: line.rejectQuantity,
          });

        return {
          lineNumber: line.lineNumber,
          statusNotes: line.statusNotes,
          actions,
        };
      });
    }

    if (transitionActionStatus === ClaimTransitionAction.VERIFICATION && acceptAllLines === true) {
      return transitionLines.map((line) => {
        return {
          lineNumber: line.lineNumber,
          statusNotes: line.statusNotes,
          actions: [
            {
              type,
              quantity: line.claimedQuantity,
            },
          ],
        };
      });
    }

    if (transitionActionStatus === ClaimTransitionAction.DECISION && acceptAllLines === false) {
      return transitionLines
        .filter((line) => line.toReceiveQuantity && line.toReceiveQuantity > 0)
        .map((line) => {
          const actions = [];
          if (line.RECEIVED_FULL_VALUE && line.RECEIVED_FULL_VALUE.acceptedQuantity !== 0) {
            actions.push({
              type: ClaimTransitionLineActionType.DECISION_ACCEPTED,
              quantity: line.RECEIVED_FULL_VALUE.acceptedQuantity || line.receivedQuantityFullValue,
              target: 'RECEIVED_FULL_VALUE',
            });
          }

          if (!line.RECEIVED_FULL_VALUE && line.receivedQuantityFullValue) {
            actions.push({
              type: ClaimTransitionLineActionType.DECISION_ACCEPTED,
              quantity: line.receivedQuantityFullValue,
              target: 'RECEIVED_FULL_VALUE',
            });
          }

          if (line.RECEIVED_FULL_VALUE && line.RECEIVED_FULL_VALUE.decisionAcceptedWithoutCorrectionQuantity)
            actions.push({
              type: ClaimTransitionLineActionType.DECISION_ACCEPTED_WITHOUT_CORRECTION,
              quantity: line.RECEIVED_FULL_VALUE.decisionAcceptedWithoutCorrectionQuantity,
              target: 'RECEIVED_FULL_VALUE',
            });

          if (line.RECEIVED_FULL_VALUE && line.RECEIVED_FULL_VALUE.decisionToDestroyQuantity)
            actions.push({
              type: ClaimTransitionLineActionType.DECISION_TO_DESTROY,
              quantity: line.RECEIVED_FULL_VALUE.decisionToDestroyQuantity,
              target: 'RECEIVED_FULL_VALUE',
            });

          if (line.RECEIVED_FULL_VALUE && line.RECEIVED_FULL_VALUE.decisionToDestroyWithoutCorrectionQuantity)
            actions.push({
              type: ClaimTransitionLineActionType.DECISION_TO_DESTROY_WITHOUT_CORRECTION,
              quantity: line.RECEIVED_FULL_VALUE.decisionToDestroyWithoutCorrectionQuantity,
              target: 'RECEIVED_FULL_VALUE',
            });

          if (line.RECEIVED_FULL_VALUE && line.RECEIVED_FULL_VALUE.decisionRejectedToClientQuantity)
            actions.push({
              type: ClaimTransitionLineActionType.DECISION_REJECTED_TO_CLIENT,
              quantity: line.RECEIVED_FULL_VALUE.decisionRejectedToClientQuantity,
              target: 'RECEIVED_FULL_VALUE',
            });

          if (!line.RECEIVED_DEFECTIVE && line.receivedQuantityDefective) {
            actions.push({
              type: ClaimTransitionLineActionType.DECISION_ACCEPTED,
              quantity: line.receivedQuantityDefective,
              target: 'RECEIVED_DEFECTIVE',
            });
          }

          if (line.RECEIVED_DEFECTIVE && line.RECEIVED_DEFECTIVE.acceptedQuantity !== 0)
            actions.push({
              type: ClaimTransitionLineActionType.DECISION_ACCEPTED,
              quantity: line.RECEIVED_DEFECTIVE.acceptedQuantity || line.receivedQuantityDefective,
              target: 'RECEIVED_DEFECTIVE',
            });

          if (line.RECEIVED_DEFECTIVE && line.RECEIVED_DEFECTIVE.decisionAcceptedWithoutCorrectionQuantity)
            actions.push({
              type: ClaimTransitionLineActionType.DECISION_ACCEPTED_WITHOUT_CORRECTION,
              quantity: line.RECEIVED_DEFECTIVE.decisionAcceptedWithoutCorrectionQuantity,
              target: 'RECEIVED_DEFECTIVE',
            });

          if (line.RECEIVED_DEFECTIVE && line.RECEIVED_DEFECTIVE.decisionToDestroyQuantity)
            actions.push({
              type: ClaimTransitionLineActionType.DECISION_TO_DESTROY,
              quantity: line.RECEIVED_DEFECTIVE.decisionToDestroyQuantity,
              target: 'RECEIVED_DEFECTIVE',
            });

          if (line.RECEIVED_DEFECTIVE && line.RECEIVED_DEFECTIVE.decisionToDestroyWithoutCorrectionQuantity)
            actions.push({
              type: ClaimTransitionLineActionType.DECISION_TO_DESTROY_WITHOUT_CORRECTION,
              quantity: line.RECEIVED_DEFECTIVE.decisionToDestroyWithoutCorrectionQuantity,
              target: 'RECEIVED_DEFECTIVE',
            });

          if (line.RECEIVED_DEFECTIVE && line.RECEIVED_DEFECTIVE.decisionRejectedToClientQuantity)
            actions.push({
              type: ClaimTransitionLineActionType.DECISION_REJECTED_TO_CLIENT,
              quantity: line.RECEIVED_DEFECTIVE.decisionRejectedToClientQuantity,
              target: 'RECEIVED_DEFECTIVE',
            });

          return {
            lineNumber: line.lineNumber,
            statusNotes: line.statusNotes,
            actions,
          };
        });
    }
  };

  const filterActions = (option: string) => {
    if (!claimCategoriesToDisplayInputs['VERIFICATION'][option]) return false;
    if (claimCategoriesToDisplayInputs['VERIFICATION'][option].includes(claimCategory)) return true;
  };

  const acceptAllLines = (type: string) => {
    createClaimTransition(
      claimNumber,
      transitionAction || transitionActionStatus,
      claimNote,
      transitionLinesMapped(true, type)
    );
  };

  const handleConfirm = (): void => {
    createClaimTransition(claimNumber, transitionAction || transitionActionStatus, claimNote, transitionLinesMapped());
  };

  const handleCancel = (): void => setOpen(false);

  const handleEdit = (index: number): void => setTransitionLineIndex(index);

  const handleOpen = (): void => setOpen(true);

  const handleConfirmOption = (e: any, data: any): void => setConfirmOption(data.value);

  if (transitionChanged && open) {
    clearTransitionClaim();
    createdSuccess();
    handleCancel();
    setTransitionLines(lines);
    setTransitionAction('');
    setOpen(false);
  }

  return (
    <Modal
      size="large"
      trigger={children}
      open={open}
      onOpen={handleOpen}
      onClose={handleCancel}
      closeOnDimmerClick={false}
    >
      <ModelFormWrapper onSubmit={handleConfirm}>
        <Header icon="edit" content={`Tranzycja reklamacji ${claimNumber}`} />
        <Modal.Content>
          {!(
            transitionActionStatus === ClaimTransitionAction.VERIFICATION ||
            transitionActionStatus === ClaimTransitionAction.DECISION
          ) && (
            <>
              <TransitionChange
                claimStatus={claimStatus}
                claimGroup={claimGroup}
                grn={grn}
                setTransitionAction={setTransitionAction}
                transitionAction={transitionAction}
              />
              <Divider />
            </>
          )}

          <Form>
            <Form.Field>
              <label>Komentarz do reklamacji</label>
              <TextArea
                placeholder="Wypełnij opis"
                value={claimNote ? claimNote : ''}
                onChange={(e, d: any) => setClaimNotes(d.value)}
              />
            </Form.Field>
          </Form>
          {transitionActionStatus === ClaimTransitionAction.VERIFICATION ||
          transitionActionStatus === ClaimTransitionAction.DECISION ? (
            <ClaimLinesTable
              transitionLines={transitionLines}
              handleEdit={handleEdit}
              transitionActionStatus={transitionActionStatus}
              claimCategory={claimCategory}
            />
          ) : null}
          <Divider />
          {transitionLineIndex !== null && transitionActionStatus === ClaimTransitionAction.VERIFICATION ? (
            <VerificationEditLine
              claimCategory={claimCategory}
              transitionLineIndex={transitionLineIndex}
              transitionLines={transitionLines}
              changeLine={changeLine}
            />
          ) : null}
          {transitionLineIndex !== null && transitionActionStatus === ClaimTransitionAction.DECISION ? (
            <AwaitingDecisionEditLine
              claimCategory={claimCategory}
              transitionLineIndex={transitionLineIndex}
              transitionLines={transitionLines}
              changeLine={changeLine}
              formCategory={FormCategory.RECEIVED_FULL_VALUE}
              claimedQuantity={transitionLines[transitionLineIndex].receivedQuantityFullValue}
            />
          ) : null}
          {transitionLineIndex !== null && transitionActionStatus === ClaimTransitionAction.DECISION ? (
            <AwaitingDecisionEditLine
              claimCategory={claimCategory}
              transitionLineIndex={transitionLineIndex}
              transitionLines={transitionLines}
              changeLine={changeLine}
              formCategory={FormCategory.RECEIVED_DEFECTIVE}
              claimedQuantity={transitionLines[transitionLineIndex].receivedQuantityDefective}
            />
          ) : null}
        </Modal.Content>
        <Modal.Actions>
          {transitionActionStatus === 'VERIFICATION' && (
            <>
              <Button
                icon="check"
                disabled={!confirmOption}
                content="Zatwierdź wszystko jako:"
                labelPosition="right"
                primary
                onClick={() => acceptAllLines(confirmOption)}
              />
              <Dropdown
                placeholder="Typ masowej operacji"
                selection
                value={confirmOption}
                onChange={handleConfirmOption}
                options={optionsVerification.filter((option) => filterActions(option.key))}
                noResultsMessage={<FormattedMessage id="app.noResultsMessage" />}
              />
            </>
          )}
          <SubmitButton
            content="Zatwierdź"
            icon="add"
            labelPosition="right"
            primary
            loading={false}
            disabled={disableSubmit}
          />
          <Button content="Anuluj" onClick={handleCancel} />
        </Modal.Actions>
      </ModelFormWrapper>
    </Modal>
  );
};

const mapStateToProps = ({ claims }: ApplicationState) => {
  return {
    transitionChanged: claims.transitionChanged,
  };
};

const mapDispatchToProps: TReduxActions = { createClaimTransition, clearTransitionClaim };

export default connect(mapStateToProps, mapDispatchToProps)(ClaimTransition);
