import { connect } from "react-redux";
import {
  Button,
  Form,
  Header,
  Modal,
  Input,
  Grid,
  Divider,
} from "semantic-ui-react";
import { ApplicationState } from "~/store";
import { createInvoiceTransport, clearCreated } from "~/store/invoices/actions";
import { TDeliveryPoint } from "~/store/customer/types";
import { FormInputNumber } from "~/components/InputNumber/InputNumber";
import { TDeliveryType } from "~/store/orders/types";
import { toastInfo } from "~/utils/toast";
import { SmartLabel } from "~/components/SmartField/SmartLabel";

import React, { useState, SyntheticEvent, useEffect } from "react";
import UberSearch from "~/components/UberSearch/UberSearch";
import DetailCard from "~/components/DetailCard/DetailCard";
import {
  TAddInvoiceTransportProps,
  TReduxState,
  TReduxActions,
} from "~/pages/Accounting/lib/types/addInvoiceTables.types";
import {
  paymentsOptions,
  TRANSPORT_DEFAULT_DESCRIPTION,
  TRANSPORT_FIXED_RATE,
} from "~/pages/Accounting/lib/utils/addInvoiceTables.utils";

const AddInvoiceTransport: React.FC<TAddInvoiceTransportProps> = ({
  children,
  triggerOpen,
  created,
  creating,
  createInvoiceTransport,
  createdSuccess,
  clearCreated,
  setOpenAddTransportFVModal,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [product, setProduct] = useState<number>();
  const [deliveryType, setDeliveryType] = useState<TDeliveryType>();
  const [deliveryPoint, setDeliveryPoint] = useState<TDeliveryPoint>();
  const [paymentOption, setPaymentOption] = useState<number>(1);
  const [amount, setAmount] = useState<number>();
  const [lineDescription, setLineDescription] = useState<string>(
    TRANSPORT_DEFAULT_DESCRIPTION
  );

  useEffect(() => {
    setOpen(triggerOpen);
  }, [triggerOpen]);

  const selectDeliveryPoint = (selected: any): string => {
    if (selected && selected.point.urgent) {
      setDeliveryType("URGENT");
      setDeliveryPoint(selected && selected.point);
    } else if (selected && selected.point.turboPro) {
      setDeliveryType("TURBO_PRO");
      setDeliveryPoint(selected && selected.point);
    } else {
      setDeliveryType(undefined);
      setDeliveryPoint(undefined);
      toastInfo(
        "Błąd konfiguracyjny",
        "Wybrany punkt dostawy skonfigurowany jest nieprawidłowo."
      );
    }
    return "";
  };

  const changeLineDescription = (event: SyntheticEvent, data: any): void => {
    setLineDescription(data.value);
  };

  const changeProduct = (event: SyntheticEvent, data: any): void => {
    setProduct(parseFloat(data.value));
  };

  const handleConfirm = (): void => {
    if (!product && paymentOption === 1) {
      return;
    } else {
      let netPrice = 1;
      switch (paymentOption) {
        case 1:
          netPrice = Number(((product || 1) * TRANSPORT_FIXED_RATE).toFixed(2));
          break;
        case 2:
          netPrice = amount || 1;
          break;
        case 3:
          netPrice = 30;
          break;
        default:
          break;
      }
      createInvoiceTransport({
        deliveryCustomerId: getDeliveryTypeId() || "",
        netPrice,
        lineDescription: getLineDescription(),
      });
      toastInfo(
        "Dokument zostanie utworzony w najbliższym cyklu księgowym wynikającym z harmonogramu tworzenia dokumentów księgowych",
        ""
      );
    }
  };
  const getLineDescription = (): string => {
    if (lineDescription === undefined || lineDescription === "") {
      return TRANSPORT_DEFAULT_DESCRIPTION;
    } else {
      return lineDescription;
    }
  };

  const getDeliveryTypeId = (): string => {
    switch (deliveryType) {
      case "STOCK":
        // @ts-ignore: deliveryPoint undefined no chance
        return deliveryPoint.stock.rrdi;
      case "URGENT":
        // @ts-ignore: deliveryPoint undefined no chance
        return deliveryPoint.urgent.rrdi;
      case "TURBO_PRO":
        // @ts-ignore: deliveryPoint undefined no chance
        return deliveryPoint.turboPro.rrdi;
      default:
        return "";
    }
  };

  const clearForm = (): void => {
    setProduct(undefined);
    setLineDescription(TRANSPORT_DEFAULT_DESCRIPTION);
    setDeliveryPoint(undefined);
    setDeliveryType(undefined);
  };

  const handleCancel = (): void => {
    clearForm();
    if (!creating) {
      setOpen(false);
    }
    setDeliveryPoint(undefined);
    setDeliveryType(undefined);
  };

  const handleOpen = (): void => {
    setOpen(true);
  };

  if (created && open) {
    clearCreated();
    clearForm();
    setOpen(false);
    createdSuccess();
    setAmount(undefined);
    setPaymentOption(1);
  }

  const radioPaymentsOptions = (): JSX.Element => {
    return (
      <>
        {paymentsOptions.map((action, index) => {
          return (
            <Button
              key={index}
              fluid
              size="mini"
              basic={action.key !== paymentOption}
              onClick={() => setPaymentOption(action.key)}
              style={{
                marginBottom: 10,
                textAlign: "left",
                display: "inline-block",
                width: "30%",
              }}
            >
              <Form.Radio
                label={action.text}
                checked={action.key === paymentOption}
                value={action.key}
              />
            </Button>
          );
        })}
      </>
    );
  };

  const inputPayments = (): JSX.Element => {
    switch (paymentOption) {
      case 1:
        return (
          <Form.Group widths="equal">
            <Form.Field>
              <label>Koszt produktów (netto PLN)*</label>
              <FormInputNumber
                placeholder="Wypełnij koszt"
                min={0}
                precision={2}
                value={product}
                onChange={changeProduct}
              />
            </Form.Field>
            <Form.Field>
              <label>Koszt transportu (netto PLN)*</label>
              <Input
                placeholder="Wypełnij koszt"
                value={getTransport(product ? product : 0)}
                readOnly
              />
            </Form.Field>
          </Form.Group>
        );
      case 2:
        return (
          <Form.Field>
            <label>Dowolna kwota:</label>
            <FormInputNumber
              placeholder="Wypełnij koszt"
              min={0}
              precision={2}
              onChange={(e, d) => setAmount(Number(d.value))}
              required
            />
          </Form.Field>
        );
      case 3:
        return <strong>Stała kwota - 30zł</strong>;
      default:
        return <></>;
    }
  };

  const getTransport = (product: number): string => {
    return validNumber(product)
      ? (product * TRANSPORT_FIXED_RATE).toFixed(2)
      : "";
  };

  const validNumber = (value: any): boolean => {
    if (isNaN(value)) {
      return false;
    }
    return typeof value === "number" ? value > 0 : false;
  };

  const isValidForm = (): boolean => {
    switch (paymentOption) {
      case 1:
        return !!deliveryType && validNumber(product);
      case 2:
        return !!(amount && deliveryType != null);
      case 3:
        return !!deliveryType;
      default:
        return false;
    }
  };

  const mapDelivery = (deliveryPoints: TDeliveryPoint[]): any => {
    return deliveryPoints.map((point, index) => {
      const key = `${point.displayName}-${index}`;
      return {
        key,
        title: point.displayName,
        description: point.displayAddress,
        point,
      };
    });
  };

  return (
    <Modal
      size="small"
      trigger={children}
      open={open}
      onOpen={handleOpen}
      onClose={handleCancel}
      closeOnDimmerClick={false}
      onUnmount={() => setOpenAddTransportFVModal(false)}
    >
      <Header icon="briefcase" content="Dodaj koszty transportu" />
      <Modal.Content>
        <Grid>
          <label style={{ fontWeight: "bold" }}>Wybór płatności:</label>
          <Grid.Row>{radioPaymentsOptions()}</Grid.Row>
        </Grid>
        <Form>
          <Grid style={{ marginBottom: "unset" }} stretched>
            <DetailCard
              title="app.customer.delivery"
              id="customer-delivery"
              key="delivery"
              width={8}
            >
              <UberSearch
                placeholder="Wyszukaj..."
                fluid
                endpoint="/customers/delivery-points"
                mapper={(response) => mapDelivery(response)}
                onResultSelect={(selected: any) =>
                  selectDeliveryPoint(selected)
                }
              />
              <Divider />
              {!!deliveryPoint && (
                <>
                  <SmartLabel label="Nazwa" value={deliveryPoint.displayName} />
                  <SmartLabel
                    label="Adres"
                    value={deliveryPoint.displayAddress}
                  />
                </>
              )}
            </DetailCard>
          </Grid>
          <Form.Field>
            <label>Opis linii faktury:</label>
            <Input
              maxLength="180"
              placeholder="Wypełnij opis"
              value={lineDescription}
              onChange={changeLineDescription}
            />
          </Form.Field>
          {inputPayments()}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          content="Dodaj"
          icon="add"
          labelPosition="left"
          primary
          loading={creating}
          onClick={handleConfirm}
          disabled={!isValidForm() || !!creating}
        />
        <Button content="Anuluj" onClick={handleCancel} />
      </Modal.Actions>
    </Modal>
  );
};

const mapStateToProps: (state: ApplicationState) => TReduxState = ({
  customers,
  invoices,
  dictionaries,
}: ApplicationState) => ({
  customers: customers.list,
  loadingCustomers: customers.loading,
  creating: invoices.creating,
  created: invoices.created,
  dictionaries,
});

const mapDispatchToProps: TReduxActions = {
  createInvoiceTransport,
  clearCreated,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddInvoiceTransport);
