import { Checkbox, List, ListItem } from "semantic-ui-react";

import React, { useCallback } from "react";
import { RoleListProps } from "~/pages/Administration/lib/MenageUserRole/RoleList/constants";
import { VIEW } from "~/pages/Administration/lib/MenageUserRole/constants";
import { AVAILABLE_ROLE_ENUM, AvailableRole } from "~/store/users/types";

const RoleList: React.FC<RoleListProps> = ({
  change,
  roles,
  groupName,
  mainRoleIsChecked,
  extendedRoleGroups,
}) => {
  const checkIsAvailableRole = useCallback(
    (role) => {
      if (role.name === AVAILABLE_ROLE_ENUM.LDC_CUSTOMER_MANAGEMENT) {
        const foundRole = extendedRoleGroups[
          AVAILABLE_ROLE_ENUM.LDC_CUSTOMER
        ]?.find(
          (role: AvailableRole) =>
            AVAILABLE_ROLE_ENUM.LDC_CUSTOMER_EDIT === role.name
        );

        return !foundRole?.isSelected;
      }

      return false;
    },
    [extendedRoleGroups]
  );

  return (
    <List relaxed>
      {roles
        .filter((item) => !item.name.includes(VIEW))
        .map((role) => (
          <ListItem key={`${groupName}-${role.id}`}>
            <Checkbox
              disabled={checkIsAvailableRole(role) || !mainRoleIsChecked}
              size="small"
              label={{ children: role.description }}
              checked={role.isSelected}
              onChange={(e, d) => change(e, d, [role])}
            />
          </ListItem>
        ))}
    </List>
  );
};

export default RoleList;
