import { action } from "typesafe-actions";
import {
  AvailableRole,
  AvailableRolesInGroups,
  PasswordPolicy,
  User,
  UserBase,
  UsersActionTypes,
} from "./types";

export const fetchUsers = () => action(UsersActionTypes.FETCH_USERS_REQUEST);

export const fetchUsersSuccess = (users: User[]) =>
  action(UsersActionTypes.FETCH_USERS_SUCCESS, { users });

export const addUser = (user: UserBase) =>
  action(UsersActionTypes.ADD_USER, user);

export const addSuccess = () => action(UsersActionTypes.ADD_SUCCESS);

export const clearCreated = () => action(UsersActionTypes.CLEAR_CREATED);

export const enableUser = (userId: string, enable: boolean) =>
  action(UsersActionTypes.ENABLE_USER, { userId, enable });

export const removeUser = (userId: string) =>
  action(UsersActionTypes.REMOVE_USER, userId);

export const fetchAvailableRoles = (userId: string) =>
  action(UsersActionTypes.FETCH_AVAILABLE_ROLES, userId);

export const fetchAvailableRolesSuccess = (
  availableRoles: AvailableRolesInGroups
) => action(UsersActionTypes.FETCH_AVAILABLE_ROLES_SUCCESS, { availableRoles });

export const fetchAvailableRolesFailed = () =>
  action(UsersActionTypes.FETCH_AVAILABLE_ROLES_FAILED);

export const fetchUserRoles = (userId: string) =>
  action(UsersActionTypes.FETCH_USER_ROLES, userId);

export const fetchUserRolesSuccess = (userRoles: AvailableRolesInGroups) =>
  action(UsersActionTypes.FETCH_USER_ROLES_SUCCESS, { userRoles });

export const fetchUserRolesFailed = () =>
  action(UsersActionTypes.FETCH_USER_ROLES_FAILED);

export const changeUserRolesAndLimit = (
  userId: string,
  dataToSend: {
    usersDiscountLimit: number;
    rolesToAdd: AvailableRole[];
    rolesToRemove: AvailableRole[];
  },
  cb: () => void
) =>
  action(UsersActionTypes.CHANGE_USER_ROLES_AND_LIMIT, {
    userId,
    dataToSend,
    cb,
  });

export const changeUserRolesAndLimitSuccess = () =>
  action(UsersActionTypes.CHANGE_USER_ROLES_AND_LIMIT_SUCCESS);

export const actionSuccess = () => action(UsersActionTypes.ACTION_SUCCESS);

export const fetchPasswordPolicies = () =>
  action(UsersActionTypes.FETCH_PASSWORD_POLICIES);

export const fetchPasswordPoliciesSuccess = (
  passwordPolicies: PasswordPolicy[]
) =>
  action(UsersActionTypes.FETCH_PASSWORD_POLICIES_SUCCESS, {
    passwordPolicies,
  });

export const exportUserListToXls = (fileName: string) =>
  action(UsersActionTypes.EXPORT_USER_LIST_TO_XLS, fileName);

  export const exportFP0536ToCsv = (fileName: string) =>
  action(UsersActionTypes.DOWNLOAD_FP0536_CSV, fileName);

export const exportFP0536ToCsvSuccesss = () =>
  action(UsersActionTypes.DOWNLOAD_FP0536_CSV_SUCCESS);

export const exportFP0536ToCsvFailed = () =>
  action(UsersActionTypes.DOWNLOAD_FP0536_CSV_FAILED);


export const fetchUserById = (id: string) =>
  action(UsersActionTypes.FETCH_USER_BY_ID, { id });

export const fetchUserByIdSuccess = (user: User) =>
  action(UsersActionTypes.FETCH_USER_BY_ID_SUCCESS, {
    user,
  });

export const fetchUserByIdFailed = () =>
  action(UsersActionTypes.FETCH_USER_BY_ID_FAILED);

export const resetUser = () => action(UsersActionTypes.RESET_USER);
