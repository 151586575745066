import { Reducer } from 'redux'
import { ErrorActionTypes } from '~/store/error/types'
import { DictionariesActionTypes, DictionariesState, DictionaryName } from './types'

export const initialDictionariesState: DictionariesState = {
    "customer-category-zone": [],
    "payment-method": [],
    "payment-due": [],
    "payment-factor": [],
    // "customer-delivery-warehouse": [],
    "customer-category-organization": [],
    "customer-information-address-region": [],
    "customer-payment-currency": [],
    "spare-part-category-adv": [],
    "spare-part-category-origin": [],
    "spare-part-tax-category": [],
    "spare-part-discount-pcd": [],
    "spare-part-discount-iam": [],
    "spare-part-state": [],
    "spare-part-replacement-category": [],
    "spare-part-brand": [],
    "spare-part-hierarchy-family": [],
    "spare-part-hierarchy-slot": [],
    "spare-part-hierarchy-segment": [],
    "spare-part-hierarchy-index": [],
    "spare-part-provision-category": [],
    "spare-part-provision-force-category": [],
    "gtu-code-formula": [],
    "spare-part-gtu-code": [],
    "backorder-state": [],
    "order-delivery-type": [],
    "order-type": [],
    "order-status": [],
    "anomaly-type": [],
    "anomaly-status": [],
    "grn-type": [],
    "gdn-type": [],
    "claim-type": [],
    "claim-category": [],
    "claim-group": [],
    "claim-status": [],
    "claim-line-status": [],
    "item-to-invoice-status": [],
    "item-to-invoice-category": [],
    "spare-part-replenishment-mode": [],
    "customer-sap-class": [],
    "warehouse-movement-status": [],
    "financing-reason": [],
    "gdn-postponed-part-status": [],
    "promo-type": [],
    "transport-type": [],
    "sincoms": [],
    loadingNames: [],
    loading: false,
    names: [],
    details: {
        name: "",
        fields: [],
        data: []
    },
    created: false,
    creating: false
}

const reducer: Reducer<DictionariesState> = (state = initialDictionariesState, action) => {
    switch (action.type) {
        case DictionariesActionTypes.FETCH_REQUEST: {
            const updatedState = {
                ...state,
                loadingNames: [...state.loadingNames, ...action.payload]
            }
            updatedState.loading = updatedState.loadingNames.length > 0
            return updatedState
        }
        case DictionariesActionTypes.FETCH_SUCCESS: {
            const name: DictionaryName = action.payload.name
            const updatedState = { ...state }
            if (action.payload.data) {
                updatedState[name] = action.payload.data
            }
            updatedState.loadingNames = state.loadingNames.filter((loadingName) => loadingName !== name)
            updatedState.loading = updatedState.loadingNames.length > 0
            return updatedState
        }
        case ErrorActionTypes.HANDLE_ERROR: {
            return { ...state, loading: false, creating: false }
        }
        case DictionariesActionTypes.FETCH_DICTIONARIES_NAMES: {
            return { ...state, loading: true, names: [] }
        }
        case DictionariesActionTypes.FETCH_DICTIONARIES_NAMES_SUCCESS: {
            return { ...state, loading: false, names: action.payload.names }
        }
        case DictionariesActionTypes.FETCH_DICTIONARIES_NAMES_DETAILS: {
            return { ...state, loading: true, details: { name: "", fields: [], data: [] } }
        }
        case DictionariesActionTypes.FETCH_DICTIONARIES_NAMES_DETAILS_SUCCESS: {

            const details = { ...action.payload.details }
            if (action.payload.details.name === 'gtu-code-formula') {
                details.fields.push({ name: 'start', type: 'DATE' })
                details.fields.push({ name: 'end', type: 'DATE' })
            }

            return { ...state, loading: false, details: { ...details, data: action.payload.data[action.payload.details.name] } }
        }
        case DictionariesActionTypes.ADD_DICTIONARY_ITEM:
        case DictionariesActionTypes.UPDATE_DICTIONARY_ITEM:
        case DictionariesActionTypes.REMOVE_DICTIONARY_ITEM: {
            return { ...state, created: false, creating: true }
        }
        case DictionariesActionTypes.ACTION_DICTIONARY_ITEM_SUCCESS: {
            const newState = { ...state, created: true, creating: false }
            if (action.payload) {
                delete newState[action.payload as DictionaryName]
            }
            return newState
        }
        case DictionariesActionTypes.CLEAR_CREATED: {
            return { ...state, created: false, creating: false }
        }
        default: {
            return state
        }
    }
}

export { reducer as dictionariesReducer }
