import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Dimmer, Divider, Grid } from "semantic-ui-react";
import { fetchDictionary } from "~/store/dictionaries/actions";
import { DictionariesState, DictionaryName } from "~/store/dictionaries/types";
import { parsePrice } from "~/utils/parsePrice";
import { acceptedValue, claimedValue } from "./lib/AcceptValues";
import { useRenderingFunctions } from "~/components/SmartField/hooks/useRenderingFunctions";
import { ApplicationState } from "~/store";
import {
  getAttachment,
  selectClaim,
  getClaimLabel,
} from "~/store/claims/actions";
import { getInvoicePdf } from "~/store/invoices/actions";
import { CLAIM_STATUS_ENUM, IClaim } from "~/store/claims/types";
import { DictionaryLabel } from "~/components/MapDictionary/DictionaryLabel";
import { parseDate } from "~/utils/dateUtils";

import React, { useContext, useEffect, useMemo, useState } from "react";
import DetailCard from "~/components/DetailCard/DetailCard";
import CommonLoader from "~/components/Loaders/CommonLoader";
import ClaimLines from "./lib/Lines";
import AddAttachment from "./lib/Attachments/Add";
import ClaimAttachment from "./lib/Attachments/Single";
import ClaimTransition from "./lib/Transition";
import PageHeader from "~/components/PageHeader/PageHeader";
import translations from "~/utils/translations";
import ClaimHistory from "./lib/History";
import "./claim-details.scss";
import { decodeUrlClaimNumber } from "~/utils/claimNumberUrlFormatter";
import { AVAILABLE_ROLE_ENUM } from "~/store/users/types";
import { AppContext } from "~/context/AuthContext";
import { DocumentCardContent } from "./lib/Cards/DocumentsCardContent";
import mapDictionary from "~/components/MapDictionary/mapDictionary";

type TReduxState = {
  claimIn?: IClaim;
  loading: boolean;
  dictionaries: DictionariesState;
};

type TReduxActions = {
  selectClaim: typeof selectClaim;
  getAttachment: typeof getAttachment;
  fetchDictionary: typeof fetchDictionary;
  getInvoicePdf: typeof getInvoicePdf;
  getClaimLabel: typeof getClaimLabel;
};

type TRouteParams = RouteComponentProps<{
  claimNumber: string;
}>;

type TClaimDetailsProps = TReduxState & TReduxActions & TRouteParams;

const ClaimDetails: React.FC<TClaimDetailsProps> = ({
  match,
  claimIn,
  loading,
  selectClaim,
  dictionaries,
  fetchDictionary,
  getInvoicePdf,
  getClaimLabel,
}) => {
  const [claim, setClaim] = useState<IClaim>();
  const [openAddAttachment, setOpenAddAttachment] = useState<boolean>(false);
  const [openHistory, setOpenHistory] = useState<boolean>(false);
  const [openTransition, setOpenTransition] = useState<boolean>(false);

  const decodedUrlClaimNumber = decodeUrlClaimNumber(match.params.claimNumber);

  const appContext = useContext(AppContext);

  const userHaveLdcUiClaimsProcessRole = useMemo(
    () =>
      appContext?.keycloak.hasResourceRole(
        AVAILABLE_ROLE_ENUM.LDC_CLAIMS_PROCESS
      ),
    [appContext]
  );

  useEffect(() => {
    if (!claim || claim.claimNumber !== match.params.claimNumber) {
      fetchDictionary(
        DictionaryName.claimType,
        DictionaryName.claimCategory,
        DictionaryName.claimGroup,
        DictionaryName.claimStatus,
        DictionaryName.claimLineStatus
      );
      selectClaim(decodeUrlClaimNumber(match.params.claimNumber));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    decodedUrlClaimNumber,
    fetchDictionary,
    match.params.claimNumber,
    selectClaim,
  ]);

  useEffect(() => {
    document.title = translations.format("LDC_CLAIMS");
    setClaim(claimIn);
  }, [claimIn]);

  useEffect(() => {
    if (openAddAttachment) {
      setOpenAddAttachment(false);
    }
  }, [openAddAttachment]);

  useEffect(() => {
    if (openHistory) {
      setOpenHistory(false);
    }
  }, [openHistory]);

  useEffect(() => {
    if (openTransition) {
      setOpenTransition(false);
    }
  }, [openTransition]);

  const { renderLabel } = useRenderingFunctions(claim, false);

  const cards: JSX.Element[] = useMemo(() => {
    return !claim
      ? []
      : [
          <DetailCard
            title="app.informations"
            id="info"
            key="info"
            minHeight={100}
            width={6}
          >
            {renderLabel("Numer reklamacji", claim.claimNumber)}
            {renderLabel("Data utworzenia", parseDate(claim.createDate))}
            {renderLabel("ID klienta", claim.orderCustomerID)}
            {renderLabel("Kod płatnika", claim.accountingCustomerID)}
            {claim.supplierSystem &&
            claim.supplierSystem.findIndex(
              (sys) => sys.businessSector === "FLHAP"
            ) >= 0 ? (
              <Divider />
            ) : (
              ""
            )}
            {claim.supplierSystem &&
              renderLabel(
                translations.format("app.customer.FLHAP-Payer"),
                claim.supplierSystem[
                  claim.supplierSystem?.findIndex(
                    (sys) => sys.businessSector === "FLHAP"
                  )
                ].clientId
              )}
            {claim.supplierSystem &&
              renderLabel(
                translations.format("app.customer.FLHAP-Recipient"),
                claim.supplierSystem[
                  claim.supplierSystem?.findIndex(
                    (sys) => sys.businessSector === "FLHAP"
                  )
                ].deliveryId
              )}
            {claim.supplierSystem &&
            claim.supplierSystem.findIndex(
              (sys) => sys.businessSector === "FLHAP"
            ) >= 0 ? (
              <Divider />
            ) : (
              ""
            )}
            {claim.supplierSystem &&
              renderLabel(
                translations.format("app.customer.CJD-Payer"),
                claim.supplierSystem[
                  claim.supplierSystem?.findIndex(
                    (sys) => sys.businessSector === "CJD"
                  )
                ].clientId
              )}
            {claim.supplierSystem &&
              renderLabel(
                translations.format("app.customer.CJD-Recipient"),
                claim.supplierSystem[
                  claim.supplierSystem?.findIndex(
                    (sys) => sys.businessSector === "CJD"
                  )
                ].deliveryId
              )}
            {claim.supplierSystem &&
            claim.supplierSystem.findIndex(
              (sys) => sys.businessSector === "CJD"
            ) >= 0 ? (
              <Divider />
            ) : (
              ""
            )}
            {renderLabel(
              "Grupa",
              <DictionaryLabel
                flexDisplay
                value={claim.claimGroup}
                dictionary={dictionaries[DictionaryName.claimGroup]}
              />
            )}
            {renderLabel(
              "Kategoria",
              <DictionaryLabel
                flexDisplay
                value={claim.claimCategory}
                dictionary={dictionaries[DictionaryName.claimCategory]}
              />
            )}
            {renderLabel(
              "Typ",
              <DictionaryLabel
                flexDisplay
                value={claim.claimType}
                dictionary={dictionaries[DictionaryName.claimType]}
              />
            )}
            {renderLabel("Opis", claim.description)}
          </DetailCard>,
          <DetailCard
            title="app.documents"
            id="order"
            key="order"
            minHeight={100}
            width={6}
          >
            <DocumentCardContent
              claim={claim}
              getClaimLabel={getClaimLabel}
              getInvoicePdf={getInvoicePdf}
              renderLabel={renderLabel}
            />
          </DetailCard>,
          <DetailCard
            title={translations.format(`app.attachments`, {
              "0": claim.attachments.length,
            })}
            titleFormat={true}
            id="attachments"
            key="attachments"
            minHeight={100}
            width={4}
          >
            <div className="claim-attachment">
              {claim.attachments.map((attachment, index) => {
                return (
                  <ClaimAttachment
                    key={index}
                    claimNumber={claim.claimNumber}
                    attachment={attachment}
                  />
                );
              })}
            </div>
          </DetailCard>,
          <DetailCard
            title="app.status"
            id="status"
            key="status"
            minHeight={100}
            width={6}
          >
            {renderLabel(
              "Status",
              mapDictionary(
                claim.status,
                dictionaries[DictionaryName.claimStatus] || [],
                true,
                true,
                true
              )
            )}
            {renderLabel(
              "Data aktualizacji statusu",
              parseDate(claim.statusDate)
            )}
            {renderLabel(
              "Procesowano automatycznie",
              <div>{claim.processAutomatically ? "Tak" : "Nie"}</div>
            )}
            {renderLabel("Komentarz", claim.statusNotes)}
          </DetailCard>,
          <DetailCard
            title="app.carInfo"
            id="car"
            key="cart"
            minHeight={100}
            width={6}
          >
            {renderLabel("VIN", claim.vin)}
            {renderLabel("Nazwa", claim.vehicleModel)}
            {renderLabel("Zastępczy samochód", claim.rentalCar)}
          </DetailCard>,
          <DetailCard
            title="app.summary"
            id="summary"
            key="summary"
            minHeight={100}
            width={4}
          >
            {renderLabel(
              "Wartość reklamacji",
              <b>{parsePrice(claimedValue(claim))}</b>
            )}
            {renderLabel(
              "Wartość zaakceptowana",
              <b>{parsePrice(acceptedValue(claim))}</b>
            )}
            {claim.claimCategory !== "RK4-P2" && (
              <>
                <Divider></Divider>
                {renderLabel("Numer VIN", <b>{claim.vin}</b>)}
                {renderLabel("Numer BIN", <b>{claim.bin}</b>)}
                {renderLabel("Numer PAMIR", <b>{claim.pamir}</b>)}
              </>
            )}
          </DetailCard>,
          <DetailCard
            title="app.claimLines"
            id="claimLines"
            key="claimLines"
            width={16}
            minHeight={100}
          >
            <div style={{ overflowX: "scroll" }}>
              <ClaimLines
                lines={claim.claimLines}
                statusDictionary={dictionaries[DictionaryName.claimLineStatus]}
              />
            </div>
          </DetailCard>,
        ];
  }, [claim, dictionaries, getInvoicePdf, renderLabel, getClaimLabel]);

  const handleCreateTransition = (): any =>
    selectClaim(decodeUrlClaimNumber(match.params.claimNumber));

  const cardsRendered: JSX.Element = useMemo(() => {
    return (
      <div className="uber-content">
        <Grid stretched className="claim-details">
          {cards}
        </Grid>
      </div>
    );
  }, [cards]);

  const renderedClaimTitle: string = useMemo(() => {
    if (!claim) {
      return "Wczytywanie...";
    }
    return claim.claimNumber;
  }, [claim]);

  const handleAddAttachment = (): any =>
    selectClaim(decodeUrlClaimNumber(match.params.claimNumber));

  return (
    <Dimmer.Dimmable style={{ minHeight: "80%" }}>
      <PageHeader
        icon="file text"
        title={<>Reklamacja {renderedClaimTitle}</>}
        breadcrumb={[
          { text: <FormattedMessage id="app.list" />, link: "/claims" },
          { text: <FormattedMessage id="app.details" /> },
        ]}
        buttons={[
          {
            icon: "file archive outline",
            content: "Dodaj załącznik",
            onClick: () => {
              setOpenAddAttachment(true);
            },
            primary: true,
          },
          {
            icon: "history",
            content: "Historia",
            onClick: () => {
              setOpenHistory(true);
            },
            primary: true,
            visible: claim && claim.statuses.length > 0,
          },
          {
            icon: "edit",
            content: "Procesuj",
            onClick: () => {
              setOpenTransition(true);
            },
            primary: true,
            visible:
              userHaveLdcUiClaimsProcessRole &&
              claim &&
              !(
                claim.status === CLAIM_STATUS_ENUM.CLOSED ||
                claim.status === CLAIM_STATUS_ENUM.AWAITING_PARTIAL_REFUND ||
                claim.status === CLAIM_STATUS_ENUM.AWAITING_REFUND ||
                claim.status === CLAIM_STATUS_ENUM.AWAITING_RECEPTION
              ),
          },
        ]}
        refreshAction={() =>
          selectClaim(decodeUrlClaimNumber(match.params.claimNumber))
        }
        loading={loading}
      />
      <AddAttachment
        triggerOpen={openAddAttachment}
        claimNumber={decodeUrlClaimNumber(match.params.claimNumber)}
        createdSuccess={() => handleAddAttachment()}
      />
      {claim && (
        <ClaimTransition
          triggerOpen={openTransition}
          claimCategory={claim.claimCategory}
          claimGroup={claim.claimGroup}
          claimStatus={claim.status}
          grn={claim.grnNumber}
          createdSuccess={() => handleCreateTransition()}
          statusNotes={claim.statusNotes}
          claimNumber={decodeUrlClaimNumber(match.params.claimNumber)}
          lines={claim.claimLines}
        />
      )}
      {cardsRendered}
      <ClaimHistory
        triggerOpen={openHistory}
        history={(claim && claim.statuses) || []}
        dictionary={dictionaries[DictionaryName.claimStatus]}
      />
      <CommonLoader loading={loading || !claim} />
    </Dimmer.Dimmable>
  );
};

const mapStateToProps: (state: ApplicationState) => TReduxState = ({
  claims,
  dictionaries,
}: ApplicationState) => {
  return {
    claimIn: claims.selected,
    loading: claims.loadingClaim || dictionaries.loading,
    dictionaries,
  };
};

const mapDispatchToProps: TReduxActions = {
  selectClaim,
  getAttachment,
  fetchDictionary,
  getInvoicePdf,
  getClaimLabel,
};

export default connect(mapStateToProps, mapDispatchToProps)(ClaimDetails);
