import { Icon, Popup, Table } from 'semantic-ui-react';
import React from 'react';
import { ReferenceIDTableCellProps } from '~/pages/Orders/Details/lib/Lines/Item/TableCell/ReferenceIsTableCell/constants';

export const ReferenceIDTableCell = ({ column, line, lines }: ReferenceIDTableCellProps) => {
  const getLineReferenceId = (lineNo: number): string | undefined => {
    const line = lines.find((line) => line.lineNo === lineNo);

    return line && line.referenceID;
  };

  return (
    <Table.Cell key={column.name} style={{ maxWidth: 'none' }}>
      <div style={{ width: 200, display: 'inline-block' }}>
        {line.type === 'REPLACEMENT' && <Icon name="recycle" color="grey" style={{ marginRight: 5 }} />}
        {line.type === 'REPLACEMENT_ORIGINAL' && <Icon name="undo" color="grey" style={{ marginRight: 5 }} />}
        {line.type === 'PROVISION' && (
          <Icon name="money bill alternate outline" color="grey" style={{ marginRight: 10 }} />
        )}
        {line.referenceID}
        {line.type === 'REPLACEMENT' && (
          <Popup
            content={`Zamiennik dla ${getLineReferenceId(line.replacedLineNo)}`}
            trigger={<Icon name="info circle" color="grey" size="large" fitted style={{ float: 'right' }} />}
          />
        )}
        {line.type === 'REPLACEMENT_ORIGINAL' && (
          <Popup
            content={`Poprzednik dla ${getLineReferenceId(line.replacedLineNo)}`}
            trigger={<Icon name="info circle" color="grey" size="large" fitted style={{ float: 'right' }} />}
          />
        )}
        {!!line.provisioningLineNo && (
          <Popup
            content={`Dodana kaucja dla ${getLineReferenceId(line.provisioningLineNo)}`}
            trigger={<Icon name="info circle" color="grey" size="large" fitted style={{ float: 'right' }} />}
          />
        )}
      </div>
    </Table.Cell>
  );
};
