import { DictionaryItem } from "./types";

const dictionariesStatic = {

  CUSTOMER_TYPE: [
    { key: "PAYER", text: "Płatnik", value: "PAYER" },
    { key: "DELIVERY", text: "Punkt odbioru", value: "DELIVERY" },
  ] as DictionaryItem[],

  PROMO_STATUS: [
    { key: "DRAFT", text: "Szablon", value: "DRAFT", icon: "edit" },
    {
      key: "ACTIVE",
      text: "Aktywna",
      value: "ACTIVE",
      icon: "clock",
      color: "orange",
    },
    { key: "CLOSED", text: "Zakończona", value: "CLOSED", icon: "check" },
    {
      key: "AWAITING_ACTIVATION",
      text: "Oczekująca na aktywację",
      value: "AWAITING_ACTIVATION",
      icon: "pen square",
    },
  ] as DictionaryItem[],

  PROMO_TYPE: [
    { key: "CASCADE", text: "Kaskadowa ", value: "CASCADE", icon: "clone" },
    { key: "ACTUAL", text: "Upustowa ", value: "ACTUAL", icon: "arrow down" },
    {
      key: "CURRENCY",
      text: "Cenowa ",
      value: "CURRENCY",
      icon: "money bill alternate outline",
    },
    {
      key: "ASSOCIATIVE",
      text: "Łączona ",
      value: "ASSOCIATIVE",
      icon: "plus square",
    },
    { key: "GROUP", text: "Grupowa ", value: "GROUP", icon: "group" },
  ] as DictionaryItem[],

  PROMO_DISCOUNT_TYPE: [
    { key: "PERCENTAGE", text: "Procentowy", value: "PERCENTAGE" },
    { key: "PRICING", text: "Kwotowy", value: "PRICING" },
    {
      key: "PERCENTAGE_AND_PRICING",
      text: "Procentowy i kwotowy",
      value: "PERCENTAGE_AND_PRICING",
    },
  ] as DictionaryItem[],

  PROMO_ASSOCIATIVE_DISCOUNT_GROUP: [
    { key: "ALL", value: "ALL", text: "Obydwie grupy" },
    { key: "GROUP_1", value: "GROUP_1", text: "Grupa pierwsza" },
    { key: "GROUP_2", value: "GROUP_2", text: "Grupa druga" },
  ] as DictionaryItem[],

  PROMO_GROUP_DISCOUNT_TYPE: [
    { key: "PRICING", value: "PRICING", text: "Cenowy" },
    { key: "PERCENTAGE", value: "PERCENTAGE", text: "Procentowy" },
  ] as DictionaryItem[],

  PROMO_ASSOCIATIVE_DISCOUNT_MODE: [
    { key: "CASCADE", value: "CASCADE", text: "Kaskadowy" },
    { key: "ACTUAL", value: "ACTUAL", text: "Upustowy" },
  ] as DictionaryItem[],

  SPARE_PART_SUPPLIER: [
    { key: "PLPR", text: "PLPR", value: "PLPR" },
    { key: "CSPS", text: "CSPS", value: "CSPS" },
  ] as DictionaryItem[],

  SUPPLIER_SYSTEMS: [
    { key: "---", text: "---", value: "" },
    { key: "FLHAP", text: "FLHAP", value: "FLHAP" },
    { key: "CJD", text: "CJD", value: "CJD" },
  ] as DictionaryItem[],

  NOTIFICATION_NAME: [
    { key: "NEW_ANOMALY", value: "NEW_ANOMALY", text: "Nowa anomalia" },
    { key: "NEW_ORDER_SB", value: "NEW_ORDER_SB", text: "Nowe zamówienie SB" },
    {
      key: "NEW_ORDER_LDC",
      value: "NEW_ORDER_LDC",
      text: "Nowe zamówienie LDC",
    },
    {
      key: "ORDER_STATUS_CHANGED",
      value: "ORDER_STATUS_CHANGED",
      text: "Zmiana statusu zamówienia",
    },
    {
      key: "NEW_REPLENISHMENT",
      value: "NEW_REPLENISHMENT",
      text: "Nowe uzupełnienie",
    },
    { key: "NEW_CLAIM", value: "NEW_CLAIM", text: "Nowa reklamacja" },
    {
      key: "CLAIM_RESERVATION_ERROR",
      value: "CLAIM_RESERVATION_ERROR",
      text: "Błąd rezerwacji części dla reklamacji",
    },
    {
      key: "CLAIM_STATUS_CHANGED",
      value: "CLAIM_STATUS_CHANGED",
      text: "Zmiana statusu reklamacji",
    },
    {
      key: "NEW_PROVISION_RETURN",
      value: "NEW_PROVISION_RETURN",
      text: "Nowy zwrot prowizji",
    },
    {
      key: "NEW_BACKORDER",
      value: "NEW_BACKORDER",
      text: "Nowe zamówienie hurtowe",
    },
    {
      key: "ORDER_TO_ACCEPT",
      value: "ORDER_TO_ACCEPT",
      text: "Zamówienie do zaakceptowania",
    },
    {
      key: "ORDER_CREDIT_LIMIT",
      value: "ORDER_CREDIT_LIMIT",
      text: "Limit kredytu zamówienia",
    },
    { key: "STOCK_UPDATE", value: "STOCK_UPDATE", text: "Aktualizacja stocku" },
    { key: "NEW_ERP_DOC", value: "NEW_ERP_DOC", text: "Nowy ERP dokument" },
    {
      key: "NEW_CLIENT_INVOICE",
      value: "NEW_CLIENT_INVOICE",
      text: "Nowa faktura klienta",
    },
    {
      key: "NEW_TRANSPORT_INVOICE",
      value: "NEW_TRANSPORT_INVOICE",
      text: "Nowa faktura transportu",
    },
    { key: "NEW_PAYMENT_LIST", value: "NEW_PAYMENT_LIST", text: "Nowy cennik" },
    {
      key: "PRICE_LIST_NO_GTU",
      value: "PRICE_LIST_NO_GTU",
      text: "Informacja o braku kodu GTU aktualizowanych części",
    },
    {
      key: "WMS_ADAPTER_ERROR_AGRN",
      value: "WMS_ADAPTER_ERROR_AGRN",
      text: "WMS adapter błąd AGRN",
    },
    {
      key: "WMS_ADAPTER_ERROR_GRN",
      value: "WMS_ADAPTER_ERROR_GRN",
      text: "WMS adapter błąd GRN",
    },
    {
      key: "WMS_ADAPTER_ERROR_AGDN",
      value: "WMS_ADAPTER_ERROR_AGDN",
      text: "WMS adapter błąd AGDN",
    },
    {
      key: "WMS_ADAPTER_ERROR_GDN",
      value: "WMS_ADAPTER_ERROR_GDN",
      text: "WMS adapter błąd GDN",
    },
    {
      key: "WMS_ADAPTER_ERROR_STOCK",
      value: "WMS_ADAPTER_ERROR_STOCK",
      text: "WMS adapter błąd stocku",
    },
    {
      key: "WMS_ADAPTER_ERROR_CONTRACTOR",
      value: "WMS_ADAPTER_ERROR_CONTRACTOR",
      text: "WMS adapter błąd kontraktora",
    },
    {
      key: "WMS_ADAPTER_ERROR_INDEX",
      value: "WMS_ADAPTER_ERROR_INDEX",
      text: "WMS adapter błąd indeksu",
    },
    {
      key: "WMS_ADAPTER_ERROR",
      value: "WMS_ADAPTER_ERROR",
      text: "WMS adapter błąd",
    },
    {
      key: "SAP_ADAPTER_ERROR_PRODUCT_CATALOG",
      value: "SAP_ADAPTER_ERROR_PRODUCT_CATALOG",
      text: "SAP adapter błąd importu cennika",
    },
    {
      key: "SAP_ADAPTER_ERROR_INVOICE",
      value: "SAP_ADAPTER_ERROR_INVOICE",
      text: "SAP adapter błąd importu faktury",
    },
    {
      key: "SAP_ADAPTER_ERROR_BACKORDER",
      value: "SAP_ADAPTER_ERROR_BACKORDER",
      text: "SAP adapter błąd wysyłki zamówienia",
    },
    {
      key: "SAP_ADAPTER_ERROR_REINVOICE",
      value: "SAP_ADAPTER_ERROR_REINVOICE",
      text: "SAP adapter błąd wysyłki refaktury",
    },
    {
      key: "SAP_ADAPTER_ERROR_SPEEDER_INVOICE_LINES",
      value: "SAP_ADAPTER_ERROR_SPEEDER_INVOICE_LINES",
      text: "SAP adapter błąd generowania transferu LIGFACTQ",
    },
    {
      key: "SAP_ADAPTER_ERROR_SPEEDER_SALES",
      value: "SAP_ADAPTER_ERROR_SPEEDER_SALES",
      text: "SAP adapter błąd generowania transferu VENTESQ",
    },
    {
      key: "SAP_ADAPTER_ERROR_SPEEDER_STOCK_STATUS",
      value: "SAP_ADAPTER_ERROR_SPEEDER_STOCK_STATUS",
      text: "SAP adapter błąd generowania transferu STOCKMQ",
    },
    {
      key: "SAP_ADAPTER_ERROR_SPEEDER_STOCK_STATUS_INITIAL",
      value: "SAP_ADAPTER_ERROR_SPEEDER_STOCK_STATUS_INITIAL",
      text: "SAP adapter błąd generowania transferu STKVTEI",
    },
    {
      key: "SAP_ADAPTER_ERROR_SPEEDER_STOCK_STATUS_WEEKLY",
      value: "SAP_ADAPTER_ERROR_SPEEDER_STOCK_STATUS_WEEKLY",
      text: "SAP adapter błąd generowania transferu STKVTEH",
    },
    {
      key: "SAP_ADAPTER_ERROR_SPEEDER_REPLENISHMENT",
      value: "SAP_ADAPTER_ERROR_SPEEDER_REPLENISHMENT",
      text: "SAP adapter błąd uzupełnienia stocku",
    },
    {
      key: "SAP_ADAPTER_ERROR_SPEEDER_REPLENISHMENT_CONFIRMATION",
      value: "SAP_ADAPTER_ERROR_SPEEDER_REPLENISHMENT_CONFIRMATION",
      text: "SAP adapter błąd potwierdzenia uzupełnienia stocku",
    },
    {
      key: "SAP_ADAPTER_ERROR_SPEEDER_REPLENISHMENT_MODE",
      value: "SAP_ADAPTER_ERROR_SPEEDER_REPLENISHMENT_MODE",
      text: "SAP adapter błąd zmiany parametru uzupełnienia stocku",
    },
    {
      key: "SAP_ADAPTER_ERROR_SPEEDER_REPLENISHMENT_MODE_CONFIRMATION",
      value: "SAP_ADAPTER_ERROR_SPEEDER_REPLENISHMENT_MODE_CONFIRMATION",
      text: "SAP adapter błąd potwierdzenia zmiany parametru uzupełnienia stocku",
    },
    {
      key: "SAP_ADAPTER_ERROR_HAZARDOUS_GOODS",
      value: "SAP_ADAPTER_ERROR_HAZARDOUS_GOODS",
      text: "SAP adapter błąd importu pliku FP0536",
    },
    {
      key: "BANK_ADAPTER_ERROR_LIMITS",
      value: "BANK_ADAPTER_ERROR_LIMITS",
      text: "Bank adapter błąd limitów",
    },
    {
      key: "JPK_ADAPTER_ERROR_INVOICES",
      value: "JPK_ADAPTER_ERROR_INVOICES",
      text: "JPK adapter błąd faktur",
    },
    {
      key: "JPK_ADAPTER_ERROR_WAREHOUSE",
      value: "JPK_ADAPTER_ERROR_WAREHOUSE",
      text: "JPK adapter błąd warehouse",
    },
    {
      key: "SB_ADAPTER_ERROR_STOCK_QUERY",
      value: "SB_ADAPTER_ERROR_STOCK_QUERY",
      text: "SB adapter błąd stocku",
    },
    {
      key: "SB_ADAPTER_ERROR_ORDER",
      value: "SB_ADAPTER_ERROR_ORDER",
      text: "SB adapter błąd zamówienia",
    },
    {
      key: "MAIL_ADAPTER_ERROR",
      value: "MAIL_ADAPTER_ERROR",
      text: "Mail adapter błąd",
    },
    {
      key: "PDF_INVOICE_GENERATION_ERROR",
      value: "PDF_INVOICE_GENERATION_ERROR",
      text: "Błąd generowania PDF",
    },
    {
      key: "PRICE_LIST_IMPORT_ERROR",
      value: "PRICE_LIST_IMPORT_ERROR",
      text: "Błąd importu cennika",
    },
    {
      key: "BACKORDER_ID_MISSING_IN_XP0093",
      value: "BACKORDER_ID_MISSING_IN_XP0093",
      text: "Błąd potwierdzenia SAP",
    },
    {
      key: "BACKORDER_ID_NOT_MATCHED",
      value: "BACKORDER_ID_NOT_MATCHED",
      text: "Potwierdzenie SAP - niewystarczające parametry",
    },
    {
      key: "SPEEDER_BACKORDER_CREATE_LINE_PART_NOT_MANAGED_BY_MODE",
      value: "SPEEDER_BACKORDER_CREATE_LINE_PART_NOT_MANAGED_BY_MODE",
      text: "SPEEDER błąd aktualizacji typu zamówienia hurtowego",
    },
    {
      key: "CODING_FOR_NON_CODED_PART",
      value: "CODING_FOR_NON_CODED_PART",
      text: "Błąd podczas tworzenia lub dodawania lini zamówienia",
    },
    {
      key: "SPEEDER_BACKORDER_CREATE_LINE_PART_NOT_FOUND",
      value: "SPEEDER_BACKORDER_CREATE_LINE_PART_NOT_FOUND",
      text: "SPEEDER błąd utworzenia zamówienia hurtowego",
    },
    {
      key: "SPEEDER_BACKORDER_CREATE_LINE_PART_NOT_MANAGED",
      value: "SPEEDER_BACKORDER_CREATE_LINE_PART_NOT_MANAGED",
      text: "SPEEDER błąd utworzenia zamówienia hurtowego",
    },
    {
      key: "SPEEDER_CREATE_BACKORDER_BATCH_ALREADY_EXISTS",
      value: "SPEEDER_CREATE_BACKORDER_BATCH_ALREADY_EXISTS",
      text: "SPEEDER błąd utworzenia zamówienia hurtowego",
    },
    {
      key: "SPEEDER_CREATE_BACKORDER_BATCH_NOT_VALID",
      value: "SPEEDER_CREATE_BACKORDER_BATCH_NOT_VALID",
      text: "SPEEDER błąd utworzenia zamówienia hurtowego",
    },
    { key: "ADAPTER_ERROR", value: "ADAPTER_ERROR", text: "Błąd adaptera" },
    {
      key: "MANUAL_ORDER_NOT_MATCHED",
      value: "MANUAL_ORDER_NOT_MATCHED",
      text: "Zamówienie manualne - brak dopasowania",
    },
  ] as DictionaryItem[],

  STATUS_TYPE: [
    { key: "CREATED", text: "Dokument został utworzony", value: "CREATED" },
    {
      key: "CANCELLED ",
      text: "Dokument został anulowany",
      value: "CANCELLED ",
    },
    { key: "DISPATCHED", text: "Części zostały wysłane", value: "DISPATCHED" },
    {
      key: "DELIVERED ",
      text: "Części zostały dostarczone",
      value: "DELIVERED ",
    },
    {
      key: "DELIVERED_WITH_ANOMALY",
      text: "Części zostały dostarczone z anomalią",
      value: "DELIVERED_WITH_ANOMALY",
    },
    {
      key: "NO_PARTS_DELIVERED",
      text: "Części nie zostały dostarczone",
      value: "NO_PARTS_DELIVERED",
    },
    {
      key: "CONFIRMED",
      text: "Dokument został potwierdzony",
      value: "CONFIRMED",
    },
    {
      key: "CONFIRMED_WITH_ANOMALY",
      text: "Dokument został potwierdzony z anomalią",
      value: "CONFIRMED_WITH_ANOMALY",
    }
  ] as DictionaryItem[],

  NOTIFICATION_TYPE: [
    { key: "INFO", text: "Info", value: "INFO" },
    { key: "STATUS", text: "Status", value: "STATUS" },
    { key: "ERROR", text: "Błąd", value: "ERROR" },
    { key: "WARN", text: "Ostrzeżenie", value: "WARN" },
  ] as DictionaryItem[],

  ORDER_TYPE: [
    { key: "NORMAL", text: "Zwykłe", value: "NORMAL" },
    { key: "WARRANTY", text: "Gwarancyjne", value: "WARRANTY" },
    { key: "BONUS", text: "Bonusowe", value: "BONUS" },
    { key: "BONUS_PLUS", text: "Bonusowe Plus", value: "BONUS_PLUS" },
    { key: "SPECIAL", text: "Specjalne", value: "SPECIAL" },
  ] as DictionaryItem[],

  PAGE_SIZE: [
    { key: "15", text: "15", value: 15 },
    { key: "50", text: "50", value: 50 },
    { key: "100", text: "100", value: 100 },
    { key: "500", text: "500", value: 500 },
  ] as DictionaryItem[],

  ORDER_SOURCE: [
    { key: "LDC", value: "LDC", text: "LDC" },
    { key: "SERVICE_BOX", value: "SERVICE_BOX", text: "SB" },
    { key: "BP", value: "BP", text: "BP" },
    { key: "CLAIM", value: "CLAIM", text: "RK" },
  ] as DictionaryItem[],

  CODED_PART_STATUS: [
    {
      key: "NEW",
      value: "NEW",
      text: "Nowe",
    },
    {
      key: "CANCELED",
      value: "CANCELED",
      text: "Anulowane",
    },
    {
      key: "ORDERED",
      value: "ORDERED",
      text: "Zamówione",
    },
    {
      key: "INVOICED",
      value: "INVOICED",
      text: "Zafakturowane",
    }  
  ] as DictionaryItem[],

  TRANSITION_ACTIONS: [
    {
      key: "VERIFICATION_ACCEPTED",
      value: "VERIFICATION_ACCEPTED",
      text: "Weryfikacja zaakceptowana",
    },
    {
      key: "VERIFICATION_REQUEST_RETURN",
      value: "VERIFICATION_REQUEST_RETURN",
      text: "Oczekiwanie na zwrot części od klienta",
    },
    {
      key: "DECISION_ACCEPTED",
      value: "DECISION_ACCEPTED",
      text: "Decyzja zaakceptowana",
    },
    {
      key: "DECISION_REJECTED",
      value: "DECISION_REJECTED",
      text: "Decyzja odmowa",
    },
    {
      key: "RETURN_TO_SUPPLIER",
      value: "RETURN_TO_SUPPLIER",
      text: "Zwrot do dostawcy",
    },
    { key: "REFUND", value: "REFUND", text: "Zwróć pieniądze" },
    {
      key: "MARK_CLOSED_WITH_TRANSPORTATION_FEE",
      value: "MARK_CLOSED_WITH_TRANSPORTATION_FEE",
      text: "Oznacz jako zamknięte z kosztem zwrotu",
    },
    { key: "MARK_CLOSED", value: "MARK_CLOSED", text: "Oznacz jako zamknięte" },
  ] as DictionaryItem[],

  REJECTION_REASON: [
    {
      key: "CUSTOMER_BLOCKED",
      value: "CUSTOMER_BLOCKED",
      text: "Kontrahent zablokowany",
    },
    {
      key: "CUSTOMER_LIMIT_EXCEEDED",
      value: "CUSTOMER_LIMIT_EXCEEDED",
      text: "Przekroczony limit kontrahenta",
    },
    { key: "ZERO_QUANTITY", value: "ZERO_QUANTITY", text: "Ilość równa 0" },
    {
      key: "NOT_PACKAGING_QUANTITY",
      value: "NOT_PACKAGING_QUANTITY",
      text: "Ilość niezgodna z UV",
    },
    {
      key: "INVALID_PART",
      value: "INVALID_PART",
      text: "Nieprawidłowa referencja",
    },
    {
      key: "PART_LOCKED",
      value: "PART_LOCKED",
      text: "Referencja zablokowana",
    },
    { key: "DFS_PART", value: "DFS_PART", text: "Referencja DFS" },
    {
      key: "REPLACEMENTS_FOUND",
      value: "REPLACEMENTS_FOUND",
      text: "Zamienność",
    },
    {
      key: "REPLACED_TO_ORIGINAL",
      value: "REPLACED_TO_ORIGINAL",
      text: "Zamiana na poprzednik",
    },
    {
      key: "PROVISION_WITHOUT_PART",
      value: "PROVISION_WITHOUT_PART",
      text: "Kaucja bez referencji",
    },
    {
      key: "PROVISIONED_PART_WITHOUT_PROVISION",
      value: "PROVISIONED_PART_WITHOUT_PROVISION",
      text: "Wymagana kaucja",
    },
    {
      key: "NOT_ENOUGH_PROVISIONS",
      value: "NOT_ENOUGH_PROVISIONS",
      text: "Niewystarczająca kaucja",
    },
    { key: "NO_VIN_PROVIDED", value: "NO_VIN_PROVIDED", text: "Brak kodu VIN" },
    { key: "NO_CLE_PROVIDED", value: "NO_CLE_PROVIDED", text: "Brak kodu CLE" },
    { key: "NO_NRE_PROVIDED", value: "NO_NRE_PROVIDED", text: "Brak kodu NRE" },
    {
      key: "NO_WHEEL_CODE_PROVIDED",
      value: "NO_WHEEL_CODE_PROVIDED",
      text: "Brak kodu kół",
    },
    {
      key: "NO_TRANSMITTER_CODE_PROVIDED",
      value: "NO_TRANSMITTER_CODE_PROVIDED",
      text: "Brak kodu nadajnika",
    },
    {
      key: "NO_TIRE_CODE_PROVIDED",
      value: "NO_TIRE_CODE_PROVIDED",
      text: "Brak kodu opon",
    },
    {
      key: "THRESHOLD_EXCEEDS",
      value: "THRESHOLD_EXCEEDS",
      text: "Przekroczony próg",
    },
    {
      key: "MORE_THAN_ONE_QUANTITY_OF_CODED_PART",
      value: "MORE_THAN_ONE_QUANTITY_OF_CODED_PART",
      text: "Ilość części kodowanych >1",
    },
    {
      key: "COMPLEX_REPLACEMENT_FOUND",
      value: "COMPLEX_REPLACEMENT_FOUND",
      text: "Zamienność wg listy",
    },
  ] as DictionaryItem[],

  CUSTOMER_BLOCKED_STATUS: [
    { key: "true", value: "true", text: "Zablokowany" },
    { key: "false", value: "false", text: "Niezablokowany" },
  ] as DictionaryItem[],

  CUSTOMER_CHANGE_PAYER: [
    { key: "not_null", value: "not_null", text: "Zmiana płatnika" },
    { key: "null", value: "null", text: "Brak zmiany płatnika" },
  ] as DictionaryItem[],

  ANOMALY_DESTROYED_STATUSES: [
    { key: "AVAILABLE", value: "AVAILABLE", text: "Dostępne" },
    { key: "UNAVAILABLE", value: "UNAVAILABLE", text: "Zablokowane" },
    {
      key: "QUALITY_CONTROL",
      value: "QUALITY_CONTROL",
      text: "Do waloryzacji",
    },
    { key: "CONSOLIDATED", value: "CONSOLIDATED", text: "Zarezerwowane" },
  ] as DictionaryItem[],

  GTU_CODES: [
    { key: "NONE", value: "BRAK", text: "BRAK" },
    { key: "GTU_01", value: "GTU_01", text: "GTU_01" },
    { key: "GTU_02", value: "GTU_02", text: "GTU_02" },
    { key: "GTU_03", value: "GTU_03", text: "GTU_03" },
    { key: "GTU_04", value: "GTU_04", text: "GTU_04" },
    { key: "GTU_05", value: "GTU_05", text: "GTU_05" },
    { key: "GTU_06", value: "GTU_06", text: "GTU_06" },
    { key: "GTU_07", value: "GTU_07", text: "GTU_07" },
    { key: "GTU_08", value: "GTU_08", text: "GTU_08" },
    { key: "GTU_09", value: "GTU_09", text: "GTU_09" },
    { key: "GTU_10", value: "GTU_10", text: "GTU_10" },
    { key: "GTU_11", value: "GTU_11", text: "GTU_11" },
    { key: "GTU_12", value: "GTU_12", text: "GTU_12" },
    { key: "GTU_13", value: "GTU_13", text: "GTU_13" },
  ] as DictionaryItem[],

  GTU_CODES_AUTOMATIC_UPDATE: [
    { key: "null", value: "null", text: "Domyślnie" },
    {
      key: "BLOCK_AUTO_UPDATE",
      value: "BLOCK_AUTO_UPDATE",
      text: "Blokuj aktualizację",
    },
  ] as DictionaryItem[],

  PAYMENT_METHODS: [
    { key: "FACTOR", value: "FACTOR", text: "Faktor" },
    { key: "CREDIT_CARD", value: "CREDIT_CARD", text: "Płatność kartą" },
    {
      key: "DISTRIGO_CREDIT",
      value: "DISTRIGO_CREDIT",
      text: "Kredyt Distrigo",
    },
  ] as DictionaryItem[],

  DISTRIGO_LIMIT_RENEWAL_TYPE: [
    { key: "WEEKLY", value: "WEEKLY", text: "Tygodniowy" },
    { key: "MONTHLY", value: "MONTHLY", text: "Miesięczny" },
    { key: "QUARTERLY", value: "QUARTERLY", text: "Kwartalny" },
    { key: "NONE", value: "NONE", text: "Brak" },
  ] as DictionaryItem[],


  STORAGE_LIMIT: [
    { key: "true", value: "true", text: "Tak" },
    { key: "false", value: "false", text: "Nie" },
  ] as DictionaryItem[],
};

export default dictionariesStatic;
