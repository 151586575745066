import { useMemo } from "react";
import { TColumn } from "~/components/Table/lib/types";
import { useCellsRenderer } from "~/services/useCellRenderer";

export const useCollectiveInvoiceCorrectionsColumns = () => {
  const { getAmountCell, getDateCell, getLinkCell, getTextCell } =
    useCellsRenderer();

  const columnsConfig: TColumn[] = useMemo(
    () => [
      {
        name: "documentMask",
        label: "Numer",
        projection: true,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "createDateTime",
        label: "Data wystawienia",
        projection: true,
        type: "date",
        getCell: (document, column) => getDateCell(document, column, true),
      },
      {
        name: "paymentCustomer.taxCode",
        label: "NIP płatnika",
        projection: true,
        getCell: (document, column) =>
          getLinkCell(
            document,
            column,
            `/customer/${document.paymentCustomer.id}`
          ),
      },
      {
        name: "bonusName",
        label: "Nazwa bonusu",
        projection: true,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "totalPriceNet",
        label: "Kwota netto",
        projection: true,
        getCell: (document, column) => getAmountCell(document, column),
      },
      {
        name: "totalPriceGross",
        label: "Kwota brutto",
        projection: true,
        getCell: (document, column) => getAmountCell(document, column),
      },
      {
        name: "totalPriceTax",
        label: "Kwota VAT",
        projection: true,
        getCell: (document, column) => getAmountCell(document, column),
      },
      {
        name: "invoiceLines.orderNumber",
        label: "Numer zamówienia",
        projection: false,
        hide: true,
        getCell: (document, column) => getTextCell(document, column),
      },
    ],
    [getAmountCell, getDateCell, getLinkCell, getTextCell]
  );

  return { columnsConfig };
};
