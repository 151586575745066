import { SyntheticEvent } from 'react';
import { DictionaryItem } from '~/store/dictionaries/types';

export interface HandleChange {
    [key: string]: string | any;
}

export enum FIELD_TYPE {
    INPUT = 'INPUT',
    SELECT = 'SELECT',
    DATE_PICKER = 'DATE_PICKER',
    CHECKBOX = 'CHECKBOX',
}

export type FormField = {
    type: FIELD_TYPE;
    name?: string;
    label: string;
    placeholder?: string;
    id?: string;
    value?: string | Date | boolean | (string | number | boolean)[];
    minDate?: Date;
    options?: DictionaryItem[];
    maxLength?: number;
    multiple?: boolean;
    onChange: (e: SyntheticEvent<Element, Event> | undefined, { name, value }: HandleChange) => void;
};
