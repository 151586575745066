import { connect } from "react-redux";
import { Button, Form, Header, Modal } from "semantic-ui-react";
import { ModelFormWrapper } from "~/components/ModelForm/ModelFormWrapper";
import { SubmitButton } from "~/components/ModelForm/SubmitButton";
import { ApplicationState } from "~/store";
import { addDictionaryItem, clearCreated } from "~/store/dictionaries/actions";

import React, { useCallback, useEffect, useState } from "react";
import { InputWrapper } from "~/pages/Administration/lib/Dictionaries/InputWrapper";
import {
  FieldNameEnum,
  TAddDictionaryItemProps,
  TReduxAddActions,
  TReduxState,
} from "~/pages/Administration/lib/Dictionaries/constants";

const AddDictionaryItem: React.FC<TAddDictionaryItemProps> = ({
  children,
  triggerOpen,
  created,
  creating,
  details,
  name,
  addDictionaryItem,
  createdSuccess,
  clearCreated,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [sell, setSell] = useState<number>();
  const [buy, setBuy] = useState<number>();

  useEffect(() => {
    if (triggerOpen) {
      setOpen(true);
    }
  }, [triggerOpen]);

  const handleConfirm = useCallback(
    (item: Object | any): void => {
      if (!creating) {
        if (sell) {
          item.sell = sell;
        }
        if (buy) {
          item.buy = buy;
        }
        addDictionaryItem(name, item);
      }
    },
    [creating, name, sell, buy, addDictionaryItem]
  );

  const handleCancel = useCallback((): void => {
    clearCreated();
    setOpen(false);
    setSell(undefined);
    setBuy(undefined);
  }, [clearCreated]);

  const handleOpen = useCallback((): void => {
    setOpen(true);
  }, []);

  if (created && open) {
    clearCreated();
    setOpen(false);
    createdSuccess();
    setSell(undefined);
    setBuy(undefined);
  }

  return (
    <Modal
      size="tiny"
      trigger={children}
      open={open}
      onOpen={handleOpen}
      onClose={handleCancel}
      closeOnDimmerClick={false}
    >
      <ModelFormWrapper onSubmit={handleConfirm}>
        <Header icon="add" content={`Dodaj element do słownika: ${name}`} />
        <Modal.Content>
          <Form style={{ padding: 10 }} noValidate="novalidate">
            {details.fields
              .filter((field) => field.name !== FieldNameEnum.id)
              .map((field) => (
                <InputWrapper
                  key={field.name}
                  name={field.name}
                  type={field.type}
                  dictionaryName={name}
                />
              ))}
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <SubmitButton
            content="Dodaj"
            icon="add"
            labelPosition="right"
            primary
            loading={creating}
          />
          <Button content="Anuluj" onClick={handleCancel} />
        </Modal.Actions>
      </ModelFormWrapper>
    </Modal>
  );
};

const mapStateToProps: (state: ApplicationState) => TReduxState = ({
  dictionaries,
}: ApplicationState) => ({
  created: dictionaries.created,
  creating: dictionaries.creating,
});

const mapDispatchToProps: TReduxAddActions = {
  addDictionaryItem,
  clearCreated,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddDictionaryItem)