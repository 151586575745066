import { Dropdown, Icon, Menu } from "semantic-ui-react";
import Notifications from "~/components/Notifications/Notifications";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import React from "react";

export type MenuUserProfileProps = {
    trigger: string | undefined,
    context: any,
    onClick: () => void
}

export const MenuUserProfile: React.FC<MenuUserProfileProps> = ({ trigger, context, onClick}) => {
    return (
        <Menu.Menu position="right">
            <Menu.Item name="notifications">
                <Notifications/>
            </Menu.Item>
            <Menu.Item
                name="account">
                <Icon name="user" inverted color="grey" size="large"/>
                <Dropdown trigger={trigger}>
                    <Dropdown.Menu>
                        <Dropdown.Item>
                            <a href={`${context.keycloak && context.keycloak.authServerUrl}/realms/ldc-realm/account/`}
                               target="_blank" rel="noreferrer" style={{ color: "black" }}>
                                <Icon name="settings" color="grey" size="large"/>
                                <FormattedMessage id="app.settings"/>
                            </a>
                        </Dropdown.Item>
                        <Dropdown.Item as={Link} to="/" onClick={onClick}>
                            <Icon name="log out" color="grey" size="large"/>
                            <FormattedMessage id="app.logout"/>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </Menu.Item>
        </Menu.Menu>
    )
}