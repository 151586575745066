import { User } from "~/store/users/types";
import { State } from "~/pages/Administration/constants";
import { enableUser, removeUser } from "~/store/users/actions";

export interface PropsFromState {
  loading: boolean;
}

export interface PropsFromDispatch {
  enableUser: typeof enableUser;
  removeUser: typeof removeUser;
}

export type UserTableWrapperT = {
  setState: any;
  state: State;
};

export type UserTableWrapperProps = UserTableWrapperT &
  PropsFromDispatch &
  PropsFromState;

export enum ACTION_TYPE_ENUM {
  ACTIVATE_USER = "ACTIVATE_USER",
  DEACTIVATE_USER = "DEACTIVATE_USER",
  REMOVE_USER = "REMOVE_USER",
}

export interface UserTablePropsFromState {
  users: User[];
}

export type UserTableProps = UserTableWrapperT & UserTablePropsFromState;
