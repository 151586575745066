import React, { useCallback, useMemo } from "react";
import { Table } from "semantic-ui-react";
import { TColumn } from "~/components/Table/lib/types";
import { useCellsRenderer } from "~/services/useCellRenderer";
import { DictionaryName } from "~/store/dictionaries/types";

export const useClaimsColumns = () => {
  const getSupplierSystemCell = useCallback(
    (claim: any, column: any, fieldName: string): JSX.Element => {
      return (
        <Table.Cell key={column.name}>
          {claim.supplierSystem ? claim.supplierSystem[0][fieldName] : ""}
        </Table.Cell>
      );
    },
    []
  );

  const { getAmountCell, getDateCell, getLinkCell, getTextCell } =
    useCellsRenderer();

  const columnsConfig: TColumn[] = useMemo(
    () => [
      {
        name: "claimNumber",
        label: "Numer",
        projection: true,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "createDate",
        label: "Utworzone",
        projection: true,
        type: "date",
        getCell: (document, column) => getDateCell(document, column, true),
      },
      {
        name: "status",
        dictionaryName: DictionaryName.claimStatus,
        label: "Status",
        projection: true,
        noKey: true,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "statusDate",
        label: "Aktualizacja",
        projection: true,
        getCell: (document, column) => getDateCell(document, column, true),
      },
      {
        name: "invoiceDocumentMask",
        label: "Faktura",
        projection: true,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "orderNumber",
        label: "Zamówienie",
        projection: true,
        getCell: (document, column) => getTextCell(document, column),
      },

      {
        name: "supplierSystem.businessSector",
        label: "Dostawca",
        projection: false,
        hide: true,
        filteringBlocked: true,
        getCell: (document, column) =>
          getSupplierSystemCell(document, column, "businessSector"),
      },
      {
        name: "supplierSystem.clientId",
        label: "Sincom",
        projection: false,
        hide: true,
        filteringBlocked: true,
        getCell: (document, column) =>
          getSupplierSystemCell(document, column, "clientId"),
      },
      {
        name: "supplierSystem.deliveryId",
        label: "Punkt dostawy",
        projection: false,
        hide: true,
        filteringBlocked: true,
        getCell: (document, column) =>
          getSupplierSystemCell(document, column, "deliveryId"),
      },
      {
        name: "claimGroup",
        dictionaryName: DictionaryName.claimGroup,
        label: "Rodzaj",
        projection: true,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "claimCategory",
        dictionaryName: DictionaryName.claimCategory,
        label: "Kategoria",
        projection: true,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "claimType",
        dictionaryName: DictionaryName.claimType,
        label: "Typ",
        projection: true,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "orderCustomerID",
        label: "Klient",
        projection: true,
        getCell: (document, column) =>
          getLinkCell(
            document,
            column,
            `/customer/${document.orderCustomerID}`
          ),
      },
      {
        name: "accountingCustomerID",
        label: "Płatnik",
        projection: true,
        getCell: (document, column) =>
          getLinkCell(
            document,
            column,
            `/customer/${document.accountingCustomerID}`
          ),
      },
      {
        name: "orderCustomerDdsID",
        label: "OV odbiorca",
        projection: true,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "backInTheBox",
        label: "Numer RPL",
        projection: true,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "claimedValue",
        label: "Wartość reklamacji",
        projection: false,
        getCell: (document, column) => getAmountCell(document, column),
      },
      {
        name: "value",
        label: "Wartość zaakceptowana",
        projection: false,
        getCell: (document, column) => getAmountCell(document, column),
      },
    ],
    [
      getAmountCell,
      getDateCell,
      getLinkCell,
      getSupplierSystemCell,
      getTextCell,
    ]
  );
  return { columnsConfig };
};
