import React from "react";

export interface AppContextInterface {
  keycloak: { [key: string]: any };
  authenticated: boolean;
  lang: string;
  menuVisibility: boolean;
  changeLanguage: (language: string) => void;
  toggleSidebar: () => void;
  changeKeycloak: () => void;
}

export const AppContext = React.createContext<AppContextInterface | null>(null);
