import { Paginable } from '~/store/types'
import { action } from 'typesafe-actions'
import { SparePart, SparePartsActionTypes } from './types'

export const fetchSpareParts = (params: any) => action(SparePartsActionTypes.FETCH_SPAREPARTS_REQUEST, params)
export const fetchSparePartsSearch = (params: any) => action(SparePartsActionTypes.FETCH_SPAREPARTS_SEARCH_REQUEST, params)
export const fetchSparePartsSuccess = (spareParts: Paginable<SparePart>) => action(SparePartsActionTypes.FETCH_SPAREPARTS_SUCCESS, spareParts)
export const fetchSparePartsSearchSuccess = (spareParts: SparePart[]) => action(SparePartsActionTypes.FETCH_SPAREPARTS_SEARCH_SUCCESS, { spareParts })

export const selectSparePart = (referenceId: string) => action(SparePartsActionTypes.SELECT_SPAREPART, referenceId)
export const sparePartSelected = (sparePart: SparePart) => action(SparePartsActionTypes.SPAREPART_SELECTED, sparePart)

export const importReferences = (file: File) => action(SparePartsActionTypes.IMPORT_REFERENCES, file)
export const importFinished = () => action(SparePartsActionTypes.IMPORT_FINISHED)
