import React, { Component, createElement } from "react";
import { connect } from "react-redux";
import { Icon, Image, Menu, FormFieldProps, Popup } from "semantic-ui-react";
import { fetchVersion } from "~/store/version/actions";
import { AppContext } from "~/context/AuthContext";
import { ApplicationState } from "~/store";
import LogoImage from "~/assets/images/distrigo.png";
import "./header.scss";
import { TReduxState, PsaHeaderProps } from "./lib/types";
import { MenuUserProfile } from "~/components/PsaHeader/MenuUserProfile";
import { MenuSearchField } from "~/components/PsaHeader/MenuSearchField";
import { getSearchOptions } from "~/components/PsaHeader/lib/utils";

export class PsaHeader extends Component<PsaHeaderProps, TReduxState> {
  static contextType = AppContext;

  state = {
    user: undefined,
    selectValue: getSearchOptions(this.context)?.[0]?.key,
    inputValue: "",
    isPreprodEnv: window.location.href.includes("ldc-preprod.distrigo"),
  };

  componentDidMount(): void {
    if (this.context.keycloak !== null) {
      this.context.keycloak.loadUserInfo().then((userInfo: any) => {
        this.clearSessionFilters();
        this.setState({
          user: userInfo.name,
          email: userInfo.email,
          id: userInfo.sub,
        });
      });
    }

    this.props.fetchVersion();
  }

  handleLanguageChange = (
    e: React.SyntheticEvent<HTMLElement>,
    data: any
  ): void => {
    this.context.changeLanguage(data.value);
  };

  handleMenuVisibilityChange = (): void => {
    this.context.toggleSidebar();
  };

  handleSelectChange = (e: any, el: FormFieldProps): void => {
    this.setState({ selectValue: el.value });
  };

  handleInputChange = (e: any, el: FormFieldProps): void => {
    this.setState({ inputValue: el.value });
  };

  getVersion = (): React.ReactNode => {
    return createElement(
      "p",
      {},
      this.props.loading
        ? "Ładowanie wersji..."
        : `Wersja ${this.props.version}`
    );
  };

  getEnvironment = (): React.ReactNode => {
    const url = window.location.href;
    let name = "Środowisko ";

    if (url.includes("ldc-preprod.distrigo")) {
      name += "PREPROD";
    } else if (url.includes("10.84.1.6")) {
      name += "DEV";
    } else if (url.includes("10.84.3.106")) {
      name += "QA";
    } else if (url.includes("ldc.distrigo")) {
      name += "PROD";
    } else {
      name += "---";
    }
    return createElement("p", {}, name);
  };

  createVersionPopup = (): React.ReactNode => {
    return createElement("div", {}, this.getVersion(), this.getEnvironment());
  };

  clearSessionFilters = (): void => {
    localStorage.removeItem("tableFilterSession");
    localStorage.removeItem("tableSortSession");
  };

  handleLogout = (): void => {
    this.clearSessionFilters();
    this.context.keycloak && this.context.keycloak.logout();
  };

  public render() {
    return (
      <header>
        <Menu
          secondary
          inverted
          style={{
            backgroundColor: "rgba(77, 77, 77, 1)",
            height: 50,
          }}
        >
          <Menu.Item name="menu" onClick={this.handleMenuVisibilityChange}>
            <Icon inverted name="bars" color="grey" size="large" />
          </Menu.Item>
          <Popup
            children={this.createVersionPopup()}
            trigger={<Image className="logo" src={LogoImage} />}
          />
          <MenuSearchField
            selectValue={this.state.selectValue}
            onChange={this.handleSelectChange}
          />
          {this.state.isPreprodEnv && (
            <h3 style={{ margin: "auto", color: "#fa4f4f", fontSize: 30 }}>
              PREPROD
            </h3>
          )}
          <MenuUserProfile
            trigger={this.state.user}
            context={this.context}
            onClick={() => this.handleLogout()}
          />
        </Menu>
      </header>
    );
  }
}

const mapStateToProps = ({ version }: ApplicationState) => ({
  version: version.version,
  loading: version.loading,
});

const mapDispatchToProps = {
  fetchVersion,
};

export default connect(mapStateToProps, mapDispatchToProps)(PsaHeader);
