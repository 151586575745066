import { FormattedMessage } from 'react-intl'
import { Search } from 'semantic-ui-react'

import React from 'react'
import './styles.scss'
import { TProps } from "~/components/UberSearch/lib/types";
import { useUberSearch } from "~/components/UberSearch/hooks/useUberSearch";

const UberSearch: React.FC<TProps> = (props) => {
    const {
        endpoint,
        mapper,
        onResultSelect,
        className,
        ...bypassProps
    } = props

    const { searchChangeHandler, resultSelectHandler, loading, results, query } = useUberSearch(props);

    return <Search
        className={`uber-dropdown ${className || ''}`}
        noResultsMessage={loading ? 'Wyszukiwanie...' : <FormattedMessage id="app.noResultsMessage"/>}
        {...bypassProps}
        onSearchChange={searchChangeHandler}
        onResultSelect={resultSelectHandler}
        results={results}
        loading={loading}
        value={query}
    />
}

export default UberSearch
