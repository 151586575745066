import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Link } from "react-router-dom";
import { Button, Container, Grid, Icon, Label } from "semantic-ui-react";
import { TLabelOptProps } from "~/components/SmartField/lib/types";
import { ApplicationState } from "~/store";
import { fetchDictionary } from "~/store/dictionaries/actions";
import { DictionariesState, DictionaryItem, DictionaryName } from "~/store/dictionaries/types";
import { selectSparePart } from "~/store/spare-parts/actions";
import {
  ReplacementPart,
  SparePart,
  TReplacementHistory,
} from "~/store/spare-parts/types";
import { useRenderingFunctions } from "~/components/SmartField/hooks/useRenderingFunctions";
import { parseDate } from "~/utils/dateUtils";
import { parsePrice } from "~/utils/parsePrice";
import { TabSwitcher, TCard } from "~/pages/SpareParts/lib/TabSwitcher";

import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import DetailCard from "~/components/DetailCard/DetailCard";
import CommonLoader from "~/components/Loaders/CommonLoader";
import mapDictionary from "~/components/MapDictionary/mapDictionary";
import { DictionaryLabel } from "~/components/MapDictionary/DictionaryLabel";
import PageHeader from "~/components/PageHeader/PageHeader";
import SmartWrapper from "~/components/SmartField/SmartWrapper";
import dictionariesStatic from "~/store/dictionaries/static";
import { AVAILABLE_ROLE_ENUM } from "~/store/users/types";
import { AppContext } from "~/context/AuthContext";
import translations from "~/utils/translations";

type TReduxState = {
  sparePartIn?: SparePart;
  dictionaries: DictionariesState;
  loading: boolean;
};
type TReduxActions = {
  selectSparePart: typeof selectSparePart;
  fetchDictionary: typeof fetchDictionary;
};
type TRouteParams = RouteComponentProps<{
  referenceId: string;
  edit?: string;
}>;

type TSparePartDetails = TReduxState & TReduxActions & TRouteParams;

const SparePartDetails: React.FC<TSparePartDetails> = ({
  match,
  sparePartIn,
  dictionaries,
  loading,
  selectSparePart,
  fetchDictionary,
}) => {
  const [sparePart, setSparePart] = useState<SparePart>();
  const [editMode, setEditMode] = useState(!!match.params.edit);
  const [visibleCards, setVisibleCards] = useState<TCard[]>([]);

  const categoryModificationDictionary = useMemo(
    () => [
      { key: "N", value: "N", text: "Zmiana statusu ADV" },
      { key: "C", value: "C", text: "Zmiana ceny" },
      { key: "X", value: "X", text: "Pozostałe zmiany" },
    ],
    []
  );

  const appContext = useContext(AppContext);

  useEffect(() => {
    document.title = translations.format("app.products")
    if (!sparePart || sparePart.referenceId !== match.params.referenceId) {
      fetchDictionary(
        DictionaryName.sparePartCategoryAdv,
        DictionaryName.sparePartCategoryOrigin,
        DictionaryName.sparePartTaxCategory,
        DictionaryName.sparePartDiscountPcd,
        DictionaryName.sparePartDiscountIam,
        DictionaryName.sparePartDiscountFca,
        DictionaryName.sparePartState,
        DictionaryName.spareParReplacementCategory,
        DictionaryName.sparePartBrand,
        DictionaryName.sparePartHierarchyFamily,
        DictionaryName.sparePartHierarchySlot,
        DictionaryName.sparePartHierarchySegment,
        DictionaryName.sparePartHierarchyIndex,
        DictionaryName.sparePartProvisionCategory,
        DictionaryName.sparePartProvisionForceCategory,
        DictionaryName.gtuCodeFormula,
        DictionaryName.sparePartGtuCode,
        DictionaryName.sparePartReplenishmentMode
      );
      selectSparePart(match.params.referenceId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.referenceId, fetchDictionary, selectSparePart]);

  const mapSparePartIn = useCallback(
    (sp: SparePart | undefined) => {
      if (sparePartIn && sparePartIn.gtuCode === null) {
        sparePartIn.gtuCode = translations.format("app.lack");
      }

      return sp;
    },
    [sparePartIn]
  );

  useEffect(() => {
    if (sparePartIn) {
      setSparePart(mapSparePartIn(sparePartIn));
    }
  }, [mapSparePartIn, sparePartIn]);

  const formatMessage = (id: string): JSX.Element => {
    return <FormattedMessage id={id} />;
  };

  const toggleEditMode = (): void => {
    setEditMode(!editMode);
  };


  const {
    renderField,
    renderSelect,
    renderTextArea,
    renderToggle,
    renderLabel: renderLabelOrg,
  } = useRenderingFunctions(sparePart, editMode);

  const renderLabel = useCallback(
    (
      label: string | JSX.Element,
      value?: any,
      optionals?: TLabelOptProps
    ): JSX.Element | undefined => {
      if (renderLabelOrg) {
        return renderLabelOrg(label, value || "-", optionals);
      }
    },
    [renderLabelOrg]
  );

  const renderReplacementHistory = useCallback(
    (replacements: TReplacementHistory[], level: number = 0): JSX.Element[] | undefined => {
      if (replacements && replacements.length) {
        return replacements.map((replacement) => {
          return (
            <Fragment key={replacement.referenceId}>
              <div style={{ marginBottom: 5 }}>
                {level > 0 && (
                  <Icon
                    name="arrow right"
                    compact
                    style={{
                      paddingLeft: 10,
                      marginRight: 10,
                      marginLeft: 30 * (level - 1),
                    }}
                  />
                )}{" "}
                <Label content={replacement.replacementDate} />
                <b>{replacement.referenceId}</b> {replacement.description}
              </div>
              {replacement.replacements &&
                renderReplacementHistory(replacement.replacements, level + 1)}
            </Fragment>
          );
        });
      }
    },
    []
  );

  const renderReplacement = useCallback(
    (replacement: ReplacementPart): JSX.Element => (
      <Fragment key={replacement.sparePartID}>
        {renderLabel(
          translations.format("app.reference"),
          <Link
            style={{ textDecoration: "underline" }}
            to={`/product/${replacement.sparePartID}`}
          >
            {replacement.sparePartID}
          </Link>
        )}
        {renderLabel(translations.format("app.quantity"), replacement.quantity)}
      </Fragment>
    ),
    [renderLabel]
  );

  const renderReplacements = useCallback(
    (replacements: ReplacementPart[]): JSX.Element => {
      const splitPoint = Math.ceil(replacements.length / 2);
      const column1 = replacements.slice(0, splitPoint);
      const column2 = replacements.slice(splitPoint);
      return (
        <>
          <Grid.Column>{column1.map(renderReplacement)}</Grid.Column>
          <Grid.Column>{column2.map(renderReplacement)}</Grid.Column>
        </>
      );
    },
    [renderReplacement]
  );

  const mapGTUCodes = useCallback((): DictionaryItem[] => {
    return dictionaries["spare-part-gtu-code"].map((dictionary) => {
      return dictionary.key === "NONE"
        ? { ...dictionary, value: translations.format("app.lack"), key: translations.format("app.lack"), text: translations.format("app.lack") }
        : { ...dictionary };
    });
  }, [dictionaries]);

  const checkDictionaryNameEnding = useCallback((): 'pcd' | 'fca' => {
    if (sparePart!.priceReductionDiscountPcd) {
      return 'pcd'
    } else if (sparePart!.priceReductionDiscountFca || sparePart!.priceListType === 'FCA') {
      return 'fca'
    } else {
      return 'pcd'
    }
  }, [sparePart])

  const cards: JSX.Element[] = useMemo(
    () =>
      !sparePart
        ? []
        : [
            <DetailCard
              title="app.identification"
              id="identification"
              key="identification"
              width={5.33}
            >
              {renderLabel(translations.format("app.reference"), sparePart.referenceId)}
              {renderLabel(translations.format("app.name"), sparePart.designationPolish)}
              {renderLabel(translations.format("app.description"), sparePart.description)}
              {renderLabel("GTIN", sparePart.packingGtin)}
              {renderLabel("IAM", sparePart.categoryIam ? translations.format("app.yes").toUpperCase() : translations.format("app.no").toUpperCase())}
              {renderLabel(translations.format("app.IAM-code"), sparePart.iamCode)}
            </DetailCard>,
            <DetailCard
              title="app.prices"
              id="prices"
              key="prices"
              width={5.33}
            >
              {renderLabel(
                translations.format("app.net-price.PVP"),
                <>{parsePrice(sparePart.priceSelling)}</>
              )}
              {renderLabel(
                translations.format("app.net-price.PNR.discount"),
                <>{parsePrice(sparePart.pricePNR)}</>
              )}
              {renderLabel(
                translations.format("app.net-purchase-price.PND"),
                <>{parsePrice(sparePart.pricePND)}</>
              )}
              {(sparePart.priceReductionDiscountPcd || sparePart.priceReductionDiscountFca) &&
                renderLabel(
                  translations.format(`app.discount-${checkDictionaryNameEnding()}`),
                  (sparePart.priceReductionDiscountPcd || sparePart.priceReductionDiscountFca  ? 
                  <DictionaryLabel
                    value={sparePart.priceReductionDiscountPcd! || sparePart.priceReductionDiscountFca!}
                    dictionary={dictionaries[`spare-part-discount-${checkDictionaryNameEnding()}`]}
                    noDescription
                    isDiscountLabel
                  /> : '-')
                )
              }
              {!(sparePart.priceReductionDiscountPcd || sparePart.priceReductionDiscountFca) &&
                renderLabel(
                  translations.format(`app.discount-${checkDictionaryNameEnding()}`),
                  <DictionaryLabel
                    value={''}
                    dictionary={dictionaries[`spare-part-discount-${checkDictionaryNameEnding()}`]}
                    noDescription
                  />
                )
              }
              {renderLabel(
                translations.format("app.IAM-discount"),
                <DictionaryLabel
                  value={sparePart.priceReductionDiscountIam}
                  dictionary={dictionaries["spare-part-discount-iam"]}
                  noDescription
                />
              )}
              {renderLabel(
                translations.format("app.VAT-rate"),
                <DictionaryLabel
                  value={sparePart.priceTax}
                  dictionary={dictionaries["spare-part-tax-category"]}
                />
              )}
              {renderLabel(translations.format("app.discount-code"), sparePart.priceReductionRebate)}
            </DetailCard>,
            <DetailCard
              title="app.status"
              id="status"
              key="status"
              width={5.33}
            >
              {renderLabel(
                translations.format("app.ADV-status"),
                mapDictionary(
                  sparePart.categoryAdv,
                  dictionaries["spare-part-category-adv"]
                )
              )}
              {renderSelect("state", dictionaries["spare-part-state"], {
                label: translations.format("app.Distrigo-status"),
                description:
                  translations.format("app.ADV-status.description"),
              })}
            </DetailCard>,
            <DetailCard
              title="app.characteristic"
              id="characteristic"
              key="characteristic"
              width={5.33}
            >
              {renderLabel(translations.format("app.price-list"), sparePart.priceListType)}
              {renderLabel(translations.format("app.supplier"), sparePart.supplier)}
              {renderLabel(translations.format("app.hazardous-good"), sparePart.isHazardousGoods ? translations.format("app.yes").toUpperCase() : translations.format("app.no").toUpperCase())}
              {renderLabel(
                translations.format("app.source"),
                mapDictionary(
                  sparePart.categoryOrigin,
                  dictionaries["spare-part-category-origin"]
                )
              )}
              {renderLabel(translations.format("app.coding"), sparePart.categoryIdentifier)}
              {renderLabel(
                translations.format("app.application"),
                mapDictionary(
                  sparePart.businessBrand,
                  dictionaries["spare-part-brand"]
                )
              )}
            </DetailCard>,
            <DetailCard
              title="app.others"
              id="others"
              key="others"
              width={5.33}
            >
              {renderLabel(translations.format("app.type-code"), sparePart.businessCode)}
              {renderLabel(translations.format("app.additional-names", {"nameNumber": "2"}), sparePart.designationFrench)}
              {renderLabel(translations.format("app.additional-names", {"nameNumber": "3"}), sparePart.designationThird)}
              {renderLabel(translations.format("app.additional-names", {"nameNumber": "4"}), sparePart.designationFourth)}
              {renderLabel(translations.format("app.basket"), sparePart.categoryBasket)}
            </DetailCard>,
            <DetailCard
              title="app.speeder"
              id="speeder"
              key="speeder"
              width={5.33}
            >
              {renderSelect(
                "replenishmentMode",
                dictionaries["spare-part-replenishment-mode"],
                {
                  label: translations.format("app.stock-replenishment-mode"),
                  description: translations.format("app.select-stock-replenishment-mode"),
                }
              )}
            </DetailCard>,
            <DetailCard
              title="app.limits"
              id="limits"
              key="limits"
              width={5.33}
            >
              {renderField("packingForceQuantity", {
                label: translations.format("app.sales-unit"),
                description: translations.format("app.minimum-sales-unit-quantity-UV"),
                type: "number",
              })}
              {renderField("thresholdStop", {
                label: translations.format("app.order-limit"),
                description:
                  translations.format("app.order-limit.description"),
                type: "number",
              })}
            </DetailCard>,
            <DetailCard
              title="app.deposit"
              id="deposit"
              key="deposit"
              width={5.33}
            >
              {renderLabel(
                translations.format("app.exchange-status"),
                mapDictionary(
                  sparePart.provisionCategory,
                  dictionaries["spare-part-provision-category"]
                )
              )}
              {
                renderLabel(
                  translations.format("app.deposit-reference"),
                  sparePart.provisionId
                ) /*parametry kaucji należy odczytać z endpointu: http://url:8080/spare-parts/[provisionId]*/
              }
              {
                renderSelect(
                  "provisionForceCategory",
                  dictionaries["spare-part-provision-force-category"],
                  {
                    label: translations.format("app.deposit-status"),
                    description:
                      translations.format("app.deposit-status.description"),
                  }
                )}

            </DetailCard>,
            <DetailCard
              title="app.hierarchy"
              id="hierarchy"
              key="hierarchy"
              width={5.33}
            >
              {renderLabel(
                translations.format("app.index"),
                mapDictionary(
                  sparePart.categoryHierarchyIndex,
                  dictionaries["spare-part-hierarchy-index"]
                )
              )}
              {renderLabel(
                translations.format("app.segment"),
                mapDictionary(
                  sparePart.categoryHierarchySegment,
                  dictionaries["spare-part-hierarchy-segment"]
                )
              )}
              {renderLabel(
                translations.format("app.slot"),
                mapDictionary(
                  sparePart.categoryHierarchySlot,
                  dictionaries["spare-part-hierarchy-slot"]
                )
              )}
              {renderLabel(
                translations.format("app.MKT-family"),
                mapDictionary(
                  sparePart.categoryHierarchyFamily,
                  dictionaries["spare-part-hierarchy-family"]
                )
              )}
            </DetailCard>,
            <DetailCard
              title="app.customer.logistic"
              id="logistics"
              key="logistics"
              width={5.33}
            >
              {renderLabel(
                translations.format("app.packing-unit.UC"),
                sparePart.packingQuantity
              )}
              {renderLabel("CTU", sparePart.packingCtu)}
              {renderLabel(translations.format("app.customs-tariff-code.TARIC"), sparePart.packingTaric)}
              {renderLabel(translations.format("app.storage-limit"), sparePart.storageLimit)}
              {renderLabel(translations.format("app.storage-period"), sparePart.storageDuration)}
              {renderSelect("gtuCode", mapGTUCodes(), {
                label: "GTU",
                description: translations.format("app.GTU-code"),
              })}
              {renderSelect(
                "gtuCodeUpdateStrategy",
                dictionariesStatic.GTU_CODES_AUTOMATIC_UPDATE,
                {
                  label: translations.format("app.GTU-code-update"),
                  description:
                    translations.format("app.GTU-code-update.description"),
                }
              )}
            </DetailCard>,
            <DetailCard
              title="app.package-dimensions"
              id="packing-dimensions"
              key="dimensions"
              width={5.33}
            >
              {renderLabel(
                translations.format("app.packing-weight"),
                sparePart.packingWeight
              )}
              {renderLabel(
                translations.format("app.packing-dimensions-volume"),
                sparePart.packingDimensionsVolume
              )}
              {renderLabel(
                translations.format("app.packing-dimensions-length"),
                sparePart.packingDimensionsLength
              )}
              {renderLabel(
                translations.format("app.packing-dimensions-width"),
                sparePart.packingDimensionsWidth
              )}
              {renderLabel(
                translations.format("app.packing-dimensions-height"),
                sparePart.packingDimensionsHeight
              )}
            </DetailCard>,
            <DetailCard
              title="app.unit-dimensions"
              id="unit-dimensions"
              key="unit-dimensions"
              width={5.33}
            >
              {renderToggle("sendUnitDimensions", {
                label: translations.format("app.should-send-unit-dimensions"),
              })}
              {renderField("unitWeight", {
                type: "number",
                label: translations.format("app.unit-weight"),
                precision: 3,
              })}
              {renderField("unitDimensionsVolume", {
                type: "number",
                label: translations.format("app.unit-dimensions-volume"),
                precision: 3,
              })}
              {renderField("unitDimensionsLength", {
                type: "number",
                label: translations.format("app.unit-dimensions-length"),
                precision: 3,
              })}
              {renderField("unitDimensionsWidth", {
                type: "number",
                label: translations.format("app.unit-dimensions-width"),
                precision: 3,
              })}
              {renderField("unitDimensionsHeight", {
                type: "number",
                label: translations.format("app.unit-dimensions-height"),
                precision: 3,
              })}
            </DetailCard>,
            <DetailCard
              title="app.sent100"
              id="sent100"
              key="sent100"
              width={5.33}
            >
              {renderToggle("packingIsSent100", {
                label: translations.format("app.applies-to-SENT100"),
                description:
                  translations.format("app.applies-to-SENT100.description"),
              })}
              {renderField("packingCapacity", {
                type: "number",
                label: translations.format("app.packing-capacity"),
                description: translations.format("app.packing-capacity.description"),
              })}
            </DetailCard>,            
            <DetailCard
              title={translations.format("app.large-size-product")}
              id="packingIsLargeSize"
              key="packingIsLargeSize"
              width={5.33}
            >
              {renderToggle("packingIsLargeSize", {
                label: translations.format("app.large-size-product"),
                description:
                  translations.format("app.large-size-product.description"),
              })}
            </DetailCard>,

            <DetailCard
              title="app.replacement_solution"
              id="replacement_solution"
              key="replacement_solution"
              width={5.33}
              minHeight={150}
            >
              {renderLabel(
                translations.format("app.interchangeability-solution-code"),
                mapDictionary(
                  sparePart.replacementCategory,
                  dictionaries["spare-part-replacement-category"]
                )
              )}
              {renderLabel(translations.format("app.interchangeability-list"), sparePart.replacementListId)}
            </DetailCard>,
            <DetailCard
              title="app.replacements"
              id="replacements"
              key="replacements"
              width={10.66}
              smallWidth={16}
              minHeight={150}
            >
              <Grid columns={2} divided>
                <Grid.Row>
                  {renderReplacements(sparePart.replacements)}
                </Grid.Row>
              </Grid>
            </DetailCard>,
            <DetailCard
              title="app.replacements_history"
              id="replacements_history"
              key="replacements_history"
              width={10.66}
              smallWidth={16}
              minHeight={150}
            >
              {sparePart.replacementHistory ? (
                renderReplacementHistory([sparePart.replacementHistory])
              ) : (
                <em>{translations.format("app.interchangeability-list.no-entries")}</em>
              )}
            </DetailCard>,
            <DetailCard
              title="app.tires"
              id="tires"
              key="tires"
              width={10.66}
              smallWidth={16}
            >
              <Grid columns={2} divided stretched>
                <Grid.Row>
                  <Grid.Column>
                    {renderLabel(translations.format("app.tyre.name"), sparePart.tyreName)}
                    {renderLabel(translations.format("app.tyre.dimensions-section"), sparePart.tyreDimensionsSection)}
                    {renderLabel(translations.format("app.tyre.dimensions-height"), sparePart.tyreDimensionsHeight)}
                    {renderLabel(translations.format("app.tyre.dimensions-diameter"), sparePart.tyreDimensionsDiameter) /*lub .tyreDimensionsR*/}
                    {renderLabel(translations.format("app.tyre.parameters-speed-rating"), sparePart.tyreParametersSpeedRating)}
                    {renderLabel(translations.format("app.tyre.parameters-load-index"), sparePart.tyreParametersLoadIndex)}
                  </Grid.Column>
                  <Grid.Column>
                    {renderLabel(translations.format("app.tyre.category"), sparePart.tyreCategory)}
                    {renderLabel(translations.format("app.tyre.parameters-fuel-efficiency"), sparePart.tyreParametersFuelEfficiency)}
                    {renderLabel(translations.format("app.tyre.parameters-road-holding"), sparePart.tyreParametersRoadHolding)}
                    {renderLabel(translations.format("app.tyre.parameters-driving-noise"), sparePart.tyreParametersDrivingNoise)}
                    {renderLabel(translations.format("app.tyre.parameters-sound-class"), sparePart.tyreParametersSoundClass)}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </DetailCard>,
            <DetailCard
              title="app.adnotation"
              id="adnotation"
              key="adnotation"
              width={5.33}
            >
              {renderTextArea("notes", {
                label: translations.format("app.notes"),
                description:
                translations.format("app.adnotation.description"),
              })}
            </DetailCard>,
            <DetailCard
              title="app.system"
              id="system"
              key="system"
              width={5.33}
            >
              {renderLabel(translations.format("app.system.price-list-ID"), sparePart.systemId)}
              {renderLabel(translations.format("app.system.start-of-validity"), parseDate(sparePart.applicationDate))}
              {renderLabel(translations.format("app.system.scope-of-changes"),
                mapDictionary(
                  sparePart.categoryModification,
                  categoryModificationDictionary
                )
              )}
              {renderLabel(translations.format("app.system.price-list-update"), parseDate(sparePart.updateTime))}
              {renderLabel(translations.format("app.system.modifying-user"), sparePart.updateUsername)}
            </DetailCard>,
          ],
    [
      categoryModificationDictionary,
      dictionaries,
      mapGTUCodes,
      renderField,
      renderLabel,
      renderReplacementHistory,
      renderReplacements,
      renderSelect,
      renderTextArea,
      renderToggle,
      sparePart,
      checkDictionaryNameEnding
    ]
  );

  const cardsRendered: JSX.Element = useMemo(() => {
    const result =
      visibleCards.length === 0
        ? cards
        : cards.filter((card) => visibleCards.indexOf(card.props.id) !== -1);
    return (
      <div className="uber-content overflow-visible">
        <Grid stretched>{result}</Grid>
      </div>
    );
  }, [cards, visibleCards]);

  const renderedProductName: JSX.Element | string = useMemo(() => {
    if (!sparePart) {
      return translations.format("app.loading");
    }
    return (
      <Fragment>
        {sparePart.referenceId}{" "}
        <small>{sparePart && sparePart.designationPolish}</small>
      </Fragment>
    );
  }, [sparePart]);

  const handleSparePartUpdate = (sparePart: SparePart, response: any): void => {
    setSparePart(sparePart);
  };

  const userHaveLdcUiProductEditRole = useMemo(
    () =>
      appContext?.keycloak.hasResourceRole(
        AVAILABLE_ROLE_ENUM.LDC_PRODUCT_EDIT
      ),
    [appContext]
  );

  return (
    <Fragment>
      <SmartWrapper
        endpoint={sparePart ? `/spare-parts/${sparePart.referenceId}` : ""}
        model={sparePart}
        onUpdate={handleSparePartUpdate}
      >
        <PageHeader
          icon="tag"
          title={<>Produkt: {renderedProductName}</>}
          breadcrumb={[
            { text: <FormattedMessage id="app.list" />, link: "/products" },
            { text: <FormattedMessage id="app.details" /> },
          ]}
          buttons={[
            {
              icon: editMode ? "lock" : "edit",
              content: editMode ? (
                <FormattedMessage id="app.button.edit-disable" />
              ) : (
                <FormattedMessage id="app.button.edit" />
              ),
              onClick: toggleEditMode,
              primary: true,
              visible: userHaveLdcUiProductEditRole,
            },
          ]}
          refreshAction={() => selectSparePart(match.params.referenceId)}
          loading={loading}
        />
        <Container fluid>
          <Button
            content={translations.format("app.menu7.distrigoStore-1")}
            basic
            as={Link}
            to={`/stock-products/PL9999P?referenceWarehouseId.referenceId=${
              sparePart && sparePart.referenceId
            }`}
          />
        </Container>
        <TabSwitcher
          tabs={[
            { name: "main", title: formatMessage("app.customer.main") },
            { name: "limits", title: formatMessage("app.limits") },
            { name: "deposit", title: formatMessage("app.deposit") },
            { name: "hierarchy", title: formatMessage("app.hierarchy")},
            { name: "logistics", title: formatMessage("app.customer.logistic")},
            { name: "replacements", title: formatMessage("app.replacements") },
            { name: "tires", title: formatMessage("app.tires")},
            { name: "other", title: formatMessage("app.customer.others") },
            { name: "all", title: formatMessage("app.customer.all") },
          ]}
          onTabChange={setVisibleCards}
          priceListType={sparePart && sparePart.priceListType}
        />
        {cardsRendered}
      </SmartWrapper>
      <CommonLoader loading={loading || !sparePart} />
    </Fragment>
  );
};

const mapStateToProps = ({ spareParts, dictionaries }: ApplicationState) => {
  return {
    sparePartIn: spareParts.selected && spareParts.selected,
    loading: spareParts.loadingSparePart || dictionaries.loading,
    dictionaries,
  };
};

const mapDispatchToProps = { selectSparePart, fetchDictionary };

export default connect(mapStateToProps, mapDispatchToProps)(SparePartDetails);