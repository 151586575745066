import { action } from 'typesafe-actions'
import { ClaimsActionTypes, PaginableClaim, IClaimCreate, IClaim, CollectiveTaskData } from "./types"

export const fetchClaims = (params: any) => action(ClaimsActionTypes.FETCH_CLAIMS, params)
export const fetchClaimsSearch = (params: any) => action(ClaimsActionTypes.FETCH_CLAIMS_SEARCH, params)
export const fetchClaimsSuccess = (claims: PaginableClaim) => action(ClaimsActionTypes.FETCH_CLAIMS_SUCCESS, claims)
export const fetchClaimsSearchSuccess = (claims: PaginableClaim) => action(ClaimsActionTypes.FETCH_CLAIMS_SEARCH_SUCCESS, claims)

export const createClaim = (claim: IClaimCreate) => action(ClaimsActionTypes.CREATE_CLAIM, { claim })
export const createClaimSuccess = () => action(ClaimsActionTypes.CREATE_CLAIM_SUCCESS)
export const clearCreated = () => action(ClaimsActionTypes.CLEAR_CREATED)

export const selectClaim = (claimNumber: string) => action(ClaimsActionTypes.SELECT_CLAIM, claimNumber)
export const claimSelected = (claim: IClaim) => action(ClaimsActionTypes.SELECTED_CLAIM, claim)
export const clearSelected = () => action(ClaimsActionTypes.CLEAR_SELECTED_CLAIM)

export const getAttachment = (claimNumber: string, attachmentStoredName: string) => action(ClaimsActionTypes.GET_ATTACHMENT, { claimNumber, attachmentStoredName })
export const addAttachments = (claimNumber: string, attachments: File[]) => action(ClaimsActionTypes.ADD_ATTACHMENT, { claimNumber, attachments })
export const addAttachmentSuccess = () => action(ClaimsActionTypes.ADD_ATTACHMENT_SUCCESS)
export const clearUpload = () => action(ClaimsActionTypes.CLEAR_ATTACHMENT)
export const createClaimTransition = (claimNumber: string, statusAction: string, statusNotes: string, lines: any) => action(ClaimsActionTypes.CREATE_CLAIM_TRANSITION, { claimNumber, statusAction, statusNotes, lines })
export const createClaimTransitionSuccess = () => action(ClaimsActionTypes.CREATE_CLAIM_TRANSITION_SUCCESS)
export const clearTransitionClaim = () => action(ClaimsActionTypes.CLEAR_TRANSITION_CLAIM)

export const uploadCollectiveTask = (collectiveTaskData: CollectiveTaskData, file: File) => action(ClaimsActionTypes.UPLOAD_COLLECTIVE_TASK, { collectiveTaskData, file })
export const uploadCollectiveTaskSuccess = (claimsCreated: string[], errors: any[]) => action(ClaimsActionTypes.UPLOAD_COLLECTIVE_TASK_SUCCESS, { claimsCreated, errors })
export const clearCollectiveData = () => action(ClaimsActionTypes.CLEAR_COLLECTIVE_DATA)
export const getClaimLabel = (claimNo: string, pdfName: string) => action(ClaimsActionTypes.GET_CLAIM_LABEL, { claimNo, pdfName })

export const fetchBackBoxReport = (params: {dateFrom: string, dateTo: string}) => action(ClaimsActionTypes.FETCH_BACK_BOX_REPORT, params)
export const fetchBackBoxReportSuccess = () => action(ClaimsActionTypes.FETCH_BACK_BOX_REPORT_SUCCESS)
