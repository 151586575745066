import React from 'react';
import { Button, Popup } from 'semantic-ui-react';
import { ImportClipboardPopupProps } from '~/pages/Orders/lib/Add/constants';

const ImportClipboardPopup = ({ handleImportClipboard, order, summary }: ImportClipboardPopupProps) => (
  <Popup
    trigger={
      <Button
        circular
        basic
        icon="paste"
        onClick={handleImportClipboard}
        disabled={!order.type || summary}
        className="display-none"
      />
    }
    position="bottom right"
    content="Importuj ze schowka linie oddzielone przecinkami"
  />
);

export default ImportClipboardPopup;
