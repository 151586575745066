import React from 'react';

import { Icon } from 'semantic-ui-react';
import { TClaimAttachment } from '~/store/claims/types';
import { useRenderingFunctions } from '~/components/SmartField/hooks/useRenderingFunctions';
import { getAttachment } from '~/store/claims/actions';
import { connect } from 'react-redux';

type TProps = {
  claimNumber: string;
  attachment: TClaimAttachment;
};

type TReduxActions = {
  getAttachment: typeof getAttachment;
};

type TClaimAttachmentProps = TProps & TReduxActions;

const ClaimAttachment: React.FC<TClaimAttachmentProps> = ({ claimNumber, attachment, getAttachment }) => {
  const downloadAttachment = (storedFileName: string): void => {
    getAttachment(claimNumber, storedFileName);
  };

  const claimAttachmentIcon = (): JSX.Element => {
    return (
      <Icon
        name="file"
        size="big"
        title={'Pobierz'}
        style={{ cursor: 'pointer' }}
        onClick={() => downloadAttachment(attachment.storedFileName)}
      />
    );
  };

  const { renderLabel } = useRenderingFunctions(attachment, false);

  return <>{renderLabel(attachment.originalFileName, claimAttachmentIcon())}</>;
};

const mapDispatchToProps: TReduxActions = { getAttachment };

export default connect(null, mapDispatchToProps)(ClaimAttachment);
