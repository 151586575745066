import { Table } from "semantic-ui-react";
import UsersList from "~/pages/Administration/List";
import PaginationBar from "~/components/PaginationBar/PaginationBar";
import React from "react";
import {
  ACTION_TYPE_ENUM,
  UserTableProps,
} from "~/pages/Administration/lib/UserTableWrapper/constants";
import { ApplicationState } from "~/store";
import { connect } from "react-redux";

const UserTable = ({ users, state, setState }: UserTableProps) => {
  const onActivateUser = (id: string, name: string): void => {
    setState({
      modalOpen: true,
      confirmType: ACTION_TYPE_ENUM.ACTIVATE_USER,
      actionId: id,
      actionUserName: name,
    });
  };

  const onDeactivateUser = (id: string, name: string): void => {
    setState({
      modalOpen: true,
      confirmType: ACTION_TYPE_ENUM.DEACTIVATE_USER,
      actionId: id,
      actionUserName: name,
    });
  };

  const onRemoveUser = (id: string, name: string): void => {
    setState({
      modalOpen: true,
      confirmType: ACTION_TYPE_ENUM.REMOVE_USER,
      actionId: id,
      actionUserName: name,
    });
  };

  return (
    <Table style={{ whiteSpace: "nowrap" }} selectable>
      <Table.Header>
        <Table.Row>
          {state.columns.map(
            (column) =>
              column.projection && (
                <Table.HeaderCell key={column.name}>
                  {column.label}
                </Table.HeaderCell>
              )
          )}
          <Table.HeaderCell collapsing />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <UsersList
          users={users}
          columns={state.columns}
          onActivateUser={onActivateUser}
          onDeactivateUser={onDeactivateUser}
          onRemoveUser={onRemoveUser}
        />
      </Table.Body>
      <Table.Footer>
        <Table.Row>
          <Table.HeaderCell colSpan="100">
            <PaginationBar
              meta={{ page: 1, pages: 1, size: 15 }}
              onPageChange={() => {}}
            />
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    </Table>
  );
};

const mapStateToProps = ({ users }: ApplicationState) => ({
  users: users.list,
});

export default connect(mapStateToProps)(UserTable);
