import React from "react";
import { TLabelProps } from "~/components/SmartField/lib/types";
import { SmartPopup } from "~/components/SmartField/SmartPopup";
import { Form } from "semantic-ui-react";
import { useLabelFormatter } from "~/components/SmartField/hooks/useLabelFormatter";

/**
 * Non-editable label that renders similary to rest of Smart components.
 * May be used for sections titles or displaying non-editable properties.
 */
export const SmartLabel: React.FC<TLabelProps> = ({
  value,
  label,
  description,
  light,
  align = "left",
  labelWidth = "6",
}) => {
  const { formatLabel } = useLabelFormatter({ label, light });

  if (value) {
    return (
      <SmartPopup mouseEnterDelay={300} description={description}>
        <Form.Group className="smart-label">
          <Form.Field label={formatLabel()} width={labelWidth} />
          <Form.Field
            label={<span>{value}</span>}
            style={{ textAlign: align }}
            width="10"
          />
        </Form.Group>
      </SmartPopup>
    );
  } else {
    return (
      <SmartPopup
        mouseEnterDelay={300}
        description={description}
        style={{ marginTop: "40px" }}
      >
        <Form.Field label={formatLabel()} style={{ marginTop: "1em" }} />
      </SmartPopup>
    );
  }
};
