import { action } from 'typesafe-actions'
import {
    InvoicesActionTypes,
    PaginableInvoice,
    ICreateInvoiceCorrection,
    PaginableGoodsDispatchNotes,
    PaginableGoodsReceivedNotes,
    PaginableInternalConsumptionNotes,
    TCreateInvoiceTransport,
    PaginableInvoiceErp,
    IGoodsDispatchNote,
    IGoodsReceivedNote,
    IInternalConsumptionNote,
    IInvoiceErp,
    ICollectiveCorrectionsList,
    PaginableICollectiveDebitNoteCorrections,
    PaginableIInvoiceLines,
    IInvoiceLinesSchedulers, PaginableGoodsDispatchNotePostponed, IGoodsDispatchNotePostponed, ICollectiveMM,
} from "./types"

export const fetchInvoices = (params: any) => action(InvoicesActionTypes.FETCH_INVOICES, params)
export const fetchInvoicesSuccess = (invoices: PaginableInvoice) => action(InvoicesActionTypes.FETCH_INVOICES_SUCCESS, { invoices })

export const fetchInvoicesWithProvision = (params: any) => action(InvoicesActionTypes.FETCH_INVOICES_WITH_PROVISION, params)
export const fetchInvoicesWithProvisionSuccess = (invoicesWithProvision: PaginableInvoice) => action(InvoicesActionTypes.FETCH_INVOICES_WITH_PROVISION_SUCCESS, { invoicesWithProvision })

export const fetchInvoicesCorrections = (params: any) => action(InvoicesActionTypes.FETCH_INVOICES_CORRECTIONS, params)
export const fetchInvoicesCorrectionsSuccess = (correction: PaginableInvoice) => action(InvoicesActionTypes.FETCH_INVOICES_CORRECTIONS_SUCCESS, { correction })

export const fetchInvoicesWithDebitNotes = (params: any) => action(InvoicesActionTypes.FETCH_INVOICES_WITH_DEBIT_NOTES, params)
export const fetchInvoicesWithDebitNotesSuccess = (invoicesWithDebitNotes: PaginableInvoice) => action(InvoicesActionTypes.FETCH_INVOICES_WITH_DEBIT_NOTES_SUCCESS, { invoicesWithDebitNotes })

export const fetchGoodsDispatchNotes = (params: any) => action(InvoicesActionTypes.FETCH_GOODS_DISPATCH_NOTES, params)
export const fetchGoodsDispatchNotesSuccess = (goodsDispatchNotes: PaginableGoodsDispatchNotes) => action(InvoicesActionTypes.FETCH_GOODS_DISPATCH_NOTES_SUCCESS, { goodsDispatchNotes })

export const selectGoodsDispatchNote = (id: string) => action(InvoicesActionTypes.SELECT_GOODS_DISPATCH_NOTE, id)
export const GoodsDispatchNoteSelected = (goodsDispatchNote: IGoodsDispatchNote) => action(InvoicesActionTypes.GOODS_DISPATCH_NOTE_SELECTED, goodsDispatchNote)

export const fetchGoodsReceivedNotes = (params: any) => action(InvoicesActionTypes.FETCH_GOODS_RECEIVED_NOTES, params)
export const fetchGoodsReceivedNotesSuccess = (goodsReceivedNotes: PaginableGoodsReceivedNotes) => action(InvoicesActionTypes.FETCH_GOODS_RECEIVED_NOTES_SUCCESS, { goodsReceivedNotes })

export const selectGoodsReceivedNote = (id: string) => action(InvoicesActionTypes.SELECT_GOODS_RECEIVED_NOTE, id)
export const goodsReceivedNoteSelected = (goodsReceivedNote: IGoodsReceivedNote) => action(InvoicesActionTypes.GOODS_RECEIVED_NOTE_SELECTED, goodsReceivedNote)

export const fetchInternalConsumptionNotes = (params: any) => action(InvoicesActionTypes.FETCH_INTERNAL_CONSUMPTION_NOTES, params)
export const fetchInternalConsumptionNotesSuccess = (internalConsumptionNote: PaginableInternalConsumptionNotes) => action(InvoicesActionTypes.FETCH_INTERNAL_CONSUMPTION_NOTES_SUCCESS, { internalConsumptionNote })

export const selectInternalConsumptionNote = (id: string) => action(InvoicesActionTypes.SELECT_INTERNAL_CONSUMPTION_NOTE, id)
export const internalConsumptionNoteSelected = (internalConsumptionNote: IInternalConsumptionNote) => action(InvoicesActionTypes.INTERNAL_CONSUMPTION_NOTE_SELECTED, internalConsumptionNote)

export const fetchInvoicesErp = (params: any) => action(InvoicesActionTypes.FETCH_INVOICES_ERP, params)
export const fetchInvoicesErpSuccess = (invoicesErp: PaginableInvoiceErp) => action(InvoicesActionTypes.FETCH_INVOICES_ERP_SUCCESS, { invoicesErp })


export const fetchGoodsDispatchNotePostponed = (params: any) => action(InvoicesActionTypes.FETCH_GOOD_DISPATCH_NOTE_POSTPONED, params)
export const fetchGoodsDispatchNotePostponedSuccess = (goodsDispatchNotePostponed: PaginableGoodsDispatchNotePostponed) => action(InvoicesActionTypes.FETCH_GOOD_DISPATCH_NOTE_POSTPONED_SUCCESS, { goodsDispatchNotePostponed })


export const fetchInvoiceLines = (params: any) => action(InvoicesActionTypes.FETCH_INVOICE_LINES, params)
export const fetchInvoiceLinesSuccess = (invoiceLine: PaginableIInvoiceLines) => action(InvoicesActionTypes.FETCH_INVOICE_LINES_SUCCESS, { invoiceLine })

export const fetchInvoiceLinesSchedulers = () => action(InvoicesActionTypes.FETCH_INVOICE_LINES_SCHEDULERS)
export const fetchInvoiceLinesSchedulersSuccess = (schedulers: IInvoiceLinesSchedulers) => action(InvoicesActionTypes.FETCH_INVOICE_LINES_SCHEDULERS_SUCCESS, { schedulers })

export const fetchInvoiceSchedulers = () => action(InvoicesActionTypes.FETCH_INVOICE_SCHEDULERS)
export const fetchInvoiceSchedulersSuccess = (schedulers: IInvoiceLinesSchedulers) => action(InvoicesActionTypes.FETCH_INVOICE_SCHEDULERS_SUCCESS, { schedulers })

export const selectInvoiceErp = (id: string) => action(InvoicesActionTypes.SELECT_INVOICE_ERP, id)
export const invoiceErpSelected = (invoiceErp: IInvoiceErp) => action(InvoicesActionTypes.INVOICE_ERP_SELECTED, invoiceErp)

export const selectGoodsDispatchNotePostponed = (id: string) => action(InvoicesActionTypes.SELECT_GOOD_DISPATCH_NOTE_POSTPONED, id)
export const goodsDispatchNotePostponedSelected = (goodsDispatchNotePostponed: IGoodsDispatchNotePostponed) => action(InvoicesActionTypes.GOOD_DISPATCH_NOTE_POSTPONED_SELECTED, goodsDispatchNotePostponed)

export const createInvoiceCorrection = (invoiceNo: string, correction: ICreateInvoiceCorrection) => action(InvoicesActionTypes.CREATE_INVOICE_CORRECTION, { correction, invoiceNo })
export const createInvoiceCorrectionSuccess = () => action(InvoicesActionTypes.CREATE_INVOICE_CORRECTION_SUCCESS)

export const createInvoiceTransport = (transport: TCreateInvoiceTransport) => action(InvoicesActionTypes.CREATE_INVOICE_TRANSPORT, { transport })
export const createInvoiceTransportSuccess = () => action(InvoicesActionTypes.CREATE_INVOICE_TRANSPORT_SUCCESS)

export const getInvoicePdf = (invoiceNo: string, pdfName: string) => action(InvoicesActionTypes.GET_INVOICE_PDF, { invoiceNo, pdfName })

export const getCollectiveDebitCorrectionPdf = (id: string, pdfName: string) => action(InvoicesActionTypes.GET_COLLECTIVE_DEBIT_CORRECTION_PDF, { id, pdfName })

export const getCollectiveCorrectionPdf = (id: string, pdfName: string) => action(InvoicesActionTypes.GET_COLLECTIVE_CORRECTION_PDF, { id, pdfName })
export const previewCollectiveCorrectionPdf = (lines: ICollectiveCorrectionsList) => action(InvoicesActionTypes.PREVIEW_COLLECTIVE_CORRECTION_PDF, { lines })

export const clearCreated = () => action(InvoicesActionTypes.CLEAR_CREATED)

export const retrySent = (invoiceNo: string) => action(InvoicesActionTypes.INVOICE_RETRY_SENT, { invoiceNo })

export const fetchFinalInvoice = (invoiceNo: string) => action(InvoicesActionTypes.FETCH_INVOICE_FINAL, invoiceNo)
export const fetchFinalInvoiceSuccess = (finalInvoice: any) => action(InvoicesActionTypes.FETCH_INVOICE_FINAL_SUCCESS, finalInvoice)
export const clearFinalInvoice = () => action(InvoicesActionTypes.CLEAR_FINAL_INVOICE)

export const uploadCollectiveCorrections = (file: File) => action(InvoicesActionTypes.UPLOAD_COLLECTIVE_CORRECTIONS, file)
export const loadCollectiveCorrectionsLines = (lines: []) => action<InvoicesActionTypes.LOAD_COLLECTIVE_CORRECTIONS, any[]>(InvoicesActionTypes.LOAD_COLLECTIVE_CORRECTIONS, lines)
export const loadCollectiveCorrectionsLinesError = (error: any) => action<InvoicesActionTypes.LOAD_COLLECTIVE_CORRECTIONS_ERROR, any>(InvoicesActionTypes.LOAD_COLLECTIVE_CORRECTIONS_ERROR, error)
export const confirmCollectiveCorrectionsLines = (lines: ICollectiveCorrectionsList[]) => action<InvoicesActionTypes.CONFIRM_COLLECTIVE_CORRECTIONS, any[]>(InvoicesActionTypes.CONFIRM_COLLECTIVE_CORRECTIONS, lines)
export const clearCollectiveCorrections = () => action(InvoicesActionTypes.CLEAR_COLLECTIVE_CORRECTIONS)
export const confirmCollectiveCorrectionSuccess = () => action(InvoicesActionTypes.CONFIRM_COLLECTIVE_CORRECTIONS_SUCCESS)

export const fetchCollectiveCorrections = (params: any) => action(InvoicesActionTypes.FETCH_COLLECTIVE_CORRECTIONS, params)
export const fetchCollectiveCorrectionsSuccess = (collectiveCorrections: PaginableInvoice) => action(InvoicesActionTypes.FETCH_COLLECTIVE_CORRECTIONS_SUCCESS, { collectiveCorrections })

export const fetchCollectiveDebitNoteCorrections = (params: any) => action(InvoicesActionTypes.FETCH_COLLECTIVE_DEBIT_NOTE_CORRECTIONS, params)
export const fetchCollectiveDebitNoteCorrectionsSuccess = (collectiveDebitNoteCorrections: PaginableICollectiveDebitNoteCorrections) => action(InvoicesActionTypes.FETCH_COLLECTIVE_DEBIT_NOTE_CORRECTIONS_SUCCESS, { collectiveDebitNoteCorrections })

export const fetchDebitNotes = (params: any) => action(InvoicesActionTypes.FETCH_DEBIT_NOTES, params)
export const fetchDebitNotesSuccess = (debitNotes: PaginableInvoice) => action(InvoicesActionTypes.FETCH_DEBIT_NOTES_SUCCESS, { debitNotes })

export const fetchDebitNotesCorrections = (params: any) => action(InvoicesActionTypes.FETCH_DEBIT_NOTE_CORRECTIONS, params)
export const fetchDebitNotesCorrectionsSuccess = (debitNotesCorrections: PaginableInvoice) => action(InvoicesActionTypes.FETCH_DEBIT_NOTE_CORRECTIONS_SUCCESS, { debitNotesCorrections })

export const setTypeOfExportData = (typeOfExportData?: string) => action(InvoicesActionTypes.SET_TYPE_OF_EXPORT_DATA, typeOfExportData);

export const openChooseTypeOfExportDataModal = (params: any) => action(InvoicesActionTypes.OPEN_CHOOSE_EXPORT_DATA_MODAL, params);


export const saveQuantityToRedux = (quantity?: number) => action(InvoicesActionTypes.SAVE_QUANTITY_TO_REDUX, quantity);

export const fetchMMData = (params: any) => action(InvoicesActionTypes.FETCH_MM_DATA, params)
export const fetchMMDataSuccess = (mmData:PaginableInvoice ) => action(InvoicesActionTypes.FETCH_MM_DATA_SUCCESS, { mmData })

export const selectMM = (id: string) => action(InvoicesActionTypes.SELECT_MM, id)

export const mmSelected = (mmSelected: ICollectiveMM) => action(InvoicesActionTypes.MM_SELECTED, mmSelected)

