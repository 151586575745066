import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { Dropdown, DropdownItemProps, DropdownProps, Form } from 'semantic-ui-react';
import { ClaimType, SearchType } from './Add';
import { IInvoice } from '~/store/invoices/types';
import { DictionaryItem } from '~/store/dictionaries/types';

type Props = {
  group: string;
  invoice: string;
  loadingInvoicesWithDebitNotes: boolean;
  loadingInvoicesWithProvision: boolean;
  invoicesWithDebitNotes: IInvoice[];
  invoicesWithProvision: IInvoice[];
  changeInvoice: (event: SyntheticEvent, data: any) => void;
  searchInvoice: (event: SyntheticEvent, data: any) => void;
  searchInvoiceProvision: (event: SyntheticEvent, data: any) => void;
  resetAfterSearchType: () => void;
};

export const useSearch = (props: Props) => {
  const {
    group,
    invoice,
    loadingInvoicesWithDebitNotes,
    loadingInvoicesWithProvision,
    invoicesWithDebitNotes,
    invoicesWithProvision,
    changeInvoice,
    searchInvoice,
    searchInvoiceProvision,
    resetAfterSearchType,
  } = props;

  const [searchType, setSearchType] = useState<SearchType>();
  const [labelForSearch, setLabelForSearch] = useState<string>('Wybierz fakturę / numer paczki');
  const [placeholderForSearch, setPlaceholderForSearch] = useState<string>('');
  const [searchInputValue, setSearchInputValue] = useState<string>('');

  const options: DropdownItemProps[] = [
    { text: 'Numer faktury', value: SearchType.documentMask },
    { text: 'Numer paczki', value: SearchType.packageNumber },
  ];

  const changeSearchType = (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    resetAfterSearchType();
    setSearchType(undefined);
    setSearchInputValue('');
    setTimeout(() => setSearchType(data.value as SearchType), 100);
  };

  useEffect(() => {
    if (searchType === SearchType.documentMask) {
      setLabelForSearch('Numer faktury sprzedaży, do której jest składana reklamacja lub zwrot*');
      setPlaceholderForSearch('Wybierz fakturę');
    }
    if (searchType === SearchType.packageNumber) {
      setLabelForSearch('Numer paczki, do której jest składana reklamacja lub zwrot*');
      setPlaceholderForSearch('Wybierz numer paczki');
    }
    setMappedOptions([]);
  }, [searchType]);

  const mapInvoices = useCallback(
    (invoices: IInvoice[]): DictionaryItem[] => {
      const items = invoices.map((invoiceItem) => ({
        key: invoiceItem.invoiceNo,
        text:
          searchType === SearchType.documentMask
            ? invoiceItem.documentMask
            : `${invoiceItem.documentMask} | "${searchInputValue}"`,
        value: invoiceItem.invoiceNo,
      }));
      return items;
    },
    [searchInputValue, searchType]
  );

  useEffect(() => {
    setMappedOptions(mapInvoices(invoicesWithDebitNotes));
  }, [invoicesWithDebitNotes, mapInvoices]);

  useEffect(() => {
    setMappedOptions(mapInvoices(invoicesWithProvision));
  }, [invoicesWithProvision, mapInvoices]);

  const [mappedOptions, setMappedOptions] = useState<DictionaryItem[]>([]);

  const handleSearchInvoice = useCallback(
    (event: SyntheticEvent, data: any) => {
      setSearchInputValue(data.searchQuery);
      if (group === ClaimType.realtedToInvoice) {
        searchInvoice(event, data);
      } else {
        searchInvoiceProvision(event, data);
      }
    },
    [searchInvoice, searchInvoiceProvision, group]
  );

  const SearchFormField = (
    <Form.Group widths={'equal'}>
      <Form.Field disabled={!(group === ClaimType.realtedToInvoice || group === ClaimType.provision)}>
        <label>Wybierz rodzaj wyszukiwania</label>
        <Dropdown
          placeholder="Wybierz rodzaj wyszukiwania"
          fluid
          selection
          value={searchType}
          options={options}
          onChange={changeSearchType}
          disabled={loadingInvoicesWithDebitNotes || loadingInvoicesWithProvision}
        />
      </Form.Field>

      <Form.Field disabled={!(group === ClaimType.realtedToInvoice || group === ClaimType.provision)}>
        <label>{labelForSearch}</label>
        {searchType === SearchType.packageNumber ? (
          <Dropdown
            placeholder={placeholderForSearch}
            fluid
            search
            selection
            value={invoice}
            onChange={changeInvoice}
            onSearchChange={handleSearchInvoice}
            loading={
              group === ClaimType.realtedToInvoice ? loadingInvoicesWithDebitNotes : loadingInvoicesWithProvision
            }
            options={mappedOptions}
            disabled={!searchType}
          />
        ) : (
          <Dropdown
            placeholder={placeholderForSearch}
            fluid
            search
            selection
            value={invoice}
            onChange={changeInvoice}
            onSearchChange={handleSearchInvoice}
            loading={
              group === ClaimType.realtedToInvoice ? loadingInvoicesWithDebitNotes : loadingInvoicesWithProvision
            }
            options={mappedOptions}
            disabled={!searchType}
          />
        )}
      </Form.Field>
    </Form.Group>
  );

  return { SearchFormField, searchType };
};
