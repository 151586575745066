import { TColumn } from "~/components/Table/lib/types";
import dictionariesStatic from "~/store/dictionaries/static";
import { DictionaryName } from "~/store/dictionaries/types";
import React, { useCallback } from "react";
import { Label, Table } from "semantic-ui-react";
import { sourceColorMap, sourceNameMap } from "../lib/helpers";
import { useCellsRenderer } from "~/services/useCellRenderer";
// import {
//   getAmountCell,
//   getDateCell,
//   getDictionaryCell,
//   getLinkCell,
//   getTextCell,
// } from "~/services/CellRenderer";

export const useOrdersColumns = () => {
  const {
    getTextCell,
    getDateCell,
    getAmountCell,
    getDictionaryCell,
    getLinkCell,
  } = useCellsRenderer();

  const getSourceCell = useCallback(
    (source: string) => (
      <Table.Cell key={"source-cell"}>
        <Label basic size="tiny" color={sourceColorMap[source] || "grey"}>
          {sourceNameMap[source] || "?"}
        </Label>
      </Table.Cell>
    ),
    []
  );

  const getSupplierSystemCell = useCallback(
    (order: any, column: any, fieldName: string): JSX.Element => (
      <Table.Cell key={column.name}>
        {order.deliveryCustomer.supplierSystem
          ? order.deliveryCustomer.supplierSystem[0][fieldName]
          : ""}
      </Table.Cell>
    ),
    []
  );

  const columnsConfig: TColumn[] = [
    {
      name: "source",
      label: "Źródło",
      dictionary: dictionariesStatic.ORDER_SOURCE,
      projection: true,
      noKey: true,
      getCell: (document) => getSourceCell(document.source),
    },
    {
      name: "orderNumber",
      label: "Nr LDC",
      projection: true,
      getCell: (document, column) => getTextCell(document, column),
    },
    {
      name: "serviceBoxOrderNo",
      label: "Nr SB",
      projection: true,
      getCell: (document, column) => getTextCell(document, column),
    },
    {
      name: "orderDate",
      label: "Utworzone",
      projection: true,
      type: "date",
      getCell: (document, column) => getDateCell(document, column, true),
    },
    {
      name: "customer.rrid",
      label: "Płatnik",
      projection: true,
      getCell: (document, column) =>
        getLinkCell(document, column, `/customer/${document.customer.rrid}`),
    },
    {
      name: "deliveryCustomer.rrid",
      label: "Odbiorca",
      projection: true,
      getCell: (document, column) =>
        getLinkCell(
          document,
          column,
          `/customer/${document.deliveryCustomer.rrid}`
        ),
    },
    {
      name: "deliveryCustomer.ddsDeliveryId",
      label: "OV Odbiorca",
      projection: true,
      getCell: (document, column) => getTextCell(document, column),
    },
    {
      name: "deliveryCustomer.name",
      label: "Nazwa odbiorcy",
      projection: true,
      getCell: (document, column) => getTextCell(document, column),
    },
    {
      name: "deliveryCustomer.street",
      label: "Adres odbiorcy",
      projection: true,
      getCell: (document, column) => getTextCell(document, column),
    },
    {
      name: "deliveryCustomer.supplierSystem.businessSector",
      label: "Dostawca",
      projection: false,
      hide: true,
      filteringBlocked: true,
      getCell: (document, column) =>
        getSupplierSystemCell(document, column, "bussinesSystem"),
    },
    {
      name: "deliveryCustomer.supplierSystem.clientId",
      label: "Sincom",
      projection: false,
      hide: true,
      filteringBlocked: true,
      getCell: (document, column) =>
        getSupplierSystemCell(document, column, "clientId"),
    },
    {
      name: "deliveryCustomer.supplierSystem.deliveryId",
      label: "Punkt dostawy",
      projection: false,
      hide: true,
      filteringBlocked: true,
      getCell: (document, column) =>
        getSupplierSystemCell(document, column, "deliveryId"),
    },
    {
      name: "type",
      dictionary: dictionariesStatic.ORDER_TYPE,
      label: "Typ",
      projection: true,
      noKey: true,
      getCell: (document, column) => getDictionaryCell(document, column),
    },
    {
      name: "deliveryType",
      dictionaryName: DictionaryName.orderDeliveryType,
      label: "Dostawa",
      projection: true,
      noKey: true,
      getCell: (document, column) => getDictionaryCell(document, column),
    },
    {
      name: "priority",
      label: "Priorytet",
      projection: true,
      getCell: (document, column) => getTextCell(document, column),
    },
    {
      name: "status",
      dictionaryName: DictionaryName.orderStatus,
      label: "Status",
      projection: true,
      noKey: true,
      getCell: (document, column) => getDictionaryCell(document, column),
    },
    {
      name: "linesCount",
      label: "Linie",
      projection: true,
      getCell: (document, column) => getTextCell(document, column),
    },
    {
      name: "realisationPercent",
      label: "Realizacja",
      projection: true,
      getCell: (document, column) =>
        getTextCell(document, column, `${document[column.name]}%`),
    },
    {
      name: "priceNetTotal",
      label: "Wartość netto",
      projection: true,
      getCell: (document, column) => getAmountCell(document, column),
    },
    {
      name: "priceGrossTotal",
      label: "Wartość brutto",
      projection: true,
      getCell: (document, column) => getAmountCell(document, column),
    },
  ];

  const columnsWithInvoiceLineConfig: TColumn[] = [
    {
      name: "source",
      label: "Źródło",
      dictionary: dictionariesStatic.ORDER_SOURCE,
      projection: true,
      noKey: true,
    },
    {
      name: "orderNumber",
      label: "Nr LDC",
      projection: true,
    },
    {
      name: "serviceBoxOrderNo",
      label: "Nr SB",
      projection: true,
    },
    {
      name: "orderDate",
      label: "Utworzone",
      projection: true,
      type: "date",
    },
    { name: "customer.rrid", label: "Płatnik", projection: true },
    {
      name: "deliveryCustomer.rrid",
      label: "Odbiorca",
      projection: true,
    },
    {
      name: "deliveryCustomer.name",
      label: "Nazwa odbiorcy",
      projection: true,
    },
    {
      name: "deliveryCustomer.street",
      label: "Adres odbiorcy",
      projection: true,
    },
    {
      name: "type",
      dictionary: dictionariesStatic.ORDER_TYPE,
      label: "Typ",
      projection: true,
      noKey: true,
    },
    {
      name: "deliveryType",
      dictionaryName: DictionaryName.orderDeliveryType,
      label: "Dostawa",
      projection: true,
      noKey: true,
    },
    {
      name: "paymentMethod",
      dictionaryName: DictionaryName.paymentMethod,
      label: "Płatność",
      projection: true,
      noKey: true,
    },
    {
      name: "priority",
      label: "Priorytet",
      projection: true,
    },
    {
      name: "status",
      dictionaryName: DictionaryName.orderStatus,
      label: "Status",
      projection: true,
      noKey: true,
    },
    { name: "linesCount", label: "Linie", projection: true },
    { name: "realisationPercent", label: "Realizacja", projection: true },
    { name: "netPrice", label: "Wartość netto", projection: false },
    { name: "taxPercent", label: "VAT", projection: false },
    { name: "referenceID", label: "Referencja", projection: false },
    { name: "quantity", label: "Ilość", projection: false },
    {
      name: "netPriceListPrice",
      label: "Wartość netto na linii",
      projection: false,
    },
  ];

  return { columnsConfig, columnsWithInvoiceLineConfig };
};
