import DetailCard from '~/components/DetailCard/DetailCard';
import React from 'react';

import { DetailCardServiceProps } from '~/pages/Orders/Details/lib/constants';
import { DATE } from '~/components/SmartField/lib/validator';
import { Form } from 'semantic-ui-react';
import { SmartLabel } from '~/components/SmartField/SmartLabel';
import { options } from '~/pages/Orders/Details/constants';
import { OrderStatusEnum } from '~/store/orders/types';

const DetailCardService = ({
  shrink,
  editMode,
  order,
  renderLabel,
  renderField,
  renderTextArea,
  isStatus,
  handlePrioritetUpdate,
}: DetailCardServiceProps) => (
  <DetailCard title="app.staff" id="service" key="service" minHeight={40} width={shrink ? 2.75 : 4} smallWidth={5.33}>
    {!editMode && order?.deliveryDate == null ? (
      <>
        {renderLabel('Planowana data realizacji')}
        <span style={{ display: 'block', marginBottom: '15px' }}>n/d</span>
      </>
    ) : (
      order &&
      renderField('deliveryDate', {
        label: 'Planowana data realizacji',
        type: 'date',
        validators: [DATE],
        readOnly: !isStatus(
          order.status,
          OrderStatusEnum.WAITING_FOR_CUSTOMER_LIMIT,
          OrderStatusEnum.WAITING_FOR_MANUAL_ACCEPTANCE
        ),
      })
    )}
    {order &&
      renderLabel(
        'Priorytetowe zamówienie',
        <Form.Select fluid value={order.prioritized} options={options} onChange={handlePrioritetUpdate} />
      )}
    {order &&
      renderTextArea('serviceNotes', {
        label: 'Notatki',
        rows: 2,
        description: 'Uwagi dotyczące obsługi zamówienia. Maksymalnie 500 znaków',
        readOnly: !isStatus(
          order.status,
          OrderStatusEnum.WAITING_FOR_CUSTOMER_LIMIT,
          OrderStatusEnum.WAITING_FOR_MANUAL_ACCEPTANCE
        ),
        style: {
          marginBottom: 15,
        },
      })}
    <SmartLabel label="Utworzone przez" value={order?.author} />
    <SmartLabel label="Zaakceptowane przez" value={order?.acceptingUser} />
  </DetailCard>
);

export default DetailCardService;
