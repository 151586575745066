import React, { useCallback, useEffect, useState } from "react";
import PageHeader from "~/components/PageHeader/PageHeader";
import translations from "~/utils/translations";
import { DictionariesState, DictionaryName } from "~/store/dictionaries/types";
import { connect } from "react-redux";
import { fetchDictionary } from "~/store/dictionaries/actions";
import { ApplicationState, ConnectedReduxProps } from "~/store";
import "./accounting.scss";
import { useAccountingBreadcrumbs } from "./hooks/useAccountingBreadcrumbs";
import Corrections from "./Tabs/Corrections/Corrections";
import Invoices from "./Tabs/Invoices/Invoices";
import { useAccountingButtons } from "./hooks/useAccountingButtons";
import DebitNotes from "./Tabs/DebitNotes/DebitNotes";
import DebitNotesCorrections from "./Tabs/DebitNotesCorrections/DebitNotesCorrections";
import { openChooseTypeOfExportDataModal } from "~/store/invoices/actions";
import CollectiveInvoiceCorrections from "./Tabs/CollectiveInvoiceCorrections/CollectiveInvoiceCorrections";
import CollectiveDebitNoteCorrections from "./Tabs/CollectiveDebitNoteCorrections/CollectiveDebitNoteCorrections";
import GoodsDispatchNotes from "./Tabs/GoodsDispatchNotes/GoodsDispatchNotes";
import GoodsReceivedNotes from "./Tabs/GoodsReceivedNotes/GoodsReceivedNotes";
import InventoryTransfer from "./Tabs/InventoryTransfer/InventoryTransfer";
import InternalConsumptions from "./Tabs/InternalConsumptions/InternalConsumptions";
import InvoicesErp from "./Tabs/InvoicesErp/InvoicesErp";
import ItemsToInvoice from "./Tabs/ItemsToInvoice/ItemsToInvoice";
import {
  IInvoiceLinesSchedulers,
  IInvoiceSchedulers,
} from "~/store/invoices/types";
import GoodsDispatchNotePostponed from "./Tabs/GoodsDispatchNotePostponed/GoodsDispatchNotePostponed";

export enum AccountingBreadcrumbs {
  INVOICES,
  CORRECTIONS,
  DEBIT_NOTES,
  DEBIT_NOTES_CORRECTIONS,
  COLLECTIVE_CORRECTIONS_LIST,
  COLLECTIVE_DEBIT_NOTE_CORRECTIONS,
  WZ,
  PZ,
  MM,
  RW,
  SOURCE_DOCUMENTS,
  ITEMS_TO_INVOICE,
  SENT100,
}

type TReduxState = {
  dictionaries: DictionariesState;
  invoiceLinesSchedulers: IInvoiceLinesSchedulers;
  gdnPositionsSchedulers: IInvoiceSchedulers;
};

interface TReduxActions {
  fetchDictionary: typeof fetchDictionary;
  openChooseTypeOfExportDataModal: typeof openChooseTypeOfExportDataModal;
}

export type AccountingProps = TReduxState & TReduxActions & ConnectedReduxProps;

const Accounting: React.FC<AccountingProps> = ({
  fetchDictionary,
  openChooseTypeOfExportDataModal,
  invoiceLinesSchedulers,
  gdnPositionsSchedulers,
}) => {
  const [activeTab, setActiveTab] = useState<AccountingBreadcrumbs>(
    AccountingBreadcrumbs.INVOICES
  );
  const [refreshTrigger, setRefreshTrigger] = useState<number>(0);
  const [disableExportButton, setDisableExportButton] =
    useState<boolean>(false);
  const [percenExportButton, setPercenExportButton] = useState<number>(0);

  const [openAddCorrectionModal, setOpenAddCorrectionModal] =
    useState<boolean>(false);
  const [openBulkCorrectionsModal, setOpenBulkCorrectionsModal] =
    useState<boolean>(false);
  const [openAddTransportFVModal, setOpenAddTransportFVModal] =
    useState<boolean>(false);

  const breadcrumbs = useAccountingBreadcrumbs({ setActiveTab });

  const handleExportClick = useCallback(() => {
    openChooseTypeOfExportDataModal(true);
  }, [openChooseTypeOfExportDataModal]);

  const handleRefresh = useCallback(() => {
    setRefreshTrigger((refreshTrigger) => refreshTrigger + 1);
  }, [setRefreshTrigger]);

  const buttons = useAccountingButtons({
    disableExportButton,
    handleExportClick,
    percenExportButton,
    activeTab,
    setOpenAddCorrectionModal,
    setOpenBulkCorrectionsModal,
    setOpenAddTransportFVModal,
    gdnPositionsSchedulers,
    invoiceLinesSchedulers,
  });

  useEffect(() => {
    document.title = translations.format("LDC_ACCOUNTING");
    fetchDictionary(
      DictionaryName.orderDeliveryType,
      DictionaryName.grnType,
      DictionaryName.gdnType,
      DictionaryName.warehouseMovementStatus,
      DictionaryName.itemToInvoiceCategory,
      DictionaryName.itemToInvoiceStatus,
      DictionaryName.gdnPostponedPartStatus,
      DictionaryName.paymentMethod
    );
  }, [fetchDictionary]);

  return (
    <>
      <PageHeader
        icon="briefcase"
        title="Księgowość"
        breadcrumb={breadcrumbs}
        buttons={buttons}
        refreshAction={() => handleRefresh()}
        breadCrumbTab={activeTab}
        breadCrumbStyles={`accounting-crumbs`}
        loading={false}
      />
      {activeTab === AccountingBreadcrumbs.INVOICES && (
        <Invoices
          refreshTrigger={refreshTrigger}
          setDisableExportButton={setDisableExportButton}
          setPercenExportButton={setPercenExportButton}
        />
      )}
      {activeTab === AccountingBreadcrumbs.CORRECTIONS && (
        <Corrections
          refreshTrigger={refreshTrigger}
          setDisableExportButton={setDisableExportButton}
          setPercenExportButton={setPercenExportButton}
          openAddCorrectionModal={openAddCorrectionModal}
          setOpenAddCorrectionModal={setOpenAddCorrectionModal}
        />
      )}
      {activeTab === AccountingBreadcrumbs.DEBIT_NOTES && (
        <DebitNotes
          refreshTrigger={refreshTrigger}
          setDisableExportButton={setDisableExportButton}
          setPercenExportButton={setPercenExportButton}
        />
      )}
      {activeTab === AccountingBreadcrumbs.DEBIT_NOTES_CORRECTIONS && (
        <DebitNotesCorrections
          refreshTrigger={refreshTrigger}
          setDisableExportButton={setDisableExportButton}
          setPercenExportButton={setPercenExportButton}
          openAddCorrectionModal={openAddCorrectionModal}
          setOpenAddCorrectionModal={setOpenAddCorrectionModal}
        />
      )}
      {activeTab === AccountingBreadcrumbs.COLLECTIVE_CORRECTIONS_LIST && (
        <CollectiveInvoiceCorrections
          refreshTrigger={refreshTrigger}
          setDisableExportButton={setDisableExportButton}
          setPercenExportButton={setPercenExportButton}
          openBulkCorrectionsModal={openBulkCorrectionsModal}
          setOpenBulkCorrectionsModal={setOpenBulkCorrectionsModal}
        />
      )}
      {activeTab ===
        AccountingBreadcrumbs.COLLECTIVE_DEBIT_NOTE_CORRECTIONS && (
        <CollectiveDebitNoteCorrections
          refreshTrigger={refreshTrigger}
          setDisableExportButton={setDisableExportButton}
          setPercenExportButton={setPercenExportButton}
          openBulkCorrectionsModal={openBulkCorrectionsModal}
          setOpenBulkCorrectionsModal={setOpenBulkCorrectionsModal}
        />
      )}
      {activeTab === AccountingBreadcrumbs.WZ && (
        <GoodsDispatchNotes
          refreshTrigger={refreshTrigger}
          setDisableExportButton={setDisableExportButton}
          setPercenExportButton={setPercenExportButton}
        />
      )}
      {activeTab === AccountingBreadcrumbs.PZ && (
        <GoodsReceivedNotes
          refreshTrigger={refreshTrigger}
          setDisableExportButton={setDisableExportButton}
          setPercenExportButton={setPercenExportButton}
        />
      )}
      {activeTab === AccountingBreadcrumbs.MM && (
        <InventoryTransfer
          refreshTrigger={refreshTrigger}
          setDisableExportButton={setDisableExportButton}
          setPercenExportButton={setPercenExportButton}
        />
      )}
      {activeTab === AccountingBreadcrumbs.RW && (
        <InternalConsumptions
          refreshTrigger={refreshTrigger}
          setDisableExportButton={setDisableExportButton}
          setPercenExportButton={setPercenExportButton}
        />
      )}
      {activeTab === AccountingBreadcrumbs.SOURCE_DOCUMENTS && (
        <InvoicesErp
          refreshTrigger={refreshTrigger}
          openAddTransportFVModal={openAddTransportFVModal}
          setOpenAddTransportFVModal={setOpenAddTransportFVModal}
          setDisableExportButton={setDisableExportButton}
          setPercenExportButton={setPercenExportButton}
        />
      )}
      {activeTab === AccountingBreadcrumbs.ITEMS_TO_INVOICE && (
        <ItemsToInvoice
          refreshTrigger={refreshTrigger}
          setDisableExportButton={setDisableExportButton}
          setPercenExportButton={setPercenExportButton}
        />
      )}
      {activeTab === AccountingBreadcrumbs.SENT100 && (
        <GoodsDispatchNotePostponed
          refreshTrigger={refreshTrigger}
          setDisableExportButton={setDisableExportButton}
          setPercenExportButton={setPercenExportButton}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ dictionaries, invoices }: ApplicationState) => ({
  dictionaries,
  gdnPositionsSchedulers: invoices.gdnPositionsSchedulers,
  invoiceLinesSchedulers: invoices.invoiceLinesSchedulers,
});
const mapDispatchToProps = {
  fetchDictionary,
  openChooseTypeOfExportDataModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(Accounting);
