import React, { Fragment, LegacyRef, SyntheticEvent, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { Button, Container, Form, Grid, GridColumn, Header, Modal, Popup, Table } from 'semantic-ui-react';

import DetailCard from '~/components/DetailCard/DetailCard';
import { FormInputNumber, InputNumberOnChangeData } from '~/components/InputNumber/InputNumber';
import CommonLoader from '~/components/Loaders/CommonLoader';
import UberSearch from '~/components/UberSearch/UberSearch';
import { ApplicationState } from '~/store';
import { DistrigoWarehouse, StockWarehouse, TWareLine } from '~/store/distrigo-warehouses/types';

import './MoveWareContent.scss';

type MoveWareContentProps = {
    addLine: () => void,
    changeQuantity: (lineNumber: number, currentValue: number, round: boolean) => void,
    changeReferenceId: (lineNumber: number, data: TWareLine) => string,
    clearDeliveryWareValue: (key: SyntheticEvent) => void,
    clearReceiveWareValue: (key: SyntheticEvent) => void,
    closeMoveWareModal: () => void,
    confirmCancel?: boolean,
    deliveryWareValue?: string | number | boolean | (string | number | boolean)[],
    handleDeliveryWarehouseChange: (key: SyntheticEvent, data: string | number | boolean | (string | number | boolean)[] | undefined) => void,
    handleReceiveWarehouseChange: (key: SyntheticEvent, data: string | number | boolean | (string | number | boolean)[] | undefined) => void,
    handleSubmit: () => void,
    lines: TWareLine[],
    maximized?: boolean,
    open?: boolean,
    openConfirmModal?: boolean,
    openMoveWareModal?: boolean,
    setCancel: (key: boolean) => void,
    setConfirmCancel: (key: boolean) => void,
    setOpenConfirmModal: (key: boolean) => void,
    receiveWareValue?: string | number | boolean | (string | number | boolean)[],
    removeLine: (key: TWareLine) => void,
    tableEl?: LegacyRef<HTMLDivElement>,
    toggleMaximized: () => void,
    validNumber: (key: number) => boolean
}

type TReduxState = {
    loading?: boolean,
    distrigoWarehouses?: DistrigoWarehouse[],
    distrigoStockWarehouses?: StockWarehouse[]
}

type TMoveWareContentProps = MoveWareContentProps & TReduxState

const MoveWareContent = ({
    addLine, changeQuantity, changeReferenceId,
    clearDeliveryWareValue, clearReceiveWareValue, closeMoveWareModal, confirmCancel,
    deliveryWareValue, distrigoWarehouses, distrigoStockWarehouses,
    handleDeliveryWarehouseChange, handleReceiveWarehouseChange, handleSubmit, lines,
    loading, maximized, open, openMoveWareModal, openConfirmModal, setOpenConfirmModal,
    receiveWareValue, removeLine, setCancel,
    setConfirmCancel, tableEl, toggleMaximized, validNumber
}: TMoveWareContentProps) => {

    const mapDistrigoWarehouses = useCallback((items: any[]) => {
        if (distrigoWarehouses && distrigoWarehouses.length) {
            return items.map(distrigoWarehouse => ({
                key: distrigoWarehouse.id,
                text: `${distrigoWarehouse.id} ${distrigoWarehouse.name}`,
                value: `${distrigoWarehouse.id} ${distrigoWarehouse.name}`
            }))
        }
    }, [distrigoWarehouses]);

    const mapDistrigoStockWarehouse = (items: TWareLine[]): any[] => {
        return items.map(item => ({
            item,
            key: item.referenceWarehouseId.referenceId,
            text: item.referenceWarehouseId.referenceId,
            title: item.designationPolish,
            value: item.referenceWarehouseId.referenceId
        }))
    }

    const isValidForm = useCallback((): string | undefined => {
        if (!deliveryWareValue) {
            return "Nie wybrano magazynu dostawczego."
        }

        if (!receiveWareValue) {
            return "Nie wybranego magazybnu odbiorczego."
        }

        if (!lines.length) {
            return "Zamówienie musi mieć co najmniej jedną pozycję."
        }

        const invalidQuantity = lines.some(line => isNaN(Number(line.quantity)))
        if (invalidQuantity) {
            return "Ilość części musi być wartością liczbową."
        }

        const someInvalid = lines.some(line => !line.partID)
        if (someInvalid) {
            return "Przesunięcie produktu nie może zawierać pustej linii."
        }
    }, [deliveryWareValue, lines, receiveWareValue]);

    const errorMessage = isValidForm();

    const productStockInfo = (line: any): JSX.Element | null => {
        return (
            line.available !== undefined ?
                <label style={{
                    color: '#9f9f9f',
                    fontSize: '12px',
                    marginTop: '-10px',
                    display: 'block'
                }}>Dostępne: <strong>{line.available}</strong></label> :
                null
        )
    }

    return useMemo(() => (
        <Fragment>
            <Modal
                className="MoveModal"
                closeOnDimmerClick={false}
                open={open}
                size='small'
            >
                <Header icon="add" content="Przesuń produkt"/>
                <Modal.Content>
                    <Container className="Container">
                        <Form>
                            <div className="WareSection">
                                <Form.Group widths='equal'>
                                    <div className="WareDeliveryHeader">Magazyn dostawczy</div>
                                    <div className="WareSelect">
                                        <Form.Dropdown
                                            className="ModalDropdown"
                                            id="deliveryWarehouse"
                                            onChange={(e, data) => handleDeliveryWarehouseChange(e, data.value)}
                                            options={distrigoWarehouses ? mapDistrigoWarehouses(distrigoWarehouses) : []}
                                            placeholder="Wybierz magazyn dostawczy"
                                            search
                                            selection
                                            value={deliveryWareValue}
                                        />
                                        <Button
                                            onClick={(e, data) => clearDeliveryWareValue(data.value)}>Wyczyść</Button>
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="WareSection">
                                <Form.Group widths='equal'>
                                    <div className="WareReceiveHeader">Magazyn odbiorczy</div>
                                    <div className="WareReceiveSelect">
                                        <Form.Dropdown
                                            className="ModalDropdown"
                                            disabled={!deliveryWareValue}
                                            id="receiveWarehouse"
                                            onChange={(e, data) => handleReceiveWarehouseChange(e, data.value)}
                                            options={mapDistrigoWarehouses(distrigoWarehouses || [])}
                                            placeholder="Wybierz magazyn odbiorczy"
                                            search
                                            selection
                                            value={receiveWareValue}
                                        />
                                        <Button className="WareReceiveClearNutton"
                                                onClick={(e, data) => clearReceiveWareValue(data.value)}>Wyczyść</Button>
                                    </div>
                                </Form.Group>
                            </div>
                            <DetailCard title="app.warehouse.partLines" id="partLines" key="partLines" minHeight={40}
                                        width={16}>
                                <Grid columns="2" style={{ marginBottom: 0 }}>
                                    <Grid.Row>
                                        <Grid.Column verticalAlign="bottom">
                                            Ilość: {lines.length}
                                        </Grid.Column>
                                        <Grid.Column textAlign="right">
                                            <Popup
                                                trigger={
                                                    <Button circular basic
                                                            icon={maximized ? 'window restore outline' : 'expand arrows alternate'}
                                                            onClick={toggleMaximized}/>
                                                }
                                                position="bottom right"
                                                content={maximized ? "Minimalizuj widok tabeli" : "Maksymalizuj widok tabeli"}/>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                                <div className="uber-table no-wrap" ref={tableEl}
                                     style={{ maxHeight: `calc(100vh - ${maximized ? 300 : 585}px)`, minHeight: 200 }}>
                                    <Table>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell collapsing>Nr</Table.HeaderCell>
                                                <Table.HeaderCell>Id części</Table.HeaderCell>
                                                <Table.HeaderCell>Ilość</Table.HeaderCell>
                                                <Table.HeaderCell>Usuń</Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                            {lines.map(line =>
                                                <Table.Row key={line.lineNumber}>
                                                    <Table.Cell collapsing>{line.lineNumber}</Table.Cell>
                                                    <Table.Cell style={{ overflow: 'visible', maxWidth: 'none' }}>
                                                        <div style={{ width: 200, display: 'inline-block' }}>
                                                            <UberSearch
                                                                key={line.partID}
                                                                fluid
                                                                style={{ display: 'inline-block' }}
                                                                aligned="left"
                                                                endpoint={`/stock/${String(deliveryWareValue).substring(0, 7)}`}
                                                                searchParameterName="referenceWarehouseId.referenceId"
                                                                mapper={(response) => mapDistrigoStockWarehouse(response.content)}
                                                                onResultSelect={(selected: TWareLine | any) => changeReferenceId(line.lineNumber, selected)}
                                                                value={line.partID}
                                                            />
                                                        </div>
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        <FormInputNumber
                                                            min={0}
                                                            max={line.available}
                                                            fluid
                                                            style={{ width: 100, opacity: 1 }}
                                                            value={validNumber(Number(line.quantity)) ? line.quantity : 0}
                                                            onBlur={(event: any) => changeQuantity(line.lineNumber, Number(line.quantity), true)}
                                                            onChange={(e: any, d: InputNumberOnChangeData) => changeQuantity(line.lineNumber, d.value, false)}
                                                        />
                                                        {productStockInfo(line)}
                                                    </Table.Cell>
                                                    <Table.Cell className="col-dropdown-menu-sticky">
                                                        <Popup trigger={<Button compact circular basic icon="remove"
                                                                                style={{ marginRight: 0 }}
                                                                                onClick={() => removeLine(line)}/>}
                                                               content="Usuń linię z dostawy"/>
                                                    </Table.Cell>
                                                </Table.Row>
                                            )}
                                        </Table.Body>
                                        <Table.Footer>
                                            <Table.Row>
                                                <Table.HeaderCell colSpan='100'/>
                                            </Table.Row>
                                        </Table.Footer>
                                    </Table>
                                </div>
                                <Grid columns={2} style={{ marginTop: 0 }}>
                                    <Grid.Row>
                                        <GridColumn>
                                            <Popup trigger={<Button basic icon="add" circular
                                                                    disabled={lines && !lines.length && !!(lines.filter(i => !i.partID)) &&
                                                                    Boolean((!receiveWareValue && !deliveryWareValue)
                                                                        || (!receiveWareValue && deliveryWareValue) || (receiveWareValue && !deliveryWareValue)
                                                                        || (receiveWareValue && deliveryWareValue && deliveryWareValue === receiveWareValue))}
                                                                    onClick={addLine}/>}
                                                   content="Przenieś kolelną część"/>
                                        </GridColumn>
                                    </Grid.Row>
                                </Grid>
                            </DetailCard>
                        </Form>
                    </Container>
                </Modal.Content>
                <Modal.Actions>
                    <Button disabled={!!errorMessage}
                            primary content="Potwierdź przesunięcie" onClick={() => {
                        setOpenConfirmModal(true);
                        setCancel(true);
                    }}/>
                    <Button content="Anuluj" onClick={() => {
                        closeMoveWareModal()
                    }}/>
                </Modal.Actions>
            </Modal>
            {openConfirmModal && lines.length && deliveryWareValue && receiveWareValue && deliveryWareValue !== receiveWareValue
            && !!(lines.filter(i => !i.partID)) &&
            <Modal
                className="ConfirmModal"
                closeOnDimmerClick={false}
                onClose={() => {
                    setOpenConfirmModal(false);
                    setConfirmCancel(true);
                    closeMoveWareModal()
                }}
                open={openConfirmModal}
                size='small'
            >
                <Modal.Content className="ConfirmModalContent">
                    Czy chcesz na pewno przenieść część do innego magazynu?
                </Modal.Content>
                <Modal.Actions>
                    <Button primary content="Kontynuuj" onClick={() => {
                        handleSubmit();
                        setOpenConfirmModal(false);
                        setCancel(false);
                        closeMoveWareModal();
                    }}/>
                    <Button content="Anuluj" onClick={() => {
                        setOpenConfirmModal(false);
                        setCancel(false);
                    }}/>
                </Modal.Actions>
            </Modal>}
            <CommonLoader loading={loading ? loading : false}/>
        </Fragment>
    ), [distrigoWarehouses, deliveryWareValue, lines, openConfirmModal, receiveWareValue,
        addLine, changeQuantity, changeReferenceId, clearDeliveryWareValue, clearReceiveWareValue, closeMoveWareModal, errorMessage, handleDeliveryWarehouseChange, handleReceiveWarehouseChange, handleSubmit,
        loading, mapDistrigoWarehouses, maximized, open, removeLine, setCancel, setConfirmCancel, setOpenConfirmModal, tableEl, toggleMaximized, validNumber
    ])
}

const mapStateToProps = ({ distrigoWarehouses }: ApplicationState) => ({
    distrigoWarehouses: distrigoWarehouses.list,
    distrigoStockWarehouses: distrigoWarehouses.stockItemsList,
    loading: distrigoWarehouses.loadingDistrigoWarehouses,
})

export default connect(mapStateToProps, null)(MoveWareContent);
