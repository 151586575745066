import React from 'react';
import { Button, Popup } from 'semantic-ui-react';
import { ImportXlsPopupProps } from '~/pages/Orders/lib/Add/constants';

const ImportXlsPopup = ({ handleImportXls, order, summary }: ImportXlsPopupProps) => (
  <Popup
    trigger={<Button circular basic icon="file excel" onClick={handleImportXls} disabled={!order.type || summary} />}
    position="bottom right"
    content="Importuj linie z pliku XLSX, XLS, CSV"
  />
);

export default ImportXlsPopup;
