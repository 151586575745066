import { Reducer } from "redux";
import { Customer, CustomersActionTypes } from "~/store/customer/types";
import { ErrorActionTypes } from "~/store/error/types";
import { Paginable } from "~/store/types";
import {
  Order,
  OrdersActionTypes,
  OrdersState,
  PaginableOrderLine,
} from "./types";

export const initialOrdersState: OrdersState = {
  loadingOrders: false,
  loadingOrder: false,
  loadingLines: false,
  list: [],
  searchList: [],
  meta: {
    page: 1,
    pages: 1,
    total: 0,
    size: 15,
  },
  selected: undefined,
  created: undefined,
  creating: false,
  promoApplied: false,
  promoOrder: null,
  linesMeta: {
    page: 1,
    pages: 2,
    total: 0,
    size: 15,
  },
  selectedLines: [],
  parsedLines: [],
  orderBonusLines: [],
  ordersDateFrom: undefined,
  ordersDateTo: undefined,
  ordersReferenceId: undefined,
  loadingBonusOrder: false,
  totalRecords: {
    total: 0,
    filtered: 0,
  },
  operationSuccess: false,
  searchableCustomers: [],
  selectedTypeOfDataToExport: undefined,
  isOpenChooseTypeOfExportDataModal: false,
};

const reducer: Reducer<OrdersState> = (
  state = initialOrdersState,
  action
): OrdersState => {
  switch (action.type) {
    case OrdersActionTypes.FETCH_REQUEST: {
      return {
        ...state,
        loadingOrders: true,
        created: undefined,
        promoApplied: false,
        list: [],
      };
    }
    case OrdersActionTypes.FETCH_SUCCESS: {
      const payload = action.payload as Paginable<Order>;
      return {
        ...state,
        loadingOrders: false,
        list: payload.content,
        searchList: payload.content,
        meta: {
          ...state.meta,
          page: payload.number + 1,
          pages: payload.totalPages,
          size: payload.size,
        },
        totalRecords: {
          total: payload.totalElements,
          filtered: payload.filteredElements,
        },
      };
    }
    case OrdersActionTypes.FETCH_SEARCH_SUCCESS: {
      return { ...state, searchList: action.payload.orders.content };
    }
    case OrdersActionTypes.SELECT_ORDER: {
      return { ...state, loadingOrder: true };
    }
    case OrdersActionTypes.SELECTED: {
      return { ...state, loadingOrder: false, selected: action.payload };
    }
    case OrdersActionTypes.PLACE_ORDER: {
      return { ...state, created: undefined, creating: true };
    }
    case OrdersActionTypes.PLACE_ORDER_SUCCESS: {
      return { ...state, created: action.payload, creating: false };
    }
    case OrdersActionTypes.CANCEL_ORDER: {
      return { ...state, created: undefined, creating: true };
    }
    case OrdersActionTypes.CANCEL_ORDER_SUCCESS: {
      return { ...state, created: action.payload, creating: false };
    }
    case OrdersActionTypes.CLEAR_CREATED: {
      return { ...state, created: undefined, creating: false };
    }
    case CustomersActionTypes.FETCH_SUCCESS: {
      return {
        ...state,
        searchableCustomers: action.payload.content.map((c: Customer) => {
          return {
            key: c.rrdi,
            title: `${c.informationContactSurname || ""} ${
              c.informationContactName || ""
            } ${c.informationAddressCompany || ""}`,
            description: `${c.informationAddressStreet || ""} ,
                        ${c.informationAddressNumber || ""}, ${
              c.informationAddressZipcode || ""
            },
                        ${c.informationAddressCity || ""}, ${
              c.informationAddressCountry || ""
            }`,
            price: c.rrdi,
          };
        }),
      };
    }
    case OrdersActionTypes.CLEAR_SELECTED: {
      return { ...state, selected: undefined };
    }
    case OrdersActionTypes.CHANGE_STATUS_ORDER_SUCCESS: {
      const orderIndex = state.list.findIndex((x) => x.id === action.payload);
      let list = [...state.list];

      if (list.length > 0 && action.meta.onList) {
        list = list.map((item, index) =>
          index === orderIndex ? { ...item, status: action.meta.status } : item
        );

        return {
          ...state,
          list,
        };
      } else {
        return {
          ...state,
          selected: {
            ...(state.selected as Order),
            status: action.meta.status,
          },
        };
      }
    }
    case OrdersActionTypes.APPLY_PROMO: {
      return { ...state, creating: true };
    }
    case OrdersActionTypes.PROMO_APPLIED: {
      return {
        ...state,
        creating: false,
        promoApplied: true,
        promoOrder: action.payload,
      };
    }
    case OrdersActionTypes.CLEAR_PROMO: {
      return { ...state, promoApplied: false, promoOrder: null };
    }
    case OrdersActionTypes.FETCH_LINES:
    case OrdersActionTypes.ADD_LINE:
    case OrdersActionTypes.UPDATE_LINE:
    case OrdersActionTypes.DELETE_LINE:
      return { ...state, loadingLines: true, operationSuccess: false };
    case OrdersActionTypes.LOAD_XLS:
    case OrdersActionTypes.LOAD_XLS_SPECIAL:
    case OrdersActionTypes.LOAD_CSV:
      return { ...state, loadingLines: true, operationSuccess: false };
    case OrdersActionTypes.LOAD_LINES_LOADED:
      return { ...state, loadingLines: false, parsedLines: action.payload };
    case OrdersActionTypes.FETCH_LINES_SUCCESS: {
      const payload = action.payload as PaginableOrderLine;
      return {
        ...state,
        loadingLines: false,
        selectedLines: payload.content,
        linesMeta: {
          page: payload.number + 1,
          pages: payload.totalPages,
          size: payload.size,
          total: payload.totalElements,
        },
      };
    }
    case OrdersActionTypes.LINES_OPERATION_FINISHED: {
      return {
        ...state,
        loadingLines: false,
        operationSuccess: action.payload,
      };
    }
    case ErrorActionTypes.HANDLE_ERROR: {
      return {
        ...state,
        loadingOrder: false,
        loadingOrders: false,
        loadingBonusOrder: false,
        creating: false,
        loadingCancelLines: false,
      };
    }
    case OrdersActionTypes.UPLOAD_BONUS_ORDERS: {
      return { ...state, loadingBonusOrder: true };
    }
    case OrdersActionTypes.UPLOAD_BONUS_ORDERS_SUCCESS: {
      return {
        ...state,
        orderBonusLines: action.payload,
        loadingBonusOrder: false,
      };
    }

    case OrdersActionTypes.UPLOAD_CANCEL_LINES: {
      return { ...state, loadingCancelLines: true };
    }

    case OrdersActionTypes.UPLOAD_CANCEL_LINES_SUCCESS: {
      return { ...state, loadingCancelLines: false };
    }

    case OrdersActionTypes.CANCEL_LINES: {
      return { ...state, loadingCancelLine: true };
    }

    case OrdersActionTypes.CANCEL_LINES_SUCCESS: {
      return { ...state, created: action.payload, loadingCancelLine: false };
    }
    case OrdersActionTypes.UPLOAD_BONUS_ORDERS_CLEAR: {
      return { ...state, orderBonusLines: [] };
    }
    case OrdersActionTypes.SET_TYPE_OF_EXPORT_DATA: {
      return { ...state, selectedTypeOfDataToExport: action.payload };
    }
    case OrdersActionTypes.OPEN_CHOOSE_EXPORT_DATA_MODAL: {
      return { ...state, isOpenChooseTypeOfExportDataModal: action.payload };
    }
    case OrdersActionTypes.SAVE_DATA_FROM_SEARCHFORM: {
      return { ...state, ordersDateFrom: action.payload };
    }
    case OrdersActionTypes.SAVE_DATA_TO_SEARCHFORM: {
      return { ...state, ordersDateTo: action.payload };
    }
    case OrdersActionTypes.SAVE_REFERENCE_ID_SEARCHFORM: {
      return { ...state, ordersReferenceId: action.payload };
    }
    default: {
      return state;
    }
  }
};

export { reducer as ordersReducer };
