import React from "react";
import { Link } from "react-router-dom";
import { Button, Grid } from "semantic-ui-react";
import { IClaim } from "~/store/claims/types";
import translations from "~/utils/translations";
import { WZPopup } from "../WZPopup";
import { ServiveInvoicesPopup } from "./ServiceInvoicesPopup";
import GoodsReceivedNotesDetails from "~/pages/Accounting/Details/GoodsReceivedNotes";
import { DocumentCardContentProps } from "../constants";

export const DocumentCardContent = ({
  claim,
  renderLabel,
  getInvoicePdf,
  getClaimLabel,
}: DocumentCardContentProps) => {
  const onClickGetClaimLabel = (claim: IClaim) =>
    getClaimLabel(claim.claimNumber, `${claim.claimNumber}-label.pdf`);

  return (
    <>
      {renderLabel(
        translations.format("app.invoice.number"),
        claim.invoiceId ? (
          <Button
            onClick={() =>
              getInvoicePdf(
                claim.invoiceNumber,
                `${claim.invoiceDocumentMask}.pdf`
              )
            }
            style={{ display: "block" }}
            compact
            basic
          >
            {claim.invoiceDocumentMask}
          </Button>
        ) : (
          claim.customInvoiceNumber || ""
        )
      )}
      {renderLabel(
        translations.format("app.invoice.correction.number"),
        claim.invoiceCorrectionNumber && (
          <Button
            onClick={() =>
              getInvoicePdf(
                claim.invoiceCorrectionNumber,
                `${claim.invoiceCorrectionDocumentMask}.pdf`
              )
            }
            style={{ display: "block" }}
            compact
            basic
          >
            {claim.invoiceCorrectionDocumentMask}
          </Button>
        )
      )}
      {renderLabel(
        "WZ",
        claim.gdnNumbers && (
          <>
            {claim.gdnNumbers.length}
            <WZPopup claim={claim} />
          </>
        )
      )}
      {renderLabel(
        "PZ",
        claim.grnNumber && (
          <GoodsReceivedNotesDetails noteId={claim.grnNumber}>
            <Button style={{ display: "block" }} compact basic>
              {claim.grnNumber}
            </Button>
          </GoodsReceivedNotesDetails>
        )
      )}
      {renderLabel(
        translations.format("app.wms.return.code"),
        claim.wmsReturnCode
      )}
      {renderLabel(
        translations.format("app.service.orders"),
        claim.serviceOrderNumber && (
          <Button
            content={claim.serviceOrderNumber}
            basic
            compact
            as={Link}
            to={`/order/${claim.serviceOrderId}`}
          />
        )
      )}
      {renderLabel(
        translations.format("app.service.invoices"),
        claim.serviceInvoices && (
          <>
            {claim.serviceInvoices.length}
            <ServiveInvoicesPopup claim={claim} getInvoicePdf={getInvoicePdf} />
          </>
        )
      )}
      {claim.backInTheBox &&
        renderLabel(
          translations.format("Nr sprawy Back in the Box"),
          claim.backInTheBox
        )}
      <Grid columns={2}>
        <Grid.Row style={{ paddingBottom: "0px", paddingTop: "10px" }}>
          <Grid.Column width={6}>
            <Button onClick={() => onClickGetClaimLabel(claim)} compact basic>
              {translations.format("app.claim.return.label")}
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};
