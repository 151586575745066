import React from "react";
import { NavLink } from "react-router-dom";
import { Menu, Sidebar, Button, Divider } from "semantic-ui-react";
import { itemStyle } from "~/components/Sidebar/constants";
import MenuTitle from "~/components/Sidebar/MenuTitle";
import { useSidebarRolesRights } from "./hooks/useSidebarRolesRights";
import { useManualOrdersBadge } from "./hooks/useManualOrdersBadge";

const SideBarMenu: React.FC = () => {
  const {
    isAccountingViewRole,
    isAdministrationViewRole,
    isAnomalyViewRole,
    isBackorderViewRole,
    isClaimsViewRole,
    isCustomerViewRole,
    isOrderViewRole,
    isProductViewRole,
    isPromotionViewRole,
    isReportsViewRole,
    isWarehouseViewRole,
    menuVisibility,
  } = useSidebarRolesRights();

  const { manualOrdersCount } = useManualOrdersBadge({ isOrderViewRole });

  return (
    <Sidebar
      color="orange"
      as={Menu}
      animation="push"
      direction="left"
      vertical
      style={{ backgroundColor: "#E0E1E2" }}
      visible={menuVisibility}
    >
      <Button.Group vertical labeled icon fluid compact size="medium">
        <Button
          style={itemStyle}
          as={NavLink}
          to="/dashboard"
          icon="home"
          content={<MenuTitle id="app.dashboard" />}
        />
        {(isOrderViewRole || isClaimsViewRole || isCustomerViewRole) && (
          <Divider />
        )}
        {isOrderViewRole && (
          <Button
            style={itemStyle}
            as={NavLink}
            to="/orders"
            icon="shop"
            content={<MenuTitle id="app.orders" />}
          />
        )}
        {isOrderViewRole && (
          <Button
            style={itemStyle}
            as={NavLink}
            to="/manual-orders"
            icon="tag"
            content={
              <MenuTitle id="app.manual-orders" badge={manualOrdersCount} />
            }
          />
        )}
        {isClaimsViewRole && (
          <Button
            style={itemStyle}
            as={NavLink}
            to="/claims"
            icon="exclamation triangle"
            content={<MenuTitle id="app.claims" />}
          />
        )}
        {isCustomerViewRole && (
          <Button
            style={itemStyle}
            as={NavLink}
            to="/customers"
            icon="users"
            content={<MenuTitle id="app.customers" />}
          />
        )}
        {(isWarehouseViewRole || isAnomalyViewRole || isBackorderViewRole) && (
          <Divider />
        )}
        {isWarehouseViewRole && (
          <Button
            style={itemStyle}
            as={NavLink}
            to="/distrigo-warehouses"
            icon="warehouse"
            content={<MenuTitle id="app.distrigoStore" />}
          />
        )}
        {isAnomalyViewRole && (
          <Button
            style={itemStyle}
            as={NavLink}
            to="/anomalies"
            icon="exclamation circle"
            content={<MenuTitle id="app.anomalies" />}
          />
        )}
        {isBackorderViewRole && (
          <Button
            style={itemStyle}
            as={NavLink}
            to="/backorders"
            icon="shop"
            content={<MenuTitle id="app.backorders" />}
          />
        )}
        {(isAccountingViewRole || isPromotionViewRole || isProductViewRole) && (
          <Divider />
        )}
        {isAccountingViewRole && (
          <Button
            style={itemStyle}
            as={NavLink}
            to="/accounting"
            icon="briefcase"
            content={<MenuTitle id="app.bookkeeping" />}
          />
        )}
        {isPromotionViewRole && (
          <Button
            style={itemStyle}
            as={NavLink}
            to="/promotions"
            icon="star"
            content={<MenuTitle id="app.promotions" />}
          />
        )}
        {isProductViewRole && (
          <Button
            style={itemStyle}
            as={NavLink}
            to="/products"
            icon="tag"
            content={<MenuTitle id="app.products" />}
          />
        )}
        {(isReportsViewRole || isAdministrationViewRole) && <Divider />}
        {isReportsViewRole && (
          <Button
            style={itemStyle}
            as={NavLink}
            to="/reports"
            icon="chart bar"
            content={<MenuTitle id="app.reports" />}
          />
        )}
        {isAdministrationViewRole && (
          <Button
            style={itemStyle}
            as={NavLink}
            to="/administration"
            icon="settings"
            content={<MenuTitle id="app.administration" />}
          />
        )}
      </Button.Group>
    </Sidebar>
  );
};

export default SideBarMenu;
