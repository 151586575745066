import { handleFileUploadError } from "~/utils/fileUploadErrorHandler";
import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import {
  ClaimsActionTypes,
  CollectiveTaskUploaded,
  IClaim,
  PaginableClaim,
} from "./types";
import {
  fetchClaims,
  fetchClaimsSuccess,
  fetchClaimsSearchSuccess,
  createClaim,
  createClaimSuccess,
  selectClaim,
  claimSelected,
  getAttachment,
  addAttachmentSuccess,
  createClaimTransition,
  createClaimTransitionSuccess,
  addAttachments,
  uploadCollectiveTask,
  uploadCollectiveTaskSuccess,
  getClaimLabel,
  fetchBackBoxReport,
  fetchBackBoxReportSuccess,
} from "./actions";
import { handleError } from "~/store/error/actions";
import { toastSuccess, toastError } from "~/utils/toast";
import { downloadFile } from "~/utils/downloadFile";
import {
  callApiUploadFiles,
  callApi,
  callApiDownload,
  callApiUploadFile,
} from "../../utils/api";
import translations from "~/utils/translations";
import { ApiError } from "../error/types";

const getEndecodedClaimNumber = (claimNumber: string) =>
  claimNumber.replaceAll("/", "-");

function* handleGetClaimLabel(action: ReturnType<typeof getClaimLabel>) {
  try {
    const claimNumberEncoded = getEndecodedClaimNumber(action.payload.claimNo);
    const res: Response = yield call(
      callApiDownload,
      "get",
      `/claims/${claimNumberEncoded}/return-label`
    );

    downloadFile(res, action.payload.pdfName);
  } catch (error) {
    yield put(
      handleError(error, false, translations.format("app.download.error.info"))
    );
  }
}

function* handleFetchClaims(action: ReturnType<typeof fetchClaims>) {
  try {
    let filters = "";
    let sort = "";
    action.payload.filters.forEach((filter: any) => {
      filters += `&${filter.name}=${filter.key ? filter.key : filter.value}`;
    });

    if (action.payload.sortColumn && action.payload.sortDirection) {
      sort = `&sort=${action.payload.sortColumn},${action.payload.sortDirection}`;
    }

    const claims: PaginableClaim = yield call(
      callApi,
      "get",
      `/claims?page=${action.payload.page - 1}&size=${
        action.payload.size
      }${filters}${sort}`
    );
    const claimsSearchSuccess: PaginableClaim = yield put(
      fetchClaimsSearchSuccess(claims)
    );
    const claimSuccess: PaginableClaim = yield put(fetchClaimsSuccess(claims));
    return action.type.toString() === ClaimsActionTypes.FETCH_CLAIMS_SEARCH
      ? claimsSearchSuccess
      : claimSuccess;
  } catch (error) {
    yield put(handleError(error, false, "Nie można pobrać reklamacji"));
  }
}

function* handleCreateClaim(action: ReturnType<typeof createClaim>) {
  try {
    yield call(callApi, "post", "/claims/", action.payload.claim);
    yield put(createClaimSuccess());
  } catch (e) {
    const error = e as ApiError;
    if (error.status === 400) {
      yield put(
        handleError(
          error,
          false,
          "Nie udało się dodać reklamacji",
          "Co najmniej jedna linia reklamacji przekracza ilość zafakturowaną dla wszystkich aktywnych reklamacji."
        )
      );
    } else if (
      error.message.includes(
        "There are claim lines with exceeded invoiced quantity"
      )
    ) {
      let lines = error.message
        .split("(")
        .filter((text: string) => text.includes("quantity left to be claim"));
      lines = lines.map((line: string) => line.split(" "));
      const message = (line: string[]): string => {
        return translations.format("app.add-claim.lines-error", {
          lineNr: line[1],
          quantity: line[8].replace(")", "").replace(",", ""),
        });
      };
      lines = lines.map((line: string[]) => message(line));
      const messages: string = lines.join("; ") + ";";
      yield put(
        handleError(
          error,
          false,
          translations.format("app.add-claim.error"),
          messages
        )
      );
    } else if (error.message.includes("does not have line with reference id")) {
      const cuttedMessage = error.message.split(" ");
      const message = translations.format("app.add-claim.references-error", {
        invoiceNr: cuttedMessage[1],
        referenceId: cuttedMessage[9],
      });
      yield put(
        handleError(
          error,
          false,
          translations.format("app.add-claim.error"),
          message
        )
      );
    } else {
      yield put(
        handleError(
          error,
          false,
          translations.format("app.add-claim.error"),
          error.message || ""
        )
      );
    }
  }
}

function* handleCreateClaimTransition(
  action: ReturnType<typeof createClaimTransition>
) {
  try {
    const claimTransition = {
      action: action.payload.statusAction,
      lines: action.payload.lines,
      statusNotes: action.payload.statusNotes,
    };
    const claimNumberEncoded = action.payload.claimNumber.replaceAll("/", "-");
    yield call(
      callApi,
      "post",
      `/claims/${claimNumberEncoded}/transition`,
      claimTransition
    );
    yield put(createClaimTransitionSuccess());
    toastSuccess(
      "Pomyślnie zaktualizowano reklamacje",
      "Tranzycja wykonana poprawnie"
    );
  } catch (e) {
    const error = e as Error;
    let errorDescription = "";
    if (error.message === "UNSUPPORTED_PAYMENT_METHOD_FOR_CUSTOMER") {
      errorDescription =
        "Metoda płatności ustawiona dla tego klienta nie jest obsługiwana";
    }
    yield put(
      handleError(
        error,
        false,
        "Nie udało się zmienić stanu reklamacji",
        errorDescription
      )
    );
  }
}

function* handleSelectClaim(action: ReturnType<typeof selectClaim>) {
  try {
    const claimNumberEncoded = action.payload.replaceAll("/", "-");
    const claim: IClaim = yield call(
      callApi,
      "get",
      `/claims/${claimNumberEncoded}`
    );
    yield put(claimSelected(claim));
  } catch (error) {
    yield put(
      handleError(
        error,
        true,
        "Błąd podczas wyświetlania szczegółów reklamacji"
      )
    );
  }
}

function* handleGetAttatchment(action: ReturnType<typeof getAttachment>) {
  try {
    const claimNumberEncoded = action.payload.claimNumber.replaceAll("/", "-");
    const res: Response = yield call(
      callApiDownload,
      "get",
      `/claims/${claimNumberEncoded}/attachments/${action.payload.attachmentStoredName}`,
      action.payload.attachmentStoredName
    );

    downloadFile(res, action.payload.attachmentStoredName);
  } catch (error) {
    yield put(
      handleError(
        error,
        false,
        "Błąd podczas wyświetlania szczegółów reklamacji"
      )
    );
  }
}

function* handleAddAttachments(action: ReturnType<typeof addAttachments>) {
  try {
    if (action.payload.attachments.length > 0) {
      const claimNumberEncoded = action.payload.claimNumber.replaceAll(
        "/",
        "-"
      );

      yield call(
        callApiUploadFiles,
        `/claims/${claimNumberEncoded}/attachments`,
        action.payload.attachments,
        "attachments"
      );

      yield put(addAttachmentSuccess());

      toastSuccess("Pomyślnie wgrano plik", "Załącznik wgrany poprawnie");
    } else {
      yield put(
        handleError(
          {
            method: "POST",
            name: "Wgrywanie plików niemożliwe",
            message: "",
          } as Error,
          false,
          "Wybierz przynajmniej jeden plik do wgrania",
          "Żaden plik nie został wybrany"
        )
      );
    }
  } catch (error) {
    yield handleFileUploadError(error);
  }
}

function* handleUploadCollectiveTask(
  action: ReturnType<typeof uploadCollectiveTask>
) {
  try {
    // const res = yield call(callApiUploadFile, `/claims?bulk`, action.payload.collectiveTaskData, 'file', 'hero')
    const res: CollectiveTaskUploaded = yield call(
      callApiUploadFile,
      `/claims?bulk`,
      action.payload.file,
      "file",
      action.payload.collectiveTaskData
    );
    yield put(uploadCollectiveTaskSuccess(res.claimsCreated, res.errors));
    if (res.errors.length === 0) {
      toastSuccess("Pomyślnie wgrano plik", "Faktury wgrane poprawnie");
    } else {
      toastError(
        "Błąd podczas wgrywania reklamacji",
        "Wystąpił przynajmniej jeden błąd w wgrywaniu reklamacji"
      );
    }
  } catch (error) {
    yield handleFileUploadError(error);
  }
}

function* handleDownloadBackInTheBoxReport(
  action: ReturnType<typeof fetchBackBoxReport>
) {
  try {
    const { dateFrom, dateTo } = action.payload;
    const fileName = "Raport Back in th Box";
    const res: Response = yield call(
      callApiDownload,
      "get",

      `/claims/back-in-the-box/csv/${dateFrom}/${dateTo}`,
      fileName
    );

    if (res) {
      downloadFile(res, fileName + ".csv");
      yield put(fetchBackBoxReportSuccess());
      toastSuccess("Pomyślnie wyeksportowano plik", "");
    }
  } catch (error) {
    yield put(
      handleError(
        error as Error,
        false,
        translations.format("app.download-failed")
      )
    );
    yield put(fetchBackBoxReportSuccess());
  }
}

function* watchGetClaimLabel() {
  yield takeEvery(ClaimsActionTypes.GET_CLAIM_LABEL, handleGetClaimLabel);
}

function* watchFetchClaims() {
  yield takeEvery(ClaimsActionTypes.FETCH_CLAIMS, handleFetchClaims);
}

function* watchFetchClaimsSearch() {
  yield takeEvery(ClaimsActionTypes.FETCH_CLAIMS_SEARCH, handleFetchClaims);
}

function* watchCreateClaim() {
  yield takeLatest(ClaimsActionTypes.CREATE_CLAIM, handleCreateClaim);
}

function* watchSelectClaim() {
  yield takeEvery(ClaimsActionTypes.SELECT_CLAIM, handleSelectClaim);
}

function* watchGetAttachment() {
  yield takeEvery(ClaimsActionTypes.GET_ATTACHMENT, handleGetAttatchment);
}

function* watchAddAttachment() {
  yield takeEvery(ClaimsActionTypes.ADD_ATTACHMENT, handleAddAttachments);
}

function* watchCreateClaimTransition() {
  yield takeEvery(
    ClaimsActionTypes.CREATE_CLAIM_TRANSITION,
    handleCreateClaimTransition
  );
}

function* watchUploadCollectiveTask() {
  yield takeEvery(
    ClaimsActionTypes.UPLOAD_COLLECTIVE_TASK,
    handleUploadCollectiveTask
  );
}

function* watchDownloadBackInTheBoxReport() {
  yield takeEvery(
    ClaimsActionTypes.FETCH_BACK_BOX_REPORT,
    handleDownloadBackInTheBoxReport
  );
}

function* claimsSaga() {
  yield all([
    fork(watchGetClaimLabel),
    fork(watchFetchClaims),
    fork(watchCreateClaim),
    fork(watchFetchClaimsSearch),
    fork(watchSelectClaim),
    fork(watchAddAttachment),
    fork(watchGetAttachment),
    fork(watchCreateClaimTransition),
    fork(watchUploadCollectiveTask),
    fork(watchDownloadBackInTheBoxReport),
  ]);
}

export default claimsSaga;
