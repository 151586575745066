import { connect } from 'react-redux'
import { Button, Form, FormGroup, Header, Modal } from 'semantic-ui-react'
import { ModelFormWrapper } from '~/components/ModelForm/ModelFormWrapper'
import { ModelInput } from '~/components/ModelForm/ModelInput'
import { SubmitButton } from '~/components/ModelForm/SubmitButton'
import { EMAIL, MAX, MIN, REQUIRED } from '~/components/SmartField/lib/validator'
import { ApplicationState } from '~/store'
import { addUser, clearCreated, fetchPasswordPolicies } from '~/store/users/actions'
import { PasswordPolicy, UserBase } from '~/store/users/types'

import React, { useEffect, useState } from 'react'
import { ModelMessage } from "~/components/ModelForm/ModelMessage";

type TReduxState = {
    created?: boolean
    creating?: boolean,
    passwordPolicies: PasswordPolicy[]
}

type TReduxActions = {
    addUser: typeof addUser
    clearCreated: typeof clearCreated,
    fetchPasswordPolicies: typeof fetchPasswordPolicies
}

type TProps = {
    triggerOpen: boolean
    createdSuccess: () => void
}

type TAddContractorProps = TReduxState & TReduxActions & TProps


const AddUser: React.FC<TAddContractorProps> = ({
    children,
    triggerOpen,
    created,
    creating,
    passwordPolicies,
    addUser,
    createdSuccess,
    clearCreated,
    fetchPasswordPolicies
}) => {

    const [open, setOpen] = useState(false)

    useEffect(() => {
        if (triggerOpen) {
            setOpen(true)
        }
    }, [triggerOpen])

    useEffect(() => {
        fetchPasswordPolicies()
        // eslint-disable-next-line
    }, [])


    const handleConfirm = (user: UserBase): void => {
        if (!creating) {
            addUser({
                email: user.email,
                username: user.username,
                firstName: user.firstName,
                lastName: user.lastName,
                emailVerified: true,
                enabled: true,
                credentials: [
                    { type: 'password', value: user.password ? user.password : '' }
                ]
            })
        }
    }

    const handleCancel = (): void => {
        if (!creating) {
            setOpen(false)
        }
    }

    const handleOpen = (): void => {
        setOpen(true)
    }

    if (created && open) {
        clearCreated()
        setOpen(false)
        createdSuccess()
    }

    return (
        <Modal
            size="tiny"
            trigger={children}
            open={open}
            onOpen={handleOpen}
            onClose={handleCancel}
            closeOnDimmerClick={false}
        >
            <ModelFormWrapper<UserBase>
                onSubmit={handleConfirm}>
                <Header icon='user add' content='Dodaj użytkownika' />
                <Modal.Content>
                    <Form style={{ padding: 10 }}>
                        <Form.Group widths="equal">
                            <ModelInput<UserBase>
                                label="E-mail"
                                type="email"
                                placeholder="Adres e-mail użytkownika"
                                name="email"
                                validators={[REQUIRED, MAX(100), EMAIL]}/>
                        </Form.Group>
                       <FormGroup widths="equal">
                           <ModelInput<UserBase>
                               label="Imię"
                               placeholder="Imię użytkownika"
                               name="firstName"
                               validators={[REQUIRED, MAX(30)]} />
                           <ModelInput<UserBase>
                               label="Nazwisko"
                               placeholder="Nazwisko użytkownika"
                               name="lastName"
                               validators={[REQUIRED, MAX(30)]} />
                       </FormGroup>
                        <ModelInput<UserBase>
                            label="Nazwa"
                            placeholder="Nazwa użytkownika"
                            name="username"
                            validators={[REQUIRED, MAX(30)]} />
                        <ModelInput<UserBase>
                            label="Hasło"
                            type="password"
                            placeholder="Hasło użytkownika"
                            name="password"
                            validators={[REQUIRED, MIN(8)]}
                        />
                        <ModelMessage headline="Hasło:" passwordPolicies={passwordPolicies} name="message"/>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <SubmitButton content="Dodaj" icon="add" labelPosition="right" primary loading={creating} />
                    <Button content="Anuluj" onClick={handleCancel} />
                </Modal.Actions>
            </ModelFormWrapper>
        </Modal>
    )
}

const mapStateToProps: (state: ApplicationState) => TReduxState = ({ users }: ApplicationState) => {
    return {
        created: users.created,
        creating: users.creating,
        passwordPolicies: users.passwordPolicies
    }
}

const mapDispatchToProps: TReduxActions = { addUser, clearCreated, fetchPasswordPolicies }

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddUser)